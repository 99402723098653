/**
 * Module dependencies.
 */
import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';

/**
 * Define the initial state for the PERMISSIONS feature.
 * Extends from the common initial state (baseInitState) for all features,
 * with specific initial configurations for the PERMISSIONS feature.
 * @type {object}
 */
const initialState = {
  ...baseInitState,
  filter: {
    ...baseInitState.filter,
    role: 'admin',
    only_role: true,
    client_id: null,
  },
};

/**
 * Exports the constants for the PERMISSIONS feature.
 * We get this by invoking the getConstantsByFeatureName helper function
 * @type {FeatureCommonConstants}
 */
export const constants = getConstantsByFeatureName('PERMISSIONS');

/**
 * Exports the action creators for the PERMISSIONS feature.
 * We get this by invoking the getActionCreators helper function
 * @type {object}
 */
export const actions = getActionCreators(constants);

/**
 * Exports the action handlers for the PERMISSIONS feature.
 * We get this by invoking the getActionHandlers helper function
 * @type {object}
 */
export const actionHandlers = getActionHandlers(constants);

/**
 * Exports the reducer for the PERMISSIONS feature.
 * It's created by passing the actionHandlers and initialState to the handleActions function
 * @type {Function}
 */
export const reducer = handleActions(actionHandlers, initialState);
