import * as api from './adapter';

export default {
 /**
   * star User
   * @param user_id
   * @returns {Promise}
   */
starUser: (user_id) => api.apiRequest(
    '/api/favorites/star_user',
    { method: 'POST', body: JSON.stringify({ user_id }) },
  ),
}
