import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#0078FF",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#f29c27",
      contrastText: "#FFF",
    },
    text: {
      primary: "#8c8c8c",
      secondary: "#8c8c8c",
    },
    taskItem: {
      to_do: "#ff9800",
      active: "#2196f3",
      review: "#7e57c2",
      done: "#4caf50",
      failed: "#f44336",
      excluded: "#ffb8c6",
    },
  },
  typography: { useNextVariants: true },
  overrides: {
    MuiMenuItem: {
      root: {
        selected: {
          backgroundColor: "#eee",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
