import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles({
  dialog: {
    minWidth: "90vw",
    minHeight: "90vh",
  },
  fab: {
    position: "fixed",
    top: "0.65em",
    right: "0.65em",
    boxShadow: "none",
  },
  frameRoot: {
    height: "90%",
  },
});

const frameContainerStyles = { height: "90vh" };

const CrewisorGuide = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Help">
        <IconButton onClick={handleClickOpen} color="primary">
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="90vw"
        fullWidth={true}
      >
        <div style={frameContainerStyles}>
          <iframe
            src="https://docs.google.com/document/d/1LvL6Lj41N-yF0AkepY_YqIU1ZEV_WxtZgcBBtKUSz4k/edit?usp=sharing"
            title="Help"
            width="100%"
            height="100%"
          />
        </div>
        <Fab
          aria-label={"Close"}
          onClick={handleClose}
          size="medium"
          className={classes.fab}
        >
          <CloseIcon />
        </Fab>
      </Dialog>
    </>
  );
};

export default CrewisorGuide;
