import * as api from './adapter';

const requestParams = { query: { include: 'author', sort: '-created_at' } };

export default {
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/app_form_sessions/${id}`),
  /**
   * createAppFormSession
   * @param attributes
   * @returns {Promise}
   */
  create: async ({...attributes }) => {
    const resp = await api.create({ type: 'app_form_sessions', attributes });
    return resp;
  },
  /**
   * updateAppFormSession
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, ...attributes) => {
    const resp = await api.update({ id, type: 'app_form_sessions', attributes });
    return resp;
  },
  /**
   * deleteAppFormSession
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'app_form_sessions' }),
};
