import React from 'react';
import PropTypes from 'prop-types';
import DateUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const DateUtilsProvider = ({ children, utils }) => (
  <MuiPickersUtilsProvider utils={utils}>
    {children}
  </MuiPickersUtilsProvider>
);

DateUtilsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  utils: PropTypes.any,
};

DateUtilsProvider.defaultProps = {
  utils: DateUtils,
};

export default DateUtilsProvider;
