import get from 'lodash/get';

export const isUserDataChanged = (payload = {}) => {
  const { readiness_date, crewing_status, ukr_crewing_id, wage, hot } = payload;

  return readiness_date || crewing_status || ukr_crewing_id || wage || (hot === true || hot === false);
}

export const commentData = ({
  userId,
  comment,
  category,
  resource = 'users',
}, author_id) => ({
  body: comment,
  author_id,
  userId,
  resource,
  category,
});

export const commentEventData = ({
  category,
  event_result,
  event_result1,
  event_result2,
  comment_source,
}, comment_id) => ({
  comment_id,
  event_type: category,
  event_result,
  event_result1,
  event_result2,
  comment_source_id: get(comment_source, 'value')
});

export const userData = ({
  readiness_date,
  crewing_status,
  ukr_crewing_id,
  crewell_id,
  maritime_zone_id,
  wage,
  hot,
}) => ({
  readiness_date,
  crewing_status_id: get(crewing_status, 'value'),
  ukr_crewing_id,
  crewell_id,
  maritime_zone_id,
  wage,
  hot,
});
