import { call, put, select, takeEvery } from 'redux-saga/effects';
import { constants as vesselsConstants } from '../vessel-details/redux';
import { addItem as notify } from '../notifications/actions';
import { actions, constants } from './redux';
import Api from '../../utils/Api';

function* fetchItems({ payload: { id } }) {
  try {
    yield put(actions.loadItemRequest()); // set is loading state

    const data = yield call(Api.assignedVessels.getItems, { vesselId: id });

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* createItem({ payload: option }) {
  try {
    const vesselId = yield select((s) => s.vesselDetails.data.id);
    const data = yield call(Api.assignedVessels.create, vesselId, option.value);

    yield put(actions.addCreatedItem(data));
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: `${option.label} user is assigned successfully`,
    }));
  } catch (e) {
    console.error(e);
    yield put(actions.createItemError(e));
  }
}

function* deleteItem({ payload: id }) {
  try {
    yield call(Api.assignedVessels.delete, id);

    yield put(actions.deleteItem(id));
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The user is unassigned successfully',
    }));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}


/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(vesselsConstants.ADD_LOADED_ITEM, fetchItems),
  ];
}
