import * as api from './adapter';
import { normalizeRelations } from './convertors/normalizeRelations';
import { uploadAttachment } from './userAttachments';


export default {
  /**
   * getCrewUsers
   * @returns {Promise<*>}
   */
  index: async ({ pagination, sort, search, vesselId }) => {
    const query = {};
    query['include'] = 'attachments';
    if(sort) query['sort'] = `${sort.direction === 'asc' ? '' : '-'}${sort.field}`;
    if(pagination) query['page'] = { size: pagination.itemsPerPage, number: pagination.current };

    const id = vesselId ? vesselId : window.location.pathname.split('/')[2];

    const data = await api.read(`/vessels/${id}/experiences`, { query });
    return normalizeRelations(data);
  },
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/expiriences/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: async ({ files = [], ...attributes }) => {
    const resp = await api.create({ type: 'expiriences', attributes });
    files.forEach((file) => {
      uploadAttachment(
        file.name, attributes.author_id, attributes.crewing_applicant_id,
        file.localUrl, resp.data.data.id, 'Expirience',
      );
    });
    return resp;
  },
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, { files = [], ...attributes }) => {
    const resp = await api.update({ id, type: 'expiriences', attributes });
    files.forEach((file) => {
      uploadAttachment(
        file.name, attributes.author_id, attributes.crewing_applicant_id,
        file.localUrl, resp.data.data.id, 'Expirience',
      );
    });
    return resp;
  },
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'expiriences' }),
};
