import get from 'lodash/get';

const columnName = (attributes) => {
  let name = '';
  if (attributes.type_name === "Seniority Bonus") name = 'sb';
  if (attributes.type_name === "Special Bonus") name = 'spb';
  if (attributes.type_name === "Performance Bonus") name = 'pb';
  if (get(attributes, 'options.consequent_contracts')) name += `_${attributes.options.consequent_contracts}`;

  return name;
}

export const convertToTableData = (resp) => {
  const table = {};

  const { data: {data} } = resp;

  data.forEach(({id, attributes}) => {
    if(!table[attributes.role_id]) {
      table[attributes.role_id] = {
        role_name: attributes.role_name,
        role_id: attributes.role_id,
        crewing_priority: attributes.crewing_priority,
      };
    }

    const column = columnName(attributes);
    table[attributes.role_id][column] = attributes.total;
    table[attributes.role_id][`${column}_id`] = id;
  });

  const tableData = Object.keys(table).map(key => ({
    role_id: key,
    ...table[key]
  })).sort((a, b) => a.crewing_priority - b.crewing_priority);

  return {
    ...resp,
    data: {
      ...resp.data,
      tableData,
    },
  }
}
