import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import DialogManager from './features/dialog-manager';
import AsyncComponent from './components/AsyncComponent';
import LoadingPagePreview from './components/LoadingPagePreview';
import { connect } from 'react-redux'
import { getPermissions } from "./utils/selectors";
import get from 'lodash/get';

const dialogByType = {
  confirm: AsyncComponent(() => import('./components/Confirm')),
};

const Login = AsyncComponent(() => import('./features/authorization')); // don't need a preview
const Notifications = AsyncComponent(() => import('./features/notifications')); // don't need a preview
const Vessels = AsyncComponent(() => import('./features/vessels'), LoadingPagePreview);
const VesselDetails = AsyncComponent(() => import('./features/vessel-details'), LoadingPagePreview);
const Planning = AsyncComponent(() => import('./features/planning'), LoadingPagePreview);
const Owners = AsyncComponent(() => import('./features/owners'), LoadingPagePreview);
const OwnerDetails = AsyncComponent(() => import('./features/owner-details'), LoadingPagePreview);
const Managers = AsyncComponent(() => import('./features/managers'), LoadingPagePreview);
const ManagerDetails = AsyncComponent(() => import('./features/manager-details'), LoadingPagePreview);
const CrewUsers = AsyncComponent(() => import('./features/crew-users'), LoadingPagePreview);
const CrewUserDetails = AsyncComponent(() => import('./features/crew-user-details'), LoadingPagePreview);
const Roles = AsyncComponent(() => import('./features/ranks'), LoadingPagePreview);
const CrewChanges = AsyncComponent(() => import('./features/crew-changes'), LoadingPagePreview);
const CrewChangeDetails = AsyncComponent(() => import('./features/crew-change-details'), LoadingPagePreview);
const Users = AsyncComponent(() => import('./features/users'), LoadingPagePreview);
const UserDetails = AsyncComponent(() => import('./features/user-details-page'), LoadingPagePreview);
const Vacancies = AsyncComponent(() => import('./features/vacancies'), LoadingPagePreview);
const VesselTypes = AsyncComponent(() => import('./features/vessel-types'), LoadingPagePreview);
const EngineTypes = AsyncComponent(() => import('./features/engine-types'), LoadingPagePreview);
const EngineManufacturers = AsyncComponent(() => import('./features/engine-manufacturers'), LoadingPagePreview);
const VendorTypes = AsyncComponent(() => import('./features/vendor-types'), LoadingPagePreview);
const Vendors = AsyncComponent(() => import('./features/vendors'), LoadingPagePreview);
const CertificateTypes = AsyncComponent(() => import('./features/certificate-types'), LoadingPagePreview);
const RelationTypes = AsyncComponent(() => import('./features/relation-types'), LoadingPagePreview);
const CrewingStatuses = AsyncComponent(() => import('./features/crewing-statuses'), LoadingPagePreview);
const Countries = AsyncComponent(() => import('./features/countries'), LoadingPagePreview);
const Ports = AsyncComponent(() => import('./features/ports'), LoadingPagePreview);
const MainEngines = AsyncComponent(() => import('./features/main-engines'), LoadingPagePreview);
const ApplicantContracts = AsyncComponent(() => import('./features/applicant-contracts'), LoadingPagePreview);
const ApplicantDocuments = AsyncComponent(() => import('./features/applicant-documents'), LoadingPagePreview);
const EnglishTestTypes = AsyncComponent(() => import('./features/english-test-types'), LoadingPagePreview);
const TaskManager = AsyncComponent(() => import('./features/task-manager'), LoadingPagePreview);
const SurveyCreator = AsyncComponent(() => import('./features/survey-creator'), LoadingPagePreview);

const TimeplansForm = AsyncComponent(() => import('./features/timeplans/Form')); // don't need a preview
const OwnersForm = AsyncComponent(() => import('./features/owners/Form'));
const ManagersForm = AsyncComponent(() => import('./features/managers/Form'));
const CrewUsersForm = AsyncComponent(() => import('./features/crew-users/Form'));
const CrewChangeForm = AsyncComponent(() => import('./features/crew-change-details/Form'));
const EditCrewChangeForm = AsyncComponent(() => import('./features/crew-changes/Form'));
const RanksForm = AsyncComponent(() => import('./features/ranks/Form'));
const UsersForm = AsyncComponent(() => import('./features/users/Form'));
const UserExperiencesForm = AsyncComponent(() => import('./features/user-experiences/Form'));
const UserAttachmentsForm = AsyncComponent(() => import('./features/user-attachment/Form'));
const UserDocumentsForm = AsyncComponent(() => import('./features/user-documents/Form'));
const UserInterviewsNewForm = AsyncComponent(() => import('./features/user-interviews/NewForm'));
const UserInterviewsForm = AsyncComponent(() => import('./features/user-interviews/Form'));
const UserRelativesForm = AsyncComponent(() => import('./features/user-relatives/Form'));
const EngineManufacturersForm = AsyncComponent(() => import('./features/engine-manufacturers/Form'));
const VesselsForm = AsyncComponent(() => import('./features/vessels/Form'));
const VesselTypesForm = AsyncComponent(() => import('./features/vessel-types/Form'));
const EngineTypesForm = AsyncComponent(() => import('./features/engine-types/Form'));
const VendorTypesForm = AsyncComponent(() => import('./features/vendor-types/Form'));
const VendorsForm = AsyncComponent(() => import('./features/vendors/Form'));
const CertificateTypesForm = AsyncComponent(() => import('./features/certificate-types/Form'));
const RelationTypesForm = AsyncComponent(() => import('./features/relation-types/Form'));
const CrewingStatusesForm = AsyncComponent(() => import('./features/crewing-statuses/Form'));
const CountriesForm = AsyncComponent(() => import('./features/countries/Form'));
const PortsForm = AsyncComponent(() => import('./features/ports/Form'));
const MainEnginesForm = AsyncComponent(() => import('./features/main-engines/Form'));
const ApplicantContractsForm = AsyncComponent(() => import('./features/applicant-contracts/Form'));
const ApplicantDocumentsForm = AsyncComponent(() => import('./features/applicant-documents/Form'));
const EnglishTestTypesForm = AsyncComponent(() => import('./features/english-test-types/Form'));
const UserRecommendationsForm = AsyncComponent(() => import('./features/user-recommendations/Form'));
const UserEnglishTestsForm = AsyncComponent(() => import('./features/user-english-tests/Form'));
const SurveyCreatorForm = AsyncComponent(() => import('./features/survey-creator/Form'));


const OwnersDeleteConfirm = AsyncComponent(() => import('./features/owners/DeleteConfirm'));
const ManagersDeleteConfirm = AsyncComponent(() => import('./features/managers/DeleteConfirm'));
const CrewUsersDeleteConfirm = AsyncComponent(() => import('./features/crew-users/DeleteConfirm'));
const CrewChangesDeleteConfirm = AsyncComponent(() => import('./features/crew-changes/DeleteConfirm'));
const RanksDeleteConfirm = AsyncComponent(() => import('./features/ranks/DeleteConfirm'));
const UsersDeleteConfirm = AsyncComponent(() => import('./features/users/DeleteConfirm'));
const UserExperiencesDeleteConfirm = AsyncComponent(() => import('./features/user-experiences/DeleteConfirm'));
const UserAttachmentsDeleteConfirm = AsyncComponent(() => import('./features/user-attachment/DeleteConfirm'));
const UserDocumentsDeleteConfirm = AsyncComponent(() => import('./features/user-documents/DeleteConfirm'));
const UserInterviewsDeleteConfirm = AsyncComponent(() => import('./features/user-interviews/DeleteConfirm'));
const UserRelativesDeleteConfirm = AsyncComponent(() => import('./features/user-relatives/DeleteConfirm'));
const UserRecommendationsConfirm = AsyncComponent(() => import('./features/user-recommendations/DeleteConfirm'));
const UserEnglishTestsConfirm = AsyncComponent(() => import('./features/user-english-tests/DeleteConfirm'));
const VesselsConfirm = AsyncComponent(() => import('./features/vessels/DeleteConfirm'));
const VesselTypesConfirm = AsyncComponent(() => import('./features/vessel-types/DeleteConfirm'));
const EngineTypesConfirm = AsyncComponent(() => import('./features/engine-types/DeleteConfirm'));
const EngineManufacturersConfirm = AsyncComponent(() => import('./features/engine-manufacturers/DeleteConfirm'));
const VendorTypesConfirm = AsyncComponent(() => import('./features/vendor-types/DeleteConfirm'));
const VendorsConfirm = AsyncComponent(() => import('./features/vendors/DeleteConfirm'));
const CertificateTypesConfirm = AsyncComponent(() => import('./features/certificate-types/DeleteConfirm'));
const RelationTypesConfirm = AsyncComponent(() => import('./features/relation-types/DeleteConfirm'));
const CrewingStatusesConfirm = AsyncComponent(() => import('./features/crewing-statuses/DeleteConfirm'));
const CountriesConfirm = AsyncComponent(() => import('./features/countries/DeleteConfirm'));
const PortsConfirm = AsyncComponent(() => import('./features/ports/DeleteConfirm'));
const MainEnginesConfirm = AsyncComponent(() => import('./features/main-engines/DeleteConfirm'));
const ApplicantContractsConfirm = AsyncComponent(() => import('./features/applicant-contracts/DeleteConfirm'));
const ApplicantDocumentsConfirm = AsyncComponent(() => import('./features/applicant-documents/DeleteConfirm'));
const EnglishTestTypesConfirm = AsyncComponent(() => import('./features/english-test-types/DeleteConfirm'));
const VacanciesDeleteConfirm = AsyncComponent(() => import('./features/vacancies/DeleteConfirm'));
const PageNotFound = AsyncComponent(() => import('./features/404'));
const PageForbidden = AsyncComponent(() => import('./features/403'));
// 
const SurveyCreatorConfirm = AsyncComponent(() => import('./features/survey-creator/DeleteConfirm'));


const canRead = (permission, component) => {
  return permission && permission.read ? component : null;
};

const canManage = (permission, component) => {
  return permission && permission.update ? component : null;
};

const canDelete = (permission, component) => {
  return permission && permission.delete ? component : null;
};

const denyAll = { read: false, create: false, update: false, delete: false };

const Routes = ({ history, permissions }) => (
  <div>
    <ConnectedRouter history={history}>
      <Switch>
        {
          permissions && !get(permissions, "timeplans.read", denyAll) && 
            <Redirect exact from='/' to={get(permissions, "users.read", denyAll) ? "/users" : "/task-manager"} />
        }
        <Route exact path="/login" component={Login} />

        {canRead(get(permissions, "vessels", denyAll), <Route exact path="/vessels" component={Vessels} />)}
        {canManage(get(permissions, "vessels", denyAll), <Route exact path="/vessels/:id?/(new|edit|delete)" component={Vessels} />)}
        {canRead(get(permissions, "vessels", denyAll), <Route exact path="/vessels/:id/:tab" component={VesselDetails} />)}
        {canRead(get(permissions, "vessels", denyAll), <Route exact path="/vessels/:id/:tab/edit" component={VesselDetails} />)}

        <Route exact path="/" component={Planning} />
        {canRead(get(permissions, "timeplans", denyAll), <Route path="/timeplans" component={Planning} />)}

        {canRead(get(permissions, "owners", denyAll), <Route exact path="/owners" component={Owners} />)}
        {canManage(get(permissions, "owners", denyAll), <Route exact path="/owners/:id?/(new|edit|delete)" component={Owners} />)}
        {canRead(get(permissions, "owners", denyAll), <Route exact path="/owners/:id/:tab" component={OwnerDetails} />)}

        {canRead(get(permissions, "managers", denyAll), <Route exact path="/managers" component={Managers} />)}
        {canManage(get(permissions, "managers", denyAll), <Route exact path="/managers/:id?/(new|edit|delete)" component={Managers} />)}
        {canRead(get(permissions, "managers", denyAll), <Route exact path="/managers/:id/details" component={ManagerDetails} />)}

        {canRead(get(permissions, "crewUsers", denyAll), <Route exact path="/crew-users" component={CrewUsers} />)}
        {canManage(get(permissions, "crewUsers", denyAll), <Route exact path="/crew-users/:id?/(new|edit|delete)" component={CrewUsers} />)}
        {canRead(get(permissions, "crewUsers", denyAll), <Route exact path="/crew-users/:id/details" component={CrewUserDetails} />)}

        {canRead(get(permissions, "ranks", denyAll), <Route exact path="/ranks" component={Roles} />)}
        {canManage(get(permissions, "ranks", denyAll), <Route exact path="/ranks/:id?/(new|edit|delete)" component={Roles} />)}
        {canRead(get(permissions, "ranks", denyAll), <Route exact path="/ranks/:id/details" component={Roles} />)}

        {canRead(get(permissions, "crewChange", denyAll), <Route exact path="/crew-changes" component={CrewChanges} />)}
        {canManage(get(permissions, "crewChange", denyAll), <Route exact path="/crew-changes/:id?/(edit|delete)" component={CrewChanges} />)}
        {canRead(get(permissions, "crewChange", denyAll), <Route exact path="/crew-changes/:id/details" component={CrewChangeDetails} />)}

        {canRead(get(permissions, "users", denyAll), <Route exact path="/users" component={Users} />)}
        {canManage(get(permissions, "users", denyAll), <Route exact path="/users/:id?/(new|edit|delete)" component={Users} />)}
        <Route path="/users/:id/:tab" component={UserDetails} />

        {canRead(get(permissions, "vacancies", denyAll), <Route exact path="/vacancies" component={Vacancies} />)}
        {canManage(get(permissions, "vacancies", denyAll), <Route exact path="/vacancies/:id?/(new|edit)" component={TimeplansForm} />)}
        {canDelete(get(permissions, "vacancies", denyAll), <Route exact path="/vacancies/:id/delete" component={VacanciesDeleteConfirm} />)}
        {canDelete(get(permissions, "vacancies", denyAll), <Route exact path="/vacancies/:id/v_delete" component={VacanciesDeleteConfirm} />)}

        {canRead(get(permissions, "vesselTypes", denyAll), <Route exact path="/vessel-types" component={VesselTypes} />)}
        {canManage(get(permissions, "vesselTypes", denyAll), <Route exact path="/vessel-types/:id?/(new|edit|delete)" component={VesselTypes} />)}

        {canRead(get(permissions, "engineTypes", denyAll), <Route exact path="/engine-types" component={EngineTypes} />)}
        {canManage(get(permissions, "engineTypes", denyAll), <Route exact path="/engine-types/:id?/(new|edit|delete)" component={EngineTypes} />)}

        {canRead(get(permissions, "engineManufacturers", denyAll), <Route exact path="/engine-manufacturers" component={EngineManufacturers} />)}
        {canManage(get(permissions, "engineManufacturers", denyAll), <Route exact path="/engine-manufacturers/:id?/(new|edit|delete)" component={EngineManufacturers} />)}

        {canRead(get(permissions, "vendorTypes", denyAll), <Route exact path="/vendor-types" component={VendorTypes} />)}
        {canManage(get(permissions, "vendorTypes", denyAll), <Route exact path="/vendor-types/:id?/(new|edit|delete)" component={VendorTypes} />)}

        {canRead(get(permissions, "vendors", denyAll), <Route exact path="/vendors" component={Vendors} />)}
        {canManage(get(permissions, "vendors", denyAll), <Route exact path="/vendors/:id?/(new|edit|delete)" component={Vendors} />)}

        {canRead(get(permissions, "certificateTypes", denyAll), <Route exact path="/certificate-types" component={CertificateTypes} />)}
        {canManage(get(permissions, "certificateTypes", denyAll), <Route exact path="/certificate-types/:id?/(new|edit|delete)" component={CertificateTypes} />)}


        {canRead(get(permissions, "relationTypes", denyAll), <Route exact path="/relation-types" component={RelationTypes} />)}
        {canManage(get(permissions, "relationTypes", denyAll), <Route exact path="/relation-types/:id?/(new|edit|delete)" component={RelationTypes} />)}

        {canRead(get(permissions, "crewingStatuses", denyAll), <Route exact path="/crewing-statuses" component={CrewingStatuses} />)}
        {canManage(get(permissions, "crewingStatuses", denyAll), <Route exact path="/crewing-statuses/:id?/(new|edit|delete)" component={CrewingStatuses} />)}

        {canRead(get(permissions, "countries", denyAll), <Route exact path="/countries" component={Countries} />)}
        {canManage(get(permissions, "countries", denyAll), <Route exact path="/countries/:id?/(new|edit|delete)" component={Countries} />)}

        {canRead(get(permissions, "ports", denyAll), <Route exact path="/ports" component={Ports} />)}
        {canManage(get(permissions, "ports", denyAll), <Route exact path="/ports/:id?/(new|edit|delete)" component={Ports} />)}

        {canRead(get(permissions, "mainEngines", denyAll), <Route exact path="/main-engines" component={MainEngines} />)}
        {canManage(get(permissions, "mainEngines", denyAll), <Route exact path="/main-engines/:id?/(new|edit|delete)" component={MainEngines} />)}

        {canRead(get(permissions, "applicantContracts", denyAll), <Route exact path="/applicant-contracts" component={ApplicantContracts} />)}
        {canManage(get(permissions, "applicantContracts", denyAll), <Route exact path="/applicant-contracts/:id?/(new|edit|delete)" component={ApplicantContracts} />)}

        {canRead(get(permissions, "applicantDocuments", denyAll), <Route exact path="/applicant-documents" component={ApplicantDocuments} />)}
        {canManage(get(permissions, "applicantDocuments", denyAll), <Route exact path="/applicant-documents/:id?/(new|edit|delete)" component={ApplicantDocuments} />)}

        {canRead(get(permissions, "englishTestTypes", denyAll), <Route exact path="/english-test-types" component={EnglishTestTypes} />)}
        {canManage(get(permissions, "englishTestTypes", denyAll), <Route exact path="/english-test-types/:id?/(new|edit|delete)" component={EnglishTestTypes} />)}

        {canRead(get(permissions, "taskManager", denyAll), <Route path="/task-manager" component={TaskManager} />)}

        {canRead(get(permissions, "surveyCreator", denyAll), <Route exact path="/survey-creator" component={SurveyCreator} />)}
        {canManage(get(permissions, "surveyCreator", denyAll), <Route exact path="/survey-creator/:id?/(new|edit|delete)" component={SurveyCreator} />)}
        <Route path="/page-not-found" component={PageNotFound} />
        <Route path="/page-forbidden" component={PageForbidden} />
        <Route component={PageNotFound} />
      </Switch>

      <Switch>
        {canManage(get(permissions, "timeplans", denyAll), <Route exact path="/timeplans/:id?/(new|edit)" component={TimeplansForm} />)}

        {canManage(get(permissions, "owners", denyAll), <Route path="/owners/:id?/(new|edit)" component={OwnersForm} />)}
        {canDelete(get(permissions, "owners", denyAll), <Route path="/owners/:id/delete" component={OwnersDeleteConfirm} />)}

        {canManage(get(permissions, "vessels", denyAll), <Route path="/vessels/:id?/(new|edit)" component={VesselsForm} />)}
        {canDelete(get(permissions, "vessels", denyAll), <Route path="/vessels/:id/delete" component={VesselsConfirm} />)}
        {canManage(get(permissions, "vessels", denyAll), <Route path="/vessels/:id/:tab/edit" component={VesselsForm} />)}

        {canManage(get(permissions, "managers", denyAll), <Route path="/managers/:id?/(new|edit)" component={ManagersForm} />)}
        {canDelete(get(permissions, "managers", denyAll), <Route path="/managers/:id/delete" component={ManagersDeleteConfirm} />)}

        {canManage(get(permissions, "crewUsers", denyAll), <Route path="/crew-users/:id?/(new|edit)" component={CrewUsersForm} />)}
        {canDelete(get(permissions, "crewUsers", denyAll), <Route path="/crew-users/:id/delete" component={CrewUsersDeleteConfirm} />)}

        {canManage(get(permissions, "crewChange", denyAll), <Route path="/crew-changes/:id/edit" component={EditCrewChangeForm} />)}
        {canDelete(get(permissions, "crewChange", denyAll), <Route path="/crew-changes/:id/delete" component={CrewChangesDeleteConfirm} />)}

        {canManage(get(permissions, "ranks", denyAll), <Route path="/ranks/:id?/(new|edit)" component={RanksForm} />)}
        {canDelete(get(permissions, "ranks", denyAll), <Route path="/ranks/:id/delete" component={RanksDeleteConfirm} />)}

        {canManage(get(permissions, "users", denyAll), <Route path="/users/:id?/(new|edit)" component={UsersForm} />)}
        {canDelete(get(permissions, "users", denyAll), <Route path="/users/:id/delete" component={UsersDeleteConfirm} />)}

        {canManage(get(permissions, "userExperiences", denyAll), <Route path="/users/:userId/experiences/:id?/(new|edit)" component={UserExperiencesForm} />)}
        {canDelete(get(permissions, "userExperiences", denyAll), <Route path="/users/:userId/experiences/:id/delete" component={UserExperiencesDeleteConfirm} />)}
        
        {canManage(get(permissions, "userAttachments", denyAll), <Route path="/users/:userId/attachments/new" component={UserAttachmentsForm} />)}
        {canDelete(get(permissions, "userAttachments", denyAll), <Route path="/users/:userId/attachments/:id/delete" component={UserAttachmentsDeleteConfirm} />)}
        
        {canManage(get(permissions, "userDocuments", denyAll), <Route path="/users/:userId/documents/:id?/(new|edit)" component={UserDocumentsForm} />)}
        {canDelete(get(permissions, "userDocuments", denyAll), <Route path="/users/:userId/documents/:id/delete" component={UserDocumentsDeleteConfirm} />)}

        {canManage(get(permissions, "userInterviews", denyAll), <Route path="/users/:userId/interviews/new" component={UserInterviewsNewForm} />)}
        {canManage(get(permissions, "userInterviews", denyAll), <Route path="/users/:userId/interviews/:id/edit" component={UserInterviewsForm} />)}
        {canDelete(get(permissions, "userInterviews", denyAll), <Route path="/users/:userId/interviews/:id/delete" component={UserInterviewsDeleteConfirm} />)}

        {canManage(get(permissions, "userRelatives", denyAll), <Route path="/users/:userId/relatives/:id?/(new|edit)" component={UserRelativesForm} />)}
        {canDelete(get(permissions, "userRelatives", denyAll), <Route path="/users/:userId/relatives/:id/delete" component={UserRelativesDeleteConfirm} />)}
        
        {canManage(get(permissions, "userRecommendations", denyAll), <Route path="/users/:userId/recommendations/:id?/(new|edit)" component={UserRecommendationsForm} />)}
        {canDelete(get(permissions, "userRecommendations", denyAll), <Route path="/users/:userId/recommendations/:id/delete" component={UserRecommendationsConfirm} />)}
        
        {canManage(get(permissions, "userEnglishTestResults", denyAll), <Route path="/users/:userId/english-tests/:id?/(new|edit)" component={UserEnglishTestsForm} />)}
        {canDelete(get(permissions, "userEnglishTestResult", denyAll), <Route path="/users/:userId/english-tests/:id/delete" component={UserEnglishTestsConfirm} />)}

        {canManage(get(permissions, "vesselTypes", denyAll), <Route path="/vessel-types/:id?/(new|edit)" component={VesselTypesForm} />)}
        {canDelete(get(permissions, "vesselTypes", denyAll), <Route path="/vessel-types/:id/delete" component={VesselTypesConfirm} />)}

        {canManage(get(permissions, "engineTypes", denyAll), <Route path="/engine-types/:id?/(new|edit)" component={EngineTypesForm} />)}
        {canDelete(get(permissions, "engineTypes", denyAll), <Route path="/engine-types/:id/delete" component={EngineTypesConfirm} />)}

        {canManage(get(permissions, "engineManufacturers", denyAll), <Route path="/engine-manufacturers/:id?/(new|edit)" component={EngineManufacturersForm} />)}
        {canDelete(get(permissions, "engineManufacturers", denyAll), <Route path="/engine-manufacturers/:id/delete" component={EngineManufacturersConfirm} />)}

        {canManage(get(permissions, "vendorTypes", denyAll), <Route path="/vendor-types/:id?/(new|edit)" component={VendorTypesForm} />)}
        {canDelete(get(permissions, "vendorTypes", denyAll), <Route path="/vendor-types/:id/delete" component={VendorTypesConfirm} />)}

        {canManage(get(permissions, "vendors", denyAll), <Route path="/vendors/:id?/(new|edit)" component={VendorsForm} />)}
        {canDelete(get(permissions, "vendors", denyAll), <Route path="/vendors/:id/delete" component={VendorsConfirm} />)}

        {canManage(get(permissions, "certificateTypes", denyAll), <Route path="/certificate-types/:id?/(new|edit)" component={CertificateTypesForm} />)}
        {canDelete(get(permissions, "certificateTypes", denyAll), <Route path="/certificate-types/:id/delete" component={CertificateTypesConfirm} />)}

        {canManage(get(permissions, "relationTypes", denyAll), <Route path="/relation-types/:id?/(new|edit)" component={RelationTypesForm} />)}
        {canDelete(get(permissions, "relationTypes", denyAll), <Route path="/relation-types/:id/delete" component={RelationTypesConfirm} />)}

        {canManage(get(permissions, "crewingStatuses", denyAll), <Route path="/crewing-statuses/:id?/(new|edit)" component={CrewingStatusesForm} />)}
        {canDelete(get(permissions, "crewingStatuses", denyAll), <Route path="/crewing-statuses/:id/delete" component={CrewingStatusesConfirm} />)}

        {canManage(get(permissions, "countries", denyAll), <Route path="/countries/:id?/(new|edit)" component={CountriesForm} />)}
        {canDelete(get(permissions, "countries", denyAll), <Route path="/countries/:id/delete" component={CountriesConfirm} />)}

        {canManage(get(permissions, "ports", denyAll), <Route path="/ports/:id?/(new|edit)" component={PortsForm} />)}
        {canDelete(get(permissions, "ports", denyAll), <Route path="/ports/:id/delete" component={PortsConfirm} />)}

        {canManage(get(permissions, "mainEngines", denyAll), <Route path="/main-engines/:id?/(new|edit)" component={MainEnginesForm} />)}
        {canDelete(get(permissions, "mainEngines", denyAll), <Route path="/main-engines/:id/delete" component={MainEnginesConfirm} />)}

        {canManage(get(permissions, "applicantContracts", denyAll), <Route path="/applicant-contracts/:id?/(new|edit)" component={ApplicantContractsForm} />)}
        {canDelete(get(permissions, "applicantContracts", denyAll), <Route path="/applicant-contracts/:id/delete" component={ApplicantContractsConfirm} />)}

        {canManage(get(permissions, "applicantDocuments", denyAll), <Route path="/applicant-documents/:id?/(new|edit)" component={ApplicantDocumentsForm} />)}
        {canDelete(get(permissions, "applicantDocuments", denyAll), <Route path="/applicant-documents/:id/delete" component={ApplicantDocumentsConfirm} />)}

        {canManage(get(permissions, "englishTestTypes", denyAll), <Route path="/english-test-types/:id?/(new|edit)" component={EnglishTestTypesForm} />)}
        {canDelete(get(permissions, "englishTestTypes", denyAll), <Route path="/english-test-types/:id/delete" component={EnglishTestTypesConfirm} />)}

        {canManage(get(permissions, "timeplans", denyAll), <Route path="/timeplans/crew-rotation/crew-change/:id?/(new|edit)" component={CrewChangeForm} />)}

        {canManage(get(permissions, "surveyCreator", denyAll), <Route path="/survey-creator/:id?/(new|edit)" component={SurveyCreatorForm} />)}
        {canDelete(get(permissions, "surveyCreator", denyAll), <Route path="/survey-creator/:id/delete" component={SurveyCreatorConfirm} />)}
      </Switch>

      <Notifications />
      <DialogManager dialogByType={dialogByType} />
    </ConnectedRouter>
  </div>
);

Routes.propTypes = {
  history: PropTypes.object.isRequired,
};

const selector = state => ({
  permissions: getPermissions(state),
});

export default connect(selector)(Routes);
