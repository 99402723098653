import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions, constants } from "./redux";
import Api from "../../utils/Api";
import { addItem as notify } from "../notifications/actions";
import { deleteItem as deleteDialog } from "../dialog-manager/actions";

export function* fetchItems() {
  try {
    const id = yield select((s) => s.userDetailsPage.data.id);
    const data = yield call(Api.users.getAppFormLinks, id);
    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createItem({ payload }) {
  try {
    const { data } = yield call(Api.appFormLinks.create, payload);

    yield put(actions.addCreatedItem(data));
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "The link created successfully",
      })
    );
  } catch (e) {
    yield put(actions.createItemError(e));

    console.error(e);
  } finally {
    yield put(actions.loadItemsRequest());
  }
}

export function* deleteItem({ payload: id }) {
  try {
    yield call(Api.appFormLinks.delete, id);

    yield put(actions.deleteItem(id));
    yield put(deleteDialog());
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "The link deleted successfully",
      })
    );
  } catch (e) {
    yield put(actions.deleteItemError(e));

    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
  ];
}
