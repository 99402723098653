/**
 * Api requests module.
 * @module Utils/Api
 */
import { isString } from 'lodash';
import serializeDataToQueryString from './serializeDataToQueryString';
import handleError from './handleError';

function getUrl(entity, { resource, url, query } = {}) {
  const resultEntity = resource || (url || entity);
  const parsedQuery = query ? `?${serializeDataToQueryString(query)}` : '';

  return `/api/${resultEntity}${parsedQuery}`;
}

export const getDefaultOptions = (headers = {}) => ({
  method: 'GET',
  headers: {
    'access-token': localStorage.getItem('access-token'),
    'token-type': localStorage.getItem('token-type'),
    client: localStorage.getItem('client'),
    expiry: localStorage.getItem('expiry'),
    uid: localStorage.getItem('uid'),
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
    ...headers,
  },
});

/**
 * @function apiRequest
 * @param {String} url request url.
 * @param {Object} options can has some fetch options, that will be merge with defaultOptions(method, headers, ...)
 * @return {Promise} Promise then -> json, catch -> errors
 */
export async function apiRequest(url, options = {}, headers = {}) {
  const defaultOptions = getDefaultOptions(headers);

  const resultOptions = { ...defaultOptions, ...options };

  if (!resultOptions.headers['Content-Type']) {
    delete resultOptions.headers['Content-Type'];
  }

  const response = await fetch(url, resultOptions);

  if (response.ok) {
    if (response.status === 204) return response;

    const data = await response.json();

    return new Promise((resolve) => {
      const headers = {
        'access-token': response.headers.get('access-token'),
        'token-type': response.headers.get('token-type'),
        client: response.headers.get('client'),
        expiry: response.headers.get('expiry'),
        uid: response.headers.get('uid'),
      };

      resolve({ data, headers });
    });
  }
  const error = await handleError(response);

  throw error;
}

/**
 * @function read
 * @param {String} entity can be "{ id, type }" or "some_resource/:id"
 * @param {Object} override can has resource, url, query
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function read(entity, override = {}) {
  const url = getUrl(entity, override);

  return apiRequest(url); // method get is default.
}

/**
 * @function create
 * @param {Object|String} data can be "{ id, type }" or "some_resource/:id"
 * @param {Object} override can has resource, url, query
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function create(params, override = {}) {
  const { controller, ...data } = params;
  const entity = [(controller || data.type), data.action].filter(Boolean).join('/');
  const url = getUrl(entity, override);

  return apiRequest(url, { method: 'POST', body: JSON.stringify({ data }) });
}

/**
 * @function update
 * @param {Object|String} data can be "{ id, type }" or "some_resource/:id"
 * @param {Object} override can has resource, url, query
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function update(params, override = {}) {
  const { controller, ...data } = params;
  const entity = [(controller || data.type), data.id, data.action].filter(Boolean).join('/');
  const url = getUrl(entity, override);

  return apiRequest(url, { method: 'PATCH', body: JSON.stringify({ data }) });
}

/**
 * @function destroy
 * @param {Object|String} data can be "{ id, type }" or "some_resource/:id"
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function destroy(data) {
  const entity = isString(data) ? data : `${data.type}/${data.id}`;
  const url = getUrl(entity);

  return apiRequest(url, { method: 'DELETE' });
}
