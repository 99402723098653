import { find } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { constants as vesselsConstants } from '../vessel-details/redux';
import { addError, addSuccess } from '../notifications/actions';
import { actions, constants } from './redux';
import Api from '../../utils/Api';

function* fetchItems({ payload: { id } }) {
  try {
    yield put(actions.loadItemRequest()); // set is loading state

    const data = yield call(Api.manningScales.index, id);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* fetchItemsAfterMsCopy({ payload: currentVesselId }) {
  yield fetchItems({ payload: { id: currentVesselId } });
}

function* copyManningScale({ payload: { value: vesselIdToCopy } }) {
  try {
    const currentVesselId = yield select((s) => s.vesselDetails.data.id);

    yield call(Api.vessels.copyManningScale, vesselIdToCopy, currentVesselId);
    yield put(actions.copyManningScaleSuccess(currentVesselId));
    yield put(addSuccess('Manning scale has copied successfully'));
  } catch (e) {
    console.error(e);

    yield put(actions.copyManningScaleError(e));
    yield put(addError(e.apiErrors, e.message));
  }
}

function* createItem({ payload: option }) {
  try {
    const vesselId = yield select((s) => s.vesselDetails.data.id);

    yield call(Api.manningScales.create, vesselId, option.value);

    yield fetchItems({ payload: { id: vesselId } });
    yield put(addSuccess(`${option.label} rank has added successfully`));
  } catch (e) {
    console.error(e);
    yield put(actions.createItemError(e));
  }
}

function* archiveItem({ payload: id }) {
  try {
    const { data } = yield select((s) => s.manningScales);
    const item = find(data, { id });

    yield call(Api.manningScales.archive, id);

    yield put(actions.updateItem({
      ...item,
      attributes: { ...item.attributes, state: 'archived' },
    }));
    yield put(addSuccess('Rank has deleted successfully'));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}

function* deleteItem({ payload: id }) {
  try {
    yield call(Api.manningScales.delete, id);

    yield put(actions.deleteItem(id));
    yield put(addSuccess('Rank has deleted successfully'));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}


/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, archiveItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(vesselsConstants.ADD_LOADED_ITEM, fetchItems),
    takeEvery(constants.COPY_MANNING_SCALE_REQUEST, copyManningScale),
    takeEvery(constants.COPY_MANNING_SCALE_SUCCESS, fetchItemsAfterMsCopy),
  ];
}
