import { handleActions, createAction } from 'redux-actions';
import get from 'lodash/get';

export const constants = {   
  FETCH_TYPES: '@COMMENT-TYPE-MANAGEMENT/FETCH_TYPES',
  FETCH_POSITIONS: '@COMMENT-TYPE-MANAGEMENT/FETCH_POSITIONS',
  FETCH_PERMISSIONS: '@COMMENT-TYPE-MANAGEMENT/FETCH_PERMISSIONS',
  SET_TYPES: '@COMMENT-TYPE-MANAGEMENT/SET_TYPES',
  SET_POSITIONS: '@COMMENT-TYPE-MANAGEMENT/SET_POSITIONS',
  SET_PERMISSIONS: '@COMMENT-TYPE-MANAGEMENT/SET_PERMISSIONS',
  HANDLE_CHANGE_PERMISSION: '@COMMENT-TYPE-MANAGEMENT/HANDLE_CHANGE_PERMISSION',
  SET_LOADING: '@COMMENT-TYPE-MANAGEMENT/SET_LOADING',
  RESET_DATA: '@COMMENT-TYPE-MANAGEMENT/RESET_DATA',
};

export const actions = {
  fetchTypes: createAction(constants.FETCH_TYPES),
  fetchPositions: createAction(constants.FETCH_POSITIONS),
  fetchPermissions: createAction(constants.FETCH_PERMISSIONS),
  setTypes: createAction(constants.SET_TYPES),
  setPositions: createAction(constants.SET_POSITIONS),
  setPermissions: createAction(constants.SET_PERMISSIONS),
  handleChangePermission: createAction(constants.HANDLE_CHANGE_PERMISSION),
  setLoading: createAction(constants.SET_LOADING),
  resetData: createAction(constants.RESET_DATA),
};

export const initialState = {
  types: [],
  positions: [],
  permissions: [],
  loading: false,
};

export const actionHandlers = {
  [constants.SET_TYPES]: (state, { payload }) => ({
    ...state, types: payload,
  }),
  [constants.SET_POSITIONS]: (state, { payload }) => ({
    ...state, positions: payload,
  }),
  [constants.SET_PERMISSIONS]: (state, { payload }) => ({
    ...state, permissions: payload,
  }),
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state, loading: payload,
  }),
  [constants.RESET_DATA]: () => ({
    ...initialState,
  }),

};


export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  types: state.commentTypeManagement.types,
  positions: state.commentTypeManagement.positions,
  permissions: state.commentTypeManagement.permissions,
  loading: state.commentTypeManagement.loading,
  userRole: get(state, 'auth.data.role'),
});

