import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Badge,
  Typography,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsList from "./NotificationsList";
import Pagination from "../../../components/Pagination";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialogContent-root": {
      padding: 0,
    },
    "& .MuiDialogTitle-root": {
      padding: 0,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  clearButton: {
    marginRight: "5px",
  },
  dialogTitleText: {
    paddingLeft: "15px",
  },
  pagination: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    background: "rgba(255, 255, 255, .85)",
    marginTop: 0,
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
}));

const paperProps = {
  style: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "30vw",
  },
};

const NotificationManager = ({
  data,
  pagination,
  isLoading,
  onChangePageNumber,
  loadItemsRequest,
  notificationCount,
  clearAllNotifications,
  clearNotification,
  notShownNotifications,
  showPopUp,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const dialogClasses = useMemo(() => ({ root: classes.root }), [classes]);

  const paginationOverrideClasses = useMemo(
    () => ({
      pagination: classes.pagination,
    }),
    [classes]
  );

  const handleToggle = useCallback(() => {
    if (!open) {
      loadItemsRequest();
    }

    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClearAll = useCallback(() => {
    clearAllNotifications();
    loadItemsRequest();
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!isEmpty(notShownNotifications)) {
      showPopUp(notShownNotifications);
    }
  }, [notShownNotifications]);

  return (
    <>
      <Tooltip title={"Notifications"} enterDelay={300}>
        <IconButton color="primary" onClick={handleToggle}>
          <Badge color="error" badgeContent={notificationCount}>
            <NotificationsIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Tooltip>

      <Dialog
        classes={dialogClasses}
        open={open}
        onClose={handleClose}
        aria-labelledby="notifications-dialog"
        PaperProps={paperProps}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <>
            <DialogTitle id="notifications-dialog">
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
              >
                <Grid item>
                  <Typography variant="h6" className={classes.dialogTitleText}>
                    Notifications
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title="Clear All Notifications"
                    placement="left"
                    enterDelay={300}
                  >
                    <IconButton
                      color="primary"
                      onClick={handleClearAll}
                      className={classes.clearButton}
                    >
                      <ClearAllIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </DialogTitle>
            {isLoading && <LinearProgress />}
            <DialogContent>
              <>
                <NotificationsList
                  data={data}
                  clearNotification={clearNotification}
                />
                {!isLoading && data.length > 0 && (
                  <Pagination
                    classes={paginationOverrideClasses}
                    data={pagination}
                    onChange={onChangePageNumber}
                  />
                )}
              </>
            </DialogContent>
          </>
        </ClickAwayListener>
      </Dialog>
    </>
  );
};

NotificationManager.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onChangePageNumber: PropTypes.func.isRequired,
  loadItemsRequest: PropTypes.func.isRequired,
  notificationCount: PropTypes.number,
  clearAllNotifications: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  notShownNotifications: PropTypes.object,
  showPopUp: PropTypes.func.isRequired,
};

export default NotificationManager;
