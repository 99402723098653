import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState } from '../../utils/redux/commonActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';


export const constants = getConstantsByFeatureName('OWNER_VESSELS');
export const actions = getActionCreators(constants);
export const reducer = handleActions({
  ...getActionHandlers(constants),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, { path: '/owners/:id/details' })
      ? state
      : initialState
  ),
}, initialState);
export const selector = getSelectorByFeatureName('ownerDetailsVessels');
