export default (theme) => ({
  logoLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  logo: {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    shapeRendering: 'geometricPrecision',
    textRendering: 'geometricPrecision',
    imageRendering: 'optimizeQuality',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    marginRight: theme.spacing(1),
  },
});
