import { handleActions, createAction } from 'redux-actions';
import {getCurrentUserData, getPermissions} from '../../../../utils/selectors';

export const constants = {
  FETCH_SEA_SERVICE_REQUEST: '@SEAFARERS_VIEW/FETCH_SEA_SERVICE_REQUEST',
  FETCH_COMMENTS_REQUEST: '@SEAFARERS_VIEW/FETCH_COMMENTS_REQUEST',
  SET_SEA_SERVICE: '@SEAFARERS_VIEW/SET_SEA_SERVICE',
  SET_COMMENTS: '@SEAFARERS_VIEW/SET_COMMENTS',
  SET_USER_ID: '@SEAFARERS_VIEW/SET_USER_ID',
  SET_SEA_SERVICE_LOADING: '@SEAFARERS_VIEW/SET_SEA_SERVICE_LOADING',
  SET_COMMENTS_LOADING: '@SEAFARERS_VIEW/SET_COMMENTS_LOADING',
  SET_SEA_SERVICE_ERRORS: '@SEAFARERS_VIEW/SET_SEA_SERVICE_ERRORS',
  SET_COMMENTS_ERRORS: '@SEAFARERS_VIEW/SET_COMMENTS_ERRORS',
  ASSIGN_TAG_MULTI_REQUEST: '@SEAFARERS_VIEW/ASSIGN_TAG_MULTI_REQUEST',
  ASSIGN_TAG_MULTI: '@SEAFARERS_VIEW/ASSIGN_TAG_MULTI',
  REMOVE_TAG_MULTI_REQUEST: '@SEAFARERS_VIEW/REMOVE_TAG_MULTI_REQUEST',
  REMOVE_TAG_MULTI: '@SEAFARERS_VIEW/REMOVE_TAG_MULTI',
  ADD_NEW_COMMENT_REQUEST: '@SEAFARERS_VIEW/ADD_NEW_COMMENT_REQUEST',
  ADD_NEW_COMMENT: '@SEAFARERS_VIEW/ADD_NEW_COMMENT',
  ADD_NEW_COMMENT_SUCCESS: '@SEAFARERS_VIEW/ADD_NEW_COMMENT_SUCCESS',
  APPLY_COMMENT_FILTER: '@SEAFARERS_VIEW/APPLY_COMMENT_FILTER',
  CLEAR_COMMENT_FILTER: '@SEAFARERS_VIEW/CLEAR_COMMENT_FILTER',
  STAR_USER: '@SEAFARERS_VIEW/STAR_USER',
};

export const actions = {
  fetchSeaService: createAction(constants.FETCH_SEA_SERVICE_REQUEST),
  fetchComments: createAction(constants.FETCH_COMMENTS_REQUEST),
  setSeaService: createAction(constants.SET_SEA_SERVICE),
  setComments: createAction(constants.SET_COMMENTS),
  setUserId: createAction(constants.SET_USER_ID),
  setSeaServiceLoading: createAction(constants.SET_SEA_SERVICE_LOADING),
  setCommentsLoading: createAction(constants.SET_COMMENTS_LOADING),
  setSeaServiceErrors: createAction(constants.SET_SEA_SERVICE_ERRORS),
  setCommentsErrors: createAction(constants.SET_COMMENTS_ERRORS),
  assignTagMultiRequest: createAction(constants.ASSIGN_TAG_MULTI_REQUEST),
  assignTagMulti: createAction(constants.ASSIGN_TAG_MULTI),
  removeTagMultiRequest: createAction(constants.REMOVE_TAG_MULTI_REQUEST),
  removeTagMulti: createAction(constants.REMOVE_TAG_MULTI),
  addNewCommentRequest: createAction(constants.ADD_NEW_COMMENT_REQUEST),
  addNewComment: createAction(constants.ADD_NEW_COMMENT),
  addNewCommentSuccess: createAction(constants.ADD_NEW_COMMENT_SUCCESS),
  fetchTypes: createAction('@COMMENT-TYPE-MANAGEMENT/FETCH_TYPES'),
  applyCommentFilter: createAction(constants.APPLY_COMMENT_FILTER),
  clearCommentFilter: createAction(constants.CLEAR_COMMENT_FILTER),
  starUser: createAction(constants.STAR_USER),
};

export const initialState = {
  userId: null,
  seaService: {
    loading: false,
    errors: [],
    data: [],
    assignTagModal: false,
    removeTagModal: false,
    tag: '',
    userId: '',
  },
  comments: {
    loading: false,
    errors: [],
    data: [],
    showNewComment: false,
    filters: {category: 'all'},
  },
};

export const actionHandlers = {
  [constants.FETCH_SEA_SERVICE_REQUEST]: (state) => ({
    ...state,
    seaService: { ...state.seaService, data: [], loading: true },
  }),
  [constants.FETCH_COMMENTS_REQUEST]: (state) => ({
    ...state,
    comments: { ...state.comments, data: [], loading: true },
  }),
  [constants.SET_SEA_SERVICE]: (state, action) => ({
    ...state,
    seaService: { ...state.seaService, data: action.payload, loading: false, errors: [] },
  }),
  [constants.SET_COMMENTS]: (state, action) => ({
    ...state,
    comments: { ...state.comments, data: action.payload, loading: false, errors: [] },
  }),
  [constants.SET_SEA_SERVICE_LOADING]: (state, action) => ({
    ...state,
    seaService: { ...state.seaService, loading: action.payload },
  }),
  [constants.SET_USER_ID]: (state, { payload }) => ({ ...state, userId: payload }),
  [constants.SET_COMMENTS_LOADING]: (state, action) => ({
    ...state,
    comments: { ...state.comments, loading: action.payload },
  }),
  [constants.SET_SEA_SERVICE_ERRORS]: (state, action) => ({
    ...state,
    seaService: { ...state.seaService, errors: [...state.seaService.errors, action.payload], loading: false },
  }),
  [constants.SET_COMMENTS_ERRORS]: (state, action) => ({
    ...state,
    comments: { ...state.comments, errors: [...state.comments.errors, action.payload], loading: false },
  }),
  [constants.ASSIGN_TAG_MULTI_REQUEST]: (state, { payload: { show, userId } }) => ({
    ...state,
    seaService: { ...state.seaService, assignTagModal: show, userId },
  }),
  [constants.REMOVE_TAG_MULTI_REQUEST]: (state, { payload: { show, userId, tag } }) => ({
    ...state,
    seaService: { ...state.seaService, removeTagModal: show, userId, tag },
  }),
  [constants.ADD_NEW_COMMENT_REQUEST]: (state, { payload: { show } }) => ({
    ...state,
    comments: { ...state.comments, showNewComment: show },
  }),
  [constants.APPLY_COMMENT_FILTER]: (state, { payload: filters }) => ({
    ...state,
    comments: { ...state.comments, filters },
  }),
  [constants.CLEAR_COMMENT_FILTER]: (state) => ({
    ...state,
    comments: { ...state.comments, filters: {} },
  }),
};

export const reducer = handleActions(actionHandlers, initialState);

const getUserItem = (state) =>
  state.users.data.find(({id}) => id === state.seafarersView.userId) ||
    state.userDetailsPage.data;

export const selector = (state) => ({
  userId: state.seafarersView.userId,
  userItem: getUserItem(state),
  seaService: state.seafarersView.seaService,
  comments: state.seafarersView.comments,
  itemsCount: state.users.pagination.totalItemsCount,
  types: state.commentTypeManagement.types,
  commentFilter: state.comments.filter,
  permissions: getPermissions(state),
  currentUser: getCurrentUserData(state),
  sort: state.users.sort,
});
