import * as api from "./adapter";
import { normalizeRelations } from "./convertors/normalizeRelations";
import { uploadAttachment } from "./userAttachments";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

const uploadDocAttachment = (file, attributes, respId) => {
  uploadAttachment(
    file.name,
    attributes.author_id,
    attributes.crewing_applicant_id,
    file.localUrl,
    respId,
    "ApplicantDocument"
  );
};

const defaultSort = (sort) =>
  !sort || sort?.field === ""
    ? "n_tpd,n_doc,-valid_from"
    : `${sort.direction === "asc" ? "" : "-"}${sort.field}`;

export default {
  index: async ({ pagination, sort, search }) => {
    const data = await api.read(
      `/users/${window.location.pathname.split("/")[2]}/documents`,
      {
        query: {
          include: "attachments,endorsement,endorsement_attachments",
          sort: defaultSort(sort),
          page: { size: 999, number: 1 },
        },
      }
    );
    return normalizeRelations(data);
  },
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/applicant_documents/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: async ({
    files = [],
    endorsement = null,
    filesEndorsement = [],
    ...attributes
  }) => {
    const resp = await api.create({ type: "applicant_documents", attributes });
    if (String(attributes.n_tpd) === "2" && endorsement) {
      const attributesEnd = {
        ...attributes,
        ...pickBy(endorsement, identity),
        endorsement_id: null,
      };
      const respEnd = await api.create({
        type: "applicant_documents",
        attributes: attributesEnd,
      });

      await api.update({
        id: resp.data.data.id,
        type: "applicant_documents",
        attributes: { endorsement_id: respEnd.data.data.id },
      });

      filesEndorsement.forEach((file, index) => {
        setTimeout(
          () => uploadDocAttachment(file, attributes, respEnd.data.data.id),
          index * 1000
        );
      });
    }
    files.forEach((file, index) => {
      setTimeout(
        () => uploadDocAttachment(file, attributes, resp.data.data.id),
        index * 1000
      );
    });
    return resp;
  },
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, { files = [], endorsement = null, filesEndorsement = [], ...attributes }) => {
    const resp = await api.update({ id, type: 'applicant_documents', attributes });
    if (String(attributes.n_tpd) === '2' && endorsement) {
      const attributesEnd = { ...attributes, ...pickBy(endorsement, identity), endorsement_id: null, number: attributes.number, n_tpd: 3 }
      let endId = null;
      if (attributes.endorsement_id) {
        await api.update({
          id: attributes.endorsement_id,
          type: "applicant_documents",
          attributes: attributesEnd,
        });
        endId = attributes.endorsement_id;
      } else {
        const respEnd = await api.create({ type: 'applicant_documents', attributes: attributesEnd });
        await api.update({ id: id, type: 'applicant_documents', attributes: { endorsement_id: respEnd.data.data.id } });
        endId = respEnd.data.data.id;
      }
      filesEndorsement.forEach((file, index) => {
        setTimeout(
          () => uploadDocAttachment(file, attributes, endId),
          index * 1000
        );
      });
    }

    files.forEach((file, index) => {
      setTimeout(
        () => uploadDocAttachment(file, attributes, resp.data.data.id),
        index * 1000
      );
    });
    return resp;
  },
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "applicant_documents" }),
  /**
   * renameApplicantDocumentFiles
   * @returns {Promise<*>}
   */
  renameFiles: (ids) =>
    api.apiRequest("/api/applicant_documents/restore_sequence", {
      method: "POST",
      body: JSON.stringify({ ids }),
    }),
};
