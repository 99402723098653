import * as api from "./adapter";

export default {
  /**
   * index - get all surveys
   * @returns {Promise<*>}
   */
  index: ({ pagination, sort, search } = {}) =>
    api.read("survey_schemas", {
      query: {
        page: { size: pagination.itemsPerPage, number: pagination.current },
        filter: { title: search }
      },
    }),
  /**
   * show - get survey schema
   * @param id
   * @returns {Promise}
   */
  show: (id) => api.read(`survey_schemas/${id}`),
  /**
   * createSchema
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => {
    const clientId = localStorage.getItem('clientId')
    const payload = {
      author_id: clientId,
      definition: attributes,
    };

    api.create({
      type: "survey_schemas",
      attributes: payload,
    });
  },
  /**
   * updateSchema
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => {
    api.update({
      id,
      type: "survey_schemas",
      attributes: {
        definition: attributes,
      },
    });
  },
  /**
   * deleteSchema
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "survey_schemas" }),
};
