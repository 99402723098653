import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import { initialState } from '../../utils/redux/commonActionHandlers';


const constants = getConstantsByFeatureName('VESSEL_OWNERS');
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions(actionHandlers, initialState);
const selector = getSelectorByFeatureName('vesselOwners');

export { constants, actions, reducer, selector };
