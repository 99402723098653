import { keyBy, get } from 'lodash';

/**
 * getPlanningPeriodsByUserId
 * @param {Array} plannings
 * @returns {Object}
 */
const getPlanningPeriodsByUserId = (plannings) => plannings.reduce(
  (acc, { id, start, end, userId }) => (
    userId ? ({
      ...acc,
      [userId]: [
        ...(acc[userId] || []),
        { id, start: new Date(start).getTime(), end: new Date(end).getTime() },
      ],
    }) : acc),
  {},
);

export const convertItem = (
  {
    id,
    attributes,
    relationships: {
      user: { data: user },
      last_comments: { data: comments = [] },
      manning_scale: { data: ms },
    },
  },
  groupedIncluded,
) => {
  const lastCommentRel = comments[0];

  const lastComment = lastCommentRel ? groupedIncluded[`${lastCommentRel.type}:${lastCommentRel.id}`] : null;
  const userItem = user ? groupedIncluded[`${user.type}:${user.id}`] : null;
  const msItem = ms ? groupedIncluded[`${ms.type}:${ms.id}`] : null;

  const avatarFullPath = (attr) => ((attr.avatar || '').includes('active_storage')
    ? `/api${attr.avatar}`
    : `https://pms.staff-centre.com/${attr.avatar}`);

  return {
    id,
    ...attributes,
    msId: get(msItem, 'id'),
    role: get(msItem, 'id'),
    lastComment: get(lastComment, 'attributes.body'),
    userId: userItem ? `${userItem.id}` : null,
    userName: (
      userItem
        ? `${userItem.attributes.name} ${userItem.attributes.surname}`
        : null
    ),
    readinessDate: userItem ? userItem.attributes.readiness_date : null,
    promotionsCount: userItem ? userItem.attributes.promotions_count : 0,
    msItem,
    avatar: userItem ? avatarFullPath(userItem.attributes) : null,
  };
};

export const convertItems = ({ data, included, ...json }) => {
  const groupedIncluded = keyBy(included, ({ id, type }) => `${type}:${id}`);
  const convertedData = data.map((item) => convertItem(item, groupedIncluded));

  return {
    ...json,
    data: convertedData,
    links: {
      first: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
      last: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
    },
    meta: { total: data.length },
    periodsByUserId: getPlanningPeriodsByUserId(convertedData),
  };
};

export const convertItemJson = ({ data, included }) => {
  const groupedIncluded = keyBy(included, ({ id, type }) => `${type}:${id}`);

  return convertItem(data, groupedIncluded);
};

export default convertItems;
