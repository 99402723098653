import { select, takeEvery, put, call } from 'redux-saga/effects';
import { isNull, find } from 'lodash';
import { addError, addSuccess } from '../notifications/actions';
import * as constants from './constants';
import * as actions from './actions';
import Api from '../../utils/Api';

function* createItem({ payload: { crewChangeId, timeplan, attributes } }) {
  try {
    const { data: json } = yield call(Api.crewList.create, crewChangeId, attributes);

    yield put(addSuccess('Crew list updated successfully'));
    yield put(actions.addCreatedItem({
      item: { id: json.data.id, ...json.data.attributes },
      timeplan,
    }));
  } catch (e) {
    if (e.apiErrors[0].status === '422') {
      yield put(addError(e.apiErrors[0].title, e.message));
    }

    yield put(actions.updateItemError(e));
  }
}

function* updateItem({ payload: { id, timeplan, attributes } }) {
  try {
    const { data: json } = yield call(Api.crewList.update, id, attributes);

    yield put(addSuccess('Crew list updated successfully'));
    yield put(actions.updateItem({
      item: { id: json.data.id, ...json.data.attributes },
      timeplan,
    }));
  } catch (e) {
    if (e.apiErrors[0].status === '422') {
      yield put(addError(e.apiErrors[0].title, e.message));
    }

    yield put(actions.updateItemError(e));
  }
}

function* updateItemEndOfContract({ payload: { id, eocValue } }) {
  try {
    const attributes = { reason_id: eocValue };
    const { data: json } = yield call(Api.crewList.update, id, attributes);

    yield put(addSuccess('[End of contract] updated successfully'));
  } catch (e) {
    if (e.apiErrors[0].status === '422') {
      yield put(addError(e.apiErrors[0].title, e.message));
    }

    yield put(actions.updateItemError(e));
  }
}



function* deleteItem({ payload: { id, timeplan } }) {
  try {
    yield call(Api.crewList.delete, id);

    yield put(addSuccess('Crew list updated successfully'));
    yield put(actions.deleteItem({ id, timeplan }));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}

function* handleEditCrewList({ payload: timeplan }) {
  try {
    const crewListState = yield select((s) => s.crewList);
    const { data: crewChange, data: { crewList } } = yield select((s) => s.crewChangeDetails);

    const crewListItem = crewList.find((i) => (
      i.sign_off_id === Number(timeplan.id)
      || i.sign_on_id === Number(timeplan.id)
    ));

    if (crewListItem) {
      const hasOnSign = !isNull(crewListItem.sign_on_id);
      const hasOffSign = !isNull(crewListItem.sign_off_id);

      const isTimeplanOnSign = crewListItem.sign_on_id === Number(timeplan.id);
      const isTimeplanOffSign = crewListItem.sign_off_id === Number(timeplan.id);

      if (!isTimeplanOnSign && !isTimeplanOffSign) {
        yield put(addError('Something went wrong, error line is: "if (!isTimeplanOnSign && !isTimeplanOffSign) {"'));
        return;
      }

      if (hasOnSign && hasOffSign) {
        yield put(actions.updateItemRequest({
          id: crewListItem.id,
          attributes: { [isTimeplanOnSign ? 'sign_on_id' : 'sign_off_id']: null },
          timeplan,
        }));
      } else {
        yield put(actions.deleteItemRequest({ id: crewListItem.id, timeplan }));
      }
      return;
    }

    const isNewTimeplanOnSign = (
      new Date(
        new Date(timeplan.start).getTime() + (
          (new Date(timeplan.end).getTime() - new Date(timeplan.start).getTime()) / 2
        ),
      ).getTime() > new Date(crewChange.date).getTime()
    );

    const timeplanChange = find(crewListState.data, (t) => t.msId === timeplan.msId);

    if (timeplanChange) {
      const timeplanChangeCrewList = crewList.find((i) => (
        i[isNewTimeplanOnSign ? 'sign_off_id' : 'sign_on_id'] === Number(timeplanChange.id)
      ));

      yield put(actions.updateItemRequest({
        id: timeplanChangeCrewList.id,
        attributes: { [isNewTimeplanOnSign ? 'sign_on_id' : 'sign_off_id']: timeplan.id },
        timeplan,
      }));
    } else {
      yield put(actions.createItemRequest({
        crewChangeId: crewChange.id,
        attributes: { [isNewTimeplanOnSign ? 'sign_on_id' : 'sign_off_id']: timeplan.id },
        timeplan,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.RESELECT_TIMEPLAN, handleEditCrewList),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.UPDATE_ITEM_EOC_REQUEST, updateItemEndOfContract),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
  ];
}
