import { isPlainObject, reduce } from "lodash";

const convertMultiValue = (value, asAnd = false) => {
  return asAnd ? value.replace(/\|/g, "&") : value;
};

const convertFilterToElasticQuery = (filter, filterType = {}) =>
  reduce(
    filter,
    (acc, value, keyName) =>
      value
        ? [
            ...acc,
            `${keyName}:${convertMultiValue(
              isPlainObject(value) ? value.label : value,
              filterType[keyName]
            )}`,
          ]
        : acc,
    []
  ).join(",");

const modifiedSearchField = (searchInput) => {
  const trimmedInput = searchInput?.trim();

  if (!trimmedInput) return "";

  const splitedInput = trimmedInput.split(/\s+/).join(" ");

  if (!/\s+/g.test(splitedInput) || /\b(OR|AND)\b/i.test(splitedInput))
    return splitedInput;
  return splitedInput.replace(/\s+/g, " AND ");
};

export const buildFilterQuery = (filter, filterType, name) => {
  const {
    company_manager_name,
    favorites, 
    show_archived, 
    ism_manager, 
    engine_type, 
    ...restFilters
  } = filter;

  const search = convertFilterToElasticQuery(
    {
      name: modifiedSearchField(name),
      ism_manager: modifiedSearchField(ism_manager),
      engine_type: modifiedSearchField(engine_type),
      ...restFilters,
    },
    filterType
  );

  return {
    search, 
    favorites, 
    show_archived, 
    company_manager_name: company_manager_name ? company_manager_name : undefined,
  };  
};
