import { isEmpty, isArray } from 'lodash';

/**
 * add422ErrorsIfWeHaveIt
 * @param mainError
 * @param responseErrors
 */
export const add422ErrorsIfWeHaveIt = (mainError, responseErrors) => {
  const errorByField = responseErrors.reduce((acc, { status, source, title }) => {
    if (status === '422' && source.pointer) {
      const key = source.pointer.split('/')[3];

      return { ...acc, [key]: title };
    }

    return acc;
  }, {});

  if (!isEmpty(errorByField)) mainError.errorByField = errorByField; // eslint-disable-line no-param-reassign
};

export default async (response) => {
  const errorName = `${response.status} ${response.statusText}`;
  const mainError = new Error(errorName);

  let responseData;

  try {
    responseData = await response.json();
  } catch (e) {
    responseData = {
      errors: [{
        status: response.status,
        title: response.statusText,
      }],
    };
  }

  mainError.response = response;
  mainError.apiErrors = responseData.errors;

  if (isArray(responseData.errors)) {
    add422ErrorsIfWeHaveIt(mainError, responseData.errors);
  }

  return mainError;
};
