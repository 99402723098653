import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import { createMatchSelector } from 'connected-react-router';
import { actions, constants } from './redux';
import Api from '../../utils/Api';
import { addItem as notify } from '../notifications/actions';
import { deleteItem as deleteDialog } from '../dialog-manager/actions';
import { fetchItem as fetchUser } from '../user-details-page/sagas';
import {sagas as userExperienceSagas} from '../user-experiences/redux';

const pathConfig = { path: '/users/:id/:tab', exact: true };

export function* fetchItems({ payload: {id}}) {
  try {
    const data = yield call(Api.userExperiences.getPerformances, id);
    
    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createItem({ payload: {form: payload, cb} }) {
  try {
    const match = yield select(createMatchSelector(pathConfig));
    
    const {data} = yield call(Api.performances.create, payload);
    yield delay(1000);
    yield put(actions.addCreatedItem(data));
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The performance created successfully',
    }));

    yield call (cb);
    yield userExperienceSagas.fetchItems();
    if (payload.doc_type === 'promotion') {
    yield fetchUser({payload: match.params.id});
    }
  } catch (e) {
    yield put(actions.createItemError(e));

    console.error(e);
  }
}


export function* deleteItem({ payload: id }) {
  try {
    const match = yield select(createMatchSelector(pathConfig));

    yield call(Api.performances.delete, id);

    yield put(actions.deleteItem(id));
    yield put(deleteDialog());
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The performance deleted successfully',
    }));

    yield userExperienceSagas.fetchItems();
    yield fetchUser({payload: match.params.id});
  } catch (e) {
    yield put(actions.deleteItemError(e));

    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
  */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
  ];
}
