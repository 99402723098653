import { handleActions } from 'redux-actions';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';

const routeConfig = { path: '/users/:id/:tab' };

const initialState = { ...baseInitState, data: null };
const constants = getConstantsByFeatureName('USER_DETAILS_PAGE');
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.ADD_LOADED_ITEM]: (state, action) => ({
    ...state,
    isLoading: false,
    isPushing: false,
    didLoad: true,
    data: action.payload,
  }),
  [LOCATION_CHANGE]: (state, { payload }) => (
    matchPath(payload.location.pathname, routeConfig)
      ? state
      : initialState
  ),
}, initialState);

export { constants, actions, reducer };
