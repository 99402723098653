import { call, put, select, takeEvery } from "redux-saga/effects";
import { matchPath } from "react-router-dom";
import { LOCATION_CHANGE, getLocation } from "connected-react-router";
import { actions, constants, selector, routeConfig } from "./redux";
import { addSuccess, addError } from "../notifications/actions";
import Api from "../../utils/Api";
import { didDocumentLocationChange } from "../../utils/router";
import { get } from "lodash";

export function* fetchItem() {
  try {
    const { pathname } = yield select(getLocation);
    const {
      params: { id },
    } = matchPath(pathname, routeConfig);
    const featureState = yield select(selector);
    const data = yield call(Api.crewUser.show, id, featureState);

    yield put(actions.addLoadedItem(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemError(e));
  }
}

export function* fetchItemsPageInit(action) {
  const match = didDocumentLocationChange(action, routeConfig);

  if (match) {
    yield put(actions.loadItemRequest(match.params.id));
  }
}

function* assignAsCoordinator({ payload }) {
  try {
    const { id, vesselId, relation } = payload;

    const data = [{ id: vesselId, type: "vessels" }];

    yield call(Api.crewUser.addRelation, id, relation, data);

    yield put(actions.loadItemRequest());

    yield put(addSuccess(`User is assigned as Crew Coordinator successfully`));
  } catch (e) {
    console.error(e);

    if (get(e, "apiErrors[0].status") === "400") {
      yield put(
        addError("User is already assigned as Crew Coordinator for this vessel")
      );
    }
  }
}

function* unAssignAsCoordinator({ payload }) {
  try {
    const { vesselId, relation } = payload;

    const data = { id: null, type: "clients" };

    yield call(Api.vessels.updateRelation, vesselId, relation, data);

    yield put(actions.loadItemRequest());

    yield put(addSuccess("Crew Coordinator unassigned successfully"));
  } catch (e) {
    console.error(e);
  }
}

function* assignAsRecruiter({ payload }) {
  try {
    const { id, relation, data, message } = payload;

    yield call(Api.crewUser.addRelation, id, relation, data);

    yield put(actions.loadItemRequest());

    yield put(addSuccess(message));
  } catch (e) {
    console.error(e);

    if (get(e, "apiErrors[0].status") === "400") {
      yield put(
        addError("User is already assigned as Crew Inspector for this owner")
      );
    }
  }
}

function* unassignAsRecruiter({ payload }) {
  try {
    const { id, relation, data } = payload;

    yield call(Api.crewUser.deleteRelation, id, relation, data);

    yield put(actions.loadItemRequest());

    yield put(addSuccess("The Crew Inspector is unassingned successfully"));
  } catch (e) {
    console.error(e);
  }
}

/**
 * getFeatureSagas
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    takeEvery(constants.ASSIGN_AS_COORDINATOR, assignAsCoordinator),
    takeEvery(constants.UNASSIGN_AS_COORDINATOR, unAssignAsCoordinator),
    takeEvery(constants.ASSIGN_AS_RECRUITER, assignAsRecruiter),
    takeEvery(constants.UNASSIGN_AS_RECRUITER, unassignAsRecruiter),
  ];
}
