import * as api from './adapter';


export default {
  /**
   * select vessel
   * @param {Object} credentials: login and password
   * @returns {Promise}
   */
  login: (credentials) => api.apiRequest(
    '/api/auth/sign_in',
    { method: 'POST', body: JSON.stringify(credentials) },
  ),
  /**
   * checkToken
   * @returns {Promise}
   */
  checkToken: () => api.apiRequest('/api/auth/validate_token'),
  loginGoogle: (credentials) => api.apiRequest(
    '/api/auth/google_oauth2',
    { method: 'POST', body: JSON.stringify(credentials) },
  ),
};
