import { handleActions, createAction } from "redux-actions";
import selectors from "./selector";

//PPE - Personal Protective Equipment
// @OWNER_PPE_MATRIX

const initialState = {
  // PPE MATRIX SECTION
  equipmentMatrix: null,
  equipmentMatrixLoading: false,
  equipmentMatrixError: null,

  currentEquipmentMatrix: null,

  // PPE MATRIX ITEMS SECTION
  currentEquipmentMatrixItems: null,
  currentEquipmentMatrixLoading: false,
  currentEquipmentMatrixError: null,

  // PPE MATRIX EQUIPMENTS SECTION
  currentEquipmentMatrixEquipments: null,
  currentEquipmentMatrixEquipmentsLoading: false,
  currentEquipmentMatrixEquipmentsError: null,

  // PPE MATRIX ROLES SECTION
  currentEquipmentMatrixRoles: null,
  currentEquipmentMatrixRolesLoading: false,
  currentEquipmentMatrixRolesError: null,

  // PPE MATRIX PRICES SECTION
  currentEquipmentMatrixPrices: null,
  currentEquipmentMatrixPricesLoading: false,
  currentEquipmentMatrixPricesError: null,

  // COPY MATRIX SECTION
  copyFormMatrixShow: false,
  copyFormMatrixLoading: false,
  copyFormMatrixError: null,
  copyFormMatrixSuccess: null,

  // ADD MATRIX SECTION
  createFormMatrixShow: false,
  createFormMatrixStep: 0,
  createFormAllRanks: [],
  createFormOwnerRanks: [],
  createFormAllEquipmentOptions: [],
  createMatrixLoading: false,
  createMatrixError: null,

  //EDIT MATRIX SECTION
  editFormMatrixShow: false,
  editFormMatrixStep: 0,
  editFormAllRanks: [],
  editFormOwnerRanks: [],
  editFormAllEquipmentOptions: [],
  editMatrixLoading: false,
  editMatrixError: null,

  // REPORT SECTION
  reportLoading: false,
  reportError: null,
  reportData: null,
};

export const constants = {
  RESET_STATE: "@OWNER_PPE_MATRIX/RESET_STATE",

  // PPE MATRIX SECTION
  LOAD_PPE_MATRIX_REQUEST: "@OWNER_PPE_MATRIX/LOAD_PPE_MATRIX_REQUEST",
  LOAD_PPE_MATRIX_SUCCESS: "@OWNER_PPE_MATRIX/LOAD_PPE_MATRIX_SUCCESS",
  LOAD_PPE_MATRIX_ERROR: "@OWNER_PPE_MATRIX/LOAD_PPE_MATRIX_ERROR",

  SET_CURRENT_PPE_MATRIX: "@OWNER_PPE_MATRIX/SET_CURRENT_PPE_MATRIX",

  CHANGE_ITEM_VALUE: "@OWNER_PPE_MATRIX/CHANGE_ITEM_VALUE",
  CHANGE_EQUIPMENT_PRICE: "@OWNER_PPE_MATRIX/CHANGE_EQUIPMENT_PRICE",

  LOAD_CURRENT_PPE_MATRIX_REQUEST:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_REQUEST",
  LOAD_CURRENT_PPE_MATRIX_SUCCESS:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_SUCCESS",
  LOAD_CURRENT_PPE_MATRIX_ERROR:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_ERROR",

  LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_REQUEST:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_REQUEST",
  LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_SUCCESS:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_SUCCESS",
  LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_ERROR:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_ERROR",

  LOAD_CURRENT_PPE_MATRIX_PRICES_REQUEST:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_PRICES_REQUEST",
  LOAD_CURRENT_PPE_MATRIX_PRICES_SUCCESS:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_PRICES_SUCCESS",
  LOAD_CURRENT_PPE_MATRIX_PRICES_ERROR:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_PRICES_ERROR",

  LOAD_CURRENT_PPE_MATRIX_ROLES_REQUEST:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_ROLES_REQUEST",
  LOAD_CURRENT_PPE_MATRIX_ROLES_SUCCESS:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_ROLES_SUCCESS",
  LOAD_CURRENT_PPE_MATRIX_ROLES_ERROR:
    "@OWNER_PPE_MATRIX/LOAD_CURRENT_PPE_MATRIX_ROLES_ERROR",

  SET_COPY_FORM_MATRIX: "@OWNER_PPE_MATRIX/SET_COPY_FORM_MATRIX",
  COPY_MATRIX_REQUEST: "@OWNER_PPE_MATRIX/COPY_MATRIX_REQUEST",
  COPY_MATRIX_SUCCESS: "@OWNER_PPE_MATRIX/COPY_MATRIX_SUCCESS",
  COPY_MATRIX_ERROR: "@OWNER_PPE_MATRIX/COPY_MATRIX_ERROR",

  SET_CREATE_FORM_MATRIX: "@OWNER_PPE_MATRIX/SET_CREATE_FORM_MATRIX",
  SET_CREATE_FORM_MATRIX_STEP: "@OWNER_PPE_MATRIX/SET_CREATE_FORM_MATRIX_STEP",
  SET_CREATE_FORM_ALL_RANKS: "@OWNER_PPE_MATRIX/SET_CREATE_FORM_ALL_RANKS",
  SET_CREATE_FORM_ALL_EQUIPMENT_OPTIONS:
    "@OWNER_PPE_MATRIX/SET_CREATE_FORM_ALL_EQUIPMENT_OPTIONS",
  SET_CREATE_FORM_OWNER_RANKS: "@OWNER_PPE_MATRIX/SET_CREATE_FORM_OWNER_RANKS",

  CREATE_MATRIX_REQUEST: "@OWNER_PPE_MATRIX/CREATE_MATRIX_REQUEST",
  CREATE_MATRIX_SUCCESS: "@OWNER_PPE_MATRIX/CREATE_MATRIX_SUCCESS",
  CREATE_MATRIX_ERROR: "@OWNER_PPE_MATRIX/CREATE_MATRIX_ERROR",

  SET_EDIT_FORM_MATRIX: "@OWNER_PPE_MATRIX/SET_EDIT_FORM_MATRIX",
  SET_EDIT_FORM_MATRIX_STEP: "@OWNER_PPE_MATRIX/SET_EDIT_FORM_MATRIX_STEP",
  SET_EDIT_FORM_ALL_RANKS: "@OWNER_PPE_MATRIX/SET_EDIT_FORM_ALL_RANKS",
  SET_EDIT_FORM_ALL_EQUIPMENT_OPTIONS:
    "@OWNER_PPE_MATRIX/SET_EDIT_FORM_ALL_EQUIPMENT_OPTIONS",
  SET_EDIT_FORM_OWNER_RANKS: "@OWNER_PPE_MATRIX/SET_EDIT_FORM_OWNER_RANKS",

  EDIT_MATRIX_REQUEST: "@OWNER_PPE_MATRIX/EDIT_MATRIX_REQUEST",
  EDIT_MATRIX_SUCCESS: "@OWNER_PPE_MATRIX/EDIT_MATRIX_SUCCESS",
  EDIT_MATRIX_ERROR: "@OWNER_PPE_MATRIX/EDIT_MATRIX_ERROR",
};

export const actions = {
  resetState: createAction(constants.RESET_STATE),

  loadEquipmentMatrixRequest: createAction(constants.LOAD_PPE_MATRIX_REQUEST),
  loadEquipmentMatrixSuccess: createAction(constants.LOAD_PPE_MATRIX_SUCCESS),
  loadEquipmentMatrixError: createAction(constants.LOAD_PPE_MATRIX_ERROR),

  setCurrentEquipmentMatrix: createAction(constants.SET_CURRENT_PPE_MATRIX),

  changeItemValue: createAction(constants.CHANGE_ITEM_VALUE),
  changeEquipmentPrice: createAction(constants.CHANGE_EQUIPMENT_PRICE),

  loadCurrentEquipmentMatrixRequest: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_REQUEST
  ),
  loadCurrentEquipmentMatrixSuccess: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_SUCCESS
  ),
  loadCurrentEquipmentMatrixError: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_ERROR
  ),

  loadCurrentEquipmentMatrixEquipmentsRequest: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_REQUEST
  ),
  loadCurrentEquipmentMatrixEquipmentsSuccess: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_SUCCESS
  ),
  loadCurrentEquipmentMatrixEquipmentsError: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_ERROR
  ),

  loadCurrentEquipmentMatrixRolesRequest: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_ROLES_REQUEST
  ),
  loadCurrentEquipmentMatrixRolesSuccess: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_ROLES_SUCCESS
  ),
  loadCurrentEquipmentMatrixRolesError: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_ROLES_ERROR
  ),

  loadCurrentEquipmentMatrixPricesRequest: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_PRICES_REQUEST
  ),
  loadCurrentEquipmentMatrixPricesSuccess: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_PRICES_SUCCESS
  ),
  loadCurrentEquipmentMatrixPricesError: createAction(
    constants.LOAD_CURRENT_PPE_MATRIX_PRICES_ERROR
  ),

  setCopyFormMatrixShow: createAction(constants.SET_COPY_FORM_MATRIX),
  copyMatrixRequest: createAction(constants.COPY_MATRIX_REQUEST),
  copyMatrixSuccess: createAction(constants.COPY_MATRIX_SUCCESS),
  copyMatrixError: createAction(constants.COPY_MATRIX_ERROR),

  setCreateFormMatrixShow: createAction(constants.SET_CREATE_FORM_MATRIX),
  setCreateFormMatrixStep: createAction(constants.SET_CREATE_FORM_MATRIX_STEP),
  setCreateFormAllRanks: createAction(constants.SET_CREATE_FORM_ALL_RANKS),
  setCreateFormAllEquipmentOptions: createAction(
    constants.SET_CREATE_FORM_ALL_EQUIPMENT_OPTIONS
  ),
  setCreateFormOwnerRanks: createAction(constants.SET_CREATE_FORM_OWNER_RANKS),

  createMatrixRequest: createAction(constants.CREATE_MATRIX_REQUEST),
  createMatrixSuccess: createAction(constants.CREATE_MATRIX_SUCCESS),
  createMatrixError: createAction(constants.CREATE_MATRIX_ERROR),

  setEditFormMatrixShow: createAction(constants.SET_EDIT_FORM_MATRIX),
  setEditFormMatrixStep: createAction(constants.SET_EDIT_FORM_MATRIX_STEP),
  setEditFormAllRanks: createAction(constants.SET_EDIT_FORM_ALL_RANKS),
  setEditFormAllEquipmentOptions: createAction(
    constants.SET_EDIT_FORM_ALL_EQUIPMENT_OPTIONS
  ),
  setEditFormOwnerRanks: createAction(constants.SET_EDIT_FORM_OWNER_RANKS),

  editMatrixRequest: createAction(constants.EDIT_MATRIX_REQUEST),
  editMatrixSuccess: createAction(constants.EDIT_MATRIX_SUCCESS),
  editMatrixError: createAction(constants.EDIT_MATRIX_ERROR),
};

const actionHandlers = {
  [constants.RESET_STATE]: () => initialState,

  [constants.LOAD_PPE_MATRIX_REQUEST]: (state) => ({
    ...state,
    equipmentMatrixLoading: true,
    equipmentMatrix: null,
  }),
  [constants.LOAD_PPE_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    equipmentMatrix: data,
    equipmentMatrixLoading: false,
  }),
  [constants.LOAD_PPE_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    equipmentMatrixError: error,
    equipmentMatrixLoading: false,
  }),

  [constants.SET_CURRENT_PPE_MATRIX]: (
    state,
    { payload: currentEquipmentMatrix }
  ) => ({
    ...state,
    currentEquipmentMatrix,
  }),

  [constants.LOAD_CURRENT_PPE_MATRIX_REQUEST]: (state) => ({
    ...state,
    currentEquipmentMatrixLoading: true,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    currentEquipmentMatrixItems: data,
    currentEquipmentMatrixLoading: false,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_ERROR]: (
    state,
    { payload: { error } }
  ) => ({
    ...state,
    currentEquipmentMatrixError: error,
  }),

  [constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_REQUEST]: (state) => ({
    ...state,
    currentEquipmentMatrixEquipmentsLoading: true,
    currentEquipmentMatrixEquipments: null,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    currentEquipmentMatrixEquipments: data,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_EQUIPMENTS_ERROR]: (
    state,
    { payload: { error } }
  ) => ({
    ...state,
    currentEquipmentMatrixEquipmentsError: error,
  }),

  [constants.LOAD_CURRENT_PPE_MATRIX_ROLES_REQUEST]: (state) => ({
    ...state,
    currentEquipmentMatrixRolesLoading: true,
    currentEquipmentMatrixRoles: null,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_ROLES_SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    currentEquipmentMatrixRoles: data,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_ROLES_ERROR]: (
    state,
    { payload: { error } }
  ) => ({
    ...state,
    currentEquipmentMatrixRolesError: error,
  }),

  [constants.LOAD_CURRENT_PPE_MATRIX_PRICES_REQUEST]: (state) => ({
    ...state,
    currentEquipmentMatrixPricesLoading: true,
    currentEquipmentMatrixPrices: null,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_PRICES_SUCCESS]: (
    state,
    { payload: { data } }
  ) => ({
    ...state,
    currentEquipmentMatrixPrices: data,
  }),
  [constants.LOAD_CURRENT_PPE_MATRIX_PRICES_ERROR]: (
    state,
    { payload: { error } }
  ) => ({
    ...state,
    currentEquipmentMatrixPricesError: error,
  }),

  // COPY MATRIX SECTION
  [constants.SET_COPY_FORM_MATRIX]: (
    state,
    { payload: copyFormMatrixShow }
  ) => ({
    ...state,
    copyFormMatrixShow,
  }),
  [constants.COPY_MATRIX_REQUEST]: (state) => ({
    ...state,
    copyMatrixLoading: true,
  }),
  [constants.COPY_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    copyMatrixError: error,
    copyMatrixLoading: false,
  }),
  [constants.COPY_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    copyMatrixSuccess: data,
    copyMatrixLoading: false,
  }),
  // CREATE MATRIX SECTION
  [constants.SET_CREATE_FORM_MATRIX]: (
    state,
    { payload: createFormMatrixShow }
  ) => ({
    ...state,
    createFormMatrixShow,
  }),
  [constants.SET_CREATE_FORM_MATRIX_STEP]: (
    state,
    { payload: createFormMatrixStep }
  ) => ({
    ...state,
    createFormMatrixStep,
  }),
  [constants.SET_CREATE_FORM_ALL_RANKS]: (
    state,
    { payload: createFormAllRanks }
  ) => ({
    ...state,
    createFormAllRanks,
  }),
  [constants.SET_CREATE_FORM_ALL_EQUIPMENT_OPTIONS]: (
    state,
    { payload: createFormAllEquipmentOptions }
  ) => ({
    ...state,
    createFormAllEquipmentOptions,
  }),
  [constants.SET_CREATE_FORM_OWNER_RANKS]: (
    state,
    { payload: createFormOwnerRanks }
  ) => ({
    ...state,
    createFormOwnerRanks,
  }),

  [constants.CREATE_MATRIX_REQUEST]: (state) => ({
    ...state,
    createMatrixLoading: true,
  }),
  [constants.CREATE_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    createMatrixError: error,
    createMatrixLoading: false,
  }),
  [constants.CREATE_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    createMatrixSuccess: data,
    createMatrixLoading: false,
  }),

  // EDIT MATRIX SECTION
  [constants.SET_EDIT_FORM_MATRIX]: (
    state,
    { payload: editFormMatrixShow }
  ) => ({
    ...state,
    editFormMatrixShow,
  }),
  [constants.SET_EDIT_FORM_MATRIX_STEP]: (
    state,
    { payload: editFormMatrixStep }
  ) => ({
    ...state,
    editFormMatrixStep,
  }),
  [constants.SET_EDIT_FORM_ALL_RANKS]: (
    state,
    { payload: editFormAllRanks }
  ) => ({
    ...state,
    editFormAllRanks,
  }),
  [constants.SET_EDIT_FORM_ALL_EQUIPMENT_OPTIONS]: (
    state,
    { payload: editFormAllEquipmentOptions }
  ) => ({
    ...state,
    editFormAllEquipmentOptions,
  }),
  [constants.SET_EDIT_FORM_OWNER_RANKS]: (
    state,
    { payload: editFormOwnerRanks }
  ) => ({
    ...state,
    editFormOwnerRanks,
  }),

  [constants.EDIT_MATRIX_REQUEST]: (state) => ({
    ...state,
    editMatrixLoading: true,
  }),
  [constants.EDIT_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    editMatrixError: error,
    editMatrixLoading: false,
  }),
  [constants.EDIT_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    editMatrixSuccess: data,
    editMatrixLoading: false,
  }),
};
export const reducer = handleActions(actionHandlers, initialState);

export const selector = selectors;
