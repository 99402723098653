import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';

const initialState = { ...baseInitState, data: {} };
const constants = getConstantsByFeatureName('USER_DETAILS');
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);

const reducer = handleActions({
  ...actionHandlers,
  [constants.ADD_LOADED_ITEM]: (state, action) => ({
    ...state,
    isLoading: false,
    isPushing: false,
    data: action.payload,
  }),
  [LOCATION_CHANGE]: () => initialState,
}, initialState);

const selector = getSelectorByFeatureName('userDetails');

export { constants, actions, reducer, selector };
