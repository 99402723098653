import * as api from './adapter';
import { convertItems, convertItem, convertListOfItems } from './convertors/assignedOwners';

export default {
  index: async (clientId) => {
    const { data } = await api.read('assign_owners', {
      query: {
        include: 'vessel,vessel.manning_scales',
        filter: { client_id: clientId },
        page: { size: 999 },
      },
    });

    return convertItems(data);
  },
  getItems: async ({ ownerId, clientId }) => {
    const { data } = await api.read('assign_owners', {
      query: (
        ownerId
          ? { include: 'client', filter: { owner_id: ownerId }, page: { size: 999 } }
          : { include: 'owner', filter: { client_id: clientId }, page: { size: 999 } }
      ),
    });

    return convertListOfItems(data);
  },
  /**
   * select vessel
   * @param vesselId
   * @param clientId
   * @returns {Promise}
   */
  create: async (vesselId, clientId) => {
    const { data } = await api.create({
      type: 'assign_owners',
      relationships: {
        owner: { data: { id: vesselId, type: 'owners' } },
        client: { data: { id: clientId, type: 'clients' } },
      },
    }, { query: { include: 'client,owner' } });

    return convertItem(data);
  },
  /**
   * unselect vessel
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'assign_owners' }),
};
