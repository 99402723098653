import get from 'lodash/get';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { constants as crewUserDetailsConstants } from '../crew-user-details/redux';
import { addSuccess, addError } from '../notifications/actions';
import { actions, constants } from './redux';
import Api from '../../utils/Api';

function* fetchItems({ payload }) {
  try {
    yield put(actions.loadItemRequest()); // set is loading state

    const data = yield call(Api.assignedVessels.getItems, { clientId: payload.id });

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* createItem({ payload: option }) {
  try {
    const crewUserId = yield select((s) => s.crewUserDetails.data.id);
    const data = yield call(Api.assignedVessels.create, option.value, crewUserId);

    yield put(actions.addCreatedItem(data));
    yield put(addSuccess(`${option.label} vessel has assigned successfully`));
  } catch (e) {
    console.error(e);

    if (get(e, 'apiErrors[0].status') === '422') {
      yield put(addError('The vessel has already assigned', e.message));
    }

    yield put(actions.createItemError(e));
  }
}

function* deleteItem({ payload: id }) {
  try {
    yield call(Api.assignedVessels.delete, id);

    yield put(actions.deleteItem(id));
    yield put(addSuccess('The vessel has unassigned successfully'));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}


/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(crewUserDetailsConstants.ADD_LOADED_ITEM, fetchItems),
  ];
}
