import * as api from './adapter';

export default {
  index: () => ({
    data: {
      data: [
        {
          id: '020b39f4',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '02db4ced',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '0ae61044',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '16c5924a',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '25e6146e',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '263afa97',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '3341aee0',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '3e60c8ee',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '44a633c8',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '483b30e1',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '6f68a009',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '7fd42918',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '86f905ba',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: '95d3e9d2',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
        {
          id: 'b6181eb9',
          attributes: { type: 'LSE', date: '17-05-2021', score: '100', evaluation: 'good' },
        },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/user_relatives/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'user_relatives', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'user_relatives', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'user_relatives' }),
};
