import { call, put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../utils/Api';
import { actions, constants } from './redux';

const filterNull = (obj) => Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null));
export function* fetchItems() {
  try {
    const filter = yield select((s) => s.permissions.filter);
    const { data } = yield call(Api.permissions.index, { filter: filterNull(filter) });

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

export function* updateItem({ payload: { id, cb, ...payload } }) {
  try {
    yield call(Api.permissions.update, id, payload);
    yield fetchItems();
    if (cb) yield call(cb);
  } catch (e) {
    yield put(actions.updateItemError(e));
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [
        constants.LOAD_ITEMS_REQUEST,
        constants.APPLY_FILTER,
      ],
      fetchItems,
    ),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
  ];
}
