import * as api from "./adapter";
import {
  convertItems,
} from "./convertors/experienceVerifications";

const requestParams = { query: { include: "author", sort: "-created_at" } };

export default {
  /**
   * index
   * @param id
   * @return {Promise<*>}
   */
  index: async (id) => {
    const { data } = await api.read(`expiriences/${id}/verifications`, {
      query: {
        ...requestParams.query,
        page: { size: 999 },
      },
    });
    return convertItems(data);
  },
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/experience_verifications/${id}`),
  /**
   * createVerification
   * @param attributes
   * @returns {Promise}
   */
  create: async ({ ...attributes }) => {
    const resp = await api.create({
      type: "experience_verifications",
      attributes,
    });
    return resp;
  },
  /**
   * updateVerifification
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, ...attributes) => {
    const resp = await api.update({
      id,
      type: "experience_verifications",
      attributes,
    });
    return resp;
  },
  /**
   * deleteVerifification
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "experience_verifications" }),
  /**
   * fetchExperienceCoincidences
   * @param id
   * @returns {Promise}
   */
  checkCoincidences: (id) =>
    api.read(`experience_verifications/check_coincidences/${id}`),
};
