import * as api from './adapter';
import { normalizeRelations } from './convertors/normalizeRelations';

export default {
  /**
   * getCompanies
   * @returns {Promise<*>}
   */
  index: ({ search } = {}) => api.read('role_levels', {
    query: {
      filter: { title: search },
    },
  }),
  /**
   * create
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({
    type: 'role_levels',
    attributes,
  }),
  /**
   * update
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({
    id,
    type: 'role_levels',
    attributes,
  }),
  /**
   * delete
   * @param id
   * @returns {Promise}
   */
  delete: (id) => api.destroy({
    id,
    type: 'role_levels',
  }),
};
