import * as api from './adapter';


export default {
  printAppForm: async (cvType, caId, cvName='') => {
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/${cvType}?crewing_applicant_id=${caId}&cv_name=${cvName}`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"', '');
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },
};
