import React from "react";
import PageToolbar from "../PageToolbar";
import wrapPage from "../decorators/wrapPage";

const PreviewContent = () => <PageToolbar />;

const Page = wrapPage(PreviewContent);

const LoadingPagePreview = () => <Page isLoading />;
export default LoadingPagePreview;
