import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import auth from './features/authorization/reducer';
import notifications from './features/notifications/reducer';
import dialogs from './features/dialog-manager/reducer';
import { reducer as engineTypes } from './features/engine-types/redux';
import { reducer as engineManufacturers } from './features/engine-manufacturers/redux';
import { reducer as crewingStatuses } from './features/crewing-statuses/redux';
import { reducer as vessels } from './features/vessels/redux';
import { reducer as vesselDetails } from './features/vessel-details/redux';
import { reducer as assignedVessels } from './features/assigned-vessels';
import { reducer as vesselOwners } from './features/vessel-owners';
import { reducer as ranks } from './features/ranks/redux';
import { reducer as usersSection } from './features/users-section/redux';
import { reducer as userDetails } from './features/user-details/redux';
import { reducer as timeplans } from './features/timeplans/redux';
import { reducer as comments } from './features/comments/redux';
import { reducer as tgChat } from './features/user-tg-chat/redux';
import { reducer as owners } from './features/owners/redux';
import { reducer as ownerDetails } from './features/owner-details/redux';
import { reducer as ownerDetailsVessels } from './features/owner-details-vessels/redux';
import { reducer as ownerDetailsWage } from './features/owner-details-wage/redux';
import { reducer as ownerDocumentMatrix } from './features/owner-details-document-matrix/redux';
import { reducer as ownerEquipmentMatrix } from './features/owner-details-ppe-matrix/redux';
import { reducer as ownerDetailsRecruiters } from './features/owner-details-recruiters/redux';
import { reducer as managers } from './features/managers/redux';
import { reducer as managerDetails } from './features/manager-details/redux';
import { reducer as managerDetailsVessels } from './features/manager-details-vessels/redux';
import { reducer as crewUsers } from './features/crew-users/redux';
import { reducer as crewUserDetails } from './features/crew-user-details/redux';
import { reducer as crewUserVessels } from './features/crew-user-vessels/redux';
import { reducer as crewUserOwners } from './features/crew-user-owners/redux';
import { reducer as manningScales } from './features/vessel-manning-scale/redux';
import { reducer as assignedCrewUsers } from './features/vessel-details-assigned-crew-users/redux';
import crewList from './features/crew-list/reducer';
import { reducer as crewChanges } from './features/crew-changes/redux';
import { reducer as crewChangeDetails } from './features/crew-change-details/redux';
import { reducer as users } from './features/users/redux';
import { reducer as userDetailsPage } from './features/user-details-page/redux';
import { reducer as userExperiences } from './features/user-experiences/redux';
import { reducer as vesselExperiences } from './features/vessel-experiences/redux';
import { reducer as userDocuments } from './features/user-documents/redux';
import { reducer as userInterviews } from './features/user-interviews/redux';
import { reducer as userRelatives } from './features/user-relatives/redux';
import { reducer as vacancies } from './features/vacancies/redux';
import { reducer as vesselTypes } from './features/vessel-types/redux';
import { reducer as vendorTypes } from './features/vendor-types/redux';
import { reducer as vendors } from './features/vendors/redux';
import { reducer as certificateTypes } from './features/certificate-types/redux';
import { reducer as relationTypes } from './features/relation-types/redux';
import { reducer as countries } from './features/countries/redux';
import { reducer as ports } from './features/ports/redux';
import { reducer as userAttachments } from './features/user-attachment/redux';
import { reducer as mainEngines } from './features/main-engines/redux';
import { reducer as applicantContracts } from './features/applicant-contracts/redux';
import { reducer as applicantDocuments } from './features/applicant-documents/redux';
import { reducer as userRecommendations } from './features/user-recommendations/redux';
import { reducer as userEnglishTests } from './features/user-english-tests/redux';
import { reducer as englishTestTypes } from './features/english-test-types/redux';
import { reducer as vesselSearchEquasis } from './features/vessel-search-equasis/redux';
import { reducer as seafarersView } from './features/users/component/SeafarersView/redux';
import { reducer as commentTypeManagement } from './features/crew-users/component/CommentTypeManagement/redux';
import { reducer as roleLevels } from './features/role-levels/redux';
import { reducer as forceUpdateFromUC } from './features/user-details-page/component/ForceUpdateFromUC/redux';
import { reducer as forceUpdateFromCW } from './features/user-details-page/component/ForceUpdateFromCrewell/redux';
import { reducer as experienceVerifications } from './features/experience-verifications/redux';
import { reducer as appFormLinks } from './features/app-form-links/redux';
import { reducer as performances } from './features/performances/redux';
import { reducer as taskManager } from './features/task-manager/redux';
import { reducer as notificationManager } from './features/notification-manager/redux';
import { reducer as surveyCreator } from './features/survey-creator/redux';
import { reducer as permissions } from './features/permissions/redux'



export default function createRootReducer(history) {
  return combineReducers({
    form,
    auth,
    dialogs,
    comments,
    tgChat,
    engineTypes,
    engineManufacturers,
    crewingStatuses,
    vessels,
    vesselDetails,
    assignedVessels,
    vesselOwners,
    notifications,
    ranks,
    usersSection,
    userDetails,
    timeplans,
    owners,
    ownerDetails,
    ownerDetailsVessels,
    ownerDetailsWage,
    ownerDetailsRecruiters,
    ownerDocumentMatrix,
    ownerEquipmentMatrix,
    managers,
    managerDetails,
    managerDetailsVessels,
    crewUsers,
    crewUserDetails,
    crewUserVessels,
    crewUserOwners,
    manningScales,
    assignedCrewUsers,
    crewList,
    crewChanges,
    crewChangeDetails,
    users,
    userDetailsPage,
    userExperiences,
    vesselExperiences,
    userAttachments,
    userDocuments,
    userInterviews,
    userRelatives,
    vacancies,
    vesselTypes,
    vendorTypes,
    vendors,
    countries,
    ports,
    certificateTypes,
    relationTypes,
    mainEngines,
    applicantContracts,
    applicantDocuments,
    userRecommendations,
    userEnglishTests,
    englishTestTypes,
    vesselSearchEquasis,
    seafarersView,
    commentTypeManagement,
    roleLevels,
    forceUpdateFromUC,
    forceUpdateFromCW,
    experienceVerifications,
    appFormLinks,
    performances,
    taskManager,
    notificationManager,
    surveyCreator,
    permissions,
    router: connectRouter(history),
  });
}
