import { keyBy } from 'lodash';
import { dateStringToDateTime } from '../../DateTime';

const defaultAuthor = {
  id: NaN,
  type: 'users',
  attributes: {
    name: 'Clarion'
  },
};

const addRelationshipsToItem = ({ id, attributes, relationships: { author } }, groupedIncluded) => {
  const ard = author.data || { };
  const authorItem = groupedIncluded[`${ard.type}:${ard.id}`] || defaultAuthor;

  return ({
    id,
    ...attributes,
    createdAt: dateStringToDateTime(attributes.created_at),
    authorId: authorItem.id,
    authorName: authorItem.attributes.name,
    authorAvatar: authorItem.attributes.avatar,
    attachment: attributes.attachment,
    category: attributes.category,
    result: attributes.event_result,
  });
};

/**
 * convertItem
 * @param { Object } item
 * @param { Array } included - list of relationship items
 * @return { Object } item wi th relationships
 */
export const convertItem = ({ data, included }) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}:${incl.id}`);

  return addRelationshipsToItem(data, groupedIncluded);
};


export const convertItems = ({ data, included, ...json }) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}:${incl.id}`);

  return {
    ...json,
    data: data.map((item) => addRelationshipsToItem(item, groupedIncluded)),
  };
};

export default convertItems;
