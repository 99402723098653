import find from 'lodash/find';
import isArray from 'lodash/isArray';


const findElement = (data, rel) => {
  let el = null
  el = find(data.data.included, rel);
  if (!el) el = find(data.data.data, rel);

  return el;
}
export const normalizeRelations = (data) => {
  if (!data.data.included) return data;

  data.data.data.forEach((row) => {
    if (!row.relationships) return;
    Object.keys(row.relationships).forEach((key) => {
      const rel_row = row.relationships[key];
      if (!rel_row.data) return;
      if (isArray(rel_row.data)) {
        rel_row.data = rel_row.data.map((rel) => {
          const el = findElement(data, rel);

          return el ? { ...rel, ...el } : rel;
        });
      } else {
        const rel = rel_row.data;
        const el = findElement(data, rel);

        rel_row.data = (el ? { ...rel, ...el } : rel);
      }
    });
  });

  return data;
};


export default {};
