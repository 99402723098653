import React, { useState, useEffect } from "react";

export const PagePreview = () => null;

const asyncComponent = (getComponent, LoadingComponent) => {
  const AsyncComponent = (props) => {
    const [Component, setComponent] = useState(AsyncComponent.Component);
    const Preview = LoadingComponent || PagePreview;

    useEffect(() => {
      if (!Component) {
        getComponent().then(({ default: LoadedComponent }) => {
          AsyncComponent.Component = LoadedComponent;
          setComponent(LoadedComponent);
        });
      }
    }, [Component]);

    return Component ? <Component {...props} /> : <Preview {...props} />;
  };

  return AsyncComponent;
};

export default asyncComponent;
