import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LinearProgress from "@material-ui/core/LinearProgress";
import styles from "./styles";
import { Avatar } from "@material-ui/core";
import Notifications from "../../features/notification-manager";
import CrewisorGuide from "../../features/crewisor-guide";
import { useSelector } from "react-redux";
import get from "lodash/get";

const anchorOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin = {
  vertical: "top",
  horizontal: "right",
};

const MenuAppBar = ({
  classes,
  username,
  logo,
  menuItems,
  tabs,
  currentTab,
  onTabChange,
  isLoading,
  avatar,
  notificationCount,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const permissions = useSelector((state) =>
    get(state, "auth.data.permissions")
  );

  const handleOpenMenu = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const linearClasses = {
    root: classes.linear,
    colorPrimary: classes.colorPrimary,
    barColorPrimary: classes.barColorPrimary,
  };

  return (
    <AppBar
      elevation={2}
      className={classes.root}
      position="sticky"
      color="inherit"
    >
      {isLoading && <LinearProgress classes={linearClasses} />}
      <Toolbar className={classes.bar}>
        <div className={classes.logo}>{logo}</div>
        {tabs && (
          <div className={classes.tabsSection}>
            <Tabs
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              value={currentTab}
              onChange={onTabChange}
            >
              {tabs}
            </Tabs>
          </div>
        )}

        <div className={classes.grow} />

        {menuItems && (
          <ClickAwayListener onClickAway={handleMenuClose}>
            <div className={classes.userSection}>
              <Grid container direction="row" wrap="nowrap">
                <Grid item className={classes.notificationsButton}>
                  {get(permissions, "showHeaderNotifications.enable") && (
                    <Notifications notificationCount={notificationCount} />
                  )}
                  {get(permissions, "showHeaderHelp.enable") && (
                    <CrewisorGuide />
                  )}
                </Grid>
                <Grid item>
                  <Toolbar
                    className={classes.userInfo}
                    onClick={handleOpenMenu}
                  >
                    <Avatar alt={username} src={avatar} />
                  </Toolbar>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={anchorOrigin}
                    keepMounted
                    transformOrigin={transformOrigin}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <Typography align="center" className={classes.userName}>
                      {username}
                    </Typography>
                    {menuItems}
                  </Menu>
                </Grid>
              </Grid>
            </div>
          </ClickAwayListener>
        )}
      </Toolbar>
    </AppBar>
  );
};

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  logo: PropTypes.any.isRequired,
  menuItems: PropTypes.any,
  tabs: PropTypes.any,
  currentTab: PropTypes.string,
  onTabChange: PropTypes.func,
  isLoading: PropTypes.bool,
  avatar: PropTypes.string,
  notificationCount: PropTypes.number,
};

MenuAppBar.defaultProps = {
  tabs: null,
  currentTab: undefined,
  onTabChange: undefined,
  username: null,
  menuItems: null,
  isLoading: false,
};

export default withStyles(styles)(MenuAppBar);
