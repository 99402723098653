import * as api from './adapter';
import selectOptionsApi from './selectOptions';
import {update} from "./adapter";

const defaultPaging = {
  page: { size: 999 },
}
export default {


  getTypes: () => selectOptionsApi.index({ type: 'crew_wage_scale_types' }),

  getScales: (override) => api.read('crew_wage/scales', {
    ...override,
    query: {
      ...defaultPaging,
      ...override.query,
    }
  }),

  updateScale: (id, attributes) => api.update({ id, type: 'scales', controller: 'crew_wage/scales', attributes: attributes }),

  getBonuses: (override) => api.read('crew_wage/bonuses', {
    ...override,
    query: {
      ...defaultPaging,
      ...override.query,
    }
  }),

  updateBonus: (id, attributes) => api.update({ id, type: 'bonuses', controller: 'crew_wage/bonuses', attributes: attributes }),


}
