import React from 'react';
import { put, takeEvery } from 'redux-saga/effects';
import { addItem as notify } from '../notifications/actions';
import get from 'lodash/get';


function* handleError({ payload: { response, apiErrors } = {} }) {
  if (!response) return;

  const errorMessage = (get(apiErrors, 'error') || response.statusText).split(',').map(e => <div>{e}</div>);

  if (response.status === 500) {
    yield put(notify({
      type: 'error',
      title: '500 Server Error',
      message: errorMessage,
    }));
  }

  if (response.status === 400) {
    yield put(notify({
      type: 'error',
      title: '400 Server Error',
      message: errorMessage,
    }));
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery((action) => action.error, handleError),
  ];
}
