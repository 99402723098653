import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from './getConstantsByFeatureName';
import getActionCreators from './getActionCreators';
import getActionHandlers from './getActionHandlers';
import getSelectorByFeatureName from './getSelectorByFeatureName';
import { initialState } from './commonActionHandlers';


export default (featureName, reduxStatePropertyName, initState = initialState) => {
  const constants = getConstantsByFeatureName(featureName);
  const actions = getActionCreators(constants);
  const actionHandlers = getActionHandlers(constants);
  const reducer = handleActions(actionHandlers, initState);
  const selector = reduxStatePropertyName
    ? getSelectorByFeatureName(reduxStatePropertyName)
    : undefined;

  return {
    constants,
    actions,
    reducer,
    selector,
  };
};
