import { createSelector, createStructuredSelector } from "reselect";
import get from "lodash/get";

const owner = (s) => s.ownerDetails.data;

const equipmentMatrix = (s) => s.ownerEquipmentMatrix.equipmentMatrix;
const equipmentMatrixLoading = (s) =>
  s.ownerEquipmentMatrix.equipmentMatrixLoading;
const equipmentMatrixError = (s) => s.ownerEquipmentMatrix.equipmentMatrixError;

const currentEquipmentMatrix = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrix;

const currentEquipmentMatrixItems = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixItems;
const currentEquipmentMatrixLoading = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixLoading;
const currentEquipmentMatrixError = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixError;

const currentEquipmentMatrixEquipments = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixEquipments;
const currentEquipmentMatrixEquipmentsLoading = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixEquipmentsLoading;
const currentEquipmentMatrixEquipmentsError = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixEquipmentsError;

const currentEquipmentMatrixRoles = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixRoles;
const currentEquipmentMatrixRolesLoading = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixRolesLoading;
const currentEquipmentMatrixRolesError = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixRolesError;

const currentEquipmentMatrixPrices = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixPrices;
const currentEquipmentMatrixPricesLoading = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixPricesLoading;
const currentEquipmentMatrixPricesError = (s) =>
  s.ownerEquipmentMatrix.currentEquipmentMatrixPricesError;

const copyFormMatrixShow = (s) => s.ownerEquipmentMatrix.copyFormMatrixShow;
const copyMatrixLoading = (s) => s.ownerEquipmentMatrix.copyMatrixLoading;
const copyMatrixError = (s) => s.ownerEquipmentMatrix.copyMatrixError;

const createFormMatrixShow = (s) => s.ownerEquipmentMatrix.createFormMatrixShow;
const createFormMatrixStep = (s) => s.ownerEquipmentMatrix.createFormMatrixStep;
const createMatrixLoading = (s) => s.ownerEquipmentMatrix.createMatrixLoading;
const createMatrixError = (s) => s.ownerEquipmentMatrix.createMatrixError;

const editFormMatrixShow = (s) => s.ownerEquipmentMatrix.editFormMatrixShow;
const editFormMatrixStep = (s) => s.ownerEquipmentMatrix.editFormMatrixStep;
const editMatrixLoading = (s) => s.ownerEquipmentMatrix.editMatrixLoading;
const editMatrixError = (s) => s.ownerEquipmentMatrix.editMatrixError;

const groupedEquipments = createSelector(
  [currentEquipmentMatrixEquipments],
  (currentEquipmentMatrixEquipmentsData) => {
    if (!currentEquipmentMatrixEquipmentsData) {
      return null;
    }

    return currentEquipmentMatrixEquipmentsData.reduce((acc, item) => {
      const { attributes } = item;
      const key = [attributes.item_id, attributes.equipment_title].join("___");
      const value = acc[key] || [];

      return { ...acc, [key]: [...value, item] };
    }, {});
  }
);

const equipmentPrices = createSelector(
  [currentEquipmentMatrixPrices],
  (currentEquipmentMatrixPricesData) => {
    if (!currentEquipmentMatrixPricesData) {
      return null;
    }

    return currentEquipmentMatrixPricesData.reduce((acc, item) => {
      const {
        attributes: { price, equipment_title },
        id,
      } = item;

      return { ...acc, [equipment_title]: { price: price, id: id } };
    }, {});
  }
);

const structuredItems = createSelector(
  [currentEquipmentMatrixItems],
  (currentEquipmentMatrixItemsData) => {
    if (!currentEquipmentMatrixItemsData) {
      return null;
    }

    return currentEquipmentMatrixItemsData.reduce((acc, item) => {
      const {
        attributes: { role_id, equipment_option_id },
      } = item;
      const value = get(acc, equipment_option_id, {});

      return { ...acc, [equipment_option_id]: { ...value, [role_id]: item } };
    }, {});
  }
);

export default createStructuredSelector({
  owner,
  equipmentMatrix,
  equipmentMatrixLoading,
  equipmentMatrixError,
  currentEquipmentMatrix,
  currentEquipmentMatrixItems,
  currentEquipmentMatrixLoading,
  currentEquipmentMatrixError,
  currentEquipmentMatrixEquipments,
  currentEquipmentMatrixEquipmentsLoading,
  currentEquipmentMatrixEquipmentsError,
  currentEquipmentMatrixRoles,
  currentEquipmentMatrixRolesLoading,
  currentEquipmentMatrixRolesError,
  currentEquipmentMatrixPrices,
  currentEquipmentMatrixPricesLoading,
  currentEquipmentMatrixPricesError,
  copyFormMatrixShow,
  copyMatrixLoading,
  copyMatrixError,
  createFormMatrixShow,
  createFormMatrixStep,
  createMatrixLoading,
  createMatrixError,
  editFormMatrixShow,
  editFormMatrixStep,
  editMatrixLoading,
  editMatrixError,
  groupedEquipments,
  structuredItems,
  equipmentPrices,
});
