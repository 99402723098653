export const RESELECT_TIMEPLAN = '@CREW_LIST/RESELECT_ITEM';
export const SELECT_TIMEPLAN = '@CREW_LIST/SELECT_TIMEPLAN';
export const CHANGE_MODE = '@CREW_LIST/CHANGE_MODE';
export const ADD_ITEMS = '@CREW_LIST/ADD_ITEMS';

export const CREATE_ITEM_REQUEST = '@CREW_LIST/CREATE_ITEM_REQUEST';
export const ADD_CREATED_ITEM = '@CREW_LIST/ADD_CREATED_ITEM';
export const CREATE_ITEM_ERROR = '@CREW_LIST/CREATE_ITEM_ERROR';

export const UPDATE_ITEM_REQUEST = '@CREW_LIST/UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_EOC_REQUEST = '@CREW_LIST/UPDATE_ITEM_EOC_REQUEST';
export const ITEM_UPDATED_SUCCESSFULLY = '@CREW_LIST/ITEM_UPDATED_SUCCESSFULLY';
export const UPDATE_ITEM_ERROR = '@CREW_LIST/UPDATE_ITEM_ERROR';

export const DELETE_ITEM_REQUEST = '@CREW_LIST/DELETE_ITEM_REQUEST';
export const ITEM_DELETED_SUCCESSFULLY = '@CREW_LIST/ITEM_DELETED_SUCCESSFULLY';
export const DELETE_ITEM_ERROR = '@CREW_LIST/DELETE_ITEM_ERROR';
