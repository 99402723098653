import * as api from "./adapter";

export default {
  /**
   * getCrewUsers
   * @returns {Promise<*>}
   */
  index: ({
    pagination,
    sort,
    filter: { role, manager, owner, vessel, published },
    search,
  }) => {
    const filter = {};

    if (!sort.field) sort = { field: "start", direction: "asc" };

    if (role) filter.role_id = role.value;
    if (manager) filter.manager_id = manager.value;
    if (owner) filter.owner_id = owner.value;
    if (vessel) filter.vessel_id = vessel.value;
    if (published) filter.published = published.value;
    if (search) filter.search = search;

    return api.read("vacancies", {
      query: {
        filter,
        sort: `${sort.direction === "asc" ? "" : "-"}${sort.field}`,
        page: { size: pagination.itemsPerPage, number: pagination.current },
      },
    });
  },
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/vacancies/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: "vacancies", attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: "vacancies", attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "vacancies" }),
  /**
   * republish
   * @returns {Promise<*>}
   */
  republish: async ({ vacancyId, source, cb }) => {
    const { data } = await api.apiRequest("/api/vacancies/republish", {
      method: "POST",
      body: JSON.stringify({ vacancyId, source }),
    });

    return cb(data);
  },
};
