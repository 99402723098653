import { isString, isEmpty } from 'lodash';
import { parse, stringify } from 'query-string';
import { normalizeFilterObj } from '../../utils/timeplans';
import { getDurationByDates } from '../../utils/DateTime';

/**
 * getBooleanFilterValues
 * @param {String|Array} ids
 * @param {String} group
 * @returns {Object}
 */
export const getBooleanFilterValues = (ids, group) => {
  const array = isString(ids) ? ids.split(',') : ids;

  return { ...array.reduce((acc, id) => ({ ...acc, [`${group}.${id}`]: true }), {}) };
};


/**
 * getQueryParams
 * @param {String} search
 * @return {Object}
 */
export const getQueryParams = (search) => parse(search);


/**
 * getStateDiffByParamsFromQuery
 * @param {Object} params
 * @param {String} feature
 * @returns {Object}
 */
export const getStateDiffByParamsFromQuery = (params, feature) => {
  const { vesselIds, roleIds, ownerIds, additionalFilter, open, day, cell } = params;

  switch (feature) {
    case 'timeplans': {
      const diff = { filter: {}, state: {} };

      if (additionalFilter) diff.filter.additionalFilter = additionalFilter;
      if (roleIds) diff.filter = { ...diff.filter, ...getBooleanFilterValues(roleIds, 'roles') };
      if (vesselIds) diff.filter = { ...diff.filter, ...getBooleanFilterValues(vesselIds, 'vessels') };
      if (ownerIds) diff.filter = { ...diff.filter, ...getBooleanFilterValues(ownerIds, 'owners') };
      if (open) diff.state.isUserSectionOpen = open === 'true';
      if (cell) diff.state.cellWidth = parseInt(cell, 10);
      if (day) diff.state.startDate = day;

      return diff;
    }

    default:
      return { };
  }
};

/**
 * getStateDiffByQuery
 * @param {String} query
 * @param {String} feature
 * @returns {Object}
 */
export const getStateDiffByQuery = (query, feature) => {
  const params = getQueryParams(query);

  return getStateDiffByParamsFromQuery(params, feature);
};

/**
 * getQueryParamsByState
 * @param {Object} state
 * @param {String} feature
 * @returns {Object}
 */
export const getQueryParamsByState = ({ state, appliedFilter }, feature) => {
  switch (feature) {
    case 'timeplans': {
      const { roleIds, vesselIds, ownerIds, additionalFilter } = normalizeFilterObj(appliedFilter);

      const query = {
        open: `${state.isUserSectionOpen}`,
        cell: state.cellWidth,
        day: state.startDate,
        additionalFilter: additionalFilter,
      };

      if (!isEmpty(roleIds)) query.roleIds = roleIds.join(',');
      if (!isEmpty(vesselIds)) query.vesselIds = vesselIds.join(',');
      if (!isEmpty(ownerIds)) query.ownerIds = ownerIds.join(',');

      return `?${stringify(query)}`;
    }

    default:
      return { };
  }
};

/**
 * getParamsObjAsStateDiff
 * @param {Object} queryAsObj
 * @param {String} featureName
 * @return {{filter: {}, state: {}}|{}}
 */
export const getParamsObjAsStateDiff = (queryAsObj, featureName) => (
  getStateDiffByParamsFromQuery(queryAsObj, featureName)
);

/**
 * getUrlQueryAsStateDiff
 * @param {String} query
 * @param {String} featureName
 * @return {{filter: {}, state: {}}|{}}
 */
export const getUrlQueryAsStateDiff = (query, featureName) => {
  const params = getQueryParams(query);

  return getStateDiffByParamsFromQuery(params, featureName);
};

export const eventHeight = 30;
export const getCalendarHeight = () => window.innerHeight - 110;
export const minRangeDuration = 2592000000 - 1; // one month

export const getBoldDuration = (start, end) => `<b>${getDurationByDates(start, end)}</b>`;
export const getDate = (dateObj) => dateObj.value.split('T')[0];

/**
 * convertViewPort
 * @param view
 * @returns {{roleIds: string, start: string, end: string, vesselIds: string}}
 */
export const convertViewPort = ({ resources, topLeft, bottomRight }) => ({
  firstResource: topLeft.resource,
  lastResource: bottomRight.resource,
  start: getDate(topLeft.start),
  end: getDate(bottomRight.end),
  msIds: resources.map((id) => id.split(':')[0]).join(','),
});
