import { keyBy, sumBy } from 'lodash';
import { dateToFormat } from '../../DateTime';

const addRelationshipsToItem = ({ id, attributes, relationships: r }, groupedIncluded) => {
  const portItem = r.port.data ? groupedIncluded[`${r.port.data.type}:${r.port.data.id}`] : null;
  const portArrivedItem = r.port_arrived.data ? groupedIncluded[`${r.port_arrived.data.type}:${r.port_arrived.data.id}`] : null;
  const vesselItem = groupedIncluded[`${r.vessel.data.type}:${r.vessel.data.id}`];
  const crewList = (r.crew_lists.data || []).map((cl) => groupedIncluded[`${cl.type}:${cl.id}`]);
  const timeplanIds = crewList.reduce((acc, { attributes: { sign_off_id, sign_on_id } }) => {
    const newAcc = [...acc];

    if (sign_off_id) newAcc.push(sign_off_id);
    if (sign_on_id) newAcc.push(sign_on_id);

    return newAcc;
  }, []);

  return ({
    id,
    ...attributes,
    prettyDate: dateToFormat(attributes.date),
    prettyDateSOn: dateToFormat(attributes.date_sign_on),
    crewList,
    timeplanIds,
    onSignCount: sumBy(crewList, ({ attributes: { sign_on_user_id } }) => (sign_on_user_id ? 1 : 0)),
    offSignCount: sumBy(crewList, ({ attributes: { sign_off_user_id } }) => (sign_off_user_id ? 1 : 0)),
    port: portItem ? { value: portItem.id, label: portItem.attributes.name } : null,
    portName: portItem ? portItem.attributes.name : undefined,
    portArrived: portArrivedItem ? { value: portArrivedItem.id, label: portArrivedItem.attributes.name } : null,
    portArrivedName: portArrivedItem ? portArrivedItem.attributes.name : undefined,
    vessel: vesselItem ? vesselItem.attributes.name : undefined,
  });
};

/**
 * convertItem
 * @param { Object } item
 * @param { Array } included - list of relationship items
 * @return { Object } item wi th relationships
 */
export const convertItem = ({ data, included }) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}:${incl.id}`);

  return addRelationshipsToItem(data, groupedIncluded);
};

export const convertItems = ({ data, included, ...json }) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}:${incl.id}`);

  return {
    data: data.map((item) => addRelationshipsToItem(item, groupedIncluded)),
    ...json,
  };
};

export default convertItem;
