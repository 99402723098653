import { createAction } from 'redux-actions';
import * as constants from './constants';

export const addItem = createAction(constants.ADD_ITEM, (item) => ({ ...item, id: `${new Date().getTime()}` }));
export const addItems = createAction(constants.ADD_ITEMS, (data) => data);
export const deleteItem = createAction(constants.DELETE_ITEM, (id) => id);
export const deleteItems = createAction(constants.DELETE_ITEMS);

export const addSuccess = createAction(constants.ADD_ITEM, (message, title = 'Success') => ({
  id: `${new Date().getTime()}`,
  type: 'success',
  title,
  message,
}));

export const addError = createAction(constants.ADD_ITEM, (message, title = 'Error') => ({
  id: `${new Date().getTime()}`,
  type: 'error',
  title,
  message,
}));
