import { findIndex, cloneDeep, isEmpty } from 'lodash';
import {createAction, handleActions} from 'redux-actions';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import {
  initialState as baseInitialState,
  setItemsAsData,
  changeFilter,
  changeState,
} from '../../utils/redux/commonActionHandlers';
import { constants as commentsConstants } from '../comments/redux';

import selector from './selector';

export const mainRouteConfig = { exact: true, path: '/' };
export const formRouteConfig = { exact: true, path: '/timeplans/:id?/(new|edit)' };

const addMonthsToDate = (date, months) => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

const initialState = {
  ...baseInitialState,
  state: {
    isUserSectionOpen: false,
    isSelectNewUserMode: false,
    cellWidth: 140,
    startDate: addMonthsToDate(new Date(), -5).toISOString(),
    isReadyToRenderScheduler: false,
  },
  newItemFormInitialData: null,
};

const constants = {
  ...getConstantsByFeatureName('TIMEPLANS'),
  DELETE_USER_REQUEST: `@TIMEPLANS/DELETE_USER_REQUEST`,
};

const actions = {
  ...getActionCreators(constants),
  deleteUserRequest: createAction(constants.DELETE_USER_REQUEST)
};

const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.ITEMS_LOADED_SUCCESSFULLY]: (state, action) => ({
    ...setItemsAsData(state, action),
    state: { ...state.state, periodsByUserId: action.payload.periodsByUserId },
  }),
  [constants.CHANGE_FILTER]: (state, action) => {
    const isItAll = action.payload.field.includes('all');
    const options = action.payload.options || {};

    if (isItAll) {
      const [type, ids] = action.payload.field.split('.');

      const filter = {
        ...ids.split(',').reduce((acc, id) => ({
          ...acc,
          [`${type}.${id}`]: action.payload.value,
        }), {}),
      };

      return { ...state, filter: { ...state.filter, ...filter } };
    }

    if (!isEmpty(options)) {
      return { ...state, filter: { ...state.filter, ...options, [action.payload.field]: action.payload.value } };
    }

    return changeFilter(state, action);
  },
  [constants.CHANGE_STATE]: (state, action) => {
    if (action.payload.field === 'viewport') {
      return {
        ...state,
        state: {
          ...state.state,
          [action.payload.field]: action.payload.value,
          startDate: action.payload.value.start,
        },
      };
    }

    return changeState(state, action);
  },
  [constants.APPLY_FILTER]: (state) => ({
    ...state,
    appliedFilter: cloneDeep(state.filter),
  }),
  [LOCATION_CHANGE]: (state, { payload: { location } }) => {
    const isFormOpen = matchPath(location.pathname, { path: '/timeplans/new' });

    if (isFormOpen) {
      return { ...state, newItemFormInitialData: location.state };
    }

    return state.newItemFormInitialData ? { ...state, newItemFormInitialData: null } : state;
  },
  [commentsConstants.ADD_CREATED_ITEM]: (state, { payload: { body, relationship } }) => {
    if (relationship.resource !== 'time_plans') return state;

    const data = [...state.data];
    const targetIndex = findIndex(data, (it) => it.id === relationship.id);
    const item = data[targetIndex];

    data.splice(targetIndex, 1, { ...item, lastComment: body });

    return { ...state, data };
  },
}, initialState);

export { constants, actions, reducer, selector };
