/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';

class PageToolbar extends React.Component {
  componentDidMount() {
    const { title, name } = this.props;

    document.title = (title || name);
  }

  componentDidUpdate(prevProps) {
    const { title, name } = this.props;
    const newPageTitle = title || name;
    const oldPageTitle = prevProps.title || prevProps.name;

    if (newPageTitle !== oldPageTitle) {
      document.title = newPageTitle;
    }
  }

  render() {
    const { classes, name, children, className, style } = this.props;

    return (
      <Paper elevation={0} className={`${classes.root} ${className}`} style={style}>
        <div className={classes.title}>
          <Typography variant="h5" id="tableTitle">
            {name}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {isArray(children) ? children.map((child, index) => (
            <div key={index} className={classes.action}>
              {child}
            </div>
          )) : (
            <div className={classes.action}>
              {children}
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

PageToolbar.propTypes = {
  name: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.any,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

PageToolbar.defaultProps = {
  name: '',
  title: '',
  children: null,
  className: '',
  style: {},
};

export default withStyles(styles)(PageToolbar);
