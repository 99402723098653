import queryString from 'query-string';


export default function (url, name) {
  if (!url || !name) throw new Error('No arguments was provided');
  if (typeof name !== 'string') throw new TypeError('Name have to be a string');
  if (typeof url !== 'string') throw new TypeError('Url have to be a string');

  const query = url.split('?')[1]; // eslint-disable-line no-magic-numbers

  return queryString.parse(query)[name];
}
