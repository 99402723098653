import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";
import {
  initialState as baseInitState,
  changeFilter,
} from "../../utils/redux/commonActionHandlers";
import { optionsOfManaging } from "./utils";

const baseConstants = getConstantsByFeatureName("VESSELS");
export const selector = getSelectorByFeatureName("vessels");

export const constants = {
  ...baseConstants,
  SET_ITEM: `@VESSELS/SET_ITEM`,
  ADD_IMOS_TO_PARSE: `@VESSELS/ADD_IMOS_TO_PARSE`,
};

const baseActions = getActionCreators(constants);

export const actionHandlers = getActionHandlers(constants);

export const actions = {
  ...baseActions,
  setItem: createAction(constants.SET_ITEM),
  addImosToParse: createAction(constants.ADD_IMOS_TO_PARSE),
};

const initialState = {
  ...baseInitState,
  item: null,
  filter: {
    group: null,
    company: null,
    is_selected: false,
    is_managed: optionsOfManaging[0],
    is_active: true,
  },
  saveSearchToLocalStorage: true,
  search: localStorage.getItem("vessels.search") || "",
};

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.CHANGE_FILTER]: (state, action) => {
      if (action.payload.field !== "group") return changeFilter(state, action);

      return {
        ...state,
        filter: {
          ...state.filter,
          group: action.payload.value,
          company: null,
        },
      };
    },
    [constants.CLEAR_FILTER]: (state) => ({
      ...state,
      filter: { ...initialState.filter },
    }),
    [constants.SET_ITEM]: (state, { payload: item }) => ({ ...state, item }),
  },
  initialState
);
