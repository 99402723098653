import * as api from './adapter';
import { convertItems } from './convertors/performances';
import { uploadAttachment } from './userAttachments';

const requestParams = { query: { include: 'author', sort: '-created_at' } };

export default {
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/performances/${id}`),
  /**
   * createPerformance
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) => {
    const { file, author_id, crewing_applicant_id, role, docType, ...restAttributes } = attributes;

    const resp = await api.create({ type: 'performances', attributes: restAttributes });
    const { id } = resp.data.data;

    if (file) {
      await uploadAttachment(
        file.name, author_id, crewing_applicant_id,
        file.localUrl, id, 'Performance',
      );
    }

    return resp;
  },
  /**
   * updatePerformance
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, ...attributes) => {
    const resp = await api.update({ id, type: 'performances', attributes });
    return resp;
  },
  /**
   * deleteVPerformance
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'performances' }),
};
