import { handleActions } from 'redux-actions';
import { findIndex } from 'lodash';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import { initialState } from '../../utils/redux/commonActionHandlers';
import { constants as timeplansConstants } from '../timeplans/redux';


const constants = getConstantsByFeatureName('ASSIGNED_VESSELS');
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions(
  {
    ...actionHandlers,
    [timeplansConstants.ITEM_UPDATED_SUCCESSFULLY]: (state, action) => {
      const { manningScales } = state.data;
      const { payload: { manning_scale_id, offer, status } } = action;

      if (status !== 'vacancy') return state;

      const nextManningScales = [...manningScales];
      const targetIndex = findIndex(nextManningScales, { originId: String(manning_scale_id) });
      const currentMs = nextManningScales[targetIndex];

      nextManningScales.splice(targetIndex, 1, {
        ...currentMs,
        template_offer: Number(offer),
      });

      return {
        ...state,
        data: {
          ...state.data,
          manningScales: nextManningScales,
        },
      };
    },
  },
  initialState,
);
const selector = getSelectorByFeatureName('assignedVessels');

export { constants, actions, reducer, selector };
