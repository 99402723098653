import { keyBy, reduce } from 'lodash';

export const timeplanStatuses = [
  { id: 'vacancy', name: 'Vacancy', disabled: false },
  { id: 'to_propose', name: 'To propose', disabled: false },
  { id: 'pending_approval', name: 'Pending approval', disabled: false },
  { id: 'approved', name: 'Approved', disabled: false },
  { id: 'on_board', name: 'On board', disabled: true },
  { id: 'completed', name: 'Completed', disabled: true },
  { id: 'other_agency', name: 'Other agency', disabled: false },
];

export const timeplanStatusesById = keyBy(timeplanStatuses, 'id');

export const optionsOfTimeplanStatuses = timeplanStatuses.map(({ id, name, disabled }) => ({
  value: id,
  label: name,
  disabled,
}));

export const timeplanStatusByValue = keyBy(optionsOfTimeplanStatuses, 'value');

/**
 * isPeriodValid
 * @param {Array} periods
 * @param periodToValidate
 * @returns {Boolean}
 */
export const isPeriodValid = (periods, periodToValidate) => {
  const { start: s1, end: e1, id: id1 } = periodToValidate;

  return periods
    .reduce((isValid, { id, start: s2, end: e2 }) => {
      if (id1 === id) return isValid;
      if (!isValid) return isValid;

      const case1 = s2 < s1 && s1 < e2;
      const case2 = s2 < s1 && s1 < e2;
      const case3 = (s1 < s2 && s2 < e1) && (s1 < e2 && e2 < e1);

      return !(case1 || case2 || case3);
    }, true);
};


export const normalizeFilterObj = (filter) => reduce(filter, (acc, isSelect, field) => {
  if (field === "additionalFilter") {
    return {...acc, [field]: isSelect}
  } else {
    const [type, id] = field.split('.');
    const fieldName = {
      vessels: 'vesselIds',
      roles: 'roleIds',
      owners: 'ownerIds',
    }[type];

    return isSelect ? { ...acc, [fieldName]: [...acc[fieldName], id] } : acc;
  }
}, { roleIds: [], vesselIds: [], ownerIds: [] });

/**
 * getNoUserNameTextByStatus
 * @param {String} status
 * @param {String} username
 * @returns {string}
 */
export const getNoUserNameTextByStatus = (status, username, promotionsCount) => (
  status === 'other_agency'
    ? 'Other agency'
    : getUsernameWithPromotions(username, promotionsCount, status) || 'Vacancy'
);
const getUsernameWithPromotions = (username, promotionsCount, status) => {
  const hasNotCompletedStatus = !"completed".includes(status) 
  const hasPromotions = promotionsCount > 0;

  if (hasPromotions && hasNotCompletedStatus ) {
    return `${username}⬆`;
  }
  return username;
};
export const noUserAva = 'https://cdn4.iconfinder.com/data/icons/users-and-user-settings-line/120/select_user-512.png';
