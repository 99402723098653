export const optionsOfRoles = [
  { label: 'All', value: 'all' },
  { label: 'superuser', value: 'superuser' },
  { label: 'admin', value: 'admin' },
  { label: 'manager', value: 'manager' },
  { label: 'guest', value: 'guest' },
  { label: 'partner', value: 'partner' },
  { label: 'partner view only', value: 'partner_view' },
  { label: 'freelance', value: 'freelance' },
];

export default optionsOfRoles;
