import * as api from "./adapter";

/**
 * index - get all applicant interviews for current seafarer
 */
export default {
  index: async ({ pagination, sort, search } = {}) =>
    await api.read(
      `/users/${window.location.pathname.split("/")[2]}/interviews`,
      {
        query: {
          page: { size: pagination.itemsPerPage, number: pagination.current },
          filter: { title: search },
        },
      }
    ),
  /**
   * show - get specific interview
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/applicant_interviews/${id}`),
  /**
   * createUserInterview
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) =>
    await api.create({
      type: "applicant_interviews",
      attributes,
    }),
  /**
   * updateUserInterview
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, attributes) =>
    await api.update({
      id,
      type: "applicant_interviews",
      attributes: { form: attributes },
    }),
  /**
   * deleteUserInterview
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "applicant_interviews" }),
};
