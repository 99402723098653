import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from './sagas';
import { initialState as commonInitialState } from '../../utils/redux/commonActionHandlers';
import Api from '../../utils/Api';
import {fromStore} from "../../utils/localStorageStore";


const constantsPrefix = 'USERS';
const statePropName = 'users';
const formStatePropName = 'users';
const mainPageUrl = '/users';

const initialState = {
  ...commonInitialState,
  filter: fromStore('users.filter', {
    role: null,
    status: null,
    company_manager_name: null,
    favorites: false,
  }),
  filterExpanded: fromStore('users.filterExpanded', {
    status_id: true,
  }),
  filterType: fromStore('users.filterType', {
    tags: false,
    deadweight: false,
    age: false,
  })
};

const constants = {
  ...getConstantsByFeatureName(constantsPrefix),
  CHANGE_FILTER_EXPANDED: `@${constantsPrefix}/CHANGE_FILTER_EXPANDED`,
  CHANGE_FILTER_TYPE: `@${constantsPrefix}/CHANGE_FILTER_TYPE`,
};
const actions = {
  ...getActionCreators(constants),
  changeFilterExpanded: (filterExpanded) => ({
    type: constants.CHANGE_FILTER_EXPANDED,
    payload: filterExpanded,
  }),
  changeFilterType: (filterType) => ({
    type: constants.CHANGE_FILTER_TYPE,
    payload: filterType,
  }),
};
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.CLEAR_FILTER]: (state) => ({
    ...state,
    filter: { },
  }),
  [constants.CHANGE_FILTER_EXPANDED]: (state, action) => ({
    ...state,
    filterExpanded: {
      ...state.filterExpanded,
      [action.payload.name]: action.payload.value,
    },
  }),
  [constants.CHANGE_FILTER_TYPE]: (state, action) => ({
    ...state,
    filterType: {
      ...state.filterType,
      [action.payload.name]: action.payload.value,
    },
  }),
}, {
  ...initialState,
  filter: { ...initialState.filter },
  state: {
    isFull: false,
    engineTypeInputValue: '',
    engineTypeInputDebouncedValue: '',
  },
}, initialState);
const selector = getSelectorByFeatureName(statePropName);


const sagas = getCRUDSagas(
  mainPageUrl,
  Api.users,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'User' },
);

export { constants, actions, reducer, selector, sagas };
