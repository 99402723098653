import * as api from "./adapter";

export default {
  /**
   * notifications
   * @returns {Promise<*>}
   */
  index: async ({ pagination }) => {
    const { data } = await api.read("notifications", {
      query: {
        sort: "-created_at",
        page: { size: pagination.itemsPerPage, number: pagination.current },
        filter: { current_user: true, unread: true },
      },
    });

    return data;
  },

  notificationCount: () => api.read("notifications/notification_count"),

  notificationPopup: () => api.read("notifications/notification_pop_up"),

  markAsShown: (payload) =>
    api.apiRequest("/api/notifications/mark_as_shown", {
      method: "POST",
      body: JSON.stringify({ payload }),
    }),

  markAsRead: (payload) =>
    api.apiRequest("/api/notifications/mark_as_read", {
      method: "POST",
      body: JSON.stringify({ payload }),
    }),

  markAllShown: () =>
    api.apiRequest("/api/notifications/mark_all_shown", {
      method: "POST",
    }),

  clearAll: () =>
    api.apiRequest("/api/notifications/mark_all_read", {
      method: "POST",
    }),
};
