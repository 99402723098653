import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from '../../../../utils/Api';
import { addItem as notify } from '../../../notifications/actions';
import {commentData, commentEventData, isUserDataChanged, userData} from './utils';
import { addError, addSuccess } from '../../../notifications/actions';

export function* fetchSeaService() {
  try {
    const userId = yield select((s) => s.seafarersView.userId);
    const payload = {
      userId,
      sort: { field: 'date_on', direction: 'desc' },
      pagination: { itemsPerPage: 12, current: 1 },
    };
    const { data: { data } } = yield call(Api.userExperiences.index, payload);
    yield put(actions.setSeaService(data));
  } catch (error) {
    console.error(error);
    yield put(actions.setSeaServiceErrors(error));
  }
}

export function* fetchComments() {
  try {
    const isUsers = window.location.pathname.includes('users');
    const isUserDetails = window.location.pathname.includes('comments');
    const userId = yield select((s) => isUserDetails ? s.userDetailsPage.data.id : s.seafarersView.userId);
    const c_filters = yield select((s) => s.comments.filter);
    const s_filters = yield select((s) => s.seafarersView.comments.filters);
    const { id, resource, ...filters } = { id: userId, resource: 'users', ...s_filters, ...(isUsers ? {} : c_filters) };
    const { data } = yield call(Api.comments.index, resource, id, filters);
    yield put(actions.setComments(data));
  } catch (error) {
    console.error(error);
    yield put(actions.setCommentsErrors(error));
  }
}

export function* assignTagMulti({ payload: { tag, userId } }) {
  try {
    const featureState = yield select((s) => s.users);

    yield call(Api.users.assignTag, { ...featureState, tag, userId });
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The task was successfully added to the queue. This may take a little time.',
    }));
    yield put({ type: '@USERS/LOAD_ITEMS_REQUEST' });
    yield put(actions.assignTagMultiRequest(false));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* removeTagMulti({ payload: { tag, userId } }) {
  try {
    const featureState = yield select((s) => s.users);

    yield call(Api.users.removeTag, { ...featureState, tag, userId });
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The task was successfully added to the queue. This may take a little time.',
    }));
    yield put({ type: '@USERS/LOAD_ITEMS_REQUEST' });
    yield put(actions.removeTagMultiRequest(false));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createCommentEvent({ payload: {form, cb} }) {
  try {
    const currentUser = yield select((s) => s.auth.data);
    const comment = yield call(Api.comments.createFull, commentData(form, currentUser.id));
    const { data: { data: commentEvent } } = yield call(Api.commentEvents.create, commentEventData(form, comment.id));
    const tpForm = yield select((s) => s.form.timeplans);
    const { userId } = (form.resource === 'time_plans') ? tpForm?.values : form;

    yield put(actions.addNewCommentRequest({ show: false }));
    if (isUserDataChanged(form)) {
      yield call(Api.users.update, userId, userData(form));
      yield delay(200);
      yield put({ type: '@USERS/LOAD_ITEMS_REQUEST' });
      yield put(addSuccess('Interaction successfull.'));
    }
    if (form.file) {
      yield call(Api.userAttachments.uploadAttachment, form.file.name, currentUser.id,
        '', form.file.localUrl, commentEvent.id, 'CommentEvent');
    }
    yield delay(200);
    yield put(actions.addNewCommentSuccess());
    yield fetchComments();
  } catch (e) {
    yield put(addError(e.apiErrors.error, 'Updating failed.'));
    console.error(e);
  } finally {
    yield call(cb);
  }
}

export function* starUser({ payload: {userId, isDetailsPage} }){
  try{
    yield call(Api.favorites.starUser, userId);
    const type = isDetailsPage ? '@USER_DETAILS_PAGE/LOAD_ITEM_REQUEST' : '@USERS/LOAD_ITEMS_REQUEST';
    yield put({ type, payload: userId });
  } catch (e) {
    console.error(e);
  }
}

export default () => [
  takeEvery([
    constants.FETCH_SEA_SERVICE_REQUEST,
  ], fetchSeaService),
  takeEvery([
    constants.FETCH_COMMENTS_REQUEST,
  ], fetchComments),
  takeEvery([
    constants.ASSIGN_TAG_MULTI,
  ], assignTagMulti),
  takeEvery([
    constants.REMOVE_TAG_MULTI,
  ], removeTagMulti),
  takeEvery([
    constants.ADD_NEW_COMMENT,
  ], createCommentEvent),
  takeEvery([
    constants.STAR_USER,
  ],starUser),
];
