import { call, put, takeEvery, select, debounce } from 'redux-saga/effects';
import { LOCATION_CHANGE, goBack, getLocation } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { stopSubmit } from 'redux-form';
import { addSuccess } from '../../features/notifications/actions';

const defaultItemName = 'Item';

const setDefaultValuesToConfig = ({
  itemName = defaultItemName,
  getSuccessCreateMsg = () => `${itemName} created successfully`,
  getSuccessUpdateMsg = () => `${itemName} updated successfully`,
  successDeleteMsg = () => `${itemName} deleted successfully`,
  ...options
}) => ({ itemName, getSuccessCreateMsg, getSuccessUpdateMsg, successDeleteMsg, ...options });

/**
 * getCRUDSagas
 * @param {String} basePageUrl
 * @param {Object} api
 * @param {Object} actions
 * @param {Object} constants
 * @param {String} statePropertyName
 * @param {String} formName
 * @param {Object} options
 * @returns {function(): any[]}
 */
export default function getCRUDSagas(basePageUrl, api, actions, constants, statePropertyName, formName, options = {}) {
  const config = setDefaultValuesToConfig(options);

  function* fetchItems() {
    try {
      const featureState = yield select((s) => s[statePropertyName]);

      const { data } = yield call(api.index, featureState);

      yield put(actions.addItems(data));
    } catch (e) {
      console.error(e);

      yield put(actions.loadItemsError(e));
    }
  }

  function* fetchAfterSearch() {
    yield put(actions.applySearch()); // reset current pagination page
    yield put(actions.loadItemsRequest());
  }

  function* createItem({ payload }) {
    try {
      const { data } = yield call(api.create, payload);
      yield put(goBack());

      yield put(addSuccess(config.getSuccessCreateMsg(payload, data)));
    } catch (e) {
      yield put(actions.createItemError(e));

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* updateItem({ payload }) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });

      const { data } = yield call(api.update, id, payload);
      yield put(goBack());

      yield put(addSuccess(config.getSuccessUpdateMsg(payload, data)));
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* deleteItem() {
    try {
      const pathname = yield select((s) => s.router.location.pathname);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/(v_delete|delete)` });

      yield call(api.delete, id);
      yield put(goBack());

      yield put(addSuccess(config.successDeleteMsg(id)));
    } catch (e) {
      console.error(e);

      yield put(actions.deleteItemError(e));
    }
  }

  function* fetchItemsPageInit({ payload: { isFirstRendering, location: { pathname } } }) {
    const didOpenPage = (
      matchPath(pathname, { exact: true, path: `${basePageUrl}` })
      // "|| isFirstRendering" for case when you open a form and reload the page
      // and we MUST show list items behind the form
      || (isFirstRendering && matchPath(pathname, { path: `${basePageUrl}/:id?/(new|edit|delete)` }))
    );

    if (didOpenPage) yield put(actions.loadItemsRequest());
  }

  /**
  * getFeatureSagas (rename)
  * each saga file should return all feature sagas
  * @returns {ForkEffect[]}
  */
  function getFeatureSagas() {
    return [
      takeEvery([
        constants.SORT_ITEMS,
        constants.APPLY_FILTER,
        constants.CHANGE_PAGE_NUMBER,
        constants.CHANGE_PAGE_ITEMS_COUNT,
        constants.LOAD_ITEMS_REQUEST,
      ], fetchItems),
      takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
      takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
      takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
      debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
      takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    ];
  }

  getFeatureSagas.fetchItems = fetchItems;
  getFeatureSagas.fetchAfterSearch = fetchAfterSearch;
  getFeatureSagas.createItem = createItem;
  getFeatureSagas.updateItem = updateItem;
  getFeatureSagas.deleteItem = deleteItem;
  getFeatureSagas.fetchItemsPageInit = fetchItemsPageInit;
  getFeatureSagas.getFeatureSagas = getFeatureSagas;

  return getFeatureSagas;
}
