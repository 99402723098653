import getReduxModuleByFeatureName from '../../utils/redux/getReduxModuleByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';
import Api from '../../utils/Api';


const constantsPrefix = 'MANAGERS';
const statePropName = 'managers';
const formStatePropName = 'managers';
const mainPageUrl = '/managers';

const { constants, actions, reducer, selector } = getReduxModuleByFeatureName(constantsPrefix, statePropName);
const sagas = getCRUDSagas(
  mainPageUrl,
  Api.manager,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'Manager' },
);

export { constants, actions, reducer, selector, sagas };
