import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';

/**
 * matchRouterAction
 * @param {Object} action
 * @param {Object} matchOptions
 * @return {Object|Boolean} match or false
 */
export const matchRouterAction = ({ type, payload }, matchOptions) => {
  if (type !== LOCATION_CHANGE) throw new Error('this is not router action');

  return matchPath(payload.location.pathname, matchOptions);
};

/**
 * didDocumentLocationChange
 * @param {Object} action
 * @param {Object} matchOptions
 * @return {Object|Boolean} match or false
 */
export const didDocumentLocationChange = (action, matchOptions) => matchRouterAction(action, matchOptions);
