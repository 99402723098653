const modifiedSearchField = (searchInput) => {
  const trimmedInput = searchInput?.trim();

  if (!trimmedInput) return "";

  const splitedInput = trimmedInput.split(/\s+/).join(" ");

  if (!/\s+/g.test(splitedInput) || /\b(OR|AND)\b/i.test(splitedInput))
    return splitedInput;
  return splitedInput.replace(/\s+/g, " AND ");
};

export const buildFilterQuery = (filter) => {
  const {
    author_id,
    assignee_id,
    task_status,
    task_id,
    subtasks,
    task_type_id,
    search,
  } = filter;

  return {
    elastic_search: modifiedSearchField(search),
    ...(author_id && { author_id }),
    ...(assignee_id && { assignee_id }),
    ...(task_status && { task_status }),
    ...(task_id && { task_id }),
    ...(subtasks && { addressable_task_id: subtasks }),
    ...(task_type_id && { task_type_id }),
  };
};
