import { keyBy } from 'lodash';
import { dateToFormat, getDurationInYearByDate } from '../../DateTime';

export const convertItem = (
  {
    id,
    attributes,
    relationships: { last_expirience: { data: lastExp }, role, external_applications = {} },
  },
  included,
) => {
  const groupedIncluded = keyBy(included, (incl) => `${incl.type}:${incl.id}`);

  const lastExperience = lastExp ? groupedIncluded[`${lastExp.type}:${lastExp.id}`] : { attributes: {} };
  const { attributes: { role: lastRoleExp, vessel, date_on, date_off } } = lastExperience;
  const roleItem = role.data ? groupedIncluded[`${role.data.type}:${role.data.id}`] : null;


  const avatarFullPath = (attr) => ((attr.avatar || '').includes('active_storage')
    ? `/api${attr.avatar}`
    : `https://pms.staff-centre.com/${attr.avatar}`);


  return {
    ...attributes,
    id,
    crewing_applicant_id: attributes.crewing_applicant_id,
    name: attributes.name,
    surname: attributes.surname,
    middle: attributes.middle,
    status: attributes.status,
    email: attributes.email,
    birthday: attributes.birthday,
    address: attributes.address,
    mobile_phone: attributes.mobile_phone,
    phonehome: attributes.phonehome,
    readiness_date: dateToFormat(attributes.readiness_date),
    fullName: `${attributes.surname} ${attributes.name} ${attributes.middle ? attributes.middle : '' }`,
    avatar: avatarFullPath(attributes),
    age: getDurationInYearByDate(attributes.birthday),
    role: roleItem ? { id: roleItem.id, title: roleItem.attributes.title } : undefined,
    lastExperience: (
      lastRoleExp ? `${lastRoleExp}, ${vessel}
        (${dateToFormat(date_on)} - ${date_off ? dateToFormat(date_off) : 'now'})`
        : 'None'
    ),
    external_applications: {
      data: (external_applications.data || []).map(el => groupedIncluded[`${el.type}:${el.id}`])
    },
  };
};

export const convertItems = ({ data, included, ...json }) => ({
  data: {
    ...json,
    data: data.map((item) => convertItem(item, included)),
  },
});

export default convertItems;
