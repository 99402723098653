import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getOptionsOfCrewingStatuses } from '../../utils/selectors';

const getRanks = (s) => s.ranks.data;

const getOptionsOfRoles = createSelector(
  [getRanks],
  (ranks) => ranks.map(({ id: value, attributes: { title: label } }) => ({ value, label })),
);

const getEngineTypes = (state) => state.engineTypes.data;
const getEngineTypeInputDebouncedValue = (state) => state.users.state.engineTypeInputDebouncedValue;

const getOptionsOfEngineTypes = createSelector( // that code is for perfomance because types.length > 1000
  [getEngineTypes, getEngineTypeInputDebouncedValue],
  (types, engineTypeInputDebouncedValue) => (!isEmpty(engineTypeInputDebouncedValue)
    ? types.filter(({ label }) => label.toLowerCase().includes(engineTypeInputDebouncedValue.toLowerCase()))
    : []),
);

export default (state) => ({
  ...state.usersSection,
  isOpen: state.timeplans.state.isUserSectionOpen,
  optionsOfEngineTypes: getOptionsOfEngineTypes(state),
  optionsOfEngineManufacturers: state.engineManufacturers.data,
  optionsOfCrewingStatuses: getOptionsOfCrewingStatuses(state),
  optionsOfRoles: getOptionsOfRoles(state),
});
