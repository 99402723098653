import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'material-ui-flat-pagination';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';

class CustomPagination extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.shape({
      itemsPerPage: PropTypes.number,
      current: PropTypes.number,
      totalItemsCount: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    const { data } = this.props;

    return nextProps.data !== data;
  }

  onChange = (e, offset) => {
    const { data: { itemsPerPage } } = this.props;

    this.props.onChange((offset + itemsPerPage) / itemsPerPage);
  };

  render() {
    const { classes, data } = this.props;

    return data.itemsPerPage && data.itemsPerPage < data.totalItemsCount && (
      <div className={classes.pagination}>
        <Pagination
          currentPageColor="primary"
          otherPageColor="secondary"
          limit={data.itemsPerPage}
          offset={(data.current - 1) * data.itemsPerPage}
          total={data.totalItemsCount}
          onClick={this.onChange}
          nextPageLabel={<ChevronRight />}
          previousPageLabel={<ChevronLeft />}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomPagination);
