import { createAction } from 'redux-actions';

const getActionCreators = (types = {}) => {
  const actionCreators = {};

  if (types.LOAD_ITEMS_REQUEST) actionCreators.loadItemsRequest = createAction(types.LOAD_ITEMS_REQUEST);
  if (types.ITEMS_LOADED_SUCCESSFULLY) {
    actionCreators.addItems = createAction(types.ITEMS_LOADED_SUCCESSFULLY, (data) => data);
  }
  if (types.LOAD_ITEMS_ERROR) actionCreators.loadItemsError = createAction(types.LOAD_ITEMS_ERROR, (e) => e);

  if (types.LOAD_ITEM_REQUEST) actionCreators.loadItemRequest = createAction(types.LOAD_ITEM_REQUEST);
  if (types.ADD_LOADED_ITEM) actionCreators.addLoadedItem = createAction(types.ADD_LOADED_ITEM);
  if (types.LOAD_ITEM_ERROR) actionCreators.loadItemError = createAction(types.LOAD_ITEM_ERROR, (e) => e);

  if (types.CREATE_ITEM_REQUEST) actionCreators.createItemRequest = createAction(types.CREATE_ITEM_REQUEST);
  if (types.ADD_CREATED_ITEM) actionCreators.addCreatedItem = createAction(types.ADD_CREATED_ITEM);
  if (types.CREATE_ITEM_ERROR) actionCreators.createItemError = createAction(types.CREATE_ITEM_ERROR, (e) => e);

  if (types.UPDATE_ITEM_REQUEST) actionCreators.updateItemRequest = createAction(types.UPDATE_ITEM_REQUEST);
  if (types.ITEM_UPDATED_SUCCESSFULLY) actionCreators.updateItem = createAction(types.ITEM_UPDATED_SUCCESSFULLY);
  if (types.UPDATE_ITEM_ERROR) actionCreators.updateItemError = createAction(types.UPDATE_ITEM_ERROR, (e) => e);

  if (types.DELETE_ITEM_REQUEST) actionCreators.deleteItemRequest = createAction(types.DELETE_ITEM_REQUEST);
  if (types.ITEM_DELETED_SUCCESSFULLY) actionCreators.deleteItem = createAction(types.ITEM_DELETED_SUCCESSFULLY);
  if (types.DELETE_ITEM_ERROR) actionCreators.deleteItemError = createAction(types.DELETE_ITEM_ERROR, (e) => e);

  if (types.CHANGE_PAGE_NUMBER) actionCreators.changePageNumber = createAction(types.CHANGE_PAGE_NUMBER);
  if (types.CHANGE_PAGE_ITEMS_COUNT) actionCreators.changePageItemsCount = createAction(types.CHANGE_PAGE_ITEMS_COUNT);

  if (types.CHANGE_FILTER) {
    actionCreators.changeFilter = createAction(types.CHANGE_FILTER, (field, value, options = {}) => ({ field, value, options }));
  }
  if (types.CLEAR_FILTER) actionCreators.clearFilter = createAction(types.CLEAR_FILTER);
  if (types.REMOVE_FILTER) actionCreators.removeFilter = createAction(types.REMOVE_FILTER);
  if (types.APPLY_FILTER) actionCreators.applyFilter = createAction(types.APPLY_FILTER);

  if (types.CANCEL_FILTER) actionCreators.cancelFilter = createAction(types.CANCEL_FILTER);
  if (types.OPEN_FILTER) actionCreators.openFilter = createAction(types.OPEN_FILTER);
  if (types.CLOSE_FILTER) actionCreators.closeFilter = createAction(types.CLOSE_FILTER);

  if (types.CHANGE_SEARCH) actionCreators.changeSearch = createAction(types.CHANGE_SEARCH);
  if (types.CLEAR_SEARCH) actionCreators.clearSearch = createAction(types.CLEAR_SEARCH);
  if (types.APPLY_SEARCH) actionCreators.applySearch = createAction(types.APPLY_SEARCH);

  if (types.CHANGE_PAGE_NUMBER) actionCreators.changePage = createAction(types.CHANGE_PAGE_NUMBER);
  if (types.CHANGE_PAGE_ITEMS_COUNT) {
    actionCreators.changeItemsCountPerPage = createAction(types.CHANGE_PAGE_ITEMS_COUNT);
  }

  if (types.SORT_ITEMS) actionCreators.sortItems = createAction(types.SORT_ITEMS, (field) => field);
  if (types.SORT_WITH_DIRECTION) actionCreators.sortWithDirection = createAction(types.SORT_WITH_DIRECTION, (field, direction) => ({ field, direction }));

  if (types.CHANGE_STATE) {
    actionCreators.changeState = createAction(types.CHANGE_STATE, (field, value) => ({ field, value }));
  }
  if (types.CLEAR_STATE) actionCreators.clearState = createAction(types.CLEAR_STATE);

  if (types.UPDATE_STATE_BY_PARAMS) actionCreators.updateStateByParams = createAction(types.UPDATE_STATE_BY_PARAMS);

  return actionCreators;
};

export default getActionCreators;
