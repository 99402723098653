import { isNull, isUndefined } from 'lodash';
import * as api from './adapter';
import { convertItems, convertItemJson } from './convertors/timeplans';
import { dateToFormat } from '../DateTime';

const include = 'user';
const fields = {
  users: 'name,surname,avatar,readiness_date,promotions_count',
};

const getTimeplanRequestBody = (formValues, id) => {
  const {
    userId,
    port,
    start,
    end,
    status,
    resource,
    offer,
    vacancy_description,
    to_publish,
    newStatusId,
    repair_team
  } = formValues;
  const manningScaleId = resource && resource.split(':')[0];

  const body = {
    type: 'time_plans',
    attributes: {
      start: dateToFormat(start),
      end: dateToFormat(end),
      to_publish,
      repair_team
    },
  };

  if (id) body.id = String(id);
  if (status) body.attributes.status = status.value;
  if (newStatusId) body.attributes.newStatusId = newStatusId;

  if (status && status.value === 'vacancy') {
    if (offer) body.attributes.offer = offer;
    if (vacancy_description) body.attributes.vacancy_description = vacancy_description;
  }

  if (!isUndefined(userId) || !isUndefined(port) || manningScaleId) body.relationships = {};

  if (userId) {
    body.relationships.user = {
      data: {
        id: userId,
        type: 'users'
      }
    };
  }
  if (isNull(userId)) body.relationships.user = { data: null };
  if (manningScaleId) {
    body.relationships.manning_scale = {
      data: {
        id: manningScaleId,
        type: 'manning_scales'
      }
    };
  }
  if (isNull(port)) body.relationships.port = { data: null };

  return body;
};

export default {
  /**
   * checkHasAtLeastOne
   * @returns {Promise<*>}
   */
  index: async ({
    state: {
      viewport: {
        msIds,
        start,
        end
      }
    }
  }) => {
    const { data } = await api.read('time_plans', {
      query: {
        include,
        fields,
        filter: {
          date_range: `${start},${end}`,
          manning_scale_id: msIds
        },
      },
    });

    return convertItems(data);
  },
  show: async (options = {}) => {
    const { data } = await api.read('time_plans', {
      query: {
        include,
        fields,
        ...options,
      },
    });

    return convertItems(data);
  },
  /**
   * create
   * @param formValues
   * @returns {Promise<{relationships, role, comments, vessel, attributes, id, type, user}>}
   */
  create: async (formValues) => {
    const { data } = await api.create(
      getTimeplanRequestBody(formValues),
      { query: { include } },
    );

    return convertItemJson(data);
  },
  /**
   * update
   * @param id
   * @param formValues
   * @returns {Promise<{relationships, role, comments, vessel, attributes, id, type, user}>}
   */
  update: async (id, formValues) => {
    const { data } = await api.update(
      getTimeplanRequestBody(formValues, id),
      { query: { include } },
    );

    return convertItemJson(data);
  },
  /**
   * delete
   * @param id
   * @returns {Promise}
   */
  delete: (id) => api.destroy({
    id,
    type: 'time_plans'
  }),

  printReport: async (payload) => {
    const {
      vessels,
      roles,
      date
    } = payload;
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/time_plan?date=${date}&vessels=${vessels}&roles=${roles}`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers.get('Content-Disposition')
      .split('filename=')[1].replaceAll('"', '');
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },
};
