import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';
import { initialState as commonInitialState } from '../../utils/redux/commonActionHandlers';
import Api from '../../utils/Api';


const constantsPrefix = 'USER-ENGLISH-TESTS';
const statePropName = 'userEnglishTests';
const formStatePropName = 'userEnglishTests';
const mainPageUrl = '/users/:userId/english-tests';

const initialState = {
  ...commonInitialState,
};

const constants = getConstantsByFeatureName(constantsPrefix);
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.CLEAR_FILTER]: (state) => ({ ...state, filter: { ...initialState.filter } }),
}, initialState);
const selector = getSelectorByFeatureName(statePropName);


const sagas = getCRUDSagas(
  mainPageUrl,
  Api.userEnglishTests,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'English test result' },
);

export { constants, actions, reducer, selector, sagas };
