import * as api from './adapter';

export default {
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/crew_lists/${id}`),
  /**
   * create
   * @param {String} crewChangeId
   * @param {Object} attributes
   * @returns {Promise}
   */
  create: async (crewChangeId, attributes) => api.create({
    type: 'crew_lists',
    attributes,
    relationships: {
      crew_change: { data: { id: crewChangeId, type: 'crew_changes' } },
    },
  }),
  /**
   * update
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'crew_lists', attributes }),
  /**
   * delete
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'crew_lists' }),
};
