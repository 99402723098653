import * as api from './adapter';

export default {
  index: () => ({
    data: {
      data: [
        { id: '020b39f4', type: 'main_engines', attributes: { name: 'Engine #020b39f4' } },
        { id: '02db4ced', type: 'main_engines', attributes: { name: 'Engine #02db4ced' } },
        { id: '0ae61044', type: 'main_engines', attributes: { name: 'Engine #0ae61044' } },
        { id: '16c5924a', type: 'main_engines', attributes: { name: 'Engine #16c5924a' } },
        { id: '25e6146e', type: 'main_engines', attributes: { name: 'Engine #25e6146e' } },
        { id: '263afa97', type: 'main_engines', attributes: { name: 'Engine #263afa97' } },
        { id: '3341aee0', type: 'main_engines', attributes: { name: 'Engine #3341aee0' } },
        { id: '3e60c8ee', type: 'main_engines', attributes: { name: 'Engine #3e60c8ee' } },
        { id: '44a633c8', type: 'main_engines', attributes: { name: 'Engine #44a633c8' } },
        { id: '483b30e1', type: 'main_engines', attributes: { name: 'Engine #483b30e1' } },
        { id: '6f68a009', type: 'main_engines', attributes: { name: 'Engine #6f68a009' } },
        { id: '7fd42918', type: 'main_engines', attributes: { name: 'Engine #7fd42918' } },
        { id: '86f905ba', type: 'main_engines', attributes: { name: 'Engine #86f905ba' } },
        { id: '95d3e9d2', type: 'main_engines', attributes: { name: 'Engine #95d3e9d2' } },
        { id: 'b6181eb9', type: 'main_engines', attributes: { name: 'Engine #b6181eb9' } },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/main_engines?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/main_engines?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/main_engines?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/main_engines/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'main_engines', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'main_engines', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'main_engines' }),
};
