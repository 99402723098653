import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";
import { initialState as commonInitialState } from "../../utils/redux/commonActionHandlers";
import { optionsOfRoles } from "./utils";

const constantsPrefix = "CREW-USERS";
const statePropName = "crewUsers";

const initialState = {
  ...commonInitialState,
  filter: {
    ...commonInitialState.filter,
    role: optionsOfRoles[0],
    port: null,
    vessel: null,
  },
  dataForMentions: [],
};
const baseConstants = getConstantsByFeatureName(constantsPrefix);
export const constants = {
  ...baseConstants,
  CHANGE_CREW_USER_STATUS_REQUEST: `${constantsPrefix}/CHANGE_CREW_USER_STATUS_REQUEST`,
  FETCH_USERS_FOR_MENTIONS: `${constantsPrefix}/FETCH_USERS_FOR_MENTIONS`,
  ADD_USERS_FOR_MENTIONS: `${constantsPrefix}/ADD_USERS_FOR_MENTIONS`,
};

const baseActions = getActionCreators(constants);

export const actions = {
  ...baseActions,
  changeStatus: createAction(constants.CHANGE_CREW_USER_STATUS_REQUEST),
  loadUsersForMentionsRequest: createAction(constants.FETCH_USERS_FOR_MENTIONS),
  addUsersForMentions: createAction(constants.ADD_USERS_FOR_MENTIONS),
};

export const actionHandlers = getActionHandlers(constants);
export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.CLEAR_FILTER]: (state) => ({
      ...state,
      filter: { ...initialState.filter },
    }),
    [constants.ADD_USERS_FOR_MENTIONS]: (state, { payload }) => ({
      ...state,
      dataForMentions: payload?.data?.map((user) => ({
        id: user?.id,
        display: user?.attributes?.name,
      })),
    }),
  },
  initialState
);
export const selector = getSelectorByFeatureName(statePropName);
