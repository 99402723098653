/* eslint-disable camelcase */
import { matchPath } from "react-router-dom";
import { getLocation } from "connected-react-router";
import { createStructuredSelector, createSelector } from "reselect";
import { getCrewListBySelectedTimeplans } from "../../crew-list/utils";
import { getCurrentUserData } from "../../../utils/selectors";

const formValues = ({ form: { crewChange = { values: {} } } }) =>
  crewChange.values;
const getIsPushing = ({ crewChangeDetails: { isPushing } }) => isPushing;
const getCrewList = ({ crewList: { data } }) => data;
const getCrewChange = ({ crewChangeDetails: { data } }) => data;

const getItemId = createSelector([getLocation], ({ pathname }) => {
  const editUrlMatch = matchPath(pathname, {
    path: "/timeplans/crew-rotation/crew-change/:id/edit",
  });

  if (editUrlMatch) return editUrlMatch.params.id;

  return null;
});

const initialValues = createSelector(
  [getItemId, getCrewList, getCrewChange],
  (editId, crewList, crewChange) => {
    if (editId) {
      if (!crewChange) return {};

      return {
        date: crewChange.date,
        date_sign_on: crewChange.date_sign_on,
        reason: crewChange.reason,
        state: { value: crewChange.state, label: crewChange.state },
        vessel: crewChange.vessel,
        port: crewChange.port,
        portArrived: crewChange.portArrived,
      };
    }

    const someTimeplanId = crewList ? Object.keys(crewList)[0] : null;

    if (!someTimeplanId) return {};

    const { vessel, vesselId } = crewList[someTimeplanId];

    return {
      date: null,
      vessel,
      vesselId,
      state: { value: "planned", label: "planned" },
    };
  }
);

const isEditForm = createSelector([getLocation], ({ pathname }) =>
  pathname.includes("/edit")
);

export const getCrewListTableItems = createSelector(
  [getCrewList, formValues, isEditForm],
  (list, values, isEdit) => {
    if (!isEdit) return getCrewListBySelectedTimeplans(list, values.date);

    return list;
  }
);

export default createStructuredSelector({
  formValues,
  id: getItemId,
  isPushing: getIsPushing,
  isEditForm,
  initialValues,
  crewList: getCrewListTableItems,
  currentUser: getCurrentUserData,
});
