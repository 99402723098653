import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Link,
  Chip,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { get, isEmpty } from "lodash";
import { dateStringToDateTime } from "../../../utils/DateTime";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    alignItems: "center",
    marginTop: "0.2em",
    marginLeft: "0.2em",
    marginRight: "0.2em",
    border: "1px solid rgba(0, 0, 0, 0.05)",
  },
  list: {
    marginBottom: theme.spacing(2),
    "& .MuiListItem-gutters": {
      padding: "0 6px",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "50px",
    },
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  emptyNotifications: {
    marginTop: theme.spacing(3),
  },
  chip: {
    marginLeft: theme.spacing(1),
    height: "20px",
    fontSize: "0.6rem",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "#686767",
  },
  shown: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  notShown: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const ClearButton = ({ id, clearNotification }) => {
  const handleClick = useCallback(() => {
    clearNotification({ id: id });
  }, [id, clearNotification]);

  return (
    <IconButton onClick={handleClick}>
      <CloseIcon />
    </IconButton>
  );
};

const NotificationsList = ({ data, clearNotification }) => {
  const classes = useStyles();

  const chipClasses = useCallback(
    (isShown) => {
      return `${classes.chip} ${isShown ? classes.shown : classes.notShown }`;
    },
    [classes.chip, classes.shown, classes.notShown]
  );

  if (isEmpty(data)) {
    return (
      <>
        <Typography
          variant="h6"
          align="center"
          className={classes.emptyNotifications}
          gutterBottom
        >
          Notifications are empty
        </Typography>
        ;
      </>
    );
  }

  return (
    <>
      <List className={classes.list}>
        {data.map(({ id, attributes }) => (
          <div key={id}>
            <Paper className={classes.container}>
              <Link
                component={RouterLink}
                to={get(attributes, "item_link")}
                underline="none"
                target="_blank"
                color="inherit"
              >
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar
                      alt="Profile Picture"
                      src={`/api/${get(attributes, "author_avatar")}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={get(attributes, "primary_text")}
                    secondary={
                      <>
                        {get(attributes, "secondary_text")}
                        <Chip
                          className={chipClasses(get(attributes, "shown"))}
                          size="small"
                          label={dateStringToDateTime(
                            get(attributes, "created_at")
                          )}
                        />
                      </>
                    }
                  />
                </ListItem>
              </Link>
              <ClearButton id={id} clearNotification={clearNotification} />
            </Paper>
          </div>
        ))}
      </List>
    </>
  );
};

NotificationsList.propTypes = {
  data: PropTypes.array,
  clearNotification: PropTypes.func.isRequired,
};
export default NotificationsList;
