import * as api from './adapter';

export default {
  allowed_types: async () => {
    const { data } = await api.read('crewisor_position_comment_types/allowed_types');
    return data;
  },
  types: async () => {
    const { data } = await api.read('crewisor_position_comment_types/types');
    return data;
  },
  positions: async () => {
    const { data } = await api.read('crewisor_position_comment_types/positions');
    return data;
  },
  permissions: async () => {
    const { data } = await api.read('crewisor_position_comment_types/permissions');
    return data;
  },
  changePermission: async ({ positionId, type, commentAction }) => {
    await api.read('crewisor_position_comment_types/change_permission', {
      query: { positionId, type, commentAction },
    });
    return true;
  },
};
