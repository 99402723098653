/* eslint-disable */
import { isUndefined } from 'lodash';

export default function serialize(obj, prefix) {
  const str = [];
  let key;

  for (key in obj) {
    const value = obj[key];
    const shouldSearialize = !isUndefined(value) && (
      value !== ''
    );

    if (obj.hasOwnProperty(key) && shouldSearialize) {
      const serializedKey = prefix ? `${prefix}[${key}]` : key;

      str.push(
        (value !== null && typeof value === 'object')
          ? serialize(value, serializedKey)
          : `${encodeURIComponent(serializedKey)}=${encodeURIComponent(value)}`,
      );
    }
  }
  return str.join('&');
}
