import { createAction } from 'redux-actions';
import * as types from './constants';

export const createItemRequest = createAction(types.CREATE_ITEM_REQUEST);
export const createItemError = createAction(types.CREATE_ITEM_ERROR, (e) => e);

export const loadItemRequest = createAction(types.LOAD_ITEM_REQUEST);
export const addItem = createAction(types.ADD_ITEM);
export const loadItemError = createAction(types.LOAD_ITEM_ERROR);

export const deleteItemRequest = createAction(types.DELETE_ITEM_REQUEST);
export const deleteItem = createAction(types.ITEM_DELETED_SUCCESSFULLY);
export const deleteItemError = createAction(types.DELETE_ITEM_ERROR, (e) => e);

export const loginViaGoogle = createAction(types.LOGIN_VIA_GOOGLE, (payload) => payload);
