import { getPermissions, getCurrentUserData } from '../selectors';

export default (featureName) => (state) => ({
  data: state[featureName].data,
  didLoad: state[featureName].didLoad,
  isPushing: state[featureName].isPushing,
  isLoading: state[featureName].isLoading,
  state: state[featureName].state,
  pagination: state[featureName].pagination,
  filter: state[featureName].filter,
  sort: state[featureName].sort,
  search: state[featureName].search,
  error: state[featureName].error,
  permissions: getPermissions(state),
  user: getCurrentUserData(state),
});
