import * as api from "./adapter";
import { normalizeRelations } from "./convertors/normalizeRelations";
import { uploadAttachment } from "./userAttachments";

const updateTerms = async ({ id, terms, levels, contract_terms }) => {
  if (terms)
    await api.update({ id, action: "update_terms", type: "owners", ...terms });
  if (levels) {
    const levelsData = Object.keys(levels).map((k) => ({
      level_id: k.split("_")[1],
      fee: levels[k],
    }));
    await api.update({
      id,
      action: "update_level_terms",
      type: "owners",
      data: levelsData,
    });
  }
  if (contract_terms)
    await api.update({
      id,
      action: "update_contract_terms",
      type: "owners",
      ...contract_terms,
    });
};

export default {
  /**
   * getOwners
   * @returns {Promise<*>}
   */
  index: async ({ pagination, sort, search }) => {
    const data = await api.read("owners", {
      query: {
        include: "contract_template,document_templates",
        sort: `${sort.direction === "asc" ? "" : "-"}${sort.field}`,
        page: { size: pagination.itemsPerPage, number: pagination.current },
        filter: { title: search },
      },
    });
    return normalizeRelations(data);
  },
  /**
   *
   * @param id
   * @returns {Promise}
   */
  show: (id) => api.read(`owners/${id}`),
  /**
   * createOwner
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) => {
    const {
      terms,
      levels,
      contract_template,
      current_user_id,
      document_templates,
      contract_terms,
      ...restAttributes
    } = attributes;

    const resp = await api.create({
      type: "owners",
      attributes: restAttributes,
    });
    const { id } = resp.data.data;

    for (const file of document_templates) {
      await uploadAttachment(
        file.name,
        current_user_id,
        null,
        file.localUrl,
        id,
        "Owner"
      );
    }

    await updateTerms({ id, terms, levels, contract_terms });

    return resp;
  },
  /**
   * updateOwner
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, attributes) => {
    const {
      terms,
      levels,
      contract_template,
      current_user_id,
      document_templates = [],
      contract_terms,
      ...restAttributes
    } = attributes;

    const resp = await api.update({
      id,
      type: "owners",
      attributes: restAttributes,
    });

    if (document_templates.length) {
      for (const file of document_templates) {
        await uploadAttachment(
          file.name,
          current_user_id,
          null,
          file.localUrl,
          resp.data.data.id,
          "Owner"
        );
      }
    }

    await updateTerms({ id, terms, levels, contract_terms });

    return resp;
  },
  /**
   * deleteOwner
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "owners" }),
  /**
   * get owner vessels
   * @param {String} id
   * @param queryOverride
   */
  getVessels: (id, queryOverride = {}) =>
    api.read(`owners/${id}/vessels`, {
      query: {
        page: { size: 999 },
        ...queryOverride,
      },
    }),

  getRecruiters: (id, queryOverride = {}) =>
    api.read(`owners/${id}/recruiters_list`, {
      query: {
        page: { size: 999 },
        ...queryOverride,
      },
    }),

  addRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/owners/${id}/relationships/${relation}`, {
      method: "POST",
      body: JSON.stringify({ data }),
    });
  },

  deleteRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/owners/${id}/relationships/${relation}`, {
      method: "DELETE",
      body: JSON.stringify({ data }),
    });
  },

  getScaleNationalities: (id) =>
    api.read(`owners/${id}/wage_scales_nationalities`, {
      query: { page: { size: 999 } },
    }),

  createNationalityScale: (id, attributes) =>
    api.update({
      id,
      type: "owners",
      action: "create_scale",
      attributes: attributes,
    }),

  ownerRoles: (id) =>
    api.read(`owners/${id}/roles`, { query: { page: { size: 999 } } }),
};
