import { initialState as commonInitialState } from "../../utils/redux/commonActionHandlers";

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
});

const extendedInitialState = {
  ...commonInitialState,
  saveSearchToLocalStorage: true,
  search: localStorage.getItem("vacancies.search") || "",
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
});

const newSagas = (baseSagas, constants, actions) => {
  return [];
};

const extendedSagas = (baseSagas, newSagas) => {
  return () => [...baseSagas(), ...newSagas];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
});

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors,
};
