import { call, put, takeEvery, select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE } from 'connected-react-router';
import { actions, constants, selector } from './index';
import authSelector from '../authorization/selector';
import Api from '../../utils/Api';

export function* fetchItems() {
  try {
    const authState = yield select(authSelector);
    const id = authState.data ? authState.data.id : localStorage.getItem('clientId');
    const data = yield call(Api.assignedVessels.index, id);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createItem({ payload: id }) {
  try {
    const { data } = yield call(Api.assignedVessels.create, id);

    yield put(actions.addCreatedItem(data));
  } catch (e) {
    yield put(actions.createItemError(e));
  }
}

export function* deleteItem({ payload }) {
  try {
    yield call(Api.assignedVessels.delete, payload.select_vessel_id);
    yield put(actions.deleteItem(payload));
  } catch (e) {
    yield put(actions.deleteItemError(e));
  }
}

export function* fetchItemsPageInit({ payload: { location: { pathname, state: { doWeNeedToReloadData } = {} } } }) {
  const { didLoad } = yield select(selector);

  const isPageOpened = (
    matchPath(pathname, { path: '/', exact: true })
    || matchPath(pathname, { path: '/timeplans/:id/edit', exact: true })
  );

  if (isPageOpened && (!didLoad || doWeNeedToReloadData)) yield put(actions.loadItemsRequest());
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
  ];
}
