import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Button, Typography, Box, Select } from "@material-ui/core";
import { capitalize } from "lodash";
import Emoji from "../../../components/Emoji";
import { loadItemRequest as authDataReload } from "../../authorization/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  statusContainer: {
    border: "none",
    width: "100%",
  },
  statusButton: {
    justifyContent: "flex-start",
    padding: "0px",
    width: "inherit",
    "&:hover": {
      color: "white",
      backgroundColor: "#8c8c8c50",
    },
  },
}));

const statusEmoji = (status) => {
  const emoji = {
    active: "🟢",
    away: "🟡",
    deactivated: "🚫",
  };

  return Emoji(`${emoji[status]} ${capitalize(status)}`);
};

const CrewUserStatus = ({ id, status, onStatusChange, navigationBar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const handleChange = useCallback(
    (event) => {
      const cb = () => {
        dispatch(authDataReload());
      };

      onStatusChange({
        cb: cb,
        clientId: id,
        status: event.target.value,
        navBar: navigationBar,
      });
      handleClose();
    },
    [id, onStatusChange, navigationBar]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <>
      <div>
        {!open && !navigationBar && (
          <Box className={classes.statusContainer}>
            <Button
              color="textPrimary"
              className={classes.statusButton}
              onClick={handleOpen}
            >
              <Typography variant="body2" className={classes.statusSign}>
                {statusEmoji(status)}
              </Typography>
            </Button>
          </Box>
        )}
        {!open && navigationBar && (
          <MenuItem
            key="status"
            onClick={handleOpen}
            disabled={status === "deactivated"}
          >
            {statusEmoji(status)}
          </MenuItem>
        )}
        {open && (
          <Select
            id="crew-user-status-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={status}
            onChange={handleChange}
          >
            <MenuItem value="active">{statusEmoji("active")}</MenuItem>
            <MenuItem value="away">{statusEmoji("away")}</MenuItem>
            {!navigationBar && (
              <MenuItem value="deactivated">{statusEmoji("deactivated")}</MenuItem>
            )}
          </Select>
        )}
      </div>
    </>
  );
};

CrewUserStatus.defaultProps = {
  navigationBar: false,
};

CrewUserStatus.propTypes = {
  status: PropTypes.string,
  id: PropTypes.string,
  onStatusChange: PropTypes.func,
  navigationBar: PropTypes.bool,
};

export default CrewUserStatus;
