import { call, put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../utils/Api';
import { actions, constants } from './redux';
import { addSuccess } from '../notifications/actions';
import { fetchItems } from '../vessels/sagas'


function* performSearch() {
  try {
    yield put(actions.setLoading(true));
    const search = yield select((s) => s.vesselSearchEquasis.searchTerm);
    const email = yield select((s) => s.auth.data.equasis_email);

    const response = yield call(Api.equasisVesselSearch.index, { search, email });

    yield put(actions.successSearch(response));
    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(actions.setLoading(false));
    console.error(error);
    yield put(actions.errorSearch([error]));
  }
}

function* createVesselFromEquasis({ payload: { imo, change } }) {
  try {
    yield put(actions.setLoading(true));
    const email = yield select((s) => s.auth.data.equasis_email);

    const { name: label, id: value } = yield call(Api.equasisVesselSearch.create, { imo, email });
    yield put(addSuccess('Vessel created successfully!'));
    if (change) yield call(change, 'crewing_vessel_id', { label, value });
    
    yield put(actions.setLoading(false));
    yield put(actions.abortSearch());
    if (!change) yield fetchItems();
  } catch (error) {
    yield put(actions.setLoading(false));
    console.error(error);
    yield put(actions.errorSearch([error]));
  }
}

const sagas = () => [
  takeEvery([constants.PERFORM_SEARCH], performSearch),
  takeEvery([constants.CREATE_VESSEL], createVesselFromEquasis),
];

export default sagas;
