import { get, groupBy, mapValues, map } from 'lodash';
import { createSelector } from 'reselect';
import getPermissionsByRole from './permissions';

/**
 * getOptions
 * @param {Array} array
 * @param {String} valueField
 * @param {String} labelField
 * @return {{label: String, value: String}[]}
 */
export const getOptions = (array, valueField = 'id', labelField = 'attributes.name') => map(
  array,
  (item) => ({ label: get(item, labelField), value: get(item, valueField) }),
);

export const getGroupedOptions = (array, groupField, valueField, labelField) => {
  const groupedArray = groupBy(array, groupField);

  return mapValues(groupedArray, (groups) => getOptions(groups, valueField, labelField));
};

export const getCurrentUserData = (s) => s.auth.data;
export const getPermissions = (s) => get(s.auth.data, 'permissions');

const getRanks = (s) => s.ranks.data;

export const getOptionsOfRoles = createSelector(
  [getRanks],
  (ranks) => ranks.map(({ id: value, attributes: { title: label } }) => ({ value, label })),
);

const getCrewingStatuses = (s) => s.crewingStatuses.data;

export const getOptionsOfCrewingStatuses = createSelector(
  [getCrewingStatuses],
  (data) => data.map(({ id: value, attributes: { name: label } }) => ({ value, label })),
);
