/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import ThemeProvider from "./components/ThemeProvider";
import App from "./routes";
import configureStore, { history } from "./store";
import DateUtilsProvider from "./components/DateUtilsProvider";
// import * as serviceWorker from './serviceWorker';
import { withLocalStorage } from "./utils/localStorageStore";
import { setLicenseKey } from "survey-core";

import "./assets/css/sjs/defaultV2.css";
import "./assets/css/sjs/survey-creator-core.css";

const store = withLocalStorage(configureStore());

setLicenseKey(process.env.REACT_APP_SURVEYJS_KEY);

const renderApp = (AppComponent) => {
  ReactDOM.render(
    <DateUtilsProvider>
      <ThemeProvider>
        <Provider store={store}>
          <AppComponent history={history} />
        </Provider>
      </ThemeProvider>
    </DateUtilsProvider>,
    document.getElementById("root")
  );
};

renderApp(App);

// Hot Module Replacement API
if (module && module.hot) {
  module.hot.accept("./routes.jsx", () => {
    const NextApp = require("./routes.jsx").default; // eslint-disable-line global-require

    renderApp(NextApp);
  });
}

// serviceWorker.register();
