import * as api from './adapter';

export default {
  /**
   * getManagers
   * @returns {Promise<*>}
   */
  index: ({ pagination, sort, search }) => api.read('managers', {
    query: {
      sort: `${sort.direction === 'asc' ? '' : '-'}${sort.field}`,
      page: { size: pagination.itemsPerPage, number: pagination.current },
      filter: { title: search },
    },
  }),
  /**
   * show - get manager item
   * @param id
   * @returns {Promise}
   */
  show: (id) => api.read(`managers/${id}`),
  /**
   * createManager
   * @param attributes
   * @returns {Promise}
   */
  create: ({ title }) => api.create({
    type: 'managers',
    attributes: { title },
  }),
  /**
   * updateManager
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({
    id,
    type: 'managers',
    attributes,
  }),
  /**
   * deleteManager
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'managers' }),
  /**
   * get manager vessels
   * @param {String} id
   */
  getVessels: (id) => api.read(`managers/${id}/vessels`, {
    query: { page: { size: 999 } },
  }),
};
