import * as api from "./adapter";
import { buildFilterQuery } from "./utils/tasks";

export default {
  /**
   * tasks
   * @returns {Promise<*>}
   */
  index: ({ pagination, sort, filter }) => {
    const query = {
      page: { size: pagination.itemsPerPage, number: pagination.current },
      filter: buildFilterQuery(filter),
    };

    if (sort && sort.field)
      query["sort"] = `${sort.direction === "asc" ? "" : "-"}${sort.field}`;

    return api.read("task_manager/tasks/", { query });
  },

  subtasks: (id) => api.read(`task_manager/tasks/${id}/subtasks`),

  tasksForInteraction: (id) =>
    api.read(`task_manager/tasks/tasks_for_interaction?interaction_id=${id}`),

  /**
   * showч
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/task_manager/tasks/${id}`),
  /**
   * createTask
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) => {
    const {
      assignee,
      author_id,
      deadline,
      description,
      task_type,
      item,
      addressable_task_id,
    } = attributes;

    const payload = {
      status: "to_do",
      author_id: author_id,
      assignee_id: assignee.value,
      task_type_id: task_type.value,
      deadline: deadline,
    };

    if (assignee) payload.assignee_id = assignee.value;
    if (description) payload.description = description;
    if (item) payload.item_id = item.value;
    if (item) payload.item_type = item.class;
    if (addressable_task_id) payload.addressable_task_id = addressable_task_id;

    const resp = await api.create({
      type: "tasks",
      attributes: payload,
      controller: "task_manager/tasks",
    });

    return resp;
  },
  /**
   * updateTask
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (attributes) => {
    const {
      id,
      assignee,
      deadline,
      description,
      task_type,
      item,
      addressable_task_id,
    } = attributes;

    const payload = {
      ...(deadline && { deadline }),
      ...(task_type && { task_type_id: task_type.value }),
      ...(assignee && { assignee_id: assignee.value }),
      ...(description && { description }),
      ...(item && { item_id: item.value, item_type: item.class }),
      ...(addressable_task_id && { addressable_task_id }),
    };

    const resp = await api.update({
      type: "tasks",
      id,
      attributes: payload,
      controller: "task_manager/tasks",
    });

    return resp;
  },

  updateStatus: (payload) => {
    const { id, attributes } = payload;

    return api.update({
      type: "tasks",
      id,
      attributes,
      controller: "task_manager/tasks",
    });
  },

  /**
   * deleteTask
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "task_manager/tasks" }),
};
