import React from "react";
import Emoji from "react-emoji-component";

const emojiStyle = { filter: "drop-shadow(0px 0px 1px rgb(97, 97, 97))" };

const Remoji = (em, opt = {}) => (
  <Emoji style={emojiStyle} {...opt}>
    {em}
  </Emoji>
);

export default Remoji;
