import { call, put, select, takeEvery } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE, getLocation } from 'connected-react-router';
import { actions, constants, routeConfig } from './redux';
import Api from '../../utils/Api';
import { didDocumentLocationChange } from '../../utils/router';


export function* fetchItem() {
  try {
    const { pathname } = yield select(getLocation);
    const { params: { id } } = matchPath(pathname, routeConfig);
    const { data: json } = yield call(Api.owner.show, id);

    yield put(actions.addLoadedItem(json.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemError(e));
  }
}

export function* fetchItemsPageInit(action) {
  const match = didDocumentLocationChange(action, routeConfig);

  if (match) {
    yield put(actions.loadItemRequest(match.params.id));
  }
}

/**
 * getFeatureSagas
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
  ];
}
