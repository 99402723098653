import * as api from './adapter';

export const uploadAttachment = async (title, author_id, ca_id, fileObjectUrl, add_id, add_type) => {
  const blob = await fetch(fileObjectUrl).then((r) => r.blob());

  const formData = new FormData();
  formData.append('title', title);
  formData.append('author_id', author_id);
  formData.append('crewing_applicant_id', ca_id);
  formData.append('addressable_id', add_id);
  formData.append('addressable_type', add_type);
  formData.append('document', blob, title);

  return api.apiRequest(
    '/api/attachments/create_item',
    {
      method: 'POST',
      body: formData,
      redirect: 'follow',
    },
    {
      'Content-Type': undefined,
    },
  );
};

export default {
  index: ({ pagination, sort, search }) => api.read(`/users/${window.location.pathname.split('/')[2]}/attachments`, {
    query: {
      // filter: { name: search, ...roleFilter },
      sort: `${sort.direction === 'asc' ? '' : '-'}${sort.field}`,
      page: { size: pagination.itemsPerPage, number: pagination.current },
    },
  }),

  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/attachments/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'attachments', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'attachments', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'attachments' }),
  
  uploadAttachment,
};
