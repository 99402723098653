import * as api from './adapter';

/** Represents a collection of functions that interact with the 'permissions' API. */
export default {

  /**
   * Gets a list of permissions.
   *
   * @param {Object} filter - The filter to apply to the list.
   * @returns {Promise} The result of the API call.
   */
  index: ({ filter = {} }) => api.read('permissions', {
    query: {
      filter,
      sort: 'item',
      page: { size: 999, number: 1 },
    },
  }),

  /**
   * Gets a permission by id.
   *
   * @param {string} id - The id of the permission to get.
   * @returns {Promise} The result of the API call.
   */
  show: (id) => api.read(`/permissions/${id}`),

  /**
   * Creates a permission.
   *
   * @param {Object} attributes - The attributes of the permission to create.
   * @returns {Promise} The result of the API call.
   */
  create: (attributes) => api.create({ type: 'permissions', attributes }),

  /**
   * Updates a permission.
   *
   * @param {string} id - The id of the permission to update.
   * @param {Object} attributes - The new attributes of the permission.
   * @returns {Promise} The result of the API call.
   */
  update: (id, attributes) => api.update({ id, type: 'permissions', attributes }),

  /**
   * Deletes a permission.
   *
   * @param {string} id - The id of the permission to delete.
   * @returns {Promise} The result of the API call.
   */
  delete: (id) => api.destroy({ id, type: 'permissions' }),
};
