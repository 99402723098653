import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';
import { optionsOfStates } from './utils';

export const constants = getConstantsByFeatureName('CREW_CHANGES');
export const actions = getActionCreators(constants);
export const actionHandlers = getActionHandlers(constants);
export const selector = getSelectorByFeatureName('crewChanges');

const initialState = {
  ...baseInitState,
  sort: {
    field: 'date',
    direction: 'desc',
  },
  filter: {
    state: optionsOfStates[0],
  },
};

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.CLEAR_FILTER]: (state) => ({
      ...state,
      filter: { ...initialState.filter },
    }),
  },
  initialState
);
