import * as api from "./adapter";

export default {
  ownerMatrices: ({ filter }) =>
    api.read("protective_equipment/owner_matrices", { query: { filter } }),

  matrixItems: ({ filter }) =>
    api.read("protective_equipment/matrix_items", { query: { filter } }),

  matrixPrices: ({ filter }) =>
    api.read("protective_equipment/matrix_prices", { query: { filter } }),

  matrixRoles: (matrixId) =>
    api.read(`protective_equipment/owner_matrices/${matrixId}/roles`),

  matrixEquipments: (matrixId) =>
    api.read(`protective_equipment/owner_matrices/${matrixId}/equipments`),

  allEquipments: () =>
    api.read(`protective_equipment/options`, {
      query: { page: { size: 999 } },
    }),

  createMatrix: ({ attributes }) =>
    api.create({
      type: "owner_matrices",
      controller: "protective_equipment/owner_matrices",
      attributes,
    }),

  editMatrix: ({ id, attributes }) =>
    api.update({
      id,
      type: "owner_matrices",
      controller: "protective_equipment/owner_matrices",
      attributes,
    }),

  unusedRoles: (matrixId) =>
    api.read(`protective_equipment/owner_matrices/${matrixId}/unused_roles`),

  unusedEquipments: (matrixId) =>
    api.read(
      `protective_equipment/owner_matrices/${matrixId}/unused_equipments`
    ),

  copyMatrix: ({ attributes }) =>
    api.create({
      type: "owner_matrices",
      controller: "protective_equipment/owner_matrices",
      attributes,
    }),

  updateMatrixItem: ({ id, value }) =>
    api.update({
      id,
      type: "matrix_items",
      controller: "protective_equipment/matrix_items",
      attributes: { qty: value },
    }),

  updateMatrixEquipmentPrice: ({ id, value }) =>
    api.update({
      id,
      type: "matrix_prices",
      controller: "protective_equipment/matrix_prices",
      attributes: { price: value },
    }),
};
