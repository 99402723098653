const getStyles = (theme) => ({
  root: {
    background: '#fff',
  },
  contentWrapper: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
});

export default getStyles;
