import * as api from './adapter';

export default {
  index: () => ({
    data: {
      data: [
        { id: '020b39f4', type: 'vacancies', attributes: { name: 'Shipchandler' } },
        { id: '02db4ced', type: 'vacancies', attributes: { name: 'Spare parts' } },
        { id: '0ae61044', type: 'vacancies', attributes: { name: 'Conventional Equipment Service' } },
        { id: '16c5924a', type: 'vacancies', attributes: { name: 'Assy Units/Dedicated suppliers' } },
        { id: '25e6146e', type: 'vacancies', attributes: { name: 'Diving companies' } },
        { id: '263afa97', type: 'vacancies', attributes: { name: 'Agent' } },
        { id: '3341aee0', type: 'vacancies', attributes: { name: 'Navigational Equipment Service' } },
        { id: '3e60c8ee', type: 'vacancies', attributes: { name: 'Workshops/Recondition' } },
        { id: '44a633c8', type: 'vacancies', attributes: { name: 'Scrapyards' } },
        { id: '483b30e1', type: 'vacancies', attributes: { name: 'Anchors/Chains/Wires' } },
        { id: '6f68a009', type: 'vacancies', attributes: { name: 'Shipyards' } },
        { id: '7fd42918', type: 'vacancies', attributes: { name: 'Fuel/Lubricating Oil Trader' } },
        { id: '86f905ba', type: 'vacancies', attributes: { name: 'Flag/Class' } },
        { id: '95d3e9d2', type: 'vacancies', attributes: { name: 'Logistics/Forwarding' } },
        { id: 'b6181eb9', type: 'vacancies', attributes: { name: 'Manufacturer' } },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/engine_manufacturers/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'engine_manufacturers', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'engine_manufacturers', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'engine_manufacturers' }),
};
