import {createSelector, createStructuredSelector} from "reselect";
import get from 'lodash/get';

const owner = (s) => s.ownerDetails.data;

const documentMatrix = (s) => s.ownerDocumentMatrix.documentMatrix;
const documentMatrixLoading = (s) => s.ownerDocumentMatrix.documentMatrixLoading;
const documentMatrixError = (s) => s.ownerDocumentMatrix.documentMatrixError;

const currentDocumentMatrix = (s) => s.ownerDocumentMatrix.currentDocumentMatrix;

const currentDocumentMatrixItems = (s) => s.ownerDocumentMatrix.currentDocumentMatrixItems;
const currentDocumentMatrixLoading = (s) => s.ownerDocumentMatrix.currentDocumentMatrixLoading;
const currentDocumentMatrixError = (s) => s.ownerDocumentMatrix.currentDocumentMatrixError;

const currentDocumentMatrixDocuments = (s) => s.ownerDocumentMatrix.currentDocumentMatrixDocuments;
const currentDocumentMatrixDocumentsLoading = (s) => s.ownerDocumentMatrix.currentDocumentMatrixDocumentsLoading;
const currentDocumentMatrixDocumentsError = (s) => s.ownerDocumentMatrix.currentDocumentMatrixDocumentsError;

const currentDocumentMatrixRoles = (s) => s.ownerDocumentMatrix.currentDocumentMatrixRoles;
const currentDocumentMatrixRolesLoading = (s) => s.ownerDocumentMatrix.currentDocumentMatrixRolesLoading;
const currentDocumentMatrixRolesError = (s) => s.ownerDocumentMatrix.currentDocumentMatrixRolesError;

const showFullForm = (s) => s.ownerDocumentMatrix.showFullForm;
const fullFormId = (s) => s.ownerDocumentMatrix.fullFormId;
const fullFormLoading = (s) => s.ownerDocumentMatrix.fullFormLoading;
const fullFormError = (s) => s.ownerDocumentMatrix.fullFormError;
const fullFormData = (s) => s.ownerDocumentMatrix.fullFormData;

const copyFormMatrixShow = (s) => s.ownerDocumentMatrix.copyFormMatrixShow;
const copyMatrixLoading = (s) => s.ownerDocumentMatrix.copyMatrixLoading;
const copyMatrixError = (s) => s.ownerDocumentMatrix.copyMatrixError;

const createFormMatrixShow = (s) => s.ownerDocumentMatrix.createFormMatrixShow;
const createFormMatrixStep = (s) => s.ownerDocumentMatrix.createFormMatrixStep;
const createMatrixLoading = (s) => s.ownerDocumentMatrix.createMatrixLoading;
const createMatrixError = (s) => s.ownerDocumentMatrix.createMatrixError;




const groupedDocuments = createSelector(
  [currentDocumentMatrixDocuments],
  (currentDocumentMatrixDocumentsData) => {
    if (!currentDocumentMatrixDocumentsData) {
      return null;
    }

    return currentDocumentMatrixDocumentsData.reduce((acc, item) => {

      const { attributes } = item;
      const key = [attributes.group_order, attributes.group_id, attributes.group_name].join('___');
      const value = acc[key] || [];

      return { ...acc, [key]: [...value, item] }
    }, {});
  }
)

const structuredItems = createSelector(
  [currentDocumentMatrixItems],
  (currentDocumentMatrixItemsData) => {
    if (!currentDocumentMatrixItemsData) {
      return null;
    }

    return currentDocumentMatrixItemsData.reduce((acc, item) => {
      const {attributes: { role_id, group_item_id }} = item;
      const value = get(acc, group_item_id, {});

      return {...acc, [group_item_id]: {...value, [role_id]: item}}
    }, {});
});

export default createStructuredSelector({
  owner,
  documentMatrix,
  documentMatrixLoading,
  documentMatrixError,
  currentDocumentMatrix,
  currentDocumentMatrixItems,
  currentDocumentMatrixLoading,
  currentDocumentMatrixError,
  currentDocumentMatrixDocuments,
  currentDocumentMatrixDocumentsLoading,
  currentDocumentMatrixDocumentsError,
  currentDocumentMatrixRoles,
  currentDocumentMatrixRolesLoading,
  currentDocumentMatrixRolesError,
  showFullForm,
  fullFormId,
  fullFormLoading,
  fullFormError,
  fullFormData,
  copyFormMatrixShow,
  copyMatrixLoading,
  copyMatrixError,
  createFormMatrixShow,
  createFormMatrixStep,
  createMatrixLoading,
  createMatrixError,
  groupedDocuments,
  structuredItems,
})
