import get from "lodash/get";
import isObject from "lodash/isObject";
import { useState } from "react";

const STORE_KEYS = [
  "users.filter",
  "users.filterExpanded",
  "users.filterType",
  "taskManager.filter",
  "taskManager.filterExpanded",
];

export const fromStoreByKey = (key, path, defValue = null) => {
  const value = fromStore(key, {});
  return get(value, path, defValue);
};

export const useExpandedFilter = (storeKey, filterName) => {
  const [showItems, setShowItems] = useState(
    fromStoreByKey(`${storeKey}.filterExpanded`, filterName, false)
  );
  return [showItems, setShowItems];
};

export const useFilterType = (storeKey, filterName) => {
  const [filterType, setFilterType] = useState(
    fromStoreByKey(`${storeKey}.filterType`, filterName, false)
  );
  return [filterType, setFilterType];
};

export const fromStore = (key, defValue = "") => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined;
    const value = JSON.parse(serializedState);
    if (!isObject(value)) return value;

    return { ...defValue, ...value };
  } catch (err) {
    return defValue;
  }
};

const select = (state, key) => JSON.stringify(get(state, key));

export const withLocalStorage = (store) => {
  const { getState } = store;
  const { localStorage } = window;

  const handler = () => {
    STORE_KEYS.forEach((key) => {
      const value = select(getState(), key);
      localStorage.setItem(key, value);
    });
  };

  store.subscribe(handler);

  return store;
};
