import { handleActions, createAction } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import { initialState } from "../../utils/redux/commonActionHandlers";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";

export const constants = {
  ...getConstantsByFeatureName("OWNER_RECRUITERS"),
  ASSIGN_RECRUITER: "@OWNER_RECRUITERS/ASSIGN_RECRUITER",
  UNASSIGN_RECRUITER: "@OWNER_RECRUITERS/UNASSIGN_RECRUITER",
};
export const actions = {
  ...getActionCreators(constants),
  assignRecruiter: createAction(constants.ASSIGN_RECRUITER),
  unassignRecruiter: createAction(constants.UNASSIGN_RECRUITER),
};

export const actionHandlers = getActionHandlers(constants);
export const selector = getSelectorByFeatureName("ownerDetailsRecruiters");
export const reducer = handleActions(actionHandlers, initialState);
