import { call, put, select, takeEvery, debounce } from "redux-saga/effects";
import { matchPath } from "react-router-dom";
import {
  LOCATION_CHANGE,
  goBack,
  getLocation,
} from "connected-react-router";
import { actions, constants, selector } from "./redux";
import Api from "../../utils/Api";
import { addSuccess } from "../notifications/actions";
import { stopSubmit } from "redux-form";

const formName = "crewUsers";

function* fetchItems() {
  try {
    const featureState = yield select(selector);

    const { data } = yield call(Api.crewUser.index, featureState);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* fetchUsersForMentions() {
  try {
    const {data} = yield call(Api.crewUser.fetchUsersForMentions);
    yield put(actions.addUsersForMentions(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* createItem({ payload }) {
  try {
    const attributes = {
      ...payload,
      role: payload.role.value,
    };
    yield call(Api.crewUser.create, attributes);
    yield put(goBack());

    yield put(addSuccess("User created successfully"));
  } catch (e) {
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
  }
}

function* updateItem({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const {
      params: { id },
    } = matchPath(pathname, { path: "/crew-users/:id/edit" });
    const attributes = {
      ...payload,
      role: payload.role.value,
    };
    yield call(Api.crewUser.update, id, attributes);
    yield put(goBack());

    yield put(addSuccess("User profile updated successfully"));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));

    if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
  }
}

function* changeCrewUserStatus({
  payload: { cb, navBar = false, ...payload },
}) {
  try {
    yield call(Api.crewUser.update, null, payload);
    if (!navBar) yield put(actions.loadItemsRequest());

    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

    yield put(actions.updateItemError(e));
  }
}

function* deleteItem() {
  try {
    const pathname = yield select((s) => s.router.location.pathname);
    const {
      params: { id },
    } = matchPath(pathname, { path: "/crew-users/:id/(v_delete|delete)" });

    yield call(Api.crewUser.delete, id);
    yield put(goBack());

    yield put(addSuccess("User deleted successfully"));
  } catch (e) {
    console.error(e);

    yield put(actions.deleteItemError(e));
  }
}

function* fetchAfterSearch() {
  yield put(actions.applySearch()); // reset current pagination page

  yield put(actions.loadItemsRequest());
}

function* fetchItemsPageInit({
  payload: {
    isFirstRendering,
    location: { pathname },
  },
}) {
  const didOpenPage =
    matchPath(pathname, { exact: true, path: "/crew-users" }) ||
    (isFirstRendering &&
      matchPath(pathname, { path: "/crew-users/:id?/(new|edit|delete)" }));

  if (didOpenPage) yield put(actions.loadItemsRequest());
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [
        constants.SORT_ITEMS,
        constants.APPLY_FILTER,
        constants.CHANGE_PAGE_NUMBER,
        constants.CHANGE_PAGE_ITEMS_COUNT,
        constants.LOAD_ITEMS_REQUEST,
      ],
      fetchItems
    ),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(constants.CHANGE_CREW_USER_STATUS_REQUEST, changeCrewUserStatus),
    takeEvery(constants.FETCH_USERS_FOR_MENTIONS, fetchUsersForMentions),
  ];
}
