import * as api from './adapter';
import { convertItem, convertItems } from './convertors/comments';

const requestParams = { query: { include: 'author', sort: '-created_at' } };

export default {
  /**
   * index
   * @param resource
   * @param id
   * @returns {Promise<void>}
   */
  index: async (resource, id, filter, sort = '-created_at') => {
    const { data } = await api.read(id ? `${resource}/${id}/comments` : "comments", {
      query: {
        ...requestParams.query,
        filter,
        page: { size: 999 },
        sort,
      },
    });

    return convertItems(data);
  },
  create: async ({ state: { newComment: body, addressable_id, addressable_type }, filter: { resource, id, category } }) => {
    const query = {
      type: 'comments',
      attributes: {
        body,
        addressable_id,
        addressable_type,
        category: resource === 'users' ? category : null,
      },
    };
    
    if(id) {
      query.relationships = {
        [resource]: { // time_plans -> time_plan OR users -> user
          data: [{ id, type: resource }],
        },
      };
    }

    const { data: json } = await api.create(query, requestParams);

    return convertItem(json);
  },
  createFull: async ({ resource, userId: id, ...attributes }) => {
    const { data: json } = await api.create({
      type: 'comments',
      attributes,
      relationships: {
        [resource]: { // time_plans -> time_plan OR users -> user
          data: [{ id, type: resource }],
        },
      },
    }, requestParams);

    return convertItem(json);
  },
  delete: async (id) => api.destroy({ id, type: 'comments' }),
};
