// useDefaultSagasWithExtensions.js
import { handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import getCRUDSagas from "../../utils/redux/getCRUDSagas";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";
import Api from "../../utils/Api";

const useDefaultSagasWithExtensions = (params) => {
  const {
    constantsPrefix,
    statePropName,
    formStatePropName,
    mainPageUrl,
    itemName,
    extendsOptions,
  } = params;

  const baseConstants = getConstantsByFeatureName(constantsPrefix);
  const constants = extendsOptions.extendedConstants(baseConstants);

  const baseActions = getActionCreators(constants);
  const actions = extendsOptions.extendedActions(baseActions, constants);

  const initialState = extendsOptions.extendedInitialState;

  const baseActionHandlers = getActionHandlers(constants);
  const actionHandlers = extendsOptions.extendedActionHandlers(
    baseActionHandlers,
    constants
  );

  const reducer = handleActions(actionHandlers, initialState);

  const baseSelectors = getSelectorByFeatureName(statePropName);
  const selector = extendsOptions.extendedSelectors(baseSelectors);

  const baseSagas = getCRUDSagas(
    mainPageUrl,
    Api[statePropName],
    actions,
    constants,
    formStatePropName,
    statePropName,
    { itemName }
  );

  const newSagas = extendsOptions.newSagas(baseSagas, constants, actions);
  const sagas = extendsOptions.extendedSagas(baseSagas, newSagas);

  return {
    constants,
    actions,
    reducer,
    selector,
    sagas,
  };
};

export default useDefaultSagasWithExtensions;
