import { keyBy, get, uniq, map, sortBy } from 'lodash';

const links = {
  first: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
  last: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
};

export const setRelationshipsDataToItem = (item, groupedIncluded) => {
  const owner = groupedIncluded[`owners:${get(item, 'relationships.owner.data.id')}`];
  const crewUser = groupedIncluded[`clients:${get(item, 'relationships.client.data.id')}`];

  return ({
    id: item.id,
    createdAt: new Date(item.attributes.created_at).getTime(),
    ownerId: owner ? owner.id : null,
    name: owner ? owner.attributes.title : null,
    shortName: owner ? owner.attributes.short_title : null,
    owner_id: owner ? owner.id : null,
    crewUser,
  });
};

/**
 * serializeAssignedOwners
 * This function is used in many places and each case includes different relationships
 * this function handle all types of relationships
 * @param data
 * @param groupedIncluded
 * @return {*}
 */
const serializeAssignedOwners = (data, groupedIncluded) => data
  .map((item) => setRelationshipsDataToItem(item, groupedIncluded))
  .sort((a, b) => a.createdAt - b.createdAt);

const sortAndAddCountToManningScale = (data, assignedOwnersByOwnerId) => {
  const convertedData = data.map(({ id, attributes }) => ({
    id: `${id}:${attributes.role_id}:${attributes.owner_id}`,
    originId: id,
    originRoleName: attributes.title,
    originRoleId: String(attributes.role_id),
    ...attributes,
    createdAt: new Date(attributes.created_at).getTime(),
    assignedOwnerAt: assignedOwnersByOwnerId[attributes.owner_id].createdAt,
    imo: assignedOwnersByOwnerId[attributes.owner_id].imo,
  }));

  const sortedMs = sortBy(convertedData, [(i) => i.assignedOwnerAt, 'crewing_priority', 'createdAt']);

  // add role count if we have same roles for the owner
  return sortedMs.reduce((acc, currentItem, index, array) => {
    const prevItem = index > 0 ? acc[index - 1] : null;
    const nextItem = index + 1 !== array.length ? array[index + 1] : null;
    const isSamePriorityWithPrev = prevItem && currentItem.crewing_priority === prevItem.crewing_priority;
    const isSamePriorityWithNext = nextItem && currentItem.crewing_priority === nextItem.crewing_priority;

    const isAnotherShip = !prevItem || (prevItem.owner_id !== currentItem.owner_id);

    const count = isSamePriorityWithPrev && !isAnotherShip ? prevItem.count + 1 : 1;
    // name is a prop that we need for calendar plugin
    const name = !isSamePriorityWithPrev && !isSamePriorityWithNext
      ? currentItem.title
      : `${currentItem.title} ${count}`;

    return [...acc, { ...currentItem, count, name }];
  }, []);
};

export const convertItems = ({ data, included = [] }) => {
  const groupedIncluded = keyBy(included, ({ id, type }) => `${type}:${id}`);

  const serializedAssignedOwners = serializeAssignedOwners(data, groupedIncluded);
  const assignedOwnersByOwnerId = keyBy(serializedAssignedOwners, 'ownerId');
  const ownerIds = Object.keys(assignedOwnersByOwnerId);

  const activeManningScales = included.filter((item) => (
    item.type === 'manning_scales'
    && item.attributes.state === 'active'
  ));

  const roleIds = uniq(map(activeManningScales, 'attributes.role_id'));

  return {
    data: {
      assignedOwnersByOwnerId,
      manningScales: sortAndAddCountToManningScale(activeManningScales, assignedOwnersByOwnerId),
    },
    roleIds,
    ownerIds,
    links,
    meta: { total: data.length },
  };
};

export const convertListOfItems = ({ data, included }) => {
  const groupedIncluded = keyBy(included, ({ id, type }) => `${type}:${id}`);
  return {
    data: serializeAssignedOwners(data, groupedIncluded),
    links,
    meta: { total: data.length },
  };
};

export const convertItem = ({ data, included }) => {
  const groupedIncluded = keyBy(included, ({ id, type }) => `${type}:${id}`);

  return setRelationshipsDataToItem(data, groupedIncluded);
};

export default convertItems;
