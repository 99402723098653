import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';

const initialState = {
  ...baseInitState,
  state: {
    newMessage: '',
    sending: false,
    error: null,
  }
};

export const constants = getConstantsByFeatureName('TG_CHAT');
export const actions = getActionCreators(constants);
const baseActionHandlers = getActionHandlers(constants);
export const actionHandlers = {
  ...baseActionHandlers,
  [constants.LOAD_ITEMS_REQUEST]: (state, { payload: { resource, id } }) => ({
    ...initialState,
    isLoading: true,
    filter: { ...state.filter, resource, id },
  }),
  [constants.ADD_CREATED_ITEM]: (state, action) => ({
    ...state,
    isLoading: false,
    isPushing: false,
    data: [action.payload, ...state.data],
    state: { ...state.state, newComment: '' },
  }),
};
export const reducer = handleActions(actionHandlers, initialState);
export const selector = (s) => s.tgChat;
