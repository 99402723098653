import * as api from "./adapter";
import { convertItem } from "./convertors/vessels";

export default {
  /**
   * getCompanies
   * @returns {Promise<*>}
   */
  index: ({
    pagination,
    sort,
    filter: {
      is_selected,
      is_managed: { value: is_managed },
    },
    search,
  }) => {
    const extraFilter = is_selected ? { is_selected: true } : {};

    return api.read("vessels", {
      query: {
        filter: { name: search, is_managed, ...extraFilter },
        sort: `${sort.direction === "asc" ? "" : "-"}${sort.field}`,
        page: { size: pagination.itemsPerPage, number: pagination.current },
      },
    });
  },
  show: async (id) => {
    const { data: json } = await api.read(`vessels/${id}`, {
      query: {
        include:
          "owner,manager,manager_vessel,owner_vessel,coordinator,recruiters",
      },
    });

    return convertItem(json);
  },
  /**
   * createOwner
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: "vessels", attributes }),
  /**
   * updateOwner
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: "vessels", attributes }),
  /**
   * deleteOwner
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "vessels" }),
  /**
   * setManager
   * @param vesselId
   * @param managerId
   * @return {Promise}
   */
  setManager: (vesselId, managerId) =>
    api.create({
      type: "manager_vessels",
      relationships: {
        manager: { data: { type: "managers", id: managerId } },
        vessel: { data: { type: "vessels", id: vesselId } },
      },
    }),
  /**
   * setOwner
   * @param vesselId
   * @param ownerId
   * @return {Promise}
   */
  setOwner: (vesselId, ownerId) =>
    api.create({
      type: "owner_vessels",
      relationships: {
        owner: { data: { type: "owners", id: ownerId } },
        vessel: { data: { type: "vessels", id: vesselId } },
      },
    }),
  /**
   * changeManager
   * @param id
   * @param managerId
   * @return {Promise}
   */
  changeManager: (id, managerId) =>
    api.update({
      id,
      type: "manager_vessels",
      relationships: {
        manager: { data: { type: "managers", id: managerId } },
      },
    }),
  /**
   * changeOwner
   * @param id
   * @param ownerId
   * @return {Promise}
   */
  changeOwner: (id, ownerId) =>
    api.update({
      id,
      type: "owner_vessels",
      relationships: {
        owner: { data: { type: "owners", id: ownerId } },
      },
    }),
  /**
   * deleteManager
   * @param {String} id is managerVesselId
   * @return {Promise}
   */
  deleteManager: (id) =>
    api.destroy({
      id,
      type: "manager_vessels",
    }),
  /**
   * deleteOwner
   * @param {String} id is ownerVesselId
   * @return {Promise}
   */
  deleteOwner: (id) =>
    api.destroy({
      id,
      type: "owner_vessels",
    }),
  copyManningScale: (fromId, toId) =>
    api.create({
      type: "duplicate_manning_scales",
      attributes: {
        from_vessel_id: fromId,
        to_vessel_id: toId,
      },
    }),

  printCrewRole: async (vId) => {
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/crew_role?vessel_id=${vId}&type=on_board`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1]
      .replaceAll('"', "");
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },

  updateRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/vessels/${id}/relationships/${relation}`, {
      method: "PATCH",
      body: JSON.stringify({ data, skip_parse_equasis: true }),
    });
  },

  updatePhoto: async (payload) => {
    await api.apiRequest(`/api/vessels/update_photo`, {
      method: "POST",
      body: JSON.stringify(payload),
    });
  },
};
