import React, { useMemo } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import Fade from "@material-ui/core/Fade";
import NavigationBar from "../../../features/navigation-bar";
import styles from "./styles";
import SplashScreen from "../../LoadingPagePreview/SplashScreen"; // Імпортуйте SplashScreen

export default function wrapPage(Component) {
  const Page = (props) => {
    const { classes } = props;
    const isDataEmpty = isEmpty(props.data);

    const showSplashScreen = useMemo(
      () => (props.isLoading || props.isPushing) && isDataEmpty && !props?.didLoad,
      [props.isLoading, props.isPushing, isDataEmpty, props?.didLoad]
    );

    return (
      <section className={classes.root}>
        <NavigationBar
          isLoading={props.isLoading || props.isPushing}
          onSignOut={props.onSignOut}
        />
        {showSplashScreen ? (
          <SplashScreen />
        ) : (
          <Fade in={props.didLoad}>
            <Paper elevation={0} className={classes.contentWrapper}>
              <Component {...props} isDataEmpty={isDataEmpty} />
            </Paper>
          </Fade>
        )}
      </section>
    );
  };

  Page.propTypes = {
    isLoading: PropTypes.bool,
    isPushing: PropTypes.bool,
    didLoad: PropTypes.bool,
    onSignOut: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  Page.defaultProps = {
    isLoading: false,
    isPushing: false,
    didLoad: true,
    onSignOut: () => false,
  };

  return withStyles(styles)(Page);
}
