import { getQueryParams, getUrlQueryAsStateDiff } from './utils';

export default class ParamsStorage {
  static setQuery(featureName, query) {
    localStorage.setItem(`lastUrl.${featureName}`, query);
  }

  static getQuery(featureName) {
    return localStorage.getItem(`lastUrl.${featureName}`) || '';
  }

  static getQueryAsObject(featureName) {
    const query = this.getQuery(featureName);

    return getQueryParams(query);
  }

  static getQueryAsStateDiff(featureName) {
    const query = this.getQuery(featureName);

    return getUrlQueryAsStateDiff(query, featureName);
  }
}
