import * as api from './adapter';
// import convertToOptions from './convertors/convertToOptions';

export default {
  /**
   * crewingStatuses
   * @returns {Promise<*>}
   */
  index: () => api.read(`/crewing_statuses`),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/crewing_statuses/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'crewing_statuses', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'crewing_statuses', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'crewing_statuses' }),
};
