import { handleActions, createAction } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { startPushing, pushingError } from '../../utils/redux/commonActionHandlers';

export const routeConfig = { path: '/vessels/:id/:tab' };

const initialState = {
  data: null,
  isLoading: true,
  isPushing: false,
  error: null,
  equasisLoading: false,
  photoIsLoading: false,
};

export const constants = {
  ...getConstantsByFeatureName('VESSEL_DETAILS'),
  START_CHANGING_MANAGER: '@VESSEL_DETAILS/START_CHANGING_MANAGER',
  MANAGER_CHANGED_SUCCESSFULLY: '@VESSEL_DETAILS/MANAGER_CHANGED_SUCCESSFULLY',
  CHANGE_MANAGER_ERROR: '@VESSEL_DETAILS/CHANGE_MANAGER_ERROR',

  START_CHANGING_OWNER: '@VESSEL_DETAILS/START_CHANGING_OWNER',
  OWNER_CHANGED_SUCCESSFULLY: '@VESSEL_DETAILS/OWNER_CHANGED_SUCCESSFULLY',
  CHANGE_OWNER_ERROR: '@VESSEL_DETAILS/CHANGE_OWNER_ERROR',
  FETCH_FROM_EQUASIS: `@VESSELS/FETCH_FROM_EQUASIS`,
  SET_EQUASIS_LOADING: '@VESSELS/SET_EQUASIS_LOADING',
  SET_PHOTO_IS_LOADING: '@VESSELS/SET_PHOTO_IS_LOADING',
  CHANGE_COORDINATOR: '@VESSELS/CHANGE_COORDINATOR',
  UPDATE_PHOTO: '@VESSELS/UPDATE_PHOTO',
};
export const actions = {
  ...getActionCreators(constants),
  startChangingManager: createAction(constants.START_CHANGING_MANAGER),
  managerChangedSuccessfully: createAction(constants.MANAGER_CHANGED_SUCCESSFULLY),
  changeManagerError: createAction(constants.CHANGE_MANAGER_ERROR),

  startChangingOwner: createAction(constants.START_CHANGING_OWNER),
  ownerChangedSuccessfully: createAction(constants.OWNER_CHANGED_SUCCESSFULLY),
  changeOwnerError: createAction(constants.CHANGE_OWNER_ERROR),
  fetchItemFromEquasis: createAction(constants.FETCH_FROM_EQUASIS),
  setEquasisLoading: createAction(constants.SET_EQUASIS_LOADING),
  setPhotoIsLoading: createAction(constants.SET_PHOTO_IS_LOADING),
  changeCoordinator: createAction(constants.CHANGE_COORDINATOR),
  updatePhoto: createAction(constants.UPDATE_PHOTO),
};

export const selector = getSelectorByFeatureName('vesselDetails');

export const reducer = handleActions({
  ...getActionHandlers(constants),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, routeConfig)
      ? routeConfig
      : initialState
  ),
  [constants.START_CHANGING_MANAGER]: (state, action) => ({
    ...startPushing(state, action),
    data: { ...state.data, manager: action.payload },
  }),
  [constants.MANAGER_CHANGED_SUCCESSFULLY]: (state) => ({ ...state, isPushing: false }),
  [constants.CHANGE_MANAGER_ERROR]: pushingError,

  [constants.START_CHANGING_OWNER]: (state, action) => ({
    ...startPushing(state, action),
    data: { ...state.data, owner: action.payload },
  }),
  [constants.OWNER_CHANGED_SUCCESSFULLY]: (state) => ({ ...state, isPushing: false }),
  [constants.CHANGE_OWNER_ERROR]: pushingError,
  [constants.SET_EQUASIS_LOADING]: (state, action) => ({ ...state, equasisLoading: action.payload }),
  [constants.SET_PHOTO_IS_LOADING]: (state, action) => ({ ...state, photoIsLoading: action.payload }),
}, initialState);
