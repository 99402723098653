import * as api from './adapter';


export default  {

  createMatrix: ({ attributes }) => api.create({ type: 'owner_groups', controller: 'document_matrix/owner_groups', attributes }),

  ownerMatrices: ({ filter }) => api.read('document_matrix/owner_groups', { query: { filter }}),

  matrixItems: ({ filter }) => api.read('document_matrix/items', { query: { filter }}),

  matrixItemsLoad: (id) => api.read(`document_matrix/items/${id}`),

  documents: () => api.read(`document_matrix/group_items`, {query: {page: {size: 999}}}),

  updateItem: ({ id, attributes }) => api.update({ id, type: 'items', controller: 'document_matrix/items', attributes }),

  roles: (groupId) => api.read(`document_matrix/owner_groups/${groupId}/roles`),

  copyMatrix: ({ attributes }) => api.create({ type: 'owner_groups', controller: 'document_matrix/owner_groups', attributes}),
}
