import * as api from "./adapter";
import { convertItem, convertItems } from "./convertors/users";
import serializeDataToQueryString from "./serializeDataToQueryString";
import { convertItems as convertAppFormLinkItems } from "./convertors/experienceVerifications";
import { buildFilterQuery } from "./utils/users";

export default {
  /**
   * getCompanies
   * @returns {Promise<*>}
   */
  index: async ({
    pagination,
    sort,
    filter,
    filterType,
    search: name,
    fields = {},
  }) => {
    const query = {
      include: "last_expirience,role,external_applications",
      filter: buildFilterQuery(filter, filterType, name),
      page: { size: pagination.itemsPerPage, number: pagination.current },
      fields,
    };

    if (sort && sort.field)
      query["sort"] = `${sort.direction === "asc" ? "" : "-"}${sort.field}`;

    const { data } = await api.read("users", { query });

    return convertItems(data);
  },
  show: async (id) => {
    const { data: json } = await api.read(`users/${id}`, {
      query: { include: "expiriences,role" },
    });

    return convertItem(json.data, json.included);
  },
  update: async (id, attributes) => {
    const { data: json } = await api.update(
      {
        id,
        type: "users",
        attributes,
      },
      { query: { include: "expiriences,role" } }
    );

    return convertItem(json.data, json.included);
  },
  create: async (attributes) => {
    const { data: json } = await api.create(
      {
        type: "users",
        attributes,
      },
      { query: { include: "expiriences,role" } }
    );

    return convertItem(json.data, json.included);
  },
  delete: (id) => api.destroy({ id, type: "users" }),

  getContractLink: async ({
    timeplanId,
    fullContract,
    asDocx = false,
    params = {},
  }) => {
    const qParams = [];
    if (fullContract) qParams.push("full=true");
    if (asDocx) qParams.push("as_docx=true");
    qParams.push(serializeDataToQueryString(params));

    const response = await api.read(
      `/print_contracts/${timeplanId}?${qParams.join("&")}`,
      {}
    );

    return {
      ...response.data.data.attributes,
      uri: response.data.data.attributes.xls_file,
      name: response.data.data.attributes.file_file_name,
    };
  },
  assignTag: async ({ filter, filterType, search: name, tag, userId }) => {
    await api.read("users/assign_tag", {
      query: {
        tag,
        userId,
        filter: buildFilterQuery(filter, filterType, name),
        page: { size: 10000, number: 1 },
      },
    });
    return true;
  },
  removeTag: async ({ filter, filterType, search: name, tag, userId }) => {
    await api.read("users/remove_tag", {
      query: {
        tag,
        userId,
        filter: buildFilterQuery(filter, filterType, name),
        page: { size: 10000, number: 1 },
      },
    });

    return true;
  },
  downloadXlsx: async ({ filter, filterType, search: name }) => {
    const response = await api.read("users/index_xlsx", {
      query: {
        include: "last_expirience,role,external_applications",
        filter: buildFilterQuery(filter, filterType, name),
        page: { size: 200, number: 1 },
      },
    });

    return {
      ...response.data.data.attributes,
      uri: response.data.data.attributes.xls_file,
      name: response.data.data.attributes.file_file_name,
    };
  },

  checkDocumentMatrix: ({ query }) =>
    api.read("users/check_document_matrix", { query }),

  updateFromUC: async (id) => {
    return await api.read(`users/${id}/update_from_uc`);
  },

  updateFromCW: async (id) => {
    return await api.read(`users/${id}/update_from_cw`);
  },

  getAppFormLinks: async (id) => {
    const { data } = await api.read(`users/${id}/app_form_links`, {
      query: {
        include: "author",
        sort: "-created_at",
        page: { size: 999 },
      },
    });
    return convertAppFormLinkItems(data);
  },
};
