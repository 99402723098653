import * as api from './adapter';

export default {
  /**
   * getCrewUsers
   * @returns {Promise<*>}
   */
  index: () => ({
    data: {
      data: [
        { id: '969ca', attributes: { name: 'Shipchandler' } },
        { id: '41bf5', attributes: { name: 'Spare parts' } },
        { id: '1da14', attributes: { name: 'Conventional Equipment Service' } },
        { id: 'c4cba', attributes: { name: 'Assy Units/Dedicated suppliers' } },
        { id: '7e5e6', attributes: { name: 'Diving companies' } },
        { id: '718d6', attributes: { name: 'Agent' } },
        { id: 'aa697', attributes: { name: 'Navigational Equipment Service' } },
        { id: 'ebeba', attributes: { name: 'Workshops/Recondition' } },
        { id: 'c2ab8', attributes: { name: 'Scrapyards' } },
        { id: '426ec', attributes: { name: 'Anchors/Chains/Wires' } },
        { id: '747a3', attributes: { name: 'Shipyards' } },
        { id: '3d64f', attributes: { name: 'Fuel/Lubricating Oil Trader' } },
        { id: '4b07f', attributes: { name: 'Flag/Class' } },
        { id: '26e74', attributes: { name: 'Logistics/Forwarding' } },
        { id: '77200', attributes: { name: 'Manufacturer' } },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/vessel_types/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'vessel_types', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'vessel_types', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'vessel_types' }),
};
