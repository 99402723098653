import { call, put, takeEvery , select} from 'redux-saga/effects';
import { constants as ownerDetailsConstants } from '../owner-details/redux';
import { actions } from './redux';
import Api from '../../utils/Api';
import {getLocation} from "connected-react-router";
import {matchPath} from "react-router-dom";

function* fetchItems({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/details' })
    if (!correctPath) return;

    yield put(actions.loadItemRequest()); // set is loading state
    const fields = { vessels: 'name,imo' };
    const { data } = yield call(Api.owner.getVessels, payload.id, { fields });

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(ownerDetailsConstants.ADD_LOADED_ITEM, fetchItems),
  ];
}
