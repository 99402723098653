import { createAction } from 'redux-actions';
import * as constants from './constants';

export const selectTimeplan = createAction(constants.SELECT_TIMEPLAN);
export const reselectTimeplan = createAction(constants.RESELECT_TIMEPLAN);
export const changeMode = createAction(constants.CHANGE_MODE);
export const addItems = createAction(constants.ADD_ITEMS);

export const createItemRequest = createAction(constants.CREATE_ITEM_REQUEST);
export const addCreatedItem = createAction(constants.ADD_CREATED_ITEM);
export const createItemError = createAction(constants.CREATE_ITEM_ERROR, (e) => e);

export const updateItemRequest = createAction(constants.UPDATE_ITEM_REQUEST);
export const updateItemEOCRequest = createAction(constants.UPDATE_ITEM_EOC_REQUEST);
export const updateItem = createAction(constants.ITEM_UPDATED_SUCCESSFULLY);
export const updateItemError = createAction(constants.UPDATE_ITEM_ERROR, (e) => e);

export const deleteItemRequest = createAction(constants.DELETE_ITEM_REQUEST);
export const deleteItem = createAction(constants.ITEM_DELETED_SUCCESSFULLY);
export const deleteItemError = createAction(constants.DELETE_ITEM_ERROR, (e) => e);
