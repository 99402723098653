import * as api from './adapter';

export default {

  index: async ({ search, email }) => {
    const { data } = await api.read('equasis_vessel_search', {
      query: {
        q: search,
        email,
      },
    });

    return data;
  },

  create: async ({ imo }) => {
    const { data } = await api.create(
      { type: 'equasis_vessel_search' },
      { query: { imo } },
    );

    return data;
  },

  addImosToParse: async ({ email, imos }) => {
    const { data } = await api.update(
      { type: 'equasis_vessel_search', action: 'bulk_parse', attributes: { imos, email } },
      {},
    );

    return data;
  }
};
