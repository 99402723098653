import * as api from './adapter';

export default {
  index: () => ({
    data: {
      data: [
        { id: '020b39f4', attributes: { name: '020b39f4', type: 'wife' } },
        { id: '02db4ced', attributes: { name: '02db4ced', type: 'mother' } },
        { id: '0ae61044', attributes: { name: '0ae61044', type: 'father' } },
        { id: '16c5924a', attributes: { name: '16c5924a', type: 'husband' } },
        { id: '25e6146e', attributes: { name: '25e6146e', type: 'brother' } },
        { id: '263afa97', attributes: { name: '263afa97', type: 'sister' } },
        { id: '3341aee0', attributes: { name: '3341aee0', type: 'daughter' } },
        { id: '3e60c8ee', attributes: { name: '3e60c8ee', type: 'son' } },
        { id: '44a633c8', attributes: { name: '44a633c8', type: 'aunt' } },
        { id: '483b30e1', attributes: { name: '483b30e1', type: 'uncle' } },
        { id: '6f68a009', attributes: { name: '6f68a009', type: 'bribe' } },
        { id: '7fd42918', attributes: { name: '7fd42918', type: 'grandmothe' } },
        { id: '86f905ba', attributes: { name: '86f905ba', type: 'niece' } },
        { id: '95d3e9d2', attributes: { name: '95d3e9d2', type: 'ex-wife' } },
        { id: 'b6181eb9', attributes: { name: 'b6181eb9', type: 'grandfathr' } },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/user_relatives/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'user_relatives', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'user_relatives', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'user_relatives' }),
};
