import { call, put, takeEvery } from 'redux-saga/effects';
import { constants as managerDetailsConstants } from '../manager-details/redux';
import { actions } from './redux';
import Api from '../../utils/Api';

function* fetchItems({ payload }) {
  try {
    yield put(actions.loadItemRequest()); // set is loading state

    const { data } = yield call(Api.manager.getVessels, payload.id);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(managerDetailsConstants.ADD_LOADED_ITEM, fetchItems),
  ];
}
