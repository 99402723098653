import { createAction, handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState as baseInitState, pushingError } from '../../utils/redux/commonActionHandlers';

const initialState = { ...baseInitState, isCopying: false };

export const constants = {
  ...getConstantsByFeatureName('MANNING_SCALES'),
  COPY_MANNING_SCALE_REQUEST: '@MANNING_SCALES/COPY_MANNING_SCALE_REQUEST',
  COPY_MANNING_SCALE_ERROR: '@MANNING_SCALES/COPY_MANNING_SCALE_ERROR',
  COPY_MANNING_SCALE_SUCCESS: '@MANNING_SCALES/COPY_MANNING_SCALE_SUCCESS',
};

export const actions = {
  ...getActionCreators(constants),
  copyManningScaleRequest: createAction(constants.COPY_MANNING_SCALE_REQUEST),
  copyManningScaleError: createAction(constants.COPY_MANNING_SCALE_ERROR),
  copyManningScaleSuccess: createAction(constants.COPY_MANNING_SCALE_SUCCESS),
};

export const actionHandlers = getActionHandlers(constants);
export const reducer = handleActions({
  ...actionHandlers,
  [constants.COPY_MANNING_SCALE_REQUEST]: (s) => ({ ...s, isCopying: true }),
  [constants.COPY_MANNING_SCALE_ERROR]: (s, action) => ({ ...pushingError(s, action), isCopying: false }),
  [constants.COPY_MANNING_SCALE_SUCCESS]: (s) => ({ ...s, isCopying: false, isLoading: true }),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, { path: '/vessels/:id/details' })
      ? state
      : initialState
  ),
}, initialState);
