import { get } from 'lodash';

export const labelValue = (object, kLabel, kValue) => {
  if (!object) return null;
   
  return {
    value: get(object, kValue),
    label: get(object, kLabel),
  }
}
