import * as api from './adapter';

export default {
  index: () => ({
    data: {
      data: [
        { id: '020b39f4', attributes: { name: 'Certificate of Registry' } },
        { id: '02db4ced', attributes: { name: 'Provisional Certificate of Registry' } },
        { id: '0ae61044', attributes: { name: 'Minimum Safe Manning Certificate' } },
        { id: '16c5924a', attributes: { name: 'Interim Safety Management Certificate' } },
        { id: '25e6146e', attributes: { name: 'Safety Management Certificate' } },
        { id: '263afa97', attributes: { name: 'Interim International Ship Security Certificate' } },
        { id: '3341aee0', attributes: { name: 'International Ship Security Certificate' } },
        { id: '3e60c8ee', attributes: { name: 'Ship Radio Station License' } },
        { id: '44a633c8', attributes: { name: 'ILO Compliance Certificate' } },
        { id: '483b30e1', attributes: { name: 'MLC Certificate' } },
        { id: '6f68a009', attributes: { name: 'Bunker Civil Liability Certificate' } },
        { id: '7fd42918', attributes: { name: 'Exemption Certificate for Cargo Hold CO2 system' } },
        { id: '86f905ba', attributes: { name: 'Exemption Certificate for BNWAS' } },
        { id: '95d3e9d2', attributes: { name: 'Wreckage Removal Certificate' } },
        { id: 'b6181eb9', attributes: { name: 'International Tonnage Certificate (1969)' } },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/vendors/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'vendors', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'vendors', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'vendors' }),
};
