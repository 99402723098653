import { delay, put, takeEvery } from 'redux-saga/effects';
import { deleteItem } from './actions';
import { ADD_ITEM } from './constants';

export function* deleteItemByDelay({ payload: { id, delay: ms = 5000 } }) {
  try {
    yield delay(ms);
    yield put(deleteItem(id));
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
}
/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(ADD_ITEM, deleteItemByDelay),
  ];
}
