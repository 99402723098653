import * as api from "./adapter";

export default {
  /**
   * vacancyApplies
   * @returns {Promise<*>}
   */
  index: () => api.read("vacancy_applies/"),
  /**
   * showч
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/vacancy_applies/${id}`),
  /**
   * createVacancyApply
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) => {
    const { 
      candidate, 
      author_id, 
      author_name, 
      vacancy_id, 
      task_id,
    } = attributes;

    const comment = [
      {
        author: author_name,
        body: "Candidate is applied",
        apply_status: "processing",
        date: `${new Date()}`,
      },
    ];

    const payload = {
      author_id: author_id,
      vacancy_id: vacancy_id,
      task_id: task_id,
      candidate_id: candidate.value,
      change_comments: comment,
    };

    const resp = await api.create({
      type: "vacancy_applies",
      attributes: payload,
      controller: "vacancy_applies",
    });

    return resp;
  },
  /**
   * updateVacancyApply
   * @param id
   * @param attributes
   * @returns {Promise}
   */

  updateStatus: (payload) =>
    api.apiRequest("/api/vacancy_applies/update_status", {
      method: "POST",
      body: JSON.stringify({ payload }),
    }),
};
