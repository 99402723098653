import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import { initialState as baseInitState } from '../../utils/redux/commonActionHandlers';

const initialState = {
  ...baseInitState
};

export const constants = getConstantsByFeatureName('APP_FORM_LINKS');
export const actions = getActionCreators(constants);
export const actionHandlers = getActionHandlers(constants);
export const selector = getSelectorByFeatureName('appFormLinks');
export const reducer = handleActions(actionHandlers, initialState);
