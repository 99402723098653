import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { createSelector, createStructuredSelector } from 'reselect';
import { crewListModes, getIdsGroupedByTimeplanListType } from './utils';

const getCrewList = (s) => s.crewList.data;
const getMode = (s) => s.crewList.mode;

const idsToDisplayCrewList = createSelector(
  [getMode, getCrewList],
  (mode, list) => {
    if (mode === crewListModes.DISPLAY) {
      return getIdsGroupedByTimeplanListType(list);
    }

    return {
      offWithoutOn: [],
      onWithoutOff: [],
      onWithOff: [],
      offWithOn: [],
    };
  },
);

export const isCreateCrewRotationButtonVisible = createSelector(
  [getCrewList, getMode],
  (crewList, mode) => {
    if (mode !== crewListModes.SELECT) return false;

    return !isEmpty(crewList);
  },
);

const selectedTimeplansIds = createSelector(
  [getCrewList, getMode],
  (crewList, mode) => {
    if (mode === crewListModes.SELECT || mode === crewListModes.RESELECT) return map(crewList, 'id');

    return [];
  },
);

export default createStructuredSelector({
  mode: getMode,
  selectedTimeplansIds,
  idsToDisplayCrewList,
});
