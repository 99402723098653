import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tab from '@material-ui/core/Tab';
import styles from './styles';

const CustomTab = ({ classes, ...props }) => (
  <Tab disableRipple classes={classes} {...props} />
);

CustomTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomTab);
