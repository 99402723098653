import { call, put, takeEvery, select, debounce, delay } from 'redux-saga/effects';
import { LOCATION_CHANGE, goBack, getLocation } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { stopSubmit } from 'redux-form';
import { addSuccess } from '../notifications/actions';

const defaultItemName = 'Item';

const setDefaultValuesToConfig = ({
  itemName = defaultItemName,
  getSuccessCreateMsg = () => `${itemName} created successfully`,
  getSuccessUpdateMsg = () => `${itemName} updated successfully`,
  successDeleteMsg = () => `${itemName} deleted successfully`,
  ...options
}) => ({ itemName, getSuccessCreateMsg, getSuccessUpdateMsg, successDeleteMsg, ...options });

/**
 * getCRUDSagas
 * @param {String} basePageUrl
 * @param {Object} api
 * @param {Object} actions
 * @param {Object} constants
 * @param {String} statePropertyName
 * @param {String} formName
 * @param {Object} options
 * @returns {function(): any[]}
 */
export default function getCRUDSagas(basePageUrl, api, actions, constants, statePropertyName, formName, options = {}) {
  const config = setDefaultValuesToConfig(options);

  function* fetchItems() {
    try {
      const featureState = yield select((s) => s[statePropertyName]);

      const fields = { users: 'crewing_applicant_id,surname,role,crewing_rank,vessel,last_expirience,name,' +
          'last_contact,birthday,electronic_me,status,crewing_status_id,readiness_date,avatar,wage,' +
          'vessel_planned_name,date_planned,vessel_last_rank_title,last_contract_soff,vessel_last_name,' +
          'vessel_last_manager_name,us_visa_doe,mobile_phone,tag_list,telegram_bot,external_applications,' +
          'ukr_crewing_id,no_call_answer,hot,ex_scg,external_objects,valid_state,valid_states,is_favorite,' +
          'country_birth_emoji,stable_employee,need_reaction,ext_update,promotions_count,promotions_roles,has_warnings,' +
          'mobile_phone_alternative,email_alternative,need_education,crewell_id,maritime_zone_id' };

      const { data } = yield call(api.index, { ...featureState, fields });

      yield put(actions.addItems(data));
    } catch (e) {
      console.error(e);

      yield put(actions.loadItemsError(e));
    }
  }

  function* fetchAfterSearch() {
    yield put(actions.applySearch()); // reset current pagination page
    yield put(actions.loadItemsRequest());
  }

  function* createItem({ payload }) {
    try {
      const data = yield call(api.create, payload);
      yield put(goBack());
      yield put(addSuccess(config.getSuccessCreateMsg(payload, data)));

      const id = data.id;
      const createdAccountLink = `${id}/comments`;
      window.open(createdAccountLink, '_blank');
    } catch (e) {
      yield put(actions.createItemError(e));

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* updateItem({ payload }) {
    try {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/edit` });

      const { data } = yield call(api.update, id, payload);
      yield put({ type: '@USER_DETAILS_PAGE/LOAD_ITEM_REQUEST', payload: id });
      yield put(goBack());

      yield put(addSuccess(config.getSuccessUpdateMsg(payload, data)));
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit(formName, e.errorByField));
    }
  }

  function* deleteItem() {
    try {
      const pathname = yield select((s) => s.router.location.pathname);
      const { params: { id } } = matchPath(pathname, { path: `${basePageUrl}/:id/(v_delete|delete)` });

      yield call(api.delete, id);
      yield put(goBack());

      yield put(addSuccess(config.successDeleteMsg(id)));
    } catch (e) {
      console.error(e);

      yield put(actions.deleteItemError(e));
    }
  }

  function* fetchItemsPageInit({ payload: { isFirstRendering, location: { pathname } } }) {
    const didOpenPage = (
      matchPath(pathname, { exact: true, path: `${basePageUrl}` })
      // "|| isFirstRendering" for case when you open a form and reload the page
      // and we MUST show list items behind the form
      || (isFirstRendering && matchPath(pathname, { path: `${basePageUrl}/:id?/(new|edit|delete)` }))
    );

    if (didOpenPage) yield put(actions.loadItemsRequest());
  }

  /**
  * getFeatureSagas (rename)
  * each saga file should return all feature sagas
  * @returns {ForkEffect[]}
  */
  function getFeatureSagas() {
    return [
      takeEvery([
        constants.SORT_ITEMS,
        constants.APPLY_FILTER,
        constants.CHANGE_PAGE_NUMBER,
        constants.CHANGE_PAGE_ITEMS_COUNT,
        constants.LOAD_ITEMS_REQUEST,
      ], fetchItems),
      takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
      takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
      takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
      debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
      takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    ];
  }

  getFeatureSagas.fetchItems = fetchItems;
  getFeatureSagas.fetchAfterSearch = fetchAfterSearch;
  getFeatureSagas.createItem = createItem;
  getFeatureSagas.updateItem = updateItem;
  getFeatureSagas.deleteItem = deleteItem;
  getFeatureSagas.fetchItemsPageInit = fetchItemsPageInit;
  getFeatureSagas.getFeatureSagas = getFeatureSagas;

  return getFeatureSagas;
}
