import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import { getPermissions, getCurrentUserData } from "../../utils/selectors";
import { initialState as baseInitState } from "../../utils/redux/commonActionHandlers";

const initialState = {
  ...baseInitState,
  notificationCount: 0,
  notShownNotifications: {},
};

const baseConstants = getConstantsByFeatureName("NOTIFICATION_MANAGER");
export const constants = {
  ...baseConstants,
  SET_NOTIFICATION_COUNT: "@NOTIFICATION_MANAGER/SET_NOTIFICATION_COUNT",
  SET_NOTIFICATION_POP_UP: "@NOTIFICATION_MANAGER/SET_NOTIFICATION_POP_UP",
  CLEAR_ALL_NOTIFICATIONS: "@NOTIFICATION_MANAGER/CLEAR_ALL_NOTIFICATIONS",
  CLEAR_NOTIFICATION: "@NOTIFICATION_MANAGER/CLEAR_NOTIFICATION",
  SHOW_POP_UP: "@NOTIFICATION_MANAGER/SHOW_POP_UP",
};

const baseActions = getActionCreators(constants);
export const actions = {
  ...baseActions,
  setNotificationCount: createAction(constants.SET_NOTIFICATION_COUNT),
  setNotificationPopUp: createAction(constants.SET_NOTIFICATION_POP_UP),
  clearAllNotifications: createAction(constants.CLEAR_ALL_NOTIFICATIONS),
  clearNotification: createAction(constants.CLEAR_NOTIFICATION),
  showPopUp: createAction(constants.SHOW_POP_UP),
};

const featureName = "notificationManager";

export const actionHandlers = getActionHandlers(constants);

export const selector = (state) => ({
  data: state[featureName].data,
  didLoad: state[featureName].didLoad,
  isPushing: state[featureName].isPushing,
  isLoading: state[featureName].isLoading,
  state: state[featureName].state,
  pagination: state[featureName].pagination,
  filter: state[featureName].filter,
  sort: state[featureName].sort,
  search: state[featureName].search,
  error: state[featureName].error,
  permissions: getPermissions(state),
  user: getCurrentUserData(state),
  notificationCount: state[featureName].notificationCount,
  notShownNotifications: state[featureName].notShownNotifications,
});

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.SET_NOTIFICATION_COUNT]: (state, action) => ({
      ...state,
      notificationCount: action.payload.unread_count,
    }),
    [constants.SET_NOTIFICATION_POP_UP]: (state, action) => ({
      ...state,
      notShownNotifications: action.payload.not_shown_notifications,
    }),
  },
  initialState
);
