export default (theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontSize: 14,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});
