import { call, put, select, takeEvery, debounce } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { matchPath } from 'react-router-dom';
import { getLocation, goBack, LOCATION_CHANGE } from 'connected-react-router';
import { actions, constants, selector } from './redux';
import Api from '../../utils/Api';
import { addSuccess } from '../notifications/actions';

export function* fetchItems() {
  try {
    const featureState = yield select(selector);
    const json = yield call(Api.crewChange.index, featureState);

    yield put(actions.addItems(json));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* updateItem({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const { params: { id } } = matchPath(pathname, { path: '/crew-changes/:id/edit' });

    yield call(Api.crewChange.update, id, payload);
    yield put(goBack());

    yield put(addSuccess('Crew change updated successfully'));
  } catch (e) {
    yield put(actions.updateItemError(e));

    if (e.errorByField) yield put(stopSubmit('editCrewChange', e.errorByField));
  }
}

function* deleteItem() {
  try {
    const pathname = yield select((s) => s.router.location.pathname);
    const { params: { id } } = matchPath(pathname, { path: '/crew-changes/:id/delete' });

    yield call(Api.crewChange.delete, id);
    yield put(goBack());

    yield put(addSuccess('Crew change deleted successfully'));
  } catch (e) {
    console.error(e);

    yield put(actions.deleteItemError(e));
  }
}

function* fetchAfterSearch() {
  yield put(actions.applySearch()); // reset current pagination page

  yield put(actions.loadItemsRequest());
}

export function* fetchItemsPageInit({ payload: { isFirstRendering, location: { pathname } } }) {
  const didOpenPage = (
    matchPath(pathname, { exact: true, path: '/crew-changes' })
    || (isFirstRendering && matchPath(pathname, { path: '/crew-changes/:id/edit' }))
  );

  if (didOpenPage) yield put(actions.loadItemsRequest());
}


/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SORT_ITEMS,
      constants.APPLY_FILTER,
      constants.CHANGE_PAGE_NUMBER,
      constants.CHANGE_PAGE_ITEMS_COUNT,
      constants.LOAD_ITEMS_REQUEST,
    ], fetchItems),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
  ];
}
