import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import { initialState as baseInitState } from "../../utils/redux/commonActionHandlers";

const initialState = {
  ...baseInitState,
  state: { newComment: "", tasksForInteraction: [], newCommentIsPushing: false },
};

const baseConstants = getConstantsByFeatureName("COMMENTS");

export const constants = {
  ...baseConstants,
  TASK_COMMENTS_LOAD_ITEMS_REQUEST: `@COMMENTS/TASK_COMMENTS_LOAD_ITEMS_REQUEST`,
  FETCH_TASKS_FOR_INTERACTION_REQUEST: "@SEAFARERS_VIEW/FETCH_TASKS_FOR_INTERACTION_REQUEST",
  ADD_TASKS_FOR_INTERACTION: "@SEAFARERS_VIEW/ADD_TASKS_FOR_INTERACTION",
  NEW_COMMENT_IS_PUSHING: "@COMMENTS/NEW_COMMENT_IS_PUSHING",
};

const baseActions = getActionCreators(constants);
const baseActionHandlers = getActionHandlers(constants);

export const actions = {
  ...baseActions,
  fetchTaskComments: createAction(constants.TASK_COMMENTS_LOAD_ITEMS_REQUEST),
  fetchTasksForInteraction: createAction(constants.FETCH_TASKS_FOR_INTERACTION_REQUEST),
  addTasksForInteraction: createAction(constants.ADD_TASKS_FOR_INTERACTION),
  newCommentIsPushing: createAction(constants.NEW_COMMENT_IS_PUSHING),
};

export const actionHandlers = {
  ...baseActionHandlers,
  [constants.LOAD_ITEMS_REQUEST]: (state, { payload: { resource, id } }) => ({
    ...initialState,
    isLoading: true,
    filter: { ...state.filter, resource, id },
  }),
  [constants.ADD_CREATED_ITEM]: (state, action) => ({
    ...state,
    isLoading: false,
    isPushing: false,
    data: [action.payload, ...state.data],
    state: { ...state.state, newComment: "" },
  }),
  [constants.ADD_TASKS_FOR_INTERACTION]: (state, action) => ({
    ...state,
    tasksForInteraction: action.payload,
  }),
  [constants.NEW_COMMENT_IS_PUSHING]: (state, action) => ({
    ...state,
    isPushing: action.payload,
  }),
};
export const reducer = handleActions(actionHandlers, initialState);
export const selector = (s) => s.comments;
