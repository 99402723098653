import {createAction, handleActions} from "redux-actions";
import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import Api from "../../../../utils/Api";
import { addItem as notify } from '../../../notifications/actions';

const initialState = {
  loading: false,
  error: null
};

const constants = {
  SET_LOADING: '@USER_DETAILS_PAGE/UPDATE_CW/SET_LOADING',
  UPDATE_CW_REQUEST: '@USER_DETAILS_PAGE/UPDATE_CW/UPDATE_CW_REQUEST',
  UPDATE_CW_SUCCESS: '@USER_DETAILS_PAGE/UPDATE_CW/UPDATE_CW_SUCCESS',
  UPDATE_CW_ERROR: '@USER_DETAILS_PAGE/UPDATE_CW/UPDATE_CW_ERROR',
};

const actions = {
  setLoading: createAction(constants.SET_LOADING),
  updateCWRequest: createAction(constants.UPDATE_CW_REQUEST),
  loadUserDetails: createAction('@USER_DETAILS_PAGE/LOAD_ITEM_REQUEST'),
  updateCWError: createAction(constants.UPDATE_CW_ERROR),
};

const actionHandlers = {
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state, loading: payload,
  }),
  [constants.UPDATE_CW_ERROR]: (state, { payload }) => ({
    ...state, error: payload,
  }),
};

const reducer = handleActions(actionHandlers, initialState);

const successMessage = (message) => ({ type: 'success', title: 'Success', message });

function* updateCWRequest({ payload }) {
  try {
    yield put(actions.setLoading(true));
    yield call(Api.users.updateFromCW, payload);
    yield put(actions.setLoading(false));
    yield put(actions.loadUserDetails(payload));
    yield put(notify(successMessage('User updated successfully from Crewell')));
  } catch (e) {
    yield put(actions.setLoading(false));
    yield put(actions.updateCWError(e));
  }
}

function getFeatureSagas() {
  return [
    takeEvery(constants.UPDATE_CW_REQUEST, updateCWRequest),
  ];
}

const sagas = () => getFeatureSagas();

const selectors = (state) => ({
  ...state.forceUpdateFromCW
});

export { constants, actions, reducer, sagas, selectors };
