import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';

const DialogsManager = ({ dialogs, deleteItem, dialogByType }) => dialogs.map(({ type, ...dialogProps }) => {
  const Dialog = dialogByType[type];

  return <Dialog onClose={deleteItem} key={type} {...dialogProps}/>;
});

DialogsManager.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  dialogByType: PropTypes.object,
};

DialogsManager.defaultProps = {
  dialogByType: {},
};

export default withStyles(styles)(DialogsManager);
