import { sortBy, keyBy, get } from "lodash";
import * as api from "./adapter";
import { convertItem, convertItems } from "./convertors/crewChange";
import { convertItems as convertTps } from "./convertors/timeplans";
import { getNoUserNameTextByStatus } from "../timeplans";

export default {
  index: async ({
    pagination,
    sort,
    filter: {
      state: { value: state },
      vessel,
      port,
      coordinator,
      owner,
    },
  }) => {
    const filter = state !== "all" ? { state } : {};

    if (vessel) filter.vessel_id = vessel.value;
    if (port) filter.port_id = port.value;
    if (coordinator) filter.coordinator = coordinator.value;
    if (owner) filter.owner = owner.value;

    const { data: json } = await api.read("crew_changes", {
      query: {
        include: "port,vessel,crew_lists",
        filter: { ...filter },
        sort: `${sort.direction === "asc" ? "" : "-"}${sort.field}`,
        page: { size: pagination.itemsPerPage, number: pagination.current },
      },
    });

    return convertItems(json);
  },
  /**
   * show
   * @param id
   * @return {Object}
   */
  show: async (id) => {
    const { data: json } = await api.read(`/crew_changes/${id}`, {
      query: { include: "crew_lists,port,port_arrived,vessel" },
    });

    const {
      crewList: crewListInstances,
      timeplanIds,
      ...crewChange
    } = convertItem(json);

    const { data: timeplansJson } = await api.read("time_plans", {
      query: {
        include: "user,manning_scale",
        filter: { id: timeplanIds.join(",") },
      },
    });

    const convertedTimeplans = convertTps(timeplansJson);
    const timeplansById = keyBy(convertedTimeplans.data, "id");

    const crewList = crewListInstances.map((cl) => {
      const onTp = timeplansById[cl.attributes.sign_on_id];
      const offTp = timeplansById[cl.attributes.sign_off_id];

      return {
        id: cl.id,
        ...cl.attributes,
        rank: get(onTp || offTp, "msItem.attributes.title"),
        rank_priority: get(onTp || offTp, "msItem.attributes.crewing_priority"),
        on_sign_seaman: onTp
          ? getNoUserNameTextByStatus(onTp.status, onTp.userName)
          : undefined,
        off_sign_seaman: offTp
          ? getNoUserNameTextByStatus(offTp.status, offTp.userName)
          : undefined,
      };
    });

    const sortedCrewList = sortBy(crewList, "rank_priority");

    return {
      ...crewChange,
      crewList: sortedCrewList,
      timeplansById,
    };
  },
  /**
   * create
   * @param attributes
   * @returns {Promise}
   */
  create: async (
    { date, date_sign_on, vesselId, port, portArrived, state, author_id },
    crewlists
  ) => {
    const item = await api.create({
      type: "crew_changes",
      attributes: {
        date,
        date_sign_on,
        state: state.value,
        crewlists,
        author_id,
      },
      relationships: {
        port: { data: port ? { id: port.value, type: "ports" } : null },
        port_arrived: {
          data: portArrived ? { id: portArrived.value, type: "ports" } : null,
        },
        vessel: { data: { id: vesselId, type: "vessels" } },
      },
    });

    return item;
  },
  /**
   * update
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, { date, date_sign_on, port, portArrived, state }) => {
    const resp = api.update({
      id,
      type: "crew_changes",
      attributes: {
        date,
        date_sign_on,
        state: state.value,
      },
      relationships: {
        port: { data: port ? { id: port.value, type: "ports" } : null },
        port_arrived: {
          data: portArrived ? { id: portArrived.value, type: "ports" } : null,
        },
      },
    });
    return resp;
  },
  /**
   * delete
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "crew_changes" }),

  printCrewList: async (ccId) => {
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/crew_list?crew_change_id=${ccId}`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1]
      .replaceAll('"', "");
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },

  printCrewRole: async (ccId, type) => {
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/crew_role?crew_change_id=${ccId}&type=${type}`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1]
      .replaceAll('"', "");
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },

  printEquipmentOrder: async (ccId, mtxId) => {
    const defaultOptions = api.getDefaultOptions();
    const url = `/api/print/ppe_order?crew_change_id=${ccId}&owner_matrix_id=${mtxId}`;
    const response = await fetch(url, defaultOptions);
    const blob = await response.blob();
    const filename = response.headers
      .get("Content-Disposition")
      .split("filename=")[1]
      .replaceAll('"', "");
    const uri = window.URL.createObjectURL(blob);

    return {
      uri,
      name: filename,
    };
  },
};
