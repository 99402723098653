import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import component from './component';
import selector from './selector';
import { deleteItemRequest } from '../authorization/actions';
import { actions as crewUserActions } from '../crew-users/redux';
import { loadItemRequest } from '../authorization/actions';

const mapDispatchToProps = {
  push: (event, value) => push(`/${value}`, { doWeNeedToReloadData: true }),
  onLogout: () => deleteItemRequest(),
  onStatusChange: (data) => crewUserActions.changeStatus(data),
  loadItemRequest: () => loadItemRequest(),
};

export default connect(selector, mapDispatchToProps)(component);
