import { isBoolean, isNull } from 'lodash';

/**
 * Convert permissions from JR format to a more simple, usable format
 * @param {Array} permissions - The permissions array in JR format.
 * @returns {Object} The permissions in a more simple, usable format.
 */
export const convertFromJR = (permissions) => {
  if (!permissions) return {};

  return permissions.filter((perm) => !perm.attributes.parent_id).reduce((acc, item) => {
    const buildRowWithChildren = (row) => {
      const { id, attributes } = row;
      const sRows = permissions.filter((perm) => String(perm.attributes.parent_id) === String(id));

      const {
        can_read: read,
        can_create: create,
        can_update: update,
        can_delete: _delete,
        enable,
        permission_type,
      } = attributes;

      return {
        id,
        enable,
        read,
        create,
        update,
        delete: _delete,
        permission_type,
        ...sRows.reduce((ch_acc, child) => ({ ...ch_acc, [child.attributes.item]: buildRowWithChildren(child) }), {}),
      };
    };

    acc[item.attributes.item] = buildRowWithChildren(item);

    return acc;
  }, {});
};

/**
 * Recursively build permissions objects suitable for further use
 * @param {Object} permissions - The permissions object.
 * @returns {Array} The array of modified permission objects.
 */
export const buildPermissions = (permissions) => {
  if (!permissions) return null;

  return Object.entries(permissions).map(([key, row]) => {
    const {
      id = Math.random(),
      enable,
      permission_type,
      read: can_read,
      create: can_create,
      update: can_update,
      delete: can_delete,
      ...restPerm
    } = row;

    const defEnableValue = isBoolean(row) ? row : null;
    const enableValue = isNull(enable) ? defEnableValue : enable;

    const defTypeValue = isNull(enableValue) ? 'model' : 'section';
    const typeValue = isNull(permission_type) ? defTypeValue : permission_type;

    return {
      item: key,
      id,
      enable: enableValue,
      permission_type: typeValue,
      can_read,
      can_create,
      can_update,
      can_delete,
      children: buildPermissions(restPerm),
    };
  });
};

/**
 * Main function that ties the permissions conversion and building
 * @param {Array|Object} permissions - The permissions array in JR format or permissions object.
 * @returns {Array} The array of modified permission objects.
 */
export default (permissions) => buildPermissions(convertFromJR(permissions));
