import { call, put, takeEvery, select } from "redux-saga/effects";
import { constants as ownerDetailsConstants } from "../owner-details/redux";
import { constants } from "./redux";
import { actions } from "./redux";
import Api from "../../utils/Api";
import { getLocation } from "connected-react-router";
import { matchPath } from "react-router-dom";
import { destroy } from "redux-form";
import { differenceWith, isEqual } from "lodash";

function* fetchEquipmentMatrix() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, {
      path: "/owners/:id/protective-equipment-matrix",
    });
    if (!correctPath) return;
    yield put(actions.resetState());

    yield put(actions.loadEquipmentMatrixRequest());

    const options = { filter: { owner_id: correctPath.params.id } };
    const data = yield call(Api.ownerEquipmentMatrix.ownerMatrices, options);

    yield put(actions.loadEquipmentMatrixSuccess(data.data));

    yield fetchMatrixEquipments();
  } catch (e) {
    console.error(e);

    yield put(actions.loadEquipmentMatrixError(e));
  }
}

function* fetchCurrentMatrix() {
  try {
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );
    if (!currentMatrixId) {
      yield put(actions.loadCurrentEquipmentMatrixSuccess(null));
      return;
    }
    const options = { filter: { owner_matrix_id: currentMatrixId } };

    yield put(actions.loadCurrentEquipmentMatrixRequest());

    const data = yield call(Api.ownerEquipmentMatrix.matrixItems, options);

    yield put(actions.loadCurrentEquipmentMatrixSuccess(data.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentEquipmentMatrixError(e));
  }
}

function* updateItemValue({ payload: { cb, ...payload } }) {
  try {
    yield call(Api.ownerEquipmentMatrix.updateMatrixItem, payload);

    yield fetchCurrentMatrix();

    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
  }
}

function* updateEquipmentPrice({ payload: { cb, ...payload } }) {
  try {
    yield call(Api.ownerEquipmentMatrix.updateMatrixEquipmentPrice, payload);

    yield fetchEquipmentPrices();

    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
  }
}

function* fetchMatrixEquipments() {
  try {
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );

    if (!currentMatrixId) {
      yield put(actions.loadCurrentEquipmentMatrixEquipmentsSuccess(null));
      return;
    }

    const data = yield call(
      Api.ownerEquipmentMatrix.matrixEquipments,
      currentMatrixId
    );

    yield put(actions.loadCurrentEquipmentMatrixEquipmentsSuccess(data.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentEquipmentMatrixError(e));
  }
}

function* fetchMatrixRoles() {
  try {
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );

    if (!currentMatrixId) {
      yield put(actions.loadCurrentEquipmentMatrixRolesSuccess(null));
      return;
    }

    const data = yield call(
      Api.ownerEquipmentMatrix.matrixRoles,
      currentMatrixId
    );

    yield put(actions.loadCurrentEquipmentMatrixRolesSuccess(data.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentEquipmentMatrixError(e));
  }
}

function* copyMatrix({ payload: { attributes, cb } }) {
  try {
    const { data } = yield call(Api.ownerEquipmentMatrix.copyMatrix, {
      attributes,
    });

    yield put(actions.copyMatrixSuccess(data.data));

    yield put(actions.setCopyFormMatrixShow(false));
    yield fetchEquipmentMatrix();
    yield put(actions.setCurrentEquipmentMatrix(data.data.id));
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllRoles() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, {
      path: "/owners/:id/protective-equipment-matrix",
    });
    if (!correctPath) return;

    const { data: allRoles } = yield call(Api.ranks.index);
    yield put(actions.setCreateFormAllRanks(allRoles));

    const { data: ownerRoles } = yield call(
      Api.owner.ownerRoles,
      correctPath.params.id
    );

    yield put(actions.setCreateFormOwnerRanks(ownerRoles.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllEquipments() {
  try {
    const { data } = yield call(Api.ownerEquipmentMatrix.allEquipments);
    yield put(actions.setCreateFormAllEquipmentOptions(data.data));
  } catch (e) {
    console.error(e);
  }
}

function* fetchEquipmentPrices() {
  try {
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );
    if (!currentMatrixId) {
      yield put(actions.loadCurrentEquipmentMatrixPricesSuccess(null));
      return;
    }
    const options = { filter: { owner_matrix_id: currentMatrixId } };

    yield put(actions.loadCurrentEquipmentMatrixPricesRequest());

    const data = yield call(Api.ownerEquipmentMatrix.matrixPrices, options);

    yield put(actions.loadCurrentEquipmentMatrixPricesSuccess(data.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentEquipmentMatrixPricesError(e));
  }
}

function* createMatrix({ payload: attributes }) {
  try {
    const { data } = yield call(Api.ownerEquipmentMatrix.createMatrix, {
      attributes,
    });
    yield put(actions.createMatrixSuccess(data.data));

    yield put(actions.setCreateFormMatrixShow(false));
    yield put(destroy("matrixCreate"));

    yield fetchEquipmentMatrix();
    yield put(actions.setCurrentEquipmentMatrix(data.data.id));
    yield fetchCurrentMatrix();
  } catch (e) {
    console.error(e);
    yield put(actions.createMatrixError(e));
  }
}

function* editCurrentMatrix({ payload: attributes }) {
  try {
    const { data } = yield call(Api.ownerEquipmentMatrix.editMatrix, {
      id: attributes.id,
      attributes,
    });

    yield put(actions.editMatrixSuccess(data.data));

    yield put(actions.setEditFormMatrixShow(false));
    yield put(destroy("matrixEdit"));

    yield fetchEquipmentMatrix();
    yield fetchCurrentMatrix();
  } catch (e) {
    console.error(e);

    yield put(actions.editMatrixError(e));
  }
}

function* fetchFreeRoles() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, {
      path: "/owners/:id/protective-equipment-matrix",
    });
    if (!correctPath) return;

    const currentMatrixRoles = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrixRoles
    );
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );

    const { data: allRoles } = yield call(
      Api.ownerEquipmentMatrix.unusedRoles,
      currentMatrixId
    );

    yield put(actions.setEditFormAllRanks(allRoles.data));

    const { data: ownerRoles } = yield call(
      Api.owner.ownerRoles,
      correctPath.params.id
    );

    const freeOwnerRoles = differenceWith(
      ownerRoles.data,
      currentMatrixRoles,
      isEqual
    );

    yield put(actions.setEditFormOwnerRanks(freeOwnerRoles));
  } catch (e) {
    console.error(e);
  }
}

function* fetchFreeEquipments() {
  try {
    const currentMatrixId = yield select(
      (s) => s.ownerEquipmentMatrix.currentEquipmentMatrix
    );

    if (!currentMatrixId) {
      yield put(actions.setEditFormAllEquipmentOptions(null));
      return;
    }

    const { data } = yield call(
      Api.ownerEquipmentMatrix.unusedEquipments,
      currentMatrixId
    );
    yield put(actions.setEditFormAllEquipmentOptions(data.data));
  } catch (e) {
    console.error(e);
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery([ownerDetailsConstants.ADD_LOADED_ITEM], fetchEquipmentMatrix),
    takeEvery([constants.SET_CURRENT_PPE_MATRIX], fetchCurrentMatrix),
    takeEvery([constants.SET_CURRENT_PPE_MATRIX], fetchMatrixEquipments),
    takeEvery([constants.SET_CURRENT_PPE_MATRIX], fetchMatrixRoles),
    takeEvery([constants.SET_CURRENT_PPE_MATRIX], fetchEquipmentPrices),
    takeEvery([constants.CHANGE_ITEM_VALUE], updateItemValue),
    takeEvery([constants.CHANGE_EQUIPMENT_PRICE], updateEquipmentPrice),
    takeEvery([constants.COPY_MATRIX_REQUEST], copyMatrix),
    takeEvery([constants.SET_CREATE_FORM_MATRIX], fetchAllRoles),
    takeEvery([constants.SET_CREATE_FORM_MATRIX], fetchAllEquipments),
    takeEvery([constants.CREATE_MATRIX_REQUEST], createMatrix),
    takeEvery([constants.EDIT_MATRIX_REQUEST], editCurrentMatrix),
    takeEvery([constants.SET_EDIT_FORM_MATRIX], fetchFreeRoles),
    takeEvery([constants.SET_EDIT_FORM_MATRIX], fetchFreeEquipments),
  ];
}
