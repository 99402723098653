const DENY_ALL = { read: false, create: false, update: false, delete: false };
const READ_ONLY = { read: true, create: false, update: false, delete: false };
const CAN_MANAGE = { read: true, create: true, update: true, delete: false };
const ALLOW_ALL = { read: true, create: true, update: true, delete: true };

export const TEMPLATE = {
  vessels: {
    ...ALLOW_ALL,
    manningScales: DENY_ALL,
    archivedManningScales: DENY_ALL,
    assignedUsers: DENY_ALL,
    manager: DENY_ALL,
    owner: DENY_ALL,
    coordinator: DENY_ALL,
    equasisVesselSearch: {
      ...ALLOW_ALL,
      bulkParse: false,
    },
  },
  taskManager: DENY_ALL,
  timeplans: {
    ...READ_ONLY,
    userProfile: DENY_ALL,
    comments: DENY_ALL,
    commentAssignments: READ_ONLY,
  },
  managers: DENY_ALL,
  owners: {
    ...DENY_ALL,
    assignedRecruiters: DENY_ALL,
  },
  crewUsers: {
    ...DENY_ALL,
    assignedVessels: DENY_ALL,
    assignedOwners: DENY_ALL,
    assignedAsRecruiter: DENY_ALL,
    assignedAsCoordinator: DENY_ALL,
  },
  users: {
    ...CAN_MANAGE,
    comments: ALLOW_ALL,
    tgChat: ALLOW_ALL,
    sortingMenu: ALLOW_ALL,
  },
  userExperiences: {
    ...ALLOW_ALL,
    appraisal: ALLOW_ALL,
  },
  userAttachments: ALLOW_ALL,
  userPerformance: DENY_ALL,
  experienceVerification: ALLOW_ALL,
  applicationForm: ALLOW_ALL,
  ranks: DENY_ALL,
  crewChange: DENY_ALL,
  vacancies: READ_ONLY,
  vesselTypes: DENY_ALL,
  engineTypes: DENY_ALL,
  engineManufacturers: DENY_ALL,
  vendors: DENY_ALL,
  vendorTypes: DENY_ALL,
  certificateTypes: DENY_ALL,
  relationTypes: DENY_ALL,
  crewingStatuses: DENY_ALL,
  countries: DENY_ALL,
  ports: DENY_ALL,
  mainEngines: DENY_ALL,
  userDocuments: ALLOW_ALL,
  userInterviews: ALLOW_ALL,
  userRelatives: DENY_ALL,
  applicantContracts: DENY_ALL,
  applicantDocuments: DENY_ALL,
  userRecommendations: DENY_ALL,
  userEnglishTestResults: DENY_ALL,
  englishTestTypes: DENY_ALL,
  tags: ALLOW_ALL,
  tagsBatch: ALLOW_ALL,
  externalUpdate: ALLOW_ALL,
  accountValidation: ALLOW_ALL,
  helpLegend: ALLOW_ALL,
  documentMatrix: READ_ONLY,
  crewUserStatus: DENY_ALL,
  surveyCreator: DENY_ALL,
};

const guest = {
  ...TEMPLATE,
};

const partner = {
  ...TEMPLATE,
  users: {
    ...TEMPLATE.users,
    read: false,
    comments: DENY_ALL,
  },
  timeplans: {
    ...TEMPLATE.timeplans,
    ...CAN_MANAGE,
    userProfile: READ_ONLY,
    commentAssignments: READ_ONLY,
    comments: DENY_ALL,
  },
  vacancies: {
    ...TEMPLATE.vacancies,
    ...DENY_ALL,
  },
  userExperiences: {
    ...TEMPLATE.userExperiences,
    appraisal: DENY_ALL,
  },
};

const partner_view = {
  ...partner,
  timeplans: {
    ...partner.timeplans,
    ...READ_ONLY,
    userProfile: DENY_ALL,
  },
  vessels: DENY_ALL,
  crewUsers: DENY_ALL,
};

const manager = {
  ...TEMPLATE,
  taskManager: ALLOW_ALL,
  timeplans: {
    ...ALLOW_ALL,
    userProfile: ALLOW_ALL,
    comments: ALLOW_ALL,
    commentAssignments: ALLOW_ALL,
  },
  users: {
    ...TEMPLATE.users,
    comments: ALLOW_ALL,
  },
  userPerformance: ALLOW_ALL,
  crewChange: ALLOW_ALL,
  crewUserStatus: ALLOW_ALL,
};

const admin = {
  ...TEMPLATE,
  vessels: {
    ...TEMPLATE.vessels,
    read: true,
    manningScales: {
      ...TEMPLATE.vessels.manningScales,
      ...ALLOW_ALL,
    },
    assignedUsers: {
      ...TEMPLATE.vessels.assignedUsers,
      ...ALLOW_ALL,
    },
    manager: {
      ...TEMPLATE.vessels.manager,
      ...ALLOW_ALL,
    },
    owner: {
      ...TEMPLATE.vessels.owner,
      ...ALLOW_ALL,
    },
    coordinator: ALLOW_ALL,
    equasisVesselSearch: {
      ...ALLOW_ALL,
      bulkParse: true,
    },
  },
  taskManager: ALLOW_ALL,
  timeplans: {
    ...TEMPLATE.timeplans,
    ...ALLOW_ALL,
    userProfile: ALLOW_ALL,
    comments: ALLOW_ALL,
    commentAssignments: ALLOW_ALL,
  },
  managers: {
    ...TEMPLATE.managers,
    ...ALLOW_ALL,
  },
  owners: {
    ...TEMPLATE.owners,
    ...ALLOW_ALL,
    assignedRecruiters: ALLOW_ALL,
  },
  crewUsers: {
    ...TEMPLATE.crewUsers,
    ...ALLOW_ALL,
    assignedVessels: {
      ...TEMPLATE.crewUsers.assignedVessels,
      ...ALLOW_ALL,
    },
    assignedOwners: {
      ...TEMPLATE.crewUsers.assignedOwners,
      ...ALLOW_ALL,
    },
    assignedAsRecruiter: ALLOW_ALL,
    assignedAsCoordinator: ALLOW_ALL,
  },
  users: {
    ...TEMPLATE.users,
    ...ALLOW_ALL,
    comments: {
      ...TEMPLATE.users.comments,
      ...ALLOW_ALL,
    },
  },
  userPerformance: ALLOW_ALL,
  ranks: {
    ...TEMPLATE.ranks,
    read: true,
  },
  crewChange: {
    ...TEMPLATE.crewChange,
    ...ALLOW_ALL,
  },
  vesselTypes: {
    ...TEMPLATE.vesselTypes,
    ...ALLOW_ALL,
  },
  englishTestTypes: {
    ...TEMPLATE.englishTestTypes,
    ...ALLOW_ALL,
  },
  documentMatrix: ALLOW_ALL,
  crewUserStatus: ALLOW_ALL,
  surveyCreator: ALLOW_ALL,
};

const superuser = {
  ...admin,
  vessels: {
    ...admin.vessels,
    archivedManningScales: ALLOW_ALL,
  },
};

const freelance = {
  ...TEMPLATE,
  users: {
    ...CAN_MANAGE,
    comments: {
      ...TEMPLATE.users.comments,
      ...CAN_MANAGE,
    },
    tgChat: ALLOW_ALL,
  },
  vessels: {
    ...DENY_ALL,
    manningScales: DENY_ALL,
    archivedManningScales: DENY_ALL,
    assignedUsers: DENY_ALL,
    manager: DENY_ALL,
    owner: DENY_ALL,
  },
  timeplans: {
    ...DENY_ALL,
    userProfile: DENY_ALL,
    comments: DENY_ALL,
    commentAssignments: DENY_ALL,
  },
  tags: CAN_MANAGE,
  tagsBatch: DENY_ALL,
  userAttachments: DENY_ALL,
  userDocuments: CAN_MANAGE,
  userInterviews: CAN_MANAGE,
};

const getPermissions = (role) =>
  ({
    admin,
    manager,
    guest,
    partner,
    superuser,
    partner_view,
    freelance,
  }[role]);

export default getPermissions;
