import { sortBy } from "lodash";

const sortObjectsByType = (objects = [], allowedTypes = []) => {
  return allowedTypes.reduce((sortedData, type) => {
    sortedData[type] = objects.filter((item) => item.type === type);
    return sortedData;
  }, {});
};

const getRecruitedFleets = (owners) => {
  if (!owners) return null;

  return owners.map((owner) => ({
    name: owner.attributes.title,
    ownerId: owner.id,
    shortName: owner.attributes.short_title,
  }));
};

const getCoordinatedVessels = (vessels) => {
  if (!vessels) return null;

  return vessels.map((vessel) => ({
    name: vessel.attributes.name,
    vesselId: vessel.id,
    imo: vessel.attributes.imo,
  }));
};

const addRelationshipsToItem = (
  { id, type, attributes, relationships },
  groupedIncluded
) => {
  const recruitedFleets = sortBy(
    getRecruitedFleets(groupedIncluded.owners),
    "name"
  );
  const coordinatedVessels = sortBy(
    getCoordinatedVessels(groupedIncluded.vessels),
    "name"
  );

  const data = {
    id,
    type,
    attributes,
    relationships,
    coordinatedVessels: coordinatedVessels,
    recruitedFleets: recruitedFleets,
  };

  return data;
};

/**
 * convertItem
 * @param { Object } data - vessel
 * @param { Array } included - list of relationship items
 * @return { Object } item with relationships
 */
export const convertItem = ({ data, included }) => {
  const allowedTypes = ["vessels", "owners"];

  const groupedIncluded = included
    ? sortObjectsByType(included, allowedTypes)
    : { vessels: [], owners: [] };

  return addRelationshipsToItem(data, groupedIncluded);
};

export default convertItem;
