import { call, put, takeEvery, select } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { stopSubmit } from 'redux-form';
import { getLocation, goBack } from 'connected-react-router';
import { actions, constants } from './redux';
import { ADD_ITEM } from '../authorization/constants';
import Api from '../../utils/Api';
import { addSuccess } from '../notifications/actions';

const asJson = (data) => ({
  data,
  links: {
    first: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
    last: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
  },
  meta: { total: data.length },
});

export function* fetchItems() {
  try {
    const data = yield call(Api.ranks.index);

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

function* createItem({ payload }) {
  try {
    const { data: json } = yield call(Api.ranks.create, payload);

    yield put(goBack());
    yield put(actions.createItem(json.data));
    yield put(addSuccess('The rank has successfully created'));
  } catch (e) {
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit('ranks', e.errorByField));
  }
}

function* sort({ payload: { newIndex, oldIndex } }) {
  const { data } = yield select((s) => s.ranks);
  const newPriority = data[newIndex].attributes.crewing_priority;
  const currentRole = data[oldIndex];

  if (newIndex === oldIndex) {
    yield put(actions.addItems(asJson(data))); // stop loader
    return;
  }

  const updatedRoles = data.map((item, index) => {
    const { id, attributes: { crewing_priority, ...attributes } } = item;

    if (index === oldIndex) {
      return {
        id,
        attributes: { ...attributes, crewing_priority: newPriority },
      };
    }

    if (index >= oldIndex && index <= newIndex) {
      return {
        id,
        attributes: { ...attributes, crewing_priority: crewing_priority - 1 },
      };
    }

    if (index <= oldIndex && index >= newIndex) {
      return {
        id,
        attributes: { ...attributes, crewing_priority: crewing_priority + 1 },
      };
    }

    return item;
  }).sort((a, b) => a.attributes.crewing_priority - b.attributes.crewing_priority);

  yield put(actions.addItems(asJson(updatedRoles)));

  try {
    yield call(Api.ranks.update, currentRole.id, { crewing_priority: newIndex + 1 });

    yield put(addSuccess('The ranks sorted successfully'));
  } catch (e) {
    yield put(actions.addItems(asJson(data)));

    console.error(e);
  }
}

function* updateItem({ payload }) {
  try {
    if (payload.newIndex || payload.oldIndex) {
      yield sort({ payload });
    } else {
      const { pathname } = yield select(getLocation);
      const { params: { id } } = matchPath(pathname, { path: '/ranks/:id/edit' });

      const { data: json } = yield call(Api.ranks.update, id, payload);

      yield put(goBack());
      yield put(actions.updateItem(json.data));
      yield put(addSuccess('The rank has successfully updated'));
    }
  } catch (e) {
    yield put(actions.updateItemError(e));

    if (e.errorByField) yield put(stopSubmit('ranks', e.errorByField));
  }
}

function* deleteItem() {
  try {
    const pathname = yield select((s) => s.router.location.pathname);
    const { params: { id } } = matchPath(pathname, { path: '/ranks/:id/delete' });

    yield call(Api.ranks.delete, id);
    yield put(goBack());

    yield put(addSuccess('The rank has successfully deleted'));
  } catch (e) {
    console.error(e);

    yield put(actions.deleteItemError(e));
  }
}

export function* fetchItemsPageInit() {
  yield put(actions.loadItemsRequest());
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(ADD_ITEM, fetchItemsPageInit),
  ];
}
