import { all } from 'redux-saga/effects';
import errorsSagas from './features/errors/sagas';
import authSagas from './features/authorization/sagas';
import { sagas as engineTypes } from './features/engine-types/redux';
import { sagas as engineManufacturersSagas } from './features/engine-manufacturers/redux';
import { sagas as crewingStatusesSagas } from './features/crewing-statuses/redux';
import assignedVesselsSagas from './features/assigned-vessels/sagas';
import vesselOwnersSagas from './features/vessel-owners/sagas';
import vesselsSagas from './features/vessels/sagas';
import vesselDetailsSagas from './features/vessel-details/sagas';
import notificationsSagas from './features/notifications/sagas';
import rolesSagas from './features/ranks/sagas';
import userSectionSagas from './features/users-section/sagas';
import timeplansSagas from './features/timeplans/sagas';
import commentsSagas from './features/comments/sagas';
import tgChatSagas from './features/user-tg-chat/sagas';
import { sagas as ownersSagas } from './features/owners/redux';
import ownerDetailsSagas from './features/owner-details/sagas';
import ownerDetailsVesselsSagas from './features/owner-details-vessels/sagas';
import ownerDetailsWageSagas from './features/owner-details-wage/sagas';
import ownerDetailsDocumentMatrixSagas from './features/owner-details-document-matrix/sagas';
import protectiveEquipmentMatrixSagas from './features/owner-details-ppe-matrix/sagas';
import ownerDetailsRecruiters from './features/owner-details-recruiters/sagas';
import { sagas as managersSagas } from './features/managers/redux';
import managerDetailsSagas from './features/manager-details/sagas';
import managerDetailsVesselsSagas from './features/manager-details-vessels/sagas';
import crewUsersSagas from './features/crew-users/sagas';
import crewUserDetailsSagas from './features/crew-user-details/sagas';
import crewUserVesselsSagas from './features/crew-user-vessels/sagas';
import crewUserOwnersSagas from './features/crew-user-owners/sagas';
import manningScalesSagas from './features/vessel-manning-scale/sagas';
import assignedCrewUsersSagas from './features/vessel-details-assigned-crew-users/sagas';
import crewChangesSagas from './features/crew-changes/sagas';
import crewChangeDetailsSagas from './features/crew-change-details/sagas';
import crewListSagas from './features/crew-list/sagas';
import { sagas as usersSagas } from './features/users/redux';
import userDetailsSagas from './features/user-details-page/sagas';
import { sagas as userExperiences } from './features/user-experiences/redux';
import { sagas as vesselExperiences } from './features/vessel-experiences/redux';
import { sagas as userDocuments } from './features/user-documents/redux';
import { sagas as userInterviews } from './features/user-interviews/redux';
import { sagas as userRelatives } from './features/user-relatives/redux';
import { sagas as userAttachments } from './features/user-attachment/redux';
import { sagas as vacancies } from './features/vacancies/redux';
import { sagas as vesselTypes } from './features/vessel-types/redux';
import { sagas as vendorTypes } from './features/vendor-types/redux';
import { sagas as vendors } from './features/vendors/redux';
import { sagas as certificateTypes } from './features/certificate-types/redux';
import { sagas as relationTypes } from './features/relation-types/redux';
import { sagas as countries } from './features/countries/redux';
import { sagas as ports } from './features/ports/redux';
import { sagas as mainEngines } from './features/main-engines/redux';
import { sagas as applicantContracts } from './features/applicant-contracts/redux';
import { sagas as applicantDocuments } from './features/applicant-documents/redux';
import { sagas as userRecommendations } from './features/user-recommendations/redux';
import { sagas as userEnglishTests } from './features/user-english-tests/redux';
import { sagas as englishTestTypes } from './features/english-test-types/redux';
import { sagas as roleLevels } from './features/role-levels/redux';
import { sagas as forceUpdateFromUC } from './features/user-details-page/component/ForceUpdateFromUC/redux';
import { sagas as forceUpdateFromCW } from './features/user-details-page/component/ForceUpdateFromCrewell/redux';
import { sagas as surveyCreator } from './features/survey-creator/redux';
import taskManagerSagas from './features/task-manager/sagas';
import experienceVerificationsSagas from './features/experience-verifications/sagas';
import appFormLinksSagas from './features/app-form-links/sagas';
import performances from './features/performances/sagas';
import vesselSearchEquasis from './features/vessel-search-equasis/sagas';
import seafarersView from './features/users/component/SeafarersView/sagas';
import commentTypeManagement from './features/crew-users/component/CommentTypeManagement/sagas';
import notificationManagerSagas from './features/notification-manager/sagas';
import permissionsSagas from "./features/permissions/sagas";

/**
 * rootSaga
 * @returns {IterableIterator<*>}
 */
export default function* root() {
  yield all([
    ...errorsSagas(),
    ...authSagas(),
    ...commentsSagas(),
    ...tgChatSagas(),
    ...engineTypes(),
    ...engineManufacturersSagas(),
    ...crewingStatusesSagas(),
    ...vesselsSagas(),
    ...vesselDetailsSagas(),
    ...assignedVesselsSagas(),
    ...vesselOwnersSagas(),
    ...notificationsSagas(),
    ...rolesSagas(),
    ...userSectionSagas(),
    ...timeplansSagas(),
    ...ownersSagas(),
    ...ownerDetailsSagas(),
    ...ownerDetailsVesselsSagas(),
    ...ownerDetailsWageSagas(),
    ...ownerDetailsDocumentMatrixSagas(),
    ...protectiveEquipmentMatrixSagas(),
    ...ownerDetailsRecruiters(),
    ...managersSagas(),
    ...managerDetailsSagas(),
    ...managerDetailsVesselsSagas(),
    ...crewUsersSagas(),
    ...crewUserDetailsSagas(),
    ...crewUserVesselsSagas(),
    ...crewUserOwnersSagas(),
    ...manningScalesSagas(),
    ...assignedCrewUsersSagas(),
    ...crewChangesSagas(),
    ...crewChangeDetailsSagas(),
    ...crewListSagas(),
    ...usersSagas(),
    ...userDetailsSagas(),
    ...userExperiences(),
    ...vesselExperiences(),
    ...userDocuments(),
    ...userInterviews(),
    ...userRelatives(),
    ...userAttachments(),
    ...vacancies(),
    ...vesselTypes(),
    ...vendorTypes(),
    ...vendors(),
    ...certificateTypes(),
    ...relationTypes(),
    ...countries(),
    ...ports(),
    ...mainEngines(),
    ...applicantContracts(),
    ...applicantDocuments(),
    ...userRecommendations(),
    ...userEnglishTests(),
    ...englishTestTypes(),
    ...vesselSearchEquasis(),
    ...seafarersView(),
    ...commentTypeManagement(),
    ...roleLevels(),
    ...forceUpdateFromUC(),
    ...forceUpdateFromCW(),
    ...experienceVerificationsSagas(),
    ...appFormLinksSagas(),
    ...performances(),
    ...taskManagerSagas(),
    ...notificationManagerSagas(),
    ...surveyCreator(),
    ...permissionsSagas(),
  ]);
}
