import { findIndex, cloneDeep, uniqBy, omit } from "lodash";
import getParameterByNameFromUrl from "../getParameterByNameFromUrl";

export const initialState = {
  data: [],
  didLoad: false,
  isPushing: false,
  isLoading: false,
  state: {},
  pagination: {
    first: 1,
    current: 1,
    last: undefined,
    totalItemsCount: undefined,
    itemsPerPage: 15,
  },
  filter: {},
  appliedFilter: {},
  sort: {
    field: "",
    direction: "asc",
  },
  search: "",
  error: null,
};

export const startLoading = (state) => ({
  ...state,
  isLoading: true,
});

export const loadingError = (state, action) => ({
  ...state,
  error: action.payload,
  isLoading: false,
});

export const startPushing = (state) => ({
  ...state,
  isPushing: true,
});

export const pushingError = (state, action) => ({
  ...state,
  error: action.payload,
  isPushing: false,
});

export const setItemsAsData = (state, action) => {
  return {
    ...state,
    isLoading: false,
    isPushing: false,
    didLoad: true,
    data: action.payload.data,
    pagination: {
      ...state.pagination,
      last: action.payload.links
        ? Number(
            getParameterByNameFromUrl(action.payload.links.last, "page[number]")
          )
        : 0,
      totalItemsCount: action.payload.meta.count,
    },
  };
};

export const setItemAsData = (state, action) => ({
  data: action.payload,
  error: null,
  isLoading: false,
});

export const addItemToData = (state, action) => ({
  ...state,
  isLoading: false,
  isPushing: false,
  data: uniqBy([...state.data, action.payload], "id"),
});

export const updateItem = (state, action) => {
  const data = [...state.data];
  const targetIndex = findIndex(data, (it) => it.id === action.payload.id);

  data.splice(targetIndex, 1, action.payload);

  return { ...state, data, isPushing: false };
};

export const deleteItem = (state, action) => ({
  ...state,
  isPushing: false,
  data: state.data.filter((item) => item.id !== action.payload),
});

export const changePage = (state, action) => ({
  ...state,
  isLoading: true,
  pagination: {
    ...state.pagination,
    current: action.payload,
  },
});

export const changeItemsCountPerPage = (state, action) => ({
  ...state,
  isLoading: true,
  pagination: {
    ...state.pagination,
    itemsPerPage: action.payload,
  },
});

export const changeFilter = (state, action) => ({
  ...state,
  filter: {
    ...state.filter,
    [action.payload.field]: action.payload.value,
  },
});

export const removeFilter = (state, action) => ({
  ...state,
  filter: omit(state.filter, action.payload),
});

export const clearFilter = (state) => ({
  ...state,
  filter: {},
});

export const applyNewRequestParams = (state) => ({
  ...state,
  isLoading: true,
  appliedFilter: cloneDeep(state.filter),
  pagination: {
    ...state.pagination,
    current: 1,
  },
});

export const cancelFilter = (state) => ({
  ...state,
  filter: cloneDeep(state.appliedFilter),
  isFilterOpen: false,
});

export const openFilter = (state) => ({
  ...state,
  isFilterOpen: true,
});

export const closeFilter = (state) => ({
  ...state,
  isFilterOpen: false,
});

export const changeSearch = (state, action) => {
  if (state?.saveSearchToLocalStorage) {
    const path = window.location.pathname.split("/").pop();
    localStorage.setItem(`${path}.search`, action.payload);
  }

  return {
    ...state,
    search: action.payload,
  };
};

export const clearSearch = (state) => ({
  ...state,
  search: "",
});

export const sortItems = (state, action) => {
  if (state.sort.field === action.payload) {
    const oldDirection = state.sort.direction;
    const nextDirection = oldDirection === "asc" ? "desc" : "asc";

    return {
      ...state,
      isLoading: true,
      sort: { ...state.sort, direction: nextDirection },
    };
  }

  return {
    ...state,
    isLoading: true,
    sort: { ...state.sort, field: action.payload },
  };
};

export const sortItemsWithoutSettingIsLoading = (state, action) => {
  if (state.sort.field === action.payload) {
    const oldDirection = state.sort.direction;
    const nextDirection = oldDirection === "asc" ? "desc" : "asc";

    return {
      ...state,
      sort: { ...state.sort, direction: nextDirection },
    };
  }

  return {
    ...state,
    sort: { ...state.sort, field: action.payload },
  };
};

export const sortWithDirection = (state, action) => {
  return {
    ...state,
    isLoading: true,
    sort: {
      ...state.sort,
      field: action.payload.field,
      direction: action.payload.direction,
    },
  };
};

export const changeState = (state, action) => ({
  ...state,
  state: {
    ...state.state,
    [action.payload.field]: action.payload.value,
  },
});

export const clearState = (state) => ({
  ...state,
  state: {},
});

export const updateStateByParams = (state, { payload }) => ({
  ...state,
  state: { ...state.state, ...(payload.state || {}) },
  pagination: { ...state.pagination, ...(payload.pagination || {}) },
  filter: { ...state.filter, ...(payload.filter || {}) },
  appliedFilter: { ...state.appliedFilter, ...(payload.filter || {}) },
  sort: { ...state.sort, ...(payload.sort || {}) },
  search: payload.search || state.search,
});
