import * as api from './adapter';
import { convertItems, convertItem, convertListOfItems } from './convertors/assignedVessels';

const vesselFields = "name,imo,owner_id,owner_name"

export default {
  index: async (clientId) => {
    const { data } = await api.read('vessels', {
      query: {
        fields: {  vessels: vesselFields },
        include: 'manning_scales',
        filter: { assigned_to: clientId },
        page: { size: 999 },
      },
    });

    return convertItems(data);
  },
  getItems: async ({ vesselId, clientId }) => {
    const { data } = await api.read('assign_vessels', {
      query: (
        vesselId
          ? { include: 'client', filter: { vessel_id: vesselId }, page: { size: 999 } }
          : { include: 'vessel', filter: { client_id: clientId }, page: { size: 999 } }
      ),
    });

    return convertListOfItems(data);
  },
  /**
   * select vessel
   * @param vesselId
   * @param clientId
   * @returns {Promise}
   */
  create: async (vesselId, clientId) => {
    const { data } = await api.create({
      type: 'assign_vessels',
      relationships: {
        vessel: { data: { id: vesselId, type: 'vessels' } },
        client: { data: { id: clientId, type: 'clients' } },
      },
    }, { query: { include: 'client,vessel' } });

    return convertItem(data);
  },
  /**
   * unselect vessel
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'assign_vessels' }),
};
