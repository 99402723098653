const envBackground = {
  localhost: "linear-gradient(to right, #fdce21, #f3f3f3)",
  qa: "linear-gradient(to right, #19b3947d, #f3f3f3)",
};

const getEnvColor = () => {
  const env = Object.keys(envBackground).find((env) =>
    window.location.href.includes(env)
  );
  return env ? envBackground[env] : "inherit";
};

export default (theme) => {
  const berHeight = {
    height: `${theme.spacing(6)}px !important`,
    minHeight: `${theme.spacing(6)}px !important`,
    paddingLeft: theme.spacing(3),
    color: "inherit",
    textDecoration: "none",
  };

  return {
    root: {
      background: getEnvColor(),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(0),
      },
    },
    bar: {
      paddingRight: 0,
      ...berHeight,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      color: theme.palette.primary.main,
    },
    tabsSection: {
      width: "calc(100vw - 375px)",
    },
    userSection: {
      ...berHeight,
      marginLeft: "auto",
      paddingLeft: "0px !important",
    },
    notificationsButton: {
      alignSelf: "center",
    },
    userInfo: {
      cursor: "pointer",
      ...berHeight,
    },
    userName: {
      marginLeft: "5px",
      color: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      maxWidth: "140px",
      fontSize: 13,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
    grow: {
      flexGrow: 1,
    },
    linear: {
      zIndex: 3,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: 2,
    },
    colorPrimary: {
      backgroundColor: theme.palette.primary.contrastText,
    },
    barColorPrimary: {
      backgroundColor: theme.palette.primary.main,
    },
  };
};
