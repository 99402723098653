import * as api from './adapter';
import { convertItems } from './convertors/manningScale';

export default {
  /**
   * get vessel manning scales
   * @returns {Promise<*>}
   */
  index: async (vesselId) => {
    const { data: json } = await api.read(`vessels/${vesselId}/manning_scales`, {
      query: { page: { size: 999 } },
    });

    return convertItems(json);
  },
  /**
   * create
   * @param vesselId
   * @param roleId
   * @return {Promise}
   */
  create: async (vesselId, roleId) => {
    const { data: json } = await api.create({
      type: 'manning_scales',
      relationships: {
        role: { data: { id: roleId, type: 'roles' } },
        vessel: { data: { id: vesselId, type: 'vessels' } },
      },
    });

    return json;
  },
  /**
   * archive
   * @param id
   * @return {Promise}
   */
  archive: (id) => api.update({ id, type: 'manning_scales' }),
  /**
   * delete
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'manning_scales' }),
};
