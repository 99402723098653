import { call, put, takeEvery } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from '../../utils/Api';
import { addItem as notify } from '../notifications/actions';
import { deleteItem as deleteDialog } from '../dialog-manager/actions';
import { addSuccess, addError } from '../notifications/actions';
import {fetchSeaService} from '../users/component/SeafarersView/sagas';
import {sagas as userExperienceSagas} from '../user-experiences/redux';

export function* fetchItems({ payload: {id}}) {
  try {
    const data = yield call(Api.experienceVerifications.index, id);
    
    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createItem({ payload }) {
  try {
    const isUserExperiences = window.location.pathname.includes('experiences');

    const {data} = yield call(Api.experienceVerifications.create, payload);
    yield put(actions.addCreatedItem(data));
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The verification created successfully',
    }));
    
    isUserExperiences ? yield userExperienceSagas.fetchItems() : yield fetchSeaService();
  } catch (e) {
    yield put(actions.createItemError(e));

    console.error(e);
  }
}

export function* updateItem({ payload: { id, attributes } }) {
  try {
    const { data: json } = yield call(Api.experienceVerifications.update, id, attributes);

    yield put(addSuccess('Verification updated successfully'));
    yield put(actions.updateItem({ id, attributes: json.data.attributes }));
  } catch (e) {
    if (e.apiErrors[0].status === '422') {
      yield put(addError(e.apiErrors[0].title, e.message));
    }

    yield put(actions.updateItemError(e));
  }
}


export function* deleteItem({ payload: id }) {
  try {
    yield call(Api.experienceVerifications.delete, id);

    yield put(actions.deleteItem(id));
    yield put(deleteDialog());
    yield put(notify({
      type: 'success',
      title: 'Success',
      message: 'The verification deleted successfully',
    }));
  } catch (e) {
    yield put(actions.deleteItemError(e));

    console.error(e);
  }
}

export function* removeCoincidencesFromState() {
  try {
    yield put(actions.addExperienceCoincidences([]));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchExperienceCoincidences({ payload: { id, cb } }) {
  try {
    const { data } = yield call(Api.experienceVerifications.checkCoincidences, id);

    yield put(actions.addExperienceCoincidences(data));
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}
/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
  */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(constants.FETCH_EXPERIENCE_COINCIDENCES, fetchExperienceCoincidences),
    takeEvery(constants.REMOVE_EXPERIENCE_COINCIDENCES_FROM_STATE, removeCoincidencesFromState),
  ];
}
