import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';
import { initialState } from '../../utils/redux/commonActionHandlers';
import Api from '../../utils/Api';


const constantsPrefix = 'ENGINE_TYPES';
const statePropName = 'engineTypes';
const formStatePropName = 'engineTypes';
const mainPageUrl = '/engine-types';

const constants = getConstantsByFeatureName(constantsPrefix);
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions(actionHandlers, initialState);
const selector = getSelectorByFeatureName(statePropName);

const sagas = getCRUDSagas(
  mainPageUrl,
  Api.engineTypes,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'Engine type' },
);

export { constants, actions, reducer, selector, sagas };
