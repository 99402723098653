import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { crewListModes, setTimeplanToState } from './utils';
import * as constants from './constants';
import { constants as crewChangeDetailsConstants } from '../crew-change-details/redux';
import * as commonHandlers from '../../utils/redux/commonActionHandlers';

const initialState = {
  mode: crewListModes.INACTIVE,
  data: null,
  isLoading: false,
  isPushing: false,
  error: null,
};

const crewListReducer = handleActions({
  [constants.ADD_ITEMS]: (state, action) => ({
    ...state,
    mode: action.payload.mode,
    data: action.payload.data,
  }),
  [constants.CHANGE_MODE]: (state, action) => (
    action.payload === crewListModes.INACTIVE
      ? { ...initialState }
      : ({ ...state, mode: action.payload })
  ),
  [constants.SELECT_TIMEPLAN]: (state, action) => setTimeplanToState(state, action.payload),

  [constants.CREATE_ITEM_REQUEST]: commonHandlers.startPushing,
  [constants.ADD_CREATED_ITEM]: (state, action) => setTimeplanToState(state, action.payload.timeplan),
  [constants.CREATE_ITEM_ERROR]: commonHandlers.pushingError,

  [constants.UPDATE_ITEM_REQUEST]: commonHandlers.startPushing,
  [constants.ITEM_UPDATED_SUCCESSFULLY]: (state, action) => setTimeplanToState(state, action.payload.timeplan),
  [constants.UPDATE_ITEM_ERROR]: commonHandlers.pushingError,

  [constants.DELETE_ITEM_REQUEST]: commonHandlers.startPushing,
  [constants.ITEM_DELETED_SUCCESSFULLY]: (state, action) => setTimeplanToState(state, action.payload.timeplan),
  [constants.DELETE_ITEM_ERROR]: commonHandlers.pushingError,

  [crewChangeDetailsConstants.ADD_CREATED_ITEM]: () => ({ ...initialState }),
  [crewChangeDetailsConstants.ITEM_UPDATED_SUCCESSFULLY]: () => ({ ...initialState }),
  [crewChangeDetailsConstants.ITEM_DELETED_SUCCESSFULLY]: () => ({ ...initialState }),

  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    (
      matchPath(pathname, { path: '/crew-changes/:id/details', exact: true })
    || matchPath(pathname, { path: '/timeplans/crew-rotation/crew-change/:id/edit', exact: true })
    )
      ? initialState
      : state
  ),
}, initialState);

export default crewListReducer;
