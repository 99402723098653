import { handleActions, createAction } from 'redux-actions';
import selectors from './selector';

// @OWNER_DOCUMENT_MATRIX

const initialState = {
  // DOCUMENT MATRIX SECTION
  documentMatrix: null,
  documentMatrixLoading: false,
  documentMatrixError: null,

  currentDocumentMatrix: null,

  // DOCUMENT MATRIX ITEMS SECTION
  currentDocumentMatrixItems: null,
  currentDocumentMatrixLoading: false,
  currentDocumentMatrixError: null,

  // DOCUMENT MATRIX DOCUMENTS SECTION
  currentDocumentMatrixDocuments: null,
  currentDocumentMatrixDocumentsLoading: false,
  currentDocumentMatrixDocumentsError: null,

  // DOCUMENT MATRIX ROLES SECTION
  currentDocumentMatrixRoles: null,
  currentDocumentMatrixRolesLoading: false,
  currentDocumentMatrixRolesError: null,

  // DOCUMENT MATRIX FULL FORM SECTION
  showFullForm: false,
  fullFormLoading: false,
  fullFormError: null,
  fullFormId: null,
  fullFormData: null,

  // COPY MATRIX SECTION
  copyFormMatrixShow: false,
  copyFormMatrixLoading: false,
  copyFormMatrixError: null,
  copyFormMatrixSuccess: null,

  // ADD MATRIX SECTION
  createFormMatrixShow: false,
  createFormMatrixStep: 0,
  createFormAllRanks: [],
  createFormOwnerRanks: [],

  createMatrixLoading: false,
  createMatrixError: null,

  // REPORT SECTION
  reportLoading: false,
  reportError: null,
  reportData: null,
}

export const constants = {
  RESET_STATE: '@OWNER_DOCUMENT_MATRIX/RESET_STATE',

  // DOCUMENT MATRIX SECTION
  LOAD_DOCUMENT_MATRIX_REQUEST: '@OWNER_DOCUMENT_MATRIX/LOAD_DOCUMENT_MATRIX_REQUEST',
  LOAD_DOCUMENT_MATRIX_SUCCESS: '@OWNER_DOCUMENT_MATRIX/LOAD_DOCUMENT_MATRIX_SUCCESS',
  LOAD_DOCUMENT_MATRIX_ERROR: '@OWNER_DOCUMENT_MATRIX/LOAD_DOCUMENT_MATRIX_ERROR',

  SET_CURRENT_DOCUMENT_MATRIX: '@OWNER_DOCUMENT_MATRIX/SET_CURRENT_DOCUMENT_MATRIX',

  CHANGE_ITEM_VALUE: '@OWNER_DOCUMENT_MATRIX/CHANGE_ITEM_VALUE',

  LOAD_CURRENT_DOCUMENT_MATRIX_REQUEST: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_REQUEST',
  LOAD_CURRENT_DOCUMENT_MATRIX_SUCCESS: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_SUCCESS',
  LOAD_CURRENT_DOCUMENT_MATRIX_ERROR: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_ERROR',

  LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_REQUEST: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_REQUEST',
  LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_SUCCESS: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_SUCCESS',
  LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_ERROR: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_ERROR',

  LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_REQUEST: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_REQUEST',
  LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_SUCCESS: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_SUCCESS',
  LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_ERROR: '@OWNER_DOCUMENT_MATRIX/LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_ERROR',

  SET_SHOW_FULL_FORM: '@OWNER_DOCUMENT_MATRIX/SET_SHOW_FULL_FORM',
  SET_FULL_FORM_LOADING: '@OWNER_DOCUMENT_MATRIX/SET_FULL_FORM_LOADING',
  SET_FULL_FORM_DATA: '@OWNER_DOCUMENT_MATRIX/SET_FULL_FORM_DATA',
  SET_FULL_FORM_ERROR: '@OWNER_DOCUMENT_MATRIX/SET_FULL_FORM_ERROR',

  SET_COPY_FORM_MATRIX: '@OWNER_DOCUMENT_MATRIX/SET_COPY_FORM_MATRIX',
  COPY_MATRIX_REQUEST: '@OWNER_DOCUMENT_MATRIX/COPY_MATRIX_REQUEST',
  COPY_MATRIX_SUCCESS: '@OWNER_DOCUMENT_MATRIX/COPY_MATRIX_SUCCESS',
  COPY_MATRIX_ERROR: '@OWNER_DOCUMENT_MATRIX/COPY_MATRIX_ERROR',

  SET_CREATE_FORM_MATRIX: '@OWNER_DOCUMENT_MATRIX/SET_CREATE_FORM_MATRIX',
  SET_CREATE_FORM_MATRIX_STEP: '@OWNER_DOCUMENT_MATRIX/SET_CREATE_FORM_MATRIX_STEP',
  SET_CREATE_FORM_ALL_RANKS: '@OWNER_DOCUMENT_MATRIX/SET_CREATE_FORM_ALL_RANKS',
  SET_CREATE_FORM_OWNER_RANKS: '@OWNER_DOCUMENT_MATRIX/SET_CREATE_FORM_OWNER_RANKS',

  CREATE_MATRIX_REQUEST: '@OWNER_DOCUMENT_MATRIX/CREATE_MATRIX_REQUEST',
  CREATE_MATRIX_SUCCESS: '@OWNER_DOCUMENT_MATRIX/CREATE_MATRIX_SUCCESS',
  CREATE_MATRIX_ERROR: '@OWNER_DOCUMENT_MATRIX/CREATE_MATRIX_ERROR',

  CHECK_REPORT_REQUEST: '@OWNER_DOCUMENT_MATRIX/CHECK_REPORT_REQUEST',
  CHECK_REPORT_SUCCESS: '@OWNER_DOCUMENT_MATRIX/CHECK_REPORT_SUCCESS',
  CHECK_REPORT_ERROR: '@OWNER_DOCUMENT_MATRIX/CHECK_REPORT_ERROR',
};

export const actions = {
  resetState: createAction(constants.RESET_STATE),

  loadDocumentMatrixRequest: createAction(constants.LOAD_DOCUMENT_MATRIX_REQUEST),
  loadDocumentMatrixSuccess: createAction(constants.LOAD_DOCUMENT_MATRIX_SUCCESS),
  loadDocumentMatrixError: createAction(constants.LOAD_DOCUMENT_MATRIX_ERROR),

  setCurrentDocumentMatrix: createAction(constants.SET_CURRENT_DOCUMENT_MATRIX),

  changeItemValue: createAction(constants.CHANGE_ITEM_VALUE),

  loadCurrentDocumentMatrixRequest: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_REQUEST),
  loadCurrentDocumentMatrixSuccess: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_SUCCESS),
  loadCurrentDocumentMatrixError: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_ERROR),

  loadCurrentDocumentMatrixDocumentsRequest: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_REQUEST),
  loadCurrentDocumentMatrixDocumentsSuccess: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_SUCCESS),
  loadCurrentDocumentMatrixDocumentsError: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_ERROR),

  loadCurrentDocumentMatrixRolesRequest: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_REQUEST),
  loadCurrentDocumentMatrixRolesSuccess: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_SUCCESS),
  loadCurrentDocumentMatrixRolesError: createAction(constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_ERROR),

  setShowFullForm: createAction(constants.SET_SHOW_FULL_FORM),
  setFullFormLoading: createAction(constants.SET_FULL_FORM_LOADING),
  setFullFormData: createAction(constants.SET_FULL_FORM_DATA),
  setFullFormError: createAction(constants.SET_FULL_FORM_ERROR),

  setCopyFormMatrixShow: createAction(constants.SET_COPY_FORM_MATRIX),
  copyMatrixRequest: createAction(constants.COPY_MATRIX_REQUEST),
  copyMatrixSuccess: createAction(constants.COPY_MATRIX_SUCCESS),
  copyMatrixError: createAction(constants.COPY_MATRIX_ERROR),

  setCreateFormMatrixShow: createAction(constants.SET_CREATE_FORM_MATRIX),
  setCreateFormMatrixStep: createAction(constants.SET_CREATE_FORM_MATRIX_STEP),
  setCreateFormAllRanks: createAction(constants.SET_CREATE_FORM_ALL_RANKS),
  setCreateFormOwnerRanks: createAction(constants.SET_CREATE_FORM_OWNER_RANKS),

  createMatrixRequest: createAction(constants.CREATE_MATRIX_REQUEST),
  createMatrixSuccess: createAction(constants.CREATE_MATRIX_SUCCESS),
  createMatrixError: createAction(constants.CREATE_MATRIX_ERROR),

  checkReportRequest: createAction(constants.CHECK_REPORT_REQUEST),
  checkReportSuccess: createAction(constants.CHECK_REPORT_SUCCESS),
  checkReportError: createAction(constants.CHECK_REPORT_ERROR),
};

const actionHandlers = {
  [constants.RESET_STATE]: () => initialState,

  [constants.LOAD_DOCUMENT_MATRIX_REQUEST]: (state) => ({ ...state, documentMatrixLoading: true, documentMatrix: null }),
  [constants.LOAD_DOCUMENT_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    documentMatrix: data,
    documentMatrixLoading: false,
  }),
  [constants.LOAD_DOCUMENT_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    documentMatrixError: error,
    documentMatrixLoading: false,
  }),

  [constants.SET_CURRENT_DOCUMENT_MATRIX]: (state, { payload: currentDocumentMatrix }) => ({
    ...state,
    currentDocumentMatrix,
  }),

  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_REQUEST]: (state) => ({ ...state, currentDocumentMatrixLoading: true }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    currentDocumentMatrixItems: data,
    currentDocumentMatrixLoading: false,
  }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    currentDocumentMatrixError: error,
  }),

  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_REQUEST]: (state) => ({ ...state, currentDocumentMatrixDocumentsLoading: true, currentDocumentMatrixDocuments: null }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    currentDocumentMatrixDocuments: data,
  }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_DOCUMENTS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    currentDocumentMatrixDocumentsError: error,
  }),

  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_REQUEST]: (state) => ({ ...state, currentDocumentMatrixRolesLoading: true, currentDocumentMatrixRoles: null }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    currentDocumentMatrixRoles: data,
  }),
  [constants.LOAD_CURRENT_DOCUMENT_MATRIX_ROLES_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    currentDocumentMatrixRolesError: error,
  }),
  [constants.SET_SHOW_FULL_FORM]: (state, { payload: { showFullForm, id: fullFormId } }) => ({
    ...state,
    showFullForm,
    fullFormId,
  }),
  [constants.SET_FULL_FORM_LOADING]: (state, { payload: fullFormLoading }) => ({
    ...state,
    fullFormLoading,
  }),
  [constants.SET_FULL_FORM_DATA]: (state, { payload: fullFormData }) => ({
    ...state,
    fullFormData,
  }),
  [constants.SET_FULL_FORM_ERROR]: (state, { payload: fullFormError }) => ({
    ...state,
    fullFormError,
  }),
  // COPY MATRIX SECTION
  [constants.SET_COPY_FORM_MATRIX]: (state, { payload: copyFormMatrixShow }) => ({
    ...state,
    copyFormMatrixShow,
  }),
  [constants.COPY_MATRIX_REQUEST]: (state) => ({ ...state, copyMatrixLoading: true }),
  [constants.COPY_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    copyMatrixError: error, copyMatrixLoading: false,
  }),
  [constants.COPY_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    copyMatrixSuccess: data, copyMatrixLoading: false,
  }),

  [constants.SET_CREATE_FORM_MATRIX]: (state, { payload: createFormMatrixShow }) => ({
    ...state,
    createFormMatrixShow,
  }),
  [constants.SET_CREATE_FORM_MATRIX_STEP]: (state, { payload: createFormMatrixStep }) => ({
    ...state,
    createFormMatrixStep,
  }),
  [constants.SET_CREATE_FORM_ALL_RANKS]: (state, { payload: createFormAllRanks }) => ({
    ...state,
    createFormAllRanks,
  }),
  [constants.SET_CREATE_FORM_OWNER_RANKS]: (state, { payload: createFormOwnerRanks }) => ({
    ...state,
    createFormOwnerRanks,
  }),


  [constants.CREATE_MATRIX_REQUEST]: (state) => ({ ...state, createMatrixLoading: true }),
  [constants.CREATE_MATRIX_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    createMatrixError: error, createMatrixLoading: false,
  }),
  [constants.CREATE_MATRIX_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    createMatrixSuccess: data, createMatrixLoading: false,
  }),

  [constants.CHECK_REPORT_REQUEST]: (state) => ({ ...state, reportLoading: true }),
  [constants.CHECK_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    reportError: error, reportLoading: false,
  }),
  [constants.CHECK_REPORT_SUCCESS]: (state, { payload: data }) => ({
    ...state,
    reportData: data, reportLoading: false,
  }),
};
export const reducer = handleActions(actionHandlers, initialState);


export const selector = selectors;
