import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from '../../utils/Api';
import {AUTH_TOKEN, BOT_HOST} from "../../utils/constants";
import {getCurrentUserData} from "../../utils/selectors";


const botUrl = BOT_HOST + '/send_messages';
const headersList = {
  "Accept": "*/*",
  "User-Agent": "TG Bot admin",
  "Content-Type": "application/json",
  "Authorization": AUTH_TOKEN
}

const js = JSON.stringify;

export function* fetchItems({ payload: { resource, id } = {} }) {
  try {
    const { resource: _resource, id: _id, ...filter } = yield select((s) => s.comments.filter);
    if (!(resource || _resource)) return;
    const data = yield call(
      Api.comments.index,
      resource || _resource, id || _id,
      {...filter, comments: false},
      'created_at'
    );

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

export function* sendMessage() {
  try {
    const { telegram_user_id: to, id } = yield select(s => s.userDetailsPage.data);
    const { newMessage: body } = yield select(s => s.tgChat.state);
    const { id: author_id } = yield select(getCurrentUserData);

    yield put(actions.changeState('sending', true));
    const options = {
      method: "POST",
      body: js({to, body, author_id}),
      headers: headersList
    };

    const data = yield call(fetch, botUrl, options);
    // console.log(options);
    // yield delay(1000);

    yield put(actions.changeState('newMessage', ''));
    yield delay(2000);
    yield fetchItems({payload: {resource: 'users', id: id}});
    yield put(actions.changeState('sending', false));

  } catch (e) {
    yield put(actions.changeState('sending', false));
    yield put(actions.changeState('error', e));

    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.LOAD_ITEMS_REQUEST,
    ], fetchItems),
    takeEvery(constants.CREATE_ITEM_REQUEST, sendMessage),
  ];
}
