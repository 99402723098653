import { call, put, takeEvery, select } from 'redux-saga/effects';
import { constants as ownerDetailsConstants } from '../owner-details/redux';
import { constants } from './redux';
import { actions } from './redux';
import Api from '../../utils/Api';
import {getLocation} from "connected-react-router";
import {matchPath} from "react-router-dom";
import {convertToTableData} from "./utils";


function* fetchTypes() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    if (!correctPath) return;

    const data = yield call(Api.crewWages.getTypes);

    yield put(actions.loadWageScaleTypesSuccess(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageScaleTypesError(e));
  }
}

function* fetchNationalities() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    if (!correctPath) return;

    const data = yield call(Api.owner.getScaleNationalities, correctPath.params.id);

    yield put(actions.loadWageNationalitiesSuccess(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageNationalitiesError(e));
  }
}


function* loadWageScales() {
  const { pathname } = yield select(getLocation);
  const correctPath = matchPath(pathname, { path: '/owners/:id/wage' });
  if (!correctPath) return;

  const {wageType, wageNationality} = yield select(s => s.ownerDetailsWage);
  const filter = {owner_id: correctPath.params.id, scale_type: wageType};
  if (wageType === 'alternative') filter['nationality_id'] = wageNationality;
  const data = yield call(Api.crewWages.getScales, {query: { filter }});
  yield put(actions.loadWageScaleSuccess(data));
}


function* fetchWageScales() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    if (!correctPath) return;

    yield put(actions.loadWageScaleRequest());

    yield loadWageScales();
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageScaleError(e));
  }
}

function* updateWageScale({payload: { id, field, value, cb }}) {
  try {

    const attributes = { [field]: value } ;
    yield call(Api.crewWages.updateScale, id, attributes);

    yield loadWageScales();

    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageScaleError(e));
  }
}

function* fetchWageBonuses() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    if (!correctPath) return;

    yield put(actions.loadWageBonusesRequest());

    const filter = { owner_id: correctPath.params.id };
    const data = yield call(Api.crewWages.getBonuses, {query: { filter }});

    yield put(actions.loadWageBonusesSuccess(convertToTableData(data)));
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageBonusesError(e));
  }
}

function* updateWageBonus({payload: { id, field, value, cb }}) {
  try {

    const attributes = { [field]: value } ;
    yield call(Api.crewWages.updateBonus, id, attributes);

    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    const filter = { owner_id: correctPath.params.id };
    const data = yield call(Api.crewWages.getBonuses, {query: { filter }});

    yield put(actions.loadWageBonusesSuccess(convertToTableData(data)));
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

    yield put(actions.loadWageScaleError(e));
  }
}

function* createNationalityScale({payload: { nationality_id, cb }}) {
  try {

    const attributes = { nationality_id } ;
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/wage' })
    if (!correctPath) return;

    yield call(Api.owner.createNationalityScale, correctPath.params.id, attributes);

    yield put(actions.createNationalityScaleSuccess());
    yield fetchNationalities();
    yield put(actions.wageNationalityChange(nationality_id));
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

    yield put(actions.createNationalityScaleError(e));
  }
}



export default function getFeatureSagas() {
  return [
    takeEvery(ownerDetailsConstants.ADD_LOADED_ITEM, fetchTypes),
    takeEvery(ownerDetailsConstants.ADD_LOADED_ITEM, fetchWageBonuses),
    takeEvery(ownerDetailsConstants.ADD_LOADED_ITEM, fetchNationalities),
    takeEvery(
      [ownerDetailsConstants.ADD_LOADED_ITEM, constants.WAGE_TYPE_CHANGE, constants.WAGE_NATIONALITY_CHANGE],
      fetchWageScales
    ),
    takeEvery(constants.UPDATE_WAGE_SCALE_REQUEST, updateWageScale),
    takeEvery(constants.UPDATE_WAGE_BONUSES_REQUEST, updateWageBonus),
    takeEvery(constants.CREATE_NATIONALITY_SCALE_REQUEST, createNationalityScale),
  ];
}
