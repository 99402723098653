import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";
import { initialState as baseInitState } from "../../utils/redux/commonActionHandlers";
import { fromStore } from "../../utils/localStorageStore";

const constantsPrefix = "TASK_MANAGER";
const statePropName = "taskManager";

const baseConstants = getConstantsByFeatureName(constantsPrefix);

export const constants = {
  ...baseConstants,
  UPDATE_STATUS: `${constantsPrefix}/UPDATE_STATUS`,
  SET_NOTIFICATION_COUNT: `${constantsPrefix}/SET_NOTIFICATION_COUNT`,
  FETCH_SUBTASKS: `${constantsPrefix}/FETCH_SUBTASKS`,
  ADD_SUBTASKS: `${constantsPrefix}/ADD_SUBTASKS`,
  CREATE_VACANCY_APPLY: `${constantsPrefix}/CREATE_VACANCY_APPLY`,
  UPDATE_APPLY_STATUS: `${constantsPrefix}/UPDATE_APPLY_STATUS`,
  CHANGE_FILTER_EXPANDED: `${constantsPrefix}/CHANGE_FILTER_EXPANDED`,
};

const baseActions = getActionCreators(constants);

export const actions = {
  ...baseActions,
  updateStatus: createAction(constants.UPDATE_STATUS),
  setNotificationCount: createAction(constants.SET_NOTIFICATION_COUNT),
  fetchSubtasks: createAction(constants.FETCH_SUBTASKS),
  addSubtasks: createAction(constants.ADD_SUBTASKS),
  createVacancyApply: createAction(constants.CREATE_VACANCY_APPLY),
  updateApplyStatus: createAction(constants.UPDATE_APPLY_STATUS),
  changeFilterExpanded: (filterExpanded) => ({
    type: constants.CHANGE_FILTER_EXPANDED,
    payload: filterExpanded,
  }),
};

export const actionHandlers = getActionHandlers(constants);
export const selector = getSelectorByFeatureName(statePropName);

const initialState = {
  ...baseInitState,
  filter: fromStore(`${statePropName}.filter`, { task_status: "all" }),
  filterExpanded: fromStore(`${statePropName}.filterExpanded`, {
    task_type: true,
  }),
  state: { showTaskForm: false },
  subtasks: {},
  sort: {
    field: "created_at",
    direction: "desc",
  },
};

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.CLEAR_FILTER]: (state) => ({
      ...state,
      filter: {},
    }),
    [constants.CHANGE_FILTER_EXPANDED]: (state, action) => ({
      ...state,
      filterExpanded: {
        ...state.filterExpanded,
        [action.payload.name]: action.payload.value,
      },
    }),
    [constants.ADD_SUBTASKS]: (state, action) => ({
      ...state,
      subtasks: action.payload,
    }),
  },
  initialState
);
