import get from "lodash/get";
import { createSelector } from "reselect";
import { getPermissions } from "../../utils/selectors";

const getTabs = createSelector([getPermissions], (permissions) => {
  const tabs = [];

  if (get(permissions, "vessels.read", false))
    tabs.push({ key: "vessels", value: "vessels", label: "Vessels" });
  if (get(permissions, "timeplans.read", false))
    tabs.push({ key: "timeplans", value: "", label: "Timeplans" });
  if (get(permissions, "owners.read", false))
    tabs.push({ key: "owners", value: "owners", label: "Owners" });
  if (get(permissions, "managers.read", false))
    tabs.push({ key: "managers", value: "managers", label: "Managers" });
  if (get(permissions, "crewUsers.read", false))
    tabs.push({ key: "crew-users", value: "crew-users", label: "Users" });
  if (get(permissions, "ranks.read", false))
    tabs.push({ key: "ranks", value: "ranks", label: "Ranks" });
  if (get(permissions, "crewChange.read", false))
    tabs.push({
      key: "crew-changes",
      value: "crew-changes",
      label: "Crew changes",
    });
  if (get(permissions, "users.read", false))
    tabs.push({ key: "users", value: "users", label: "Seafarers" });
  if (get(permissions, "vacancies.read", false))
    tabs.push({ key: "vacancies", value: "vacancies", label: "Vacancies" });
  if (get(permissions, "taskManager.read", false))
    tabs.push({
      key: "task-manager",
      value: "task-manager",
      label: "Task Manager",
    });
  if (get(permissions, "engineTypes.read", false))
    tabs.push({
      key: "engine-types",
      value: "engine-types",
      label: "Engine types",
    });
  if (get(permissions, "engineManufacturers.read", false)) {
    tabs.push({
      key: "engine-manufacturers",
      value: "engine-manufacturers",
      label: "Engine manufacturers",
    });
  }
  if (get(permissions, "vendorTypes.read", false))
    tabs.push({
      key: "vendor-types",
      value: "vendor-types",
      label: "Vendor types",
    });
  if (get(permissions, "vendors.read", false))
    tabs.push({ key: "vendors", value: "vendors", label: "Vendors" });
  if (get(permissions, "certificateTypes.read", false)) {
    tabs.push({
      key: "certificate-types",
      value: "certificate-types",
      label: "Certificate types",
    });
  }

  if (get(permissions, "relationTypes.read", false)) {
    tabs.push({
      key: "relation-types",
      value: "relation-types",
      label: "Relation types",
    });
  }

  if (get(permissions, "countries.read", false))
    tabs.push({ key: "countries", value: "countries", label: "Countries" });
  if (get(permissions, "surveyCreator.read", false))
    tabs.push({
      key: "survey-creator",
      value: "survey-creator",
      label: "Survey creator",
    });

  if (get(permissions, "crewingStatuses.read", false)) {
    tabs.push({
      key: "crewing-statuses",
      value: "crewing-statuses",
      label: "Crewing statuses",
    });
  }

  if (get(permissions, "ports.read", false))
    tabs.push({ key: "ports", value: "ports", label: "Ports" });

  if (get(permissions, "mainEngines.read", false))
    tabs.push({
      key: "main-engines",
      value: "main-engines",
      label: "Main engines",
    });
  if (get(permissions, "applicantContracts.read", false)) {
    tabs.push({
      key: "applicant-contracts",
      value: "applicant-contracts",
      label: "Applicant Contracts",
    });
  }
  if (get(permissions, "applicantDocuments.read", false)) {
    tabs.push({
      key: "applicant-documents",
      value: "applicant-documents",
      label: "Applicant Documents",
    });
  }

  return tabs.length > 1 ? tabs : [];
});

const mapStateToProps = (state) => {
  let currentTab = state.router.location.pathname.split("/")[1];
  if (currentTab === "timeplans") currentTab = "";

  return {
    currentTab,
    tabs: getTabs(state),
    username: get(state, "auth.data.name", "Loading..."),
    avatar: get(state, "auth.data.avatar"),
    status: get(state, "auth.data.status"),
    id: get(state, "auth.data.id"),
    permissions: get(state, "auth.data.permissions"),
  };
};

export default mapStateToProps;
