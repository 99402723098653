import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState } from '../../utils/redux/commonActionHandlers';

const initialFilterState = {
  role: null,
  status: null,
  deadweight: '',
  engine_type: null,
  engine_manufacturer: null
};

const constants = getConstantsByFeatureName('USERS-SECTION');
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.CLEAR_FILTER]: (state) => ({
    ...state,
    filter: { ...initialFilterState },
  }),
}, {
  ...initialState,
  filter: { ...initialFilterState },
  state: {
    isFull: false,
    engineTypeInputValue: '',
    engineTypeInputDebouncedValue: '',
  },
});

export { constants, actions, reducer };
