const getStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    paddingLeft: 0,
    flex: '0 0 auto',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row nowrap',
    alignItems: 'center',
  },
  action: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
});

export default getStyles;
