import React, { useMemo, memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import Tab from "../../../components/Tab";
import Logo from "../../../components/Logo";
import Navigation from "../../../components/NavigationBar";
import CrewUserStatus from "../../../features/crew-users/component/CrewUserStatus";
import { get } from "lodash";
import { Divider } from "@material-ui/core";

const clearLastSavedState = () => {
  const keysToKeep = [
    "token-type",
    "clientId",
    "uid",
    "expiry",
    "client",
    "access-token",
  ];

  Object.keys(localStorage).forEach((key) => {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });

  document.location.href = "/";
};

const clearServiceWorkerCache = () => {
  if ("serviceWorker" in navigator) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }

  document.location.href = "/";
};

const isMobileMode = window.innerWidth <= 600;

const NavigationBar = ({
  currentTab,
  tabs,
  push,
  username,
  isLoading,
  onLogout,
  avatar,
  status,
  id,
  onStatusChange,
  permissions,
}) => {
  const tabItems = useMemo(() => tabs.map((tab) => <Tab {...tab} />), [tabs]);

  return (
    <Navigation
      isLoading={isLoading}
      logo={<Logo />}
      tabs={tabItems}
      username={isMobileMode ? "" : username}
      currentTab={currentTab}
      onTabChange={push}
      avatar={`/api${avatar}`}
      menuItems={[
        <>
          {get(permissions, "crewUserStatus.update") && (
            <CrewUserStatus
              id={id}
              status={status}
              onStatusChange={onStatusChange}
              navigationBar
            />
          )}
        </>,
        <Divider key="divider" />,
        ...(isMobileMode ? tabs : []).map(({ value, label }) => (
          <MenuItem key={value} component={Link} to={`/${value}`}>
            {`${label} page`}
          </MenuItem>
        )),

        <MenuItem onClick={clearLastSavedState} key="reset-state">
          <Tooltip title="Click to reset all filters, search terms and current zoom in timeplan">
            <span>Reset application state</span>
          </Tooltip>
        </MenuItem>,
        <MenuItem onClick={clearServiceWorkerCache} key="clear-data">
          <Tooltip
            title={`
              Click to reset app cache.
              Sometimes you need to do it if you don't see changes after new version release
            `}
          >
            <span>Reset cache</span>
          </Tooltip>
        </MenuItem>,
        <MenuItem onClick={onLogout} key="Log out">
          Log out
        </MenuItem>,
      ]}
    />
  );
};

NavigationBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onLogout: PropTypes.func.isRequired,
  avatar: PropTypes.string,
};

NavigationBar.defaultProps = {
  isLoading: false,
};

export default memo(
  NavigationBar,
  (prev, next) => prev.currentTab !== next.currentTab
);
