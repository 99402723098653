import { handleActions, createAction } from 'redux-actions';

const constants = {
  SEARCH_BY_IMO: '@EQUASIS/SEARCH_BY_IMO',
  CHANGE_SEARCH_TERM: '@EQUASIS/CHANGE_SEARCH_TERM',
  ABORT_SEARCH: '@EQUASIS/ABORT_SEARCH',
  PERFORM_SEARCH: '@EQUASIS/PERFORM_SEARCH',
  CREATE_VESSEL: '@EQUASIS/CREATE_VESSEL',
  SUCCESS_SEARCH: '@EQUASIS/SUCCESS_SEARCH',
  ERROR_SEARCH: '@EQUASIS/ERROR_SEARCH',
  SET_LOADING: '@EQUASIS/SET_LOADING',
};

const actions = {
  searchByIMO: createAction(constants.SEARCH_BY_IMO),
  changeSearchTerm: createAction(constants.CHANGE_SEARCH_TERM),
  abortSearch: createAction(constants.ABORT_SEARCH),
  performSearch: createAction(constants.PERFORM_SEARCH),
  createVessel: createAction(constants.CREATE_VESSEL),
  successSearch: createAction(constants.SUCCESS_SEARCH),
  errorSearch: createAction(constants.ERROR_SEARCH),
  setLoading: createAction(constants.SET_LOADING),
};

const initialState = {
  showDialog: false,
  searchType: '', // IMO | name
  searchTerm: '',
  loading: false,
  data: {},
  errors: [],
};

const actionHandlers = {
  [constants.SEARCH_BY_IMO]: (s, { payload }) => ({ ...s, searchType: 'IMO', searchTerm: payload, showDialog: true }), // s - state
  [constants.CHANGE_SEARCH_TERM]: (s, { payload }) => ({ ...s, searchTerm: payload }),
  [constants.ABORT_SEARCH]: (s) => ({ ...s, searchType: '', searchTerm: '', showDialog: false, data: {}, errors: [] }),
  [constants.SUCCESS_SEARCH]: (s, { payload }) => ({ ...s, data: payload }),
  [constants.ERROR_SEARCH]: (s, { payload }) => ({ ...s, errors: payload }),
  [constants.SET_LOADING]: (s, { payload }) => ({ ...s, loading: payload }),
};


const reducer = handleActions(actionHandlers, initialState);

const selector = (state) => ({
  showDialog: state.vesselSearchEquasis.showDialog,
  searchType: state.vesselSearchEquasis.searchType, // IMO | name
  searchTerm: state.vesselSearchEquasis.searchTerm,
  loading: state.vesselSearchEquasis.loading,
  data: state.vesselSearchEquasis.data,
  errors: state.vesselSearchEquasis.errors,
});


export { constants, actions, reducer, selector };
