import * as api from './adapter';

import cert from './fake/certificates';

export default {
  index: () => {
    const userId = window.location.pathname.split('/')[2];

    return {
      '7c341cf071bf4bb4b097ca13a9e2628b': cert.doc180,
      '615d7f014c534a9cb2f3763c1e96098c': cert.doc1605,
    }[userId] || cert.docEmpty;
  },
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: (id) => api.read(`/certificates/${id}`),
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({ type: 'certificates', attributes }),
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({ id, type: 'certificates', attributes }),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: 'certificates' }),
};
