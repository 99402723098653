import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';

const constantsPrefix = 'CREW-CHANGE';
const statePropName = 'crewChangeDetails';

const initialState = {
  data: null,
  isPushing: false,
  isLoading: false,
  error: null,
};

const constants = getConstantsByFeatureName(constantsPrefix);
const actions = getActionCreators(constants);
const actionHandlers = getActionHandlers(constants);
const reducer = handleActions({
  ...actionHandlers,
  [constants.ADD_CREATED_ITEM]: (state, action) => ({
    data: action.payload,
    isPushing: false,
    isLoading: false,
    error: null,
  }),
  [constants.ITEM_UPDATED_SUCCESSFULLY]: (state, action) => ({
    data: action.payload,
    isPushing: false,
    isLoading: false,
    error: null,
  }),
  [constants.ITEM_DELETED_SUCCESSFULLY]: () => ({ ...initialState }),
  '@CREW_LIST/ADD_CREATED_ITEM': (state, action) => ({
    ...state,
    data: {
      ...state.data,
      crewList: [...state.data.crewList, action.payload.item],
    },
  }),
  '@CREW_LIST/ITEM_UPDATED_SUCCESSFULLY': (state, action) => ({
    ...state,
    data: {
      ...state.data,
      crewList: [
        ...state.data.crewList.filter((i) => i.id !== String(action.payload.item.id)),
        action.payload.item,
      ],
    },
  }),
  '@CREW_LIST/ITEM_DELETED_SUCCESSFULLY': (state, action) => ({
    ...state,
    data: {
      ...state.data,
      crewList: state.data.crewList.filter((i) => i.id !== String(action.payload.id)),
    },
  }),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, { path: '/crew-changes/:id/details', exact: true })
      ? initialState
      : state
  ),
}, initialState);
const selector = getSelectorByFeatureName(statePropName);

export { constants, actions, reducer, selector };
