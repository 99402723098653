import * as api from './adapter';

export default {
  index: () => api.read('comment_events'),

  show: (id) => api.read(`comment_events/${id}`),

  create: (attributes) => api.create({
    type: 'comment_events',
    attributes,
  }),
  update: (id, attributes) => api.update({
    id,
    type: 'comment_events',
    attributes,
  }),

  delete: (id) => api.destroy({ id, type: 'comment_events' }),
};
