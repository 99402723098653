import getReduxModuleByFeatureName from '../../utils/redux/getReduxModuleByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';
import Api from '../../utils/Api';


const constantsPrefix = 'OWNERS';
const statePropName = 'owners';
const formStatePropName = 'owners';
const mainPageUrl = '/owners';

const { constants, actions, reducer, selector } = getReduxModuleByFeatureName(constantsPrefix, statePropName);
const sagas = getCRUDSagas(
  mainPageUrl,
  Api.owner,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'Owner' },
);

export { constants, actions, reducer, selector, sagas };
