import { call, put, takeEvery, select } from 'redux-saga/effects';
import { constants as ownerDetailsConstants } from '../owner-details/redux';
import { constants } from './redux';
import { actions } from './redux';
import Api from '../../utils/Api';
import {getLocation} from "connected-react-router";
import {matchPath} from "react-router-dom";
import { destroy } from 'redux-form';


function* fetchDocumentMatrix() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/document_matrix' })
    if (!correctPath) return;

    yield put(actions.resetState());

    yield put(actions.loadDocumentMatrixRequest());

    const options = { filter: { owner_id: correctPath.params.id } }
    const data = yield call(Api.ownerDocumentMatrix.ownerMatrices, options)

    yield put(actions.loadDocumentMatrixSuccess(data.data))

    yield fetchAllDocuments();

  } catch (e) {
    console.error(e);

    yield put(actions.loadDocumentMatrixError(e));
  }
}

function* fetchCurrentMatrix() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/document_matrix' })
    if (!correctPath) return;

    const currentMatrixId = yield select(s => s.ownerDocumentMatrix.currentDocumentMatrix);
    if (!currentMatrixId) {
      yield put(actions.loadCurrentDocumentMatrixSuccess(null));
      return;
    };
    const options = { filter: { owner_group_id: currentMatrixId } }

    yield put(actions.loadCurrentDocumentMatrixRequest());

    const data = yield call(Api.ownerDocumentMatrix.matrixItems, options)

    yield put(actions.loadCurrentDocumentMatrixSuccess(data.data))


  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentDocumentMatrixError(e));
  }
}

function* updateItemValue({ payload: { cb, ...payload } }) {
  try {
    yield call(Api.ownerDocumentMatrix.updateItem, payload)

    yield fetchCurrentMatrix();
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
  }
}

function* fetchAllDocuments() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/document_matrix' })
    if (!correctPath) return;

    const data = yield call(Api.ownerDocumentMatrix.documents)

    yield put(actions.loadCurrentDocumentMatrixDocumentsSuccess(data.data))
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentDocumentMatrixError(e));
  }
}

function* fetchAllRoles() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/document_matrix' })
    if (!correctPath) return;

    const currentMatrixId = yield select(s => s.ownerDocumentMatrix.currentDocumentMatrix);
    const data = yield call(Api.ownerDocumentMatrix.roles, currentMatrixId)

    yield put(actions.loadCurrentDocumentMatrixRolesSuccess(data.data))
  } catch (e) {
    console.error(e);

    yield put(actions.loadCurrentDocumentMatrixError(e));
  }
}


function* loadFullFormData({ payload: { id } }) {
  try {
    if (!id) return;
    const {data} = yield call(Api.ownerDocumentMatrix.matrixItemsLoad, id);

    yield put(actions.setFullFormData(data.data))
  } catch (e) {
    console.error(e);

  }
}

function* copyMatrix({ payload: { attributes, cb }}) {
  try {
    const {data} = yield call(Api.ownerDocumentMatrix.copyMatrix, { attributes });

    yield put(actions.copyMatrixSuccess(data.data));

    yield put(actions.setCopyFormMatrixShow(false));
    yield fetchDocumentMatrix();
    yield put(actions.setCurrentDocumentMatrix(data.data.id));
    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);

  }
}

function* fetchRoles() {
  try {
    const { pathname } = yield select(getLocation);
    const correctPath = matchPath(pathname, { path: '/owners/:id/document_matrix' })
    if (!correctPath) return;

    const {data: allRoles} = yield call(Api.ranks.index);
    yield put(actions.setCreateFormAllRanks(allRoles));

    const {data: ownerRoles} = yield call(Api.owner.ownerRoles, correctPath.params.id);

    yield put(actions.setCreateFormOwnerRanks(ownerRoles.data));
  } catch (e) {
    console.error(e);

  }

}

function* createMatrix({ payload: attributes }) {
  try {
    const { data } = yield call(Api.ownerDocumentMatrix.createMatrix, { attributes });
    yield put(actions.createMatrixSuccess(data.data));

    yield put(actions.setCreateFormMatrixShow(false));
    yield put(destroy('matrixCreate'));

    yield fetchDocumentMatrix();
    yield put(actions.setCurrentDocumentMatrix(data.data.id));

  } catch (e) {
    console.error(e);
    yield put(actions.createMatrixError(e));
  }
}

function* checkMatrix({ payload: { cb, ...query } }) {
  try {
    const { data: { report } } = yield call(Api.users.checkDocumentMatrix, { query });
    yield put(actions.checkReportSuccess(report));

    if (cb) yield call(cb);
  } catch (e) {
    console.error(e);
    yield put(actions.checkReportError(e));
  }
}


export default function getFeatureSagas() {
  return [
    takeEvery(
      [ownerDetailsConstants.ADD_LOADED_ITEM],
      fetchDocumentMatrix
    ),
    takeEvery(
      [constants.SET_CURRENT_DOCUMENT_MATRIX],
      fetchCurrentMatrix
    ),
    takeEvery(
      [constants.SET_CURRENT_DOCUMENT_MATRIX],
      fetchAllDocuments
    ),
    takeEvery(
      [constants.SET_CURRENT_DOCUMENT_MATRIX],
      fetchAllRoles
    ),
    takeEvery(
      [constants.CHANGE_ITEM_VALUE],
      updateItemValue
    ),
    takeEvery(
      [constants.SET_SHOW_FULL_FORM],
      loadFullFormData
    ),
    takeEvery(
      [constants.COPY_MATRIX_REQUEST],
      copyMatrix
    ),
    takeEvery(
      [constants.SET_CREATE_FORM_MATRIX],
      fetchRoles
    ),
    takeEvery(
      [constants.CREATE_MATRIX_REQUEST],
      createMatrix
    ),
    takeEvery(
      [constants.CHECK_REPORT_REQUEST],
      checkMatrix,
    ),
  ];
}
