import { stopSubmit } from 'redux-form';
import { createAction } from 'redux-actions';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { deleteItem as deleteDialog } from "../dialog-manager/actions";
import { addItem as notify } from "../notifications/actions";
import { initialState as commonInitialState } from '../../utils/redux/commonActionHandlers';
import Api from '../../utils/Api';

const constantsPrefix = 'USER-DOCUMENTS';
const statePropName = 'userDocuments';

const extendedConstants = (baseConstants) => ({
  ...baseConstants,
  SET_SELECTED_TYPES: `${constantsPrefix}/SET_SELECTED_TYPES`,
  RENAME_FILES: `${constantsPrefix}/RENAME_FILES`,
});

const extendedActions = (baseActions, constants) => ({
  ...baseActions,
  renameFiles: createAction(constants.RENAME_FILES),
  setSelectedTypes: (payload) => ({ type: constants.SET_SELECTED_TYPES, payload }),
});

const extendedInitialState = {  
  ...commonInitialState,
  selectedTypes: null,
};

const extendedActionHandlers = (baseActionHandlers, constants) => ({
  ...baseActionHandlers,
  [constants.CLEAR_FILTER]: (state) => ({ ...state, filter: { ...extendedInitialState.filter } }),
  [constants.SET_SELECTED_TYPES]: (state, { payload }) => ({ ...state, selectedTypes: payload }),
});

const newSagas = (baseSagas, constants, actions) => {
  function* renameFiles({ payload: ids }) {
    try {
      yield call(Api.applicantDocuments.renameFiles, ids);
      yield put(deleteDialog());
      yield call(baseSagas.fetchItems);
      yield put(
        notify({
          type: "success",
          title: "Success",
          message: "File(s) have been renamed successfully.",
        })
      );
    } catch (e) {
      console.error(e);
      yield put(actions.updateItemError(e));

      if (e.errorByField) yield put(stopSubmit(statePropName, e.errorByField));
    }
  }
  
  return [
    takeEvery(constants.RENAME_FILES, renameFiles),
  ];
};


const extendedSagas = (baseSagas, newSagas) => {
  return () => [
    ...baseSagas(),
    ...newSagas
  ];
};

const extendedSelectors = (baseSelectors) => (state) => ({
  ...baseSelectors(state),
  selectedTypes: state[statePropName].selectedTypes,
})

export default {
  extendedConstants,
  extendedActions,
  extendedInitialState,
  extendedActionHandlers,
  extendedSagas,
  newSagas,
  extendedSelectors
};
