import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getLocation, push, LOCATION_CHANGE } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { stopSubmit } from 'redux-form';
import { matchPath } from 'react-router';
import { constants as assignedVesselsConstants } from '../assigned-vessels';
import { addSuccess, addError } from '../notifications/actions';
import { deleteItem as deleteDialog } from '../dialog-manager/actions';
import { constants as crewChangeDetailsConstants } from '../crew-change-details/redux';
import * as crewListConstants from '../crew-list/constants';
import { constants as commentsConstants } from '../comments/redux';
import { actions, constants, selector } from './redux';
import Api from '../../utils/Api';
import { getQueryParamsByState, getStateDiffByQuery, getBooleanFilterValues } from './utils';
import ParamsStorage from './ParamsStorage';

function* fetchItems() {
  try {
    const featureState = yield select(selector);
    const data = yield call(Api.timeplans.index, featureState);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* fetchItem({ payload: { options } }) {
  try {
    const timeplans = yield select(s => s.timeplans.data);
    const ids = timeplans.map(r => r.id);
    const data = yield call(Api.timeplans.show, { ...options, filter: { ...options.filter, id: [options.filter.id, ...ids].join(',') } });
    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* createItem({ payload }) {
  try {
    const timeplan = yield call(Api.timeplans.create, payload);

    yield put(push('/'));
    yield put(actions.addCreatedItem(timeplan));
    yield put(addSuccess('The timeplan created successfully'));
  } catch (e) {
    console.error(e);
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit('timeplans', e.errorByField));
  }
}

function* updateItem({ payload }) {
  try {
    const { pathname } = yield select(getLocation);

    const didUpdateByForm = matchPath(pathname, { path: '/timeplans/:id/edit' });
    const didUpdateByVacancy = matchPath(pathname, { path: '/vacancies/:id/edit' });
    const timeplan = yield call(Api.timeplans.update, payload.id, payload);

    if (didUpdateByForm) {
      yield put(push('/'));
    } else if (didUpdateByVacancy) {
      yield put(push('/vacancies'));
    } else {
      const timeplans = yield select((s) => s.timeplans.data);
      const currentTimeplan = timeplans.find((t) => t.id === payload.id);

      if (currentTimeplan.crew_change_sign_on_id) yield put(actions.loadItemsRequest());
    }

    yield put(actions.updateItem(timeplan));
    yield put(addSuccess('The timeplan updated successfully'));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));

    /**
     * if payload has "status" property
     * that means timeplan has updated via form, if not - by drag or resize
     * and we don't need create redux form an exception on 422 error made by resize
     */
    if (payload.status && e.errorByField) {
      yield put(stopSubmit('timeplans', e.errorByField));
    } else if (e.apiErrors[0].status === '422' && !payload.status) {
      yield put(addError(e.apiErrors[0].title, e.message));
    }
  }
}

function* deleteItem({ payload: id }) {
  try {
    yield call(Api.timeplans.delete, id);

    yield put(actions.deleteItem(id));
    yield put(deleteDialog());
    yield put(addSuccess('Planning deleted successfully'));

    const { pathname } = yield select(getLocation);
    const didDeleteByVacancy = matchPath(pathname, { path: '/vacancies/:id/delete' });
    if (didDeleteByVacancy) yield put(push('/vacancies'));
  } catch (e) {
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit('timeplans', e.errorByField));
  }
}

const isWatchedStateField = (fieldName) => [
  'isUserSectionOpen',
  'cellWidth',
  'startDate',
  'viewport',
].includes(fieldName);

function* updateUrlAndBrowserStorage({ type, payload }) {
  try {
    if (type === constants.CHANGE_STATE && !isWatchedStateField(payload.field)) return;

    const featureState = yield select((s) => s.timeplans);
    const query = getQueryParamsByState(featureState, 'timeplans');

    ParamsStorage.setQuery('timeplans', query);

    yield put(push(query));

    if (payload && payload.field === 'viewport') {
      yield put(actions.loadItemsRequest());
    }
  } catch (e) {
    console.error(e);
  }
}

export function* syncUrlParams(action) {
  try {
    const featureState = yield select((s) => s.timeplans);
    const location = yield select(getLocation);
    const hasFilterInState = !isEmpty(featureState.appliedFilter);
    const queryFromStorage = ParamsStorage.getQuery('timeplans');
    const hasParamsInUrl = Boolean(location.search);
    const hasParamsInStorage = Boolean(queryFromStorage);

    let query;
    let diff;

    if (hasFilterInState) {
      const urlQueryByState = getQueryParamsByState(featureState, 'timeplans');
      const areUrlParamsActualWithFilter = location.search === urlQueryByState;

      if (!areUrlParamsActualWithFilter) {
        query = urlQueryByState;
      }
    } else if (hasParamsInUrl) {
      diff = getStateDiffByQuery(location.search, 'timeplans');
    } else if (hasParamsInStorage) {
      diff = ParamsStorage.getQueryAsStateDiff('timeplans');
      query = queryFromStorage;
    } else {
      diff = {
        filter: {
          ...getBooleanFilterValues(action.payload.roleIds, 'roles'),
          ...getBooleanFilterValues(action.payload.vesselIds, 'vessels'),
        },
      };

      query = getQueryParamsByState({ ...featureState, ...diff, appliedFilter: diff.filter }, 'timeplans');
    }

    if (query) yield put(push(query));
    if (diff) yield put(actions.updateStateByParams(diff));

    yield put(actions.changeState('isReadyToRenderScheduler', true));
  } catch (e) {
    console.error(e);
  }
}

function* redirectIfReloadNewForm({ payload: { isFirstRendering, location } }) {
  const isNewFormWithoutNeededData = (
    matchPath(location.pathname, { path: '/timeplans/new' })
    || matchPath(location.pathname, '/timeplans/crew-rotation/crew-change/new')
  ) && isFirstRendering;

  if (isNewFormWithoutNeededData) {
    yield put(push('/'));
  }
}



/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
    takeEvery([
      constants.LOAD_ITEMS_REQUEST,
      crewChangeDetailsConstants.ADD_CREATED_ITEM,
      crewChangeDetailsConstants.ITEM_UPDATED_SUCCESSFULLY,
      crewChangeDetailsConstants.ITEM_DELETED_SUCCESSFULLY,

      crewListConstants.ADD_CREATED_ITEM,
      crewListConstants.ITEM_UPDATED_SUCCESSFULLY,
      crewListConstants.ITEM_DELETED_SUCCESSFULLY,

      commentsConstants.DELETE_ITEM_REQUEST,
    ], fetchItems),
    takeEvery(assignedVesselsConstants.ITEMS_LOADED_SUCCESSFULLY, syncUrlParams),
    takeEvery([
      constants.CHANGE_STATE,
      constants.APPLY_FILTER,
    ], updateUrlAndBrowserStorage),
    takeEvery(LOCATION_CHANGE, redirectIfReloadNewForm),
  ];
}
