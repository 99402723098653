import * as api from './adapter';
import convertItems from './convertors/roles';


export default {
  /**
   * getCompanies
   * @returns {Promise<*>}
   */
  index: async ({ search, filter } = {}) => {
    const { data } = await api.read('roles', {
      query: {
        include: 'department',
        filter: { title: search, ...filter },
      },
    });

    return convertItems(data);
  },
  /**
   * create
   * @param attributes
   * @returns {Promise}
   */
  create: (attributes) => api.create({
    type: 'roles',
    attributes,
  }),
  /**
   * update
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: (id, attributes) => api.update({
    id,
    type: 'roles',
    attributes,
  }),
  /**
   * delete
   * @param id
   * @returns {Promise}
   */
  delete: (id) => api.destroy({
    id,
    type: 'roles',
  }),
};
