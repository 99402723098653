import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import {tryParseDate} from "../../utils/DateTime";

export const crewListModes = {
  DISPLAY: 'display', // show crew lists
  SELECT: 'select', // select timeplans for a new crew change
  RESELECT: 're-select', // edit crew list
  STORE: 'store', // store crew list to display for form
  INACTIVE: 'inactive',
};

/**
 * getCrewListBySelectedTimeplans
 * @param {Object} selectedTsById
 * @param {Date} date
 * @returns {[{}]}
 */
export const getCrewListBySelectedTimeplans = (selectedTsById, dateString) => {
  const date = tryParseDate(dateString);
  const ts = Object
    .entries(selectedTsById)
    .map((i) => i[1])
    .map(({ start, end, msId, ...t }) => ({
      msId,
      start: new Date(start),
      end: new Date(end),
      ...t,
    }))
    .map((i) => {
      const halfDuration = (i.end.getTime() - i.start.getTime()) / 2;
      const middleDate = new Date(i.start.getTime() + halfDuration);

      return ({
        ...i,
        middle: middleDate,
      });
    });

  const groupedTs = ts.reduce((acc, t) => ({
    ...acc,
    [t.msId]: [...(acc[t.msId] || []), t],
  }), {});

  const chDate = date ? new Date(date) : Object.entries(groupedTs)
    .reduce((acc, [, [t1, t2]]) => {
      if (!t2) return acc;

      const offT = t1.middle < t2.middle ? t1 : t2;
      const onT = offT === t1 ? t2 : t1;

      const halfDuration = (onT.middle.getTime() - offT.middle.getTime()) / 2;
      const middleOffOnDate = new Date(offT.middle.getTime() + halfDuration);

      return [...acc, middleOffOnDate];
    }, [])
    .reduce((acc, d, i, ds) => {
      const summ = acc ? acc + d.getTime() : d.getTime();

      if (i === ds.length - 1) {
        return new Date(Math.floor(summ / ds.length));
      }

      return summ;
    }, null);

  if (!chDate) return [];

  const listById = ts.reduce((acc, t) => {
    const isOffSignTimeplan = t.middle.getTime() < chDate.getTime();
    const listItem = acc[t.msId] || {};

    const prefix = isOffSignTimeplan ? 'off_sign' : 'on_sign';

    listItem[`${prefix}_id`] = t.id;
    listItem[`${prefix}_seaman`] = t.userName || t.text;
    listItem.rank = t.role;
    listItem.rolePriority = t.rolePriority;
    listItem.roleCreatedAt = t.roleCreatedAt;

    return {
      ...acc,
      [t.msId]: listItem,
    };
  }, {});

  return sortBy(listById, ['rolePriority', 'roleCreatedAt']);
};


export const getIdsGroupedByTimeplanListType = (list) => list.reduce((acc, listItem) => {
  const onId = listItem.sign_on_id;
  const offId = listItem.sign_off_id;
  const hasReplace = onId && offId;

  if (hasReplace) return { ...acc, onWithOff: [...acc.onWithOff, onId], offWithOn: [...acc.offWithOn, offId] };
  if (onId) return { ...acc, onWithoutOff: [...acc.onWithoutOff, onId] };
  if (offId) return { ...acc, offWithoutOn: [...acc.offWithoutOn, offId] };

  return acc;
}, { offWithoutOn: [], onWithoutOff: [], onWithOff: [], offWithOn: [] });


export const setTimeplanToState = (state, timeplan) => {
  const oldData = state.data || {};

  const data = (
    oldData[timeplan.id]
      ? omit(oldData, timeplan.id)
      : { ...oldData, [timeplan.id]: timeplan }
  );

  return ({ ...state, data });
};
