import useDefaultSagasWithExtensions from "../../utils/hooks/use-default-sagas-with-extensions";
import extendsOptions from "./extends";

const params = {
  constantsPrefix: "VACANCIES",
  statePropName: "vacancies",
  formStatePropName: "vacancies",
  mainPageUrl: "/vacancies",
  itemName: "Vacancy",
  extendsOptions,
};

const { constants, actions, reducer, selector, sagas } =
  useDefaultSagasWithExtensions(params);

export { constants, actions, reducer, selector, sagas };
