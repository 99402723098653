import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import { initialState, addItemToData } from '../../utils/redux/commonActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';


export const constants = getConstantsByFeatureName('CREW_USER_VESSELS');
export const actions = getActionCreators(constants);
export const reducer = handleActions({
  ...getActionHandlers(constants),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, { path: '/crew-users/:id/details' })
      ? state
      : initialState
  ),
  [constants.ADD_CREATED_ITEM]: (state, action) => {
    const newState = addItemToData(state, action);

    return { ...newState, data: newState.data.sort((a, b) => a.createdAt - b.createdAt) };
  },
}, initialState);
export const selector = getSelectorByFeatureName('crewUserVessels');
