import * as api from "./adapter";
import { convertItem } from "./convertors/crewUsers";

const include = "coordinated_vessels,recruited_fleets";
const fields = {
  vessels: "id,name,imo",
  owners: "id,title,short_title",
};

export default {
  /**
   * getCrewUsers
   * @returns {Promise<*>}
   */
  index: ({
    pagination,
    sort,
    search,
    filter: {
      role: { value: role },
    },
  }) => {
    const roleFilter = role !== "all" ? { role } : {};

    return api.read("clients", {
      query: {
        filter: { name: search, ...roleFilter },
        sort: `${sort.direction === "asc" ? "" : "-"}${sort.field}`,
        page: { size: pagination.itemsPerPage, number: pagination.current },
      },
    });
  },
  fetchUsersForMentions: () =>
    api.read("clients", {
      query: { fields: { clients: "id,name" }, page: { size: 999 } },
    }),
  /**
   * show
   * @param id
   * @return {Promise}
   */
  show: async (id) => {
    const { data } = await api.read(`/clients/${id}`, {
      query: {
        include,
        fields,
      },
    });

    return convertItem(data);
  },
  /**
   * createCrewUser
   * @param attributes
   * @returns {Promise}
   */
  create: async (attributes) => {
    const { hidden_tags, shown_tags, new_seafarer_tags, ...restAttributes } =
      attributes;
    const resp = await api.create({
      type: "clients",
      attributes: restAttributes,
    });
    const { id } = resp.data.data;
    await api.update({
      id,
      type: "clients",
      action: "update_hidden_tags",
      attributes: { hidden_tags },
    });
    await api.update({
      id,
      type: "clients",
      action: "update_shown_tags",
      attributes: { shown_tags },
    });
    await api.update({
      id,
      type: "clients",
      action: "update_new_seafarer_tags",
      attributes: { new_seafarer_tags },
    });
    return resp;
  },
  /**
   * updateCrewUser
   * @param id
   * @param attributes
   * @returns {Promise}
   */
  update: async (id, attributes) => {
    const {
      hidden_tags,
      shown_tags,
      new_seafarer_tags,
      clientId,
      ...restAttributes
    } = attributes;
    const resp = api.update({
      id: id || clientId,
      type: "clients",
      attributes: restAttributes,
    });
    await api.update({
      id: id || clientId,
      type: "clients",
      action: "update_hidden_tags",
      attributes: { hidden_tags },
    });
    await api.update({
      id: id || clientId,
      type: "clients",
      action: "update_shown_tags",
      attributes: { shown_tags },
    });
    await api.update({
      id: id || clientId,
      type: "clients",
      action: "update_new_seafarer_tags",
      attributes: { new_seafarer_tags },
    });
    return resp;
  },
  addRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/clients/${id}/relationships/${relation}`, {
      method: "POST",
      body: JSON.stringify({ data }),
    });
  },

  deleteRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/clients/${id}/relationships/${relation}`, {
      method: "DELETE",
      body: JSON.stringify({ data }),
    });
  },

  updateRelation: async (id, relation, data) => {
    await api.apiRequest(`/api/clients/${id}/relationships/${relation}`, {
      method: "PATCH",
      body: JSON.stringify({ data }),
    });
  },
  getVesselOwners: (id) => api.read(`/clients/${id}/vessel_owners`),
  /**
   * deleteCrewUser
   * @returns {Promise<*>}
   */
  delete: (id) => api.destroy({ id, type: "clients" }),
};
