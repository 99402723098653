import { call, put, takeEvery, select } from 'redux-saga/effects';
import { actions, constants } from './redux';
import Api from '../../../../utils/Api';

export function* fetchTypes() {
  try {
    yield put(actions.setLoading(true));

    const data = yield call(Api.crewisorPositionCommentTypes.types);
    yield put(actions.setTypes(data));
  } catch (error) {
    console.error(error);
    // yield put(actions.setSeaServiceErrors(error));
  }
}

export function* fetchPositions() {
  try {
    yield put(actions.setLoading(true));

    const data = yield call(Api.crewisorPositionCommentTypes.positions);
    yield put(actions.setPositions(data));
  } catch (error) {
    console.error(error);
    // yield put(actions.setCommentsErrors(error));
  }
}

export function* fetchPermissions() {
  try {
    yield put(actions.setLoading(true));

    const data = yield call(Api.crewisorPositionCommentTypes.permissions);
    yield put(actions.setPermissions(data));
  } catch (e) {
    console.error(e);

    // yield put(actions.loadItemsError(e));
  }
}

export function* changePermission({ payload: { positionId, type, commentAction } }) {
  try {
    yield put(actions.setLoading(true));

    yield call(Api.crewisorPositionCommentTypes.changePermission, { positionId, type, commentAction });
    yield fetchPermissions();
    yield put(actions.setLoading(false));
  } catch (e) {
    console.error(e);

    // yield put(actions.loadItemsError(e));
  }
}

export default () => [
  takeEvery([
    constants.FETCH_TYPES,
  ], fetchTypes),
  takeEvery([
    constants.FETCH_POSITIONS,
  ], fetchPositions),
  takeEvery([
    constants.FETCH_PERMISSIONS,
  ], fetchPermissions),
  takeEvery([
    constants.HANDLE_CHANGE_PERMISSION,
  ], changePermission),
];
