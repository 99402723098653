import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';


const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

export const routeConfig = { path: '/owners/:id/:tab', exact: true };
export const constants = getConstantsByFeatureName('OWNER_DETAILS');
export const actions = getActionCreators(constants);

export const reducer = handleActions({
  ...getActionHandlers(constants),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, routeConfig)
      ? routeConfig
      : initialState
  ),
}, initialState);

export const selector = getSelectorByFeatureName('ownerDetails');
