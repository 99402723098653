import { DateTime, Duration } from 'luxon';
import humanizeDuration from 'humanize-duration';
import isDate from 'lodash/isDate';

import { parse } from 'date-fns';


export const tryParseDate = (dateString) => {
  if (isDate(dateString)) return dateString;
  if (!dateString) return null;

  if (typeof (dateString) === 'object') {
    return new Date(dateString.value);
  }

  if (dateString.includes('T')) {
    return new Date(dateString);
  }

  try {
    return parse(dateString, 'dd.MM.yyyy', new Date());
  } catch (e) {
    return null;
  }
};


export const convertDate = (date) => {
  const secondsInDay = 24 * 60 * 60;
  const pDate = tryParseDate(date);
  if (!pDate) return null;

  const dn = pDate.valueOf() / 1000 - 7200;
  const number = (dn + 5333472000) / secondsInDay;
  return Math.round(number);
};




/**
 * dateToFormat
 * @param date
 * @param format
 * @returns {string}
 */
export const dateToFormat = (date, format = 'dd.MM.yyyy') => date ? (
  DateTime
    .fromJSDate(new Date(date))
    .toFormat(format)
) : date;

/**
 * dateStringToDateTime
 * @param {String} date
 * @return {string}
 */
export const dateStringToDateTime = (date) => dateToFormat(date, 'dd.MM.yyyy HH:mm');

/**
 * getDurationFromSeconds
 * @param {String} date
 * @returns {String}
 */
export const getDurationInYearByDate = (date) => {
  try {
    if (!date) return '';

    const [d, m, y] = date.split('.');
    const diffBetweenDateAndNowInMillis = new Date().getTime() - new Date(`${y}-${m}-${d}`).getTime();
    const durationInYears = Duration
      .fromMillis(diffBetweenDateAndNowInMillis, {})
      .as('years');
    const fullYears = parseInt(durationInYears, 10);

    return `${fullYears} y.o.`;
  } catch (error) {
    return '???';
  }
};
// next code for 3m24d format, I don't like this way
export const shortEnglishHumanizer = humanizeDuration
  .humanizer({
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'm',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'min',
        s: () => 'sec',
        ms: () => 'ms',
      },
    },
  });

/**
 * getDurationFromSeconds
 * @param seconds
 * @returns {string}
 */
export const getDurationFromSeconds = (seconds) => {
  const durationInMilliSeconds = Duration.fromObject({ seconds }).as('milliseconds');

  return humanizeDuration(
    durationInMilliSeconds,
    { units: ['mo', 'd'], maxDecimalPoints: 0, conjunction: ' and ' },
  );

  // next code for 3m24d format, I don't like this way
  //   { units: ['mo', 'd'], maxDecimalPoints: 0, spacer: '', conjunction: '_' },
  // ).replace(/m_/g, 'm'); // because, "conjunction: ''" doesn't work (
};

/**
 * getDurationFromSeconds
 * @param {String|Date} start
 * @param {String|Date} end
 * @returns {string}
 */
export const getDurationByDates = (start, end) => getDurationFromSeconds(
  new Date(end).getTime() / 1000 - new Date(start).getTime() / 1000,
);

export default dateToFormat;
