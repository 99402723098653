import { connect } from "react-redux";
import { actions, selector } from "./redux";
import {
  addItem as createDialog,
  deleteItem as deleteDialog,
} from "../dialog-manager/actions";

const mapDispatchToProps = {
  loadItemsRequest: actions.loadItemsRequest,
  onChangePageNumber: actions.changePageNumber,
  clearAllNotifications: () =>
    createDialog({
      type: "confirm",
      text: "Are you sure you want to clear all notifications?",
      mapDispatchToProps: (dispatch) => ({
        onYes: () => {
          dispatch(actions.clearAllNotifications());
        },
        onNo: () => dispatch(deleteDialog()),
      }),
    }),
  clearNotification: actions.clearNotification,
  showPopUp: actions.showPopUp,
};

export default connect(selector, mapDispatchToProps);
