import { handleActions, createAction } from 'redux-actions';

// @OWNER_WAGE

const initialState = {
  // wageScale
  wageScale: null,
  wageScaleLoading: false,
  wageScaleError: null,

  // wageBonuses
  wageBonuses: null,
  wageBonusesLoading: false,
  wageBonusesError: null,

  // wageScaleTypes
  wageScaleTypes: null,
  wageScaleTypesLoading: false,
  wageScaleTypesError: null,
  wageType: 'normal',

  // wageScaleNationality
  wageScaleNationalities: null,
  wageScaleNationalitiesLoading: false,
  wageScaleNationalitiesError: null,
  wageNationality: null,

  // nationalityScale
  nationalityScaleLoading: false,
  nationalityScaleError: null,
}

export const constants = {
  LOAD_WAGE_SCALE_REQUEST: '@OWNER_WAGE/LOAD_WAGE_SCALE_REQUEST',
  LOAD_WAGE_SCALE_SUCCESS: '@OWNER_WAGE/LOAD_WAGE_SCALE_SUCCESS',
  LOAD_WAGE_SCALE_ERROR: '@OWNER_WAGE/LOAD_WAGE_SCALE_ERROR',
  UPDATE_WAGE_SCALE_REQUEST: '@OWNER_WAGE/UPDATE_WAGE_SCALE_REQUEST',

  LOAD_WAGE_BONUSES_REQUEST: '@OWNER_WAGE/LOAD_WAGE_BONUSES_REQUEST',
  LOAD_WAGE_BONUSES_SUCCESS: '@OWNER_WAGE/LOAD_WAGE_BONUSES_SUCCESS',
  LOAD_WAGE_BONUSES_ERROR: '@OWNER_WAGE/LOAD_WAGE_BONUSES_ERROR',
  UPDATE_WAGE_BONUSES_REQUEST: '@OWNER_WAGE/UPDATE_WAGE_BONUSES_REQUEST',

  LOAD_WAGE_SCALE_TYPES_REQUEST: '@OWNER_WAGE/LOAD_WAGE_SCALE_TYPES_REQUEST',
  LOAD_WAGE_SCALE_TYPES_SUCCESS: '@OWNER_WAGE/LOAD_WAGE_SCALE_TYPES_SUCCESS',
  LOAD_WAGE_SCALE_TYPES_ERROR: '@OWNER_WAGE/LOAD_WAGE_SCALE_TYPES_ERROR',

  LOAD_WAGE_NATIONALITY_REQUEST: '@OWNER_WAGE/LOAD_WAGE_NATIONALITY_REQUEST',
  LOAD_WAGE_NATIONALITY_SUCCESS: '@OWNER_WAGE/LOAD_WAGE_NATIONALITY_SUCCESS',
  LOAD_WAGE_NATIONALITY_ERROR: '@OWNER_WAGE/LOAD_WAGE_NATIONALITY_ERROR',

  WAGE_TYPE_CHANGE: '@OWNER_WAGE/WAGE_TYPE_CHANGE',
  WAGE_NATIONALITY_CHANGE: '@OWNER_WAGE/WAGE_NATIONALITY_CHANGE',

  CREATE_NATIONALITY_SCALE_REQUEST: '@OWNER_WAGE/CREATE_NATIONALITY_SCALE_REQUEST',
  CREATE_NATIONALITY_SCALE_SUCCESS: '@OWNER_WAGE/CREATE_NATIONALITY_SCALE_SUCCESS',
  CREATE_NATIONALITY_SCALE_ERROR: '@OWNER_WAGE/CREATE_NATIONALITY_SCALE_ERROR',

};

export const actions = {
  loadWageScaleRequest: createAction(constants.LOAD_WAGE_SCALE_REQUEST),
  loadWageScaleSuccess: createAction(constants.LOAD_WAGE_SCALE_SUCCESS),
  loadWageScaleError: createAction(constants.LOAD_WAGE_SCALE_ERROR),
  updateWageScaleRequest: createAction(constants.UPDATE_WAGE_SCALE_REQUEST),

  loadWageBonusesRequest: createAction(constants.LOAD_WAGE_BONUSES_REQUEST),
  loadWageBonusesSuccess: createAction(constants.LOAD_WAGE_BONUSES_SUCCESS),
  loadWageBonusesError: createAction(constants.LOAD_WAGE_BONUSES_ERROR),
  updateWageBonusesRequest: createAction(constants.UPDATE_WAGE_BONUSES_REQUEST),

  loadWageScaleTypesRequest: createAction(constants.LOAD_WAGE_SCALE_TYPES_REQUEST),
  loadWageScaleTypesSuccess: createAction(constants.LOAD_WAGE_SCALE_TYPES_SUCCESS),
  loadWageScaleTypesError: createAction(constants.LOAD_WAGE_SCALE_TYPES_ERROR),

  loadWageNationalitiesRequest: createAction(constants.LOAD_WAGE_NATIONALITY_REQUEST),
  loadWageNationalitiesSuccess: createAction(constants.LOAD_WAGE_NATIONALITY_SUCCESS),
  loadWageNationalitiesError: createAction(constants.LOAD_WAGE_NATIONALITY_ERROR),

  wageTypeChange: createAction(constants.WAGE_TYPE_CHANGE),
  wageNationalityChange: createAction(constants.WAGE_NATIONALITY_CHANGE),

  createNationalityScaleRequest: createAction(constants.CREATE_NATIONALITY_SCALE_REQUEST),
  createNationalityScaleSuccess: createAction(constants.CREATE_NATIONALITY_SCALE_SUCCESS),
  createNationalityScaleError: createAction(constants.CREATE_NATIONALITY_SCALE_ERROR),
};

const actionHandlers = {
  [constants.LOAD_WAGE_SCALE_REQUEST]: (state) => ({ ...state, wageScaleLoading: true, wageScale: null }),
  [constants.LOAD_WAGE_SCALE_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    wageScale: data,
    wageScaleLoading: false,
  }),
  [constants.LOAD_WAGE_SCALE_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    wageScaleError: error,
    wageScaleLoading: false,
  }),

  [constants.LOAD_WAGE_BONUSES_REQUEST]: (state) => ({ ...state, wageBonusesLoading: true, wageBonuses: null }),
  [constants.LOAD_WAGE_BONUSES_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    wageBonuses: data,
    wageBonusesLoading: false,
  }),
  [constants.LOAD_WAGE_BONUSES_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    wageBonusesError: error,
    wageBonusesLoading: false,
  }),

  [constants.LOAD_WAGE_SCALE_TYPES_REQUEST]: (state) => ({ ...state, wageScaleTypesLoading: true }),
  [constants.LOAD_WAGE_SCALE_TYPES_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    wageScaleTypes: data,
    wageScaleTypesLoading: false,
  }),
  [constants.LOAD_WAGE_SCALE_TYPES_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    wageScaleTypesError: error,
    wageScaleTypesLoading: false,
  }),

  [constants.WAGE_TYPE_CHANGE]: (state, { payload: wageType }) => ({
    ...state,
    wageType,
  }),

  [constants.LOAD_WAGE_NATIONALITY_REQUEST]: (state) => ({ ...state, wageScaleNationalitiesLoading: true }),
  [constants.LOAD_WAGE_NATIONALITY_SUCCESS]: (state, { payload: { data } }) => ({
    ...state,
    wageScaleNationalities: data,
    wageScaleNationalitiesLoading: false,
  }),
  [constants.LOAD_WAGE_NATIONALITY_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    wageScaleNationalitiesError: error,
    wageScaleNationalitiesLoading: false,
  }),

  [constants.WAGE_NATIONALITY_CHANGE]: (state, { payload: wageNationality }) => ({
    ...state,
    wageNationality,
  }),

  [constants.CREATE_NATIONALITY_SCALE_REQUEST]: (state) => ({ ...state, nationalityScaleLoading: true }),
  [constants.CREATE_NATIONALITY_SCALE_SUCCESS]: (state) => ({
    ...state,
    nationalityScaleLoading: false,
  }),
  [constants.CREATE_NATIONALITY_SCALE_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    nationalityScaleError: error,
    nationalityScaleLoading: false,
  }),

};
export const reducer = handleActions(actionHandlers, initialState);

export const selector = (state) => ({
  wageScale: state.ownerDetailsWage.wageScale,
  wageScaleLoading: state.ownerDetailsWage.wageScaleLoading,
  wageScaleError: state.ownerDetailsWage.wageScaleError,

  wageBonuses: state.ownerDetailsWage.wageBonuses,
  wageBonusesLoading: state.ownerDetailsWage.wageBonusesLoading,
  wageBonusesError: state.ownerDetailsWage.wageBonusesError,

  wageScaleTypes: state.ownerDetailsWage.wageScaleTypes,
  wageScaleTypesLoading: state.ownerDetailsWage.wageScaleTypesLoading,
  wageScaleTypesError: state.ownerDetailsWage.wageScaleTypesError,

  wageScaleNationalities: state.ownerDetailsWage.wageScaleNationalities,
  wageScaleNationalitiesLoading: state.ownerDetailsWage.wageScaleNationalitiesLoading,
  wageScaleNationalitiesError: state.ownerDetailsWage.wageScaleNationalitiesError,

  wageType: state.ownerDetailsWage.wageType,
  wageNationality: state.ownerDetailsWage.wageNationality,

  nationalityScaleLoading: state.ownerDetailsWage.nationalityScaleLoading,
  nationalityScaleError: state.ownerDetailsWage.nationalityScaleError,
});
