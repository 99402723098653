import * as api from './adapter';

export default {
  /**
   * get ports
   * {Object} featureState
   * @returns {Promise<*>}
   */
  index: async ({ search, pagination }) => {
    const responce = await api.read('ports', {
      query: {
        page: { size: pagination.itemsPerPage, number: pagination.current },
        filter: { name: search },
      },
    });

    return {
      data: {
        data: responce.data.data,
        meta: { count: 19, page_count: 2 },
        links: {
          first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
          next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
          last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        },
      },
    };
  },
};
