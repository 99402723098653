import { call, put, select, takeEvery, debounce } from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import { LOCATION_CHANGE, push, getLocation } from 'connected-react-router';
import { actions, constants, selector } from './redux';
import Api from '../../utils/Api';
import {addError, addSuccess} from "../notifications/actions";
import {stopSubmit} from "redux-form";
import {routeConfig} from "../vessel-details/redux";



export function* fetchItem() {
  try {
    const { pathname } = yield select(getLocation);
    const { params: { id } } = matchPath(pathname, routeConfig);
    const featureState = yield select(selector);
    const data = yield call(Api.vessels.show, id, featureState);

    yield put(actions.setItem(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemError(e));
  }
}


export function* fetchItems() {
  try {
    const featureState = yield select(selector);
    const { data: json } = yield call(Api.vessels.index, featureState);

    yield put(actions.addItems(json));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

function* fetchAfterSearch() {
  yield put(actions.applySearch()); // reset current pagination page

  yield put(actions.loadItemsRequest());
}

function* fetchItemsPageInit({ payload: { isFirstRendering, location: { pathname } } }) {
  const didOpenPage = (
    matchPath(pathname, { exact: true, path: '/vessels' })
    || (isFirstRendering && matchPath(pathname, { path: '/vessels/:id?/(new|edit|delete)' }))
  );

  if (didOpenPage) yield put(actions.loadItemsRequest());
}

function* createItem({ payload }) {
  try {
    const { data: { data } } = yield call(Api.vessels.create, payload);
    yield put(push(`/vessels/${data.id}/details`));

    yield put(addSuccess('Vessel created successfully'));
  } catch (e) {
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit('vessels', e.errorByField));
  }
}

function* updateItem({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const { params: { id } } = matchPath(pathname, { path: "/vessels/:id/:tab?/edit" });

    const { data: { data } } = yield call(Api.vessels.update, id, payload);
    yield put(push(`/vessels/${data.id}/details`));

    yield put(addSuccess('Vessel updated successfully'));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));

    if (e.errorByField) yield put(stopSubmit('vessels', e.errorByField));
  }
}

function* addImosToParse({ payload: { imos, onlyNew, cb } }) {
  try {
    const currentUser = yield select((s) => s.auth.data);
    yield call(Api.equasisVesselSearch.addImosToParse, {imos, email: currentUser.equasis_email, only_new: onlyNew});
    if (cb) yield call(cb);
    yield put(addSuccess('IMOs added to parse successfully'));
  } catch (e) {
    console.error(e);
    yield put(addError('Error while adding IMOs to parse'));
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.SORT_ITEMS,
      constants.APPLY_FILTER,
      constants.CHANGE_PAGE_NUMBER,
      constants.CHANGE_PAGE_ITEMS_COUNT,
      constants.LOAD_ITEMS_REQUEST,
    ], fetchItems),
    takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
    // takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.ADD_IMOS_TO_PARSE, addImosToParse),

  ];
}
