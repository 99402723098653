import { call, put, takeEvery, select } from 'redux-saga/effects';
import { LOCATION_CHANGE, createMatchSelector, push } from 'connected-react-router';
import { actions, constants } from './redux';
import selector from './selector';
import { didDocumentLocationChange } from '../../utils/router';
import Api from '../../utils/Api';
import { addSuccess, addError } from '../notifications/actions';

const pathConfig = { path: '/users/:id/:tab', exact: true };


export function* fetchItem({ payload }) {
  try {
    const data = yield call(Api.users.show, payload);

    yield put(actions.addLoadedItem(data));
  } catch (e) {
    yield put(actions.loadItemError(e));
    console.error(e);
  }
}

export function* updateItem({ payload }) {
  try {
    const match = yield select(createMatchSelector(pathConfig));

    const data = yield call(Api.users.update, match.params.id, payload);

    yield put(actions.addLoadedItem(data));
    yield put(addSuccess('The user updated successfully'));
    yield put({ type: '@USER_DETAILS_PAGE/LOAD_ITEM_REQUEST', payload: match.params.id });
  } catch (e) {
    yield put(addError('Update user error'));

    console.error(e);
  }
}

export function* fetchItemPageInit(action) {
  const { didLoad } = yield select(selector);
  const match = didDocumentLocationChange(action, { path: '/users/:id/:tab', exact: true });

  if (match && !didLoad) {
    yield put(actions.loadItemRequest(match.params.id));
  }
}
/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEM_REQUEST, fetchItem),
    takeEvery(LOCATION_CHANGE, fetchItemPageInit),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
  ];
}
