import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getLocation, LOCATION_CHANGE, push } from 'connected-react-router';
import { stopSubmit } from 'redux-form';
import { matchPath } from 'react-router';
import { addSuccess } from '../notifications/actions';
import { deleteItem as deleteDialog } from '../dialog-manager/actions';
import { addItems } from '../crew-list/actions';
import { crewListModes } from '../crew-list/utils';
import { actions, constants, selector } from './redux';
import { constants as timeplansConstants } from '../timeplans/redux';
import Api from '../../utils/Api';
import { getCrewListTableItems } from './Form/selector';
import { didDocumentLocationChange } from '../../utils/router';

function* loadItem({ payload: { id, mode } }) {
  try {
    const { timeplansById, ...crewChange } = yield call(Api.crewChange.show, id);

    const crewListActionPayload = {
      mode,
      data: (mode === crewListModes.STORE || mode === crewListModes.DISPLAY)
        ? crewChange.crewList
        : timeplansById,
    };
    yield put(actions.addLoadedItem(crewChange));
    yield put(addItems(crewListActionPayload));
  } catch (e) {
    console.error(e);
    yield put(actions.loadItemError(e));
  }
}

function* createItem({ payload }) {
  try {
    const crewList = yield select(getCrewListTableItems);
    const crewlists = crewList.map((item) => ({
      sign_on_id: item.on_sign_id || null,
      sign_off_id: item.off_sign_id || null,
    }));
    const crewChange = yield call(Api.crewChange.create, payload, crewlists);

    yield put(push('/'));
    yield put(actions.addCreatedItem(crewChange));
    yield put(addSuccess('The crew change created successfully'));
  } catch (e) {
    console.error(e);
    yield put(actions.createItemError(e));

    if (e.errorByField) yield put(stopSubmit('crewChange', e.errorByField));
  }
}

function* updateItem({ payload }) {
  try {
    const { pathname } = yield select(getLocation);
    const match = matchPath(pathname, { path: '/timeplans/crew-rotation/crew-change/:id/edit' });

    const crewChange = yield call(Api.crewChange.update, match.params.id, payload);

    yield put(push('/'));
    yield put(actions.updateItem(crewChange));
    yield put(addSuccess('The crew change updated successfully'));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));

    if (e.errorByField) yield put(stopSubmit('crewChange', e.errorByField));
  }
}

function* deleteItem({ payload: id }) {
  try {
    yield call(Api.crewChange.delete, id);

    yield put(deleteDialog());
    yield put(addSuccess('Crew change deleted successfully'));
    yield put(actions.deleteItem(id));
  } catch (e) {
    console.error(e);

    yield put(actions.deleteItemError(e));
  }
}

export function* fetchItemOnPageInit(action) {
  const match = (
    didDocumentLocationChange(action, '/timeplans/crew-rotation/crew-change/:id/edit')
    || didDocumentLocationChange(action, '/crew-changes/:id/edit')
    || didDocumentLocationChange(action, '/crew-changes/:id/details')
  );

  if (match) {
    yield put(actions.loadItemRequest({
      id: match.params.id,
      mode: crewListModes.STORE,
    }));
  }
}

function* checkDoWeNeedToReLoadCrewChange({ payload: t }) {
  try {
    const featureState = yield select(selector);
    const listFeatureState = yield select((s) => s.crewList);

    if (
      listFeatureState.mode === crewListModes.DISPLAY
      && [t.crew_change_sign_off_id, t.crew_change_sign_on_id].includes(Number(featureState.data.id))
    ) {
      yield put(actions.loadItemRequest({
        id: featureState.data.id,
        mode: crewListModes.DISPLAY,
      }));
    }
  } catch (e) {
    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEM_REQUEST, loadItem),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(timeplansConstants.ITEM_UPDATED_SUCCESSFULLY, checkDoWeNeedToReLoadCrewChange),
    takeEvery(LOCATION_CHANGE, fetchItemOnPageInit),
  ];
}
