import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions, constants } from "./index";
import authSelector from "../authorization/selector";
import Api from "../../utils/Api";
import { constants as avConstants } from "../assigned-vessels";

export function* fetchItems() {
  try {
    const authState = yield select(authSelector);
    const id = authState.data
      ? authState.data.id
      : localStorage.getItem("clientId");
    const data = yield call(Api.crewUser.getVesselOwners, id);

    yield put(actions.addItems(data.data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(constants.LOAD_ITEMS_REQUEST, fetchItems),
    takeEvery(avConstants.ITEMS_LOADED_SUCCESSFULLY, fetchItems),
  ];
}
