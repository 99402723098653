import {
  startLoading,
  startPushing,
  pushingError,
  loadingError,
  setItemsAsData,
  setItemAsData,
  addItemToData,
  updateItem,
  deleteItem,
  changePage,
  changeItemsCountPerPage,
  changeFilter,
  removeFilter,
  clearFilter,
  applyNewRequestParams,
  cancelFilter,
  openFilter,
  closeFilter,
  changeSearch,
  clearSearch,
  sortItems,
  changeState,
  clearState,
  updateStateByParams,
  sortWithDirection,
} from './commonActionHandlers';
import { FeatureCommonConstants, FeatureCommonActionHandlers } from './types';

/**
 * getActionHandlers
 * That function created for writing code using DRY principe
 * You will have action handlers only for action types what you passed
 * -----------------------------------------------------------------------------------
 * You can customize actionHandlers
 * const customizedActionHandlers = {
 *   ...getActionHandlers(yourFeatureTypes)
 *   [types.YOUR_]: yourCustomActionHandler
 * }
 * -----------------------------------------------------------------------------------
 * @param {Object} types
 * @returns {Object} ActionHandlers for handleActions function (redux-actions library)
 */
const getActionHandlers = (types: FeatureCommonConstants) => {
  const actionHandlers: FeatureCommonActionHandlers = {};

  if(!types) return actionHandlers;

  if (types.LOAD_ITEMS_REQUEST) actionHandlers[types.LOAD_ITEMS_REQUEST] = startLoading;
  if (types.ITEMS_LOADED_SUCCESSFULLY) actionHandlers[types.ITEMS_LOADED_SUCCESSFULLY] = setItemsAsData;
  if (types.LOAD_ITEMS_ERROR) actionHandlers[types.LOAD_ITEMS_ERROR] = loadingError;

  if (types.LOAD_ITEM_REQUEST) actionHandlers[types.LOAD_ITEM_REQUEST] = startLoading;
  if (types.ADD_LOADED_ITEM) actionHandlers[types.ADD_LOADED_ITEM] = setItemAsData;
  if (types.LOAD_ITEM_ERROR) actionHandlers[types.LOAD_ITEM_ERROR] = loadingError;

  if (types.CREATE_ITEM_REQUEST) actionHandlers[types.CREATE_ITEM_REQUEST] = startPushing;
  if (types.ADD_CREATED_ITEM) actionHandlers[types.ADD_CREATED_ITEM] = addItemToData;
  if (types.CREATE_ITEM_ERROR) actionHandlers[types.CREATE_ITEM_ERROR] = pushingError;

  if (types.UPDATE_ITEM_REQUEST) actionHandlers[types.UPDATE_ITEM_REQUEST] = startPushing;
  if (types.ITEM_UPDATED_SUCCESSFULLY) actionHandlers[types.ITEM_UPDATED_SUCCESSFULLY] = updateItem;
  if (types.UPDATE_ITEM_ERROR) actionHandlers[types.UPDATE_ITEM_ERROR] = pushingError;

  if (types.DELETE_ITEM_REQUEST) actionHandlers[types.DELETE_ITEM_REQUEST] = startPushing;
  if (types.ITEM_DELETED_SUCCESSFULLY) actionHandlers[types.ITEM_DELETED_SUCCESSFULLY] = deleteItem;
  if (types.DELETE_ITEM_ERROR) actionHandlers[types.DELETE_ITEM_ERROR] = pushingError;

  if (types.CHANGE_PAGE_NUMBER) actionHandlers[types.CHANGE_PAGE_NUMBER] = changePage;
  if (types.CHANGE_PAGE_ITEMS_COUNT) actionHandlers[types.CHANGE_PAGE_ITEMS_COUNT] = changeItemsCountPerPage;

  if (types.CHANGE_FILTER) actionHandlers[types.CHANGE_FILTER] = changeFilter;
  if (types.REMOVE_FILTER) actionHandlers[types.REMOVE_FILTER] = removeFilter;
  if (types.CLEAR_FILTER) actionHandlers[types.CLEAR_FILTER] = clearFilter;
  if (types.APPLY_FILTER) actionHandlers[types.APPLY_FILTER] = applyNewRequestParams;

  if (types.CHANGE_SEARCH) actionHandlers[types.CHANGE_SEARCH] = changeSearch;
  if (types.CLEAR_SEARCH) actionHandlers[types.CLEAR_SEARCH] = clearSearch;
  if (types.APPLY_SEARCH) actionHandlers[types.APPLY_SEARCH] = applyNewRequestParams;
  if (types.CANCEL_FILTER) actionHandlers[types.CANCEL_FILTER] = cancelFilter;
  if (types.OPEN_FILTER) actionHandlers[types.OPEN_FILTER] = openFilter;
  if (types.CLOSE_FILTER) actionHandlers[types.CLOSE_FILTER] = closeFilter;

  if (types.SORT_ITEMS) actionHandlers[types.SORT_ITEMS] = sortItems;
  if (types.SORT_WITH_DIRECTION) actionHandlers[types.SORT_WITH_DIRECTION] = sortWithDirection;

  if (types.CHANGE_STATE) actionHandlers[types.CHANGE_STATE] = changeState;
  if (types.CLEAR_STATE) actionHandlers[types.CLEAR_STATE] = clearState;

  if (types.UPDATE_STATE_BY_PARAMS) actionHandlers[types.UPDATE_STATE_BY_PARAMS] = updateStateByParams;

  return actionHandlers;
};

export default getActionHandlers;
