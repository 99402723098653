import { handleActions } from 'redux-actions';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getCRUDSagas from '../../utils/redux/getCRUDSagas';
import Api from '../../utils/Api';
import extendsOptions from './extends'

const constantsPrefix = 'USER-DOCUMENTS';
const statePropName = 'userDocuments';
const formStatePropName = 'userDocuments';
const mainPageUrl = '/users/:userId/documents';

const baseConstants = getConstantsByFeatureName(constantsPrefix)
export const constants = extendsOptions.extendedConstants(baseConstants);

const baseActions = getActionCreators(constants);
export const actions = extendsOptions.extendedActions(baseActions, constants);

const initialState = extendsOptions.extendedInitialState;

const baseActionHandlers = getActionHandlers(constants);
const actionHandlers = extendsOptions.extendedActionHandlers(baseActionHandlers, constants);

export const reducer = handleActions(actionHandlers, initialState);

const baseSelectors = getSelectorByFeatureName(statePropName);
export const selector = extendsOptions.extendedSelectors(baseSelectors);

const baseSagas = getCRUDSagas(
  mainPageUrl,
  Api.applicantDocuments,
  actions,
  constants,
  formStatePropName,
  statePropName,
  { itemName: 'Document' },
);

const newSagas = extendsOptions.newSagas(baseSagas, constants, actions);

export const sagas = extendsOptions.extendedSagas(baseSagas, newSagas);
