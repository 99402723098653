import { handleActions, createAction } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import getConstantsByFeatureName from '../../utils/redux/getConstantsByFeatureName';
import getSelectorByFeatureName from '../../utils/redux/getSelectorByFeatureName';
import getActionCreators from '../../utils/redux/getActionCreators';
import getActionHandlers from '../../utils/redux/getActionHandlers';

export const routeConfig = { path: '/crew-users/:id/details' };

const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

export const constants = {
  ...getConstantsByFeatureName('CREW_USER_DETAILS'),
  ASSIGN_AS_COORDINATOR: '@CREW_USER_DETAILS/ASSIGN_AS_COORDINATOR',
  UNASSIGN_AS_COORDINATOR: '@CREW_USER_DETAILS/UNASSIGN_AS_COORDINATOR',
  ASSIGN_AS_RECRUITER: '@CREW_USER_DETAILS/ASSIGN_AS_RECRUITER',
  UNASSIGN_AS_RECRUITER: '@CREW_USER_DETAILS/UNASSIGN_AS_RECRUITER',
};

export const actions = {
  ...getActionCreators(constants),
  assignAsCoordinator: createAction(constants.ASSIGN_AS_COORDINATOR),
  unassignAsCoordinator: createAction(constants.UNASSIGN_AS_COORDINATOR),
  assignAsRecruiter: createAction(constants.ASSIGN_AS_RECRUITER),
  unassignAsRecruiter: createAction(constants.UNASSIGN_AS_RECRUITER),
};

export const selector = getSelectorByFeatureName('crewUserDetails');

export const reducer = handleActions({
  ...getActionHandlers(constants),
  [LOCATION_CHANGE]: (state, { payload: { location: { pathname } } }) => (
    matchPath(pathname, routeConfig)
      ? routeConfig
      : initialState
  ),
}, initialState);
