import { call, put, takeEvery, select, debounce } from 'redux-saga/effects';
import { constants as timeplanConstants } from '../timeplans/redux';
import { actions, constants } from './redux';
import { actions as crewingStatusesActions } from '../crewing-statuses/redux';
import selector from './selector';
import Api from '../../utils/Api';

export function* fetchItems() {
  try {
    const featureState = yield select(selector);
    const fields = { users: 'crewing_applicant_id,name,surname,middle,status,email,birthday,address,mobile_phone,phonehome,readiness_date,avatar,last_expirience,role,hot,valid_states' };
    const { data } = yield call(Api.users.index, { ...featureState, fields });

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

export function* fetchItemsOnOpenSection({ payload: { field, value } }) {
  const { didLoad } = yield select(selector);

  if (field === 'isSelectNewUserMode' && value) {
    const timeplanValues = yield select((s) => s.form.timeplans.values);
    const { originRoleId, originRoleName } = timeplanValues;

    yield put(actions.changeFilter('role', { value: originRoleId, label: originRoleName }));
    yield put(actions.changeSearch(''));
    yield put(actions.loadItemsRequest());
  }

  if (field === 'isUserSectionOpen' && value && !didLoad) {
    yield put(actions.loadItemsRequest());
  }
}

export function* fetchItemsOnSetIsOpenState({ payload: { state } }) {
  if (state && state.isUserSectionOpen) {
    yield put(actions.loadItemsRequest());
  }
}

export function* fetchAfterSearch() {
  yield put(actions.applySearch()); // reset current pagination page

  yield call(fetchItems);
}

function* loadFilterDeps() {
  const { didLoad } = yield select((s) => s.crewingStatuses);

  if (!didLoad) yield put(crewingStatusesActions.loadItemsRequest());
}
/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery([
      constants.APPLY_FILTER,
      constants.CHANGE_PAGE_NUMBER,
      constants.CHANGE_PAGE_ITEMS_COUNT,
      constants.LOAD_ITEMS_REQUEST,
    ], fetchItems),

    takeEvery(timeplanConstants.CHANGE_STATE, fetchItemsOnOpenSection),
    takeEvery(timeplanConstants.UPDATE_STATE_BY_PARAMS, fetchItemsOnSetIsOpenState),
    debounce(700, constants.CHANGE_SEARCH, fetchAfterSearch),
    takeEvery(timeplanConstants.ITEMS_LOADED_SUCCESSFULLY, loadFilterDeps),
  ];
}
