import * as types from './constants';
import getPermissions from '../../utils/permissions';

/**
 * @constant
 * @name initialState
 * @description The default state of the reducer
 * @default
 * @property {null} data - holds the data about items
 * @property {boolean} isLoading - shows if items are being loaded or not
 * @property {boolean} isPushing - shows if items are being pushed or not
 * @property {null} error - holds any error messages that occur
 */
const initialState = {
  data: null,
  isLoading: false,
  isPushing: false,
  error: null,
};

/**
 * @description The main reducer function for handling all actions related to auth
 * @param {Object} state - current state of the reducer
 * @param {Object} action - the current action being dispatched
 * @return {Object} - the new state
 */
export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_ITEM_REQUEST:
    case types.DELETE_ITEM_REQUEST:
      return { ...state, isPushing: true };

    case types.CREATE_ITEM_ERROR:
    case types.DELETE_ITEM_ERROR:
      return { ...state, isPushing: false, error: action.payload };

    case types.LOAD_ITEM_REQUEST:
      return { ...state, isLoading: true };

    case types.LOAD_ITEM_ERROR:
      return { ...state, isLoading: false, error: action.payload };

    case types.ADD_ITEM:
      return {
        data: { permissions: getPermissions(action.payload.role), ...action.payload },
        isPushing: false,
        error: null,
      };

    case types.ITEM_DELETED_SUCCESSFULLY:
      return initialState;

    default:
      return state;
  }
}
