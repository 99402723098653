import { sortBy } from 'lodash';

const convertItems = ({ data }) => ({
  data: sortBy(data, 'attributes.crewing_priority'),
  links: {
    first: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
    last: 'companies?page%5Bnumber%5D=1&page%5Bsize%5D=15&sort=name',
  },
  meta: { total: data.length },
});

export default convertItems;
