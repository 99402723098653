import {createAction, handleActions} from "redux-actions";
import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import Api from "../../../../utils/Api";
import { addItem as notify } from '../../../notifications/actions';

const initialState = {
  loading: false,
  error: null
};

const constants = {
  SET_LOADING: '@USER_DETAILS_PAGE/UPDATE_UC/SET_LOADING',
  UPDATE_UC_REQUEST: '@USER_DETAILS_PAGE/UPDATE_UC/UPDATE_UC_REQUEST',
  UPDATE_UC_SUCCESS: '@USER_DETAILS_PAGE/UPDATE_UC/UPDATE_UC_SUCCESS',
  UPDATE_UC_ERROR: '@USER_DETAILS_PAGE/UPDATE_UC/UPDATE_UC_ERROR',
};

const actions = {
  setLoading: createAction(constants.SET_LOADING),
  updateUCRequest: createAction(constants.UPDATE_UC_REQUEST),
  loadUserDetails: createAction('@USER_DETAILS_PAGE/LOAD_ITEM_REQUEST'),
  updateUCError: createAction(constants.UPDATE_UC_ERROR),
};

const actionHandlers = {
  [constants.SET_LOADING]: (state, { payload }) => ({
    ...state, loading: payload,
  }),
  [constants.UPDATE_UC_ERROR]: (state, { payload }) => ({
    ...state, error: payload,
  }),
};

const reducer = handleActions(actionHandlers, initialState);

const successMessage = (message) => ({ type: 'success', title: 'Success', message });

function* updateUCRequest({ payload }) {
  try {
    yield put(actions.setLoading(true));
    yield call(Api.users.updateFromUC, payload);
    yield put(actions.setLoading(false));
    yield put(actions.loadUserDetails(payload));
    yield put(notify(successMessage('User updated successfully from UkrCrewing')));
  } catch (e) {
    yield put(actions.setLoading(false));
    yield put(actions.updateUCError(e));
  }
}

function getFeatureSagas() {
  return [
    takeEvery(constants.UPDATE_UC_REQUEST, updateUCRequest),
  ];
}

const sagas = () => getFeatureSagas();

const selectors = (state) => ({
  ...state.forceUpdateFromUC
});

export { constants, actions, reducer, sagas, selectors };
