import { call, put, takeEvery, select, debounce } from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";
import { actions, constants, selector } from "./redux";
import queryString from "query-string";
import { actions as crewUserActions } from "../crew-users/redux";
import { actions as commentActions } from "../comments/redux";
import Api from "../../utils/Api";
import {
  addItem as notify,
  addSuccess,
  addError,
} from "../notifications/actions";

const basePageUrl = "/task-manager";

export function* fetchItems() {
  try {
    const featureState = yield select(selector);
    const { data } = yield call(Api.taskManager.index, featureState);

    yield put(actions.addItems(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* clearSubtasks() {
  try {
    yield put(actions.addSubtasks({}));
  } catch (e) {
    console.error(e);
  }
}

export function* fetchSubtasks({ payload: id }) {
  try {
    const { data } = yield call(Api.taskManager.subtasks, id);

    yield put(actions.addSubtasks(data));
  } catch (e) {
    console.error(e);

    yield put(actions.loadItemsError(e));
  }
}

export function* createItem({ payload: { form: payload, cb, errorCb } }) {
  try {
    const { data } = yield call(Api.taskManager.create, payload);

    yield put(actions.addCreatedItem(data));
    yield fetchItems();
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "The task created successfully",
      })
    );
    yield call(cb);
  } catch (e) {
    yield put(actions.createItemError(e));
    console.error(e);
    yield put(
      addError(
        e.apiErrors[0].detail || "Error",
        e.apiErrors[0].title || "Internal error"
      )
    );
    yield call(errorCb);
  }
}

function* updateItem({ payload: { form: payload, cb } }) {
  try {
    yield call(Api.taskManager.update, payload);

    yield fetchItems();
    yield put(addSuccess("Task updated successfully"));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));
  } finally {
    yield call(cb);
  }
}

function* updateStatus({ payload: { form, cb, interactionId = "" } }) {
  try {
    yield call(Api.taskManager.updateStatus, form);
    interactionId
      ? yield put(commentActions.fetchTasksForInteraction({ interactionId }))
      : yield fetchItems();

    yield put(addSuccess("Task status updated successfully"));
  } catch (e) {
    console.error(e);
    yield put(
      addError("Check all conditions to finish task", "Status update failed")
    );
  } finally {
    yield call(cb);
  }
}

function* fetchItemsPageInit({
  payload: {
    isFirstRendering,
    location: { pathname },
  },
}) {
  const didOpenPage =
    matchPath(pathname, { exact: true, path: `${basePageUrl}` }) ||
    // "|| isFirstRendering" for case when you open a form and reload the page
    // and we MUST show list items behind the form
    (isFirstRendering &&
      matchPath(pathname, { path: `${basePageUrl}/:id?/(new|edit|delete)` }));
  const urlParams = queryString.parse(window.location.search);
  const taskId = urlParams.task_id;

  if (didOpenPage) {
    yield handlePageOpenNewPage(taskId);
  }
}

function* handlePageOpenNewPage(taskId) {
  if (!taskId) yield put(actions.loadItemsRequest());
  yield put(crewUserActions.loadUsersForMentionsRequest());
}

function* createVacancyApply({ payload: { vacancyApplyForm, cb } }) {
  try {
    yield call(Api.vacancyApplies.create, vacancyApplyForm);

    yield put(actions.loadItemsRequest());

    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "Your Apply created successfully",
      })
    );
  } catch (e) {
    yield put(actions.createItemError(e));
    console.error(e);

    yield put(
      addError(
        e.apiErrors[0].detail || "Error",
        e.apiErrors[0].title || "Internal error"
      )
    );
  } finally {
    yield call(cb);
  }
}

function* updateVacancyApplyStatus({ payload }) {
  try {
    yield call(Api.vacancyApplies.updateStatus, payload);

    yield fetchItems();

    yield put(addSuccess("Status updated successfully"));
  } catch (e) {
    console.error(e);
    yield put(actions.updateItemError(e));

    console.error(e);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [
        constants.APPLY_FILTER,
        constants.CHANGE_PAGE_NUMBER,
        constants.LOAD_ITEMS_REQUEST,
        constants.SORT_WITH_DIRECTION,
      ],
      fetchItems
    ),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(LOCATION_CHANGE, fetchItemsPageInit),
    takeEvery(constants.UPDATE_ITEM_REQUEST, updateItem),
    takeEvery(constants.UPDATE_STATUS, updateStatus),
    debounce(50, constants.FETCH_SUBTASKS, clearSubtasks),
    debounce(250, constants.FETCH_SUBTASKS, fetchSubtasks),
    takeEvery(constants.CREATE_VACANCY_APPLY, createVacancyApply),
    takeEvery(constants.UPDATE_APPLY_STATUS, updateVacancyApplyStatus),
  ];
}
