import { select, call, put, takeEvery } from "redux-saga/effects";
import { LOCATION_CHANGE, push, getLocation } from "connected-react-router";
import jwt_decode from "jwt-decode";
import * as types from "./constants";
import {
  addItem,
  createItemError,
  deleteItem,
  loadItemRequest,
  loadItemError,
} from "./actions";
import Api from "../../utils/Api";
import { convertFromJR } from "../permissions/utils";
import { setCookie, deleteCookie } from "../../utils/Cookie";

function* extendUserData(jsonAuth) {
  const payload = { filter: { client_id: jsonAuth.data.id } };

  const data = yield call(Api.crewUser.show, jsonAuth.data.id);
  const {
    data: { data: permissionData },
  } = yield call(Api.permissions.index, payload);
  yield put(
    addItem({
      ...data.attributes,
      ...jsonAuth.data,
      permissions: convertFromJR(permissionData),
    })
  );
}

function* setTokenToLocalStorage(headers, json) {
  localStorage.setItem("access-token", headers["access-token"]);
  localStorage.setItem("token-type", headers["token-type"]);
  localStorage.setItem("client", headers.client);
  localStorage.setItem("expiry", headers.expiry);
  localStorage.setItem("uid", headers.uid);
  localStorage.setItem("clientId", json.data.id);

  yield call(setCookie, "uid", headers.uid);
}

function* deleteTokenFromLocalStorage() {
  yield put(deleteItem());
  yield call(deleteCookie, "uid");

  localStorage.clear();
  window.location.href = "/login";
}

function* loadItem() {
  try {
    const { data: jsonAuth } = yield call(Api.auth.checkToken);
    yield extendUserData(jsonAuth);
  } catch (e) {
    const { pathname } = yield select(getLocation);

    if (e.response && pathname !== "/login") {
      yield put(
        loadItemError({
          response: e.response, // for redirection if 401
          title: `${e.response.status} - ${e.response.statusText}`,
          message: "You should sign up to continue",
        })
      );
    }
    console.error(e);
  }
}

function* loginViaGoogle({ payload }) {
  try {
    const allPayload = {
      ...payload,
      decodedCredential: jwt_decode(payload.credential),
    };

    const { data: json, headers } = yield call(
      Api.auth.loginGoogle,
      allPayload
    );

    yield setTokenToLocalStorage(headers, json);

    yield extendUserData(json);
    yield put(push("/"));
  } catch (e) {
    console.error(e);

    if (!e.response) {
      yield put(createItemError(e));
    } else {
      yield put(
        createItemError({
          response: e.response, // for redirection if 401
          title: `${e.response.status} - ${e.response.statusText}`,
          message: e.apiErrors[0],
        })
      );
    }
  }
}

function* createAccessToken({ payload }) {
  try {
    const { data: json, headers } = yield call(Api.auth.login, payload);

    yield setTokenToLocalStorage(headers, json);

    yield extendUserData(json);
    yield put(push("/"));
  } catch (e) {
    console.error(e);

    if (!e.response) {
      yield put(createItemError(e));
    } else {
      yield put(
        createItemError({
          response: e.response, // for redirection if 401
          title: `${e.response.status} - ${e.response.statusText}`,
          message: e.apiErrors[0],
        })
      );
    }
  }
}

function* checkAuthByCurrentPath({
  payload: {
    isFirstRendering,
    location: { pathname },
  },
}) {
  try {
    if (!isFirstRendering) return;

    const isLoginPage = pathname === "/login";

    const hasHeaders =
      localStorage.getItem("access-token") &&
      localStorage.getItem("token-type") &&
      localStorage.getItem("client") &&
      localStorage.getItem("expiry") &&
      localStorage.getItem("uid") &&
      localStorage.getItem("clientId");

    if (!hasHeaders && !isLoginPage) {
      yield put(push("/login"));

      return;
    }

    if (isLoginPage && hasHeaders) yield put(push("/"));

    yield put(loadItemRequest());
  } catch (e) {
    console.error(e);
  }
}

function* handleError({ payload }) {
  if (payload.response) {
    const urlByStatus = {
      401: "/login",
      404: "/page-not-found",
      403: "/page-forbidden",
    };

    if (urlByStatus[payload.response.status]) {
      yield put(push(urlByStatus[payload.response.status]));
    }
  }
}

export default function getFeatureSagas() {
  return [
    takeEvery(LOCATION_CHANGE, checkAuthByCurrentPath),
    takeEvery(types.LOAD_ITEM_REQUEST, loadItem),
    takeEvery(types.CREATE_ITEM_REQUEST, createAccessToken),
    takeEvery(types.DELETE_ITEM_REQUEST, deleteTokenFromLocalStorage),
    takeEvery((action) => action.type.includes("_ERROR"), handleError),
    takeEvery(types.LOGIN_VIA_GOOGLE, loginViaGoogle),
  ];
}
