export default (featureName) => ({
  LOAD_ITEMS_REQUEST: `@${featureName}/LOAD_ITEMS_REQUEST`,
  ITEMS_LOADED_SUCCESSFULLY: `@${featureName}/ITEMS_LOADED_SUCCESSFULLY`,
  LOAD_ITEMS_ERROR: `@${featureName}/LOAD_ITEMS_ERROR`,

  LOAD_ITEM_REQUEST: `@${featureName}/LOAD_ITEM_REQUEST`,
  ADD_LOADED_ITEM: `@${featureName}/ADD_LOADED_ITEM`,
  LOAD_ITEM_ERROR: `@${featureName}/LOAD_ITEM_ERROR`,

  CREATE_ITEM_REQUEST: `@${featureName}/CREATE_ITEM_REQUEST`,
  ADD_CREATED_ITEM: `@${featureName}/ADD_CREATED_ITEM`,
  CREATE_ITEM_ERROR: `@${featureName}/CREATE_ITEM_ERROR`,

  UPDATE_ITEM_REQUEST: `@${featureName}/UPDATE_ITEM_REQUEST`,
  ITEM_UPDATED_SUCCESSFULLY: `@${featureName}/ITEM_UPDATED_SUCCESSFULLY`,
  UPDATE_ITEM_ERROR: `@${featureName}/UPDATE_ITEM_ERROR`,

  DELETE_ITEM_REQUEST: `@${featureName}/DELETE_ITEM_REQUEST`,
  ITEM_DELETED_SUCCESSFULLY: `@${featureName}/ITEM_DELETED_SUCCESSFULLY`,
  DELETE_ITEM_ERROR: `@${featureName}/DELETE_ITEM_ERROR`,

  CHANGE_PAGE_NUMBER: `@${featureName}/CHANGE_PAGE_NUMBER`,
  CHANGE_PAGE_ITEMS_COUNT: `@${featureName}/CHANGE_PAGE_ITEMS_COUNT`,

  CHANGE_FILTER: `@${featureName}/CHANGE_FILTER`,
  REMOVE_FILTER: `@${featureName}/REMOVE_FILTER`,
  CLEAR_FILTER: `@${featureName}/CLEAR_FILTER`,
  SELECT_ALL_FILTERS: `@${featureName}/SELECT_ALL_FILTERS`,
  APPLY_FILTER: `@${featureName}/APPLY_FILTER`,
  CANCEL_FILTER: `@${featureName}/CANCEL_FILTER`,
  OPEN_FILTER: `@${featureName}/OPEN_FILTER`,
  CLOSE_FILTER: `@${featureName}/CLOSE_FILTER`,

  CHANGE_SEARCH: `@${featureName}/CHANGE_SEARCH`,
  CLEAR_SEARCH: `@${featureName}/CLEAR_SEARCH`,
  APPLY_SEARCH: `@${featureName}/APPLY_SEARCH`,

  SORT_ITEMS: `@${featureName}/SORT_ITEMS`,
  SORT_WITH_DIRECTION: `@${featureName}/SORT_WITH_DIRECTION`,

  CHANGE_STATE: `@${featureName}/CHANGE_STATE`,
  CLEAR_STATE: `@${featureName}/CLEAR_STATE`,

  UPDATE_STATE_BY_PARAMS: `@${featureName}/UPDATE_STATE_BY_PARAMS`,
});
