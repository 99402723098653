import { call, put, takeEvery, select } from "redux-saga/effects";
import { actions, constants } from "./redux";
import selector from "./selector";
import Api from "../../utils/Api";
import { addItem as notify } from "../notifications/actions";
import { deleteItem as deleteDialog } from "../dialog-manager/actions";
import { matchPath } from "react-router-dom";
import { getLocation } from "connected-react-router";
import { fetchItems as fetchTaskItems } from "../task-manager/sagas";

export function* fetchItems({ payload: { resource, id } = {} }) {
  try {
    const {
      resource: _resource,
      id: _id,
      ...filter
    } = yield select((s) => s.comments.filter);
    if (!(resource || _resource)) return;

    const data = yield call(
      Api.comments.index,
      resource || _resource,
      id || _id,
      { ...filter, comments: true }
    );

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

export function* fetchTaskComments({
  payload: { resource, id, addressableId } = {},
}) {
  try {
    const { filter } = yield select((s) => s.comments.filter);
    
    const data = yield call(
      Api.comments.index,
      resource,
      id,
      {
        ...filter,
        addressable_id: addressableId,
        addressable_type: "TaskManager::Task",
      },
      "created_at"
    );

    yield put(actions.addItems(data));
  } catch (e) {
    yield put(actions.loadItemsError(e));

    console.error(e);
  }
}

export function* createItem() {
  try {
    const { pathname } = yield select(getLocation);
    const taskManager = matchPath(pathname, {
      path: "/task-manager/",
      exact: true,
    });

    const featureState = yield select(selector);
    const data = yield call(Api.comments.create, featureState);

    yield put(
      actions.addCreatedItem({
        ...data,
        relationship: { ...featureState.filter },
      })
    );
   
    if (taskManager) {
      const addressableId = featureState.state.addressable_id;
      const resource = featureState.filter.resource;

      yield put(actions.fetchTaskComments({resource, addressableId: addressableId }));
      yield fetchTaskItems();
    }

    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "The comment created successfully",
      })
    );
  } catch (e) {
    yield put(actions.createItemError(e));

    console.error(e);
  }
}

export function* deleteItem({ payload: id }) {
  try {
    yield call(Api.comments.delete, id);

    yield put(actions.deleteItem(id));
    yield put(deleteDialog());
    yield put(
      notify({
        type: "success",
        title: "Success",
        message: "The comment deleted successfully",
      })
    );
  } catch (e) {
    yield put(actions.deleteItemError(e));

    console.error(e);
  }
}

export function* fetchTasksForInteraction({ payload: { interactionId, cb = "" } }) {
  try {
    const { data } = yield call(Api.taskManager.tasksForInteraction, interactionId);

    yield put(actions.addTasksForInteraction(data.data));
  } catch (e) {
    console.error(e);
  } finally {
    if (cb) yield call(cb);
  }
}

/**
 * getFeatureSagas (rename)
 * each saga file should return all feature sagas
 * @returns {ForkEffect[]}
 */
export default function getFeatureSagas() {
  return [
    takeEvery(
      [constants.LOAD_ITEMS_REQUEST, "@SEAFARERS_VIEW/ADD_NEW_COMMENT_SUCCESS"],
      fetchItems
    ),
    takeEvery(constants.CREATE_ITEM_REQUEST, createItem),
    takeEvery(constants.DELETE_ITEM_REQUEST, deleteItem),
    takeEvery(constants.TASK_COMMENTS_LOAD_ITEMS_REQUEST, fetchTaskComments),
    takeEvery(
      [constants.FETCH_TASKS_FOR_INTERACTION_REQUEST],
      fetchTasksForInteraction
    ),
  ];
}
