import * as constants from './constants';

export const initialState = [];

export default function DialogsReducer(state = initialState, { payload, type }) {
  switch (type) {
    case constants.ADD_ITEM:
      return [...state, payload];

    case constants.ADD_ITEMS:
      return [...state, ...payload];

    case constants.DELETE_ITEM:
      return state.splice(0, state.length - 1);

    case constants.DELETE_ITEMS:
      return initialState;

    default:
      return state;
  }
}
