export const CREATE_ITEM_REQUEST = '@AUTH/CREATE_ITEM_REQUEST';
export const CREATE_ITEM_ERROR = '@AUTH/CREATE_ITEM_ERROR';

export const LOAD_ITEM_REQUEST = '@AUTH/LOAD_ITEM_REQUEST';
export const ADD_ITEM = '@AUTH/ADD_ITEM';
export const LOAD_ITEM_ERROR = '@AUTH/LOAD_ITEM_ERROR';

export const DELETE_ITEM_REQUEST = '@AUTH/DELETE_ITEM_REQUEST';
export const ITEM_DELETED_SUCCESSFULLY = '@AUTH/ITEM_DELETED_SUCCESSFULLY';
export const DELETE_ITEM_ERROR = '@AUTH/DELETE_ITEM_ERROR';

export const LOGIN_VIA_GOOGLE = '@AUTH/LOGIN_VIA_GOOGLE';
