import { createAction, handleActions } from "redux-actions";
import getConstantsByFeatureName from "../../utils/redux/getConstantsByFeatureName";
import getActionCreators from "../../utils/redux/getActionCreators";
import getActionHandlers from "../../utils/redux/getActionHandlers";
import getSelectorByFeatureName from "../../utils/redux/getSelectorByFeatureName";
import { initialState as baseInitState } from "../../utils/redux/commonActionHandlers";

const initialState = {
  ...baseInitState,
  experienceCoincidences: [],
};

const baseConstants = getConstantsByFeatureName("EXPERIENCE_VERIFICATIONS");
export const constants = {
  ...baseConstants,
  FETCH_EXPERIENCE_COINCIDENCES:
    "@EXPERIENCE_VERIFICATIONS/FETCH_EXPERIENCE_COINCIDENCES",
  REMOVE_EXPERIENCE_COINCIDENCES_FROM_STATE:
    "@EXPERIENCE_VERIFICATIONS/REMOVE_EXPERIENCE_COINCIDENCES_FROM_STATE",
  ADD_EXPERIENCE_COINCIDENCES:
    "@EXPERIENCE_VERIFICATIONS/ADD_EXPERIENCE_COINCIDENCES",
};

const baseActions = getActionCreators(constants);
export const actions = {
  ...baseActions,
  fetchExperienceCoincidences: createAction(
    constants.FETCH_EXPERIENCE_COINCIDENCES
  ),
  removeCoincidencesFromState: createAction(
    constants.REMOVE_EXPERIENCE_COINCIDENCES_FROM_STATE
  ),
  addExperienceCoincidences: createAction(
    constants.ADD_EXPERIENCE_COINCIDENCES
  ),
};

export const actionHandlers = getActionHandlers(constants);
export const selector = getSelectorByFeatureName("experienceVerifications");

export const reducer = handleActions(
  {
    ...actionHandlers,
    [constants.ADD_EXPERIENCE_COINCIDENCES]: (state, action) => ({
      ...state,
      experienceCoincidences: action.payload,
    }),
  },
  initialState
);
