export default {
  doc180: {
    data: {
      data: [
        {
          id: '582',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/582',
          },
          attributes: {
            type: 'Travel document',
            country: 'Ukraine',
            title: 'International Passport',
            number: 'FB 944054',
            issued: '23/11/2015',
            expires: '23/11/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(PSP)_180_(1).jpg',
            },
          ],
        },
        {
          id: '583',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/583',
          },
          attributes: {
            type: 'Travel document',
            country: 'Ukraine',
            title: "Seaman's book",
            number: 'AB 679156',
            issued: '13/05/2021',
            expires: '13/50/2026',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(SBK)_180_(1).jpg',
            },
          ],
        },
        {
          id: '4329',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/4329',
          },
          attributes: {
            type: 'Travel document',
            country: 'United States Of America',
            title: 'US Visa',
            number: 'C1/D',
            issued: '07/12/2015',
            expires: '03/12/2025',
          },
          attachments: [],
        },
        {
          id: '117114',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/117114',
          },
          attributes: {
            type: 'Travel document',
            country: 'Australia',
            title: 'Maritime Crew Visa',
            number: '2009584380820',
            issued: '11/08/2021',
            expires: '11/08/2024',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Australia_(MCV)_180_(1).pdf',
            },
          ],
        },
        {
          id: '198358',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/198358',
          },
          attributes: {
            type: 'Certificate of Competency',
            country: 'Ukraine',
            title: 'Second-class engineer',
            number: '12012/2015/07',
            issued: '13/05/2015',
            expires: '',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(2CL_ENG)_180_(1).jpg',
            },
          ],
        },
        {
          id: '198359',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/198359',
          },
          attributes: {
            type: 'Endorsement',
            country: 'Ukraine',
            title: 'Second-class engineer Endorsement',
            number: '12012/2015/07',
            issued: '12/02/2020',
            expires: '28/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(2CL_ENG_END)_180_(1).jpg',
            },
          ],
        },
        {
          id: '119197',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/119197',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Safety training and instruction for all seafarers (Basic STCW)',
            number: '58',
            issued: '03/01/2020',
            expires: '03/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(BS)_180_(1).jpg',
            },
          ],
        },
        {
          id: '119198',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/119198',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Proficiency in Survival Craft and Rescue Boats (other then Fast Rescue Boats)',
            number: '49',
            issued: '03/01/2020',
            expires: '03/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(PSUC)_180_(1).jpg',
            },
          ],
        },
        {
          id: '119201',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/119201',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Advanced Fire Fighting',
            number: '57',
            issued: '03/01/2020',
            expires: '03/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(AFF)_180_(1).jpg',
            },
          ],
        },
        {
          id: '119201',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/119201',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Medical First Aid',
            number: '58',
            issued: '03/01/2020',
            expires: '03/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(MFA)_180_(1).jpg',
            },
          ],
        },
        {
          id: '119202',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/119202',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Ship Security Officer',
            number: '20',
            issued: '03/01/2020',
            expires: '03/01/2025',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(SSO)_180_(1).jpg',
            },
          ],
        },
        {
          id: '198360',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/198360',
          },
          attributes: {
            type: 'Medical',
            country: 'Marshall Islands',
            title: 'Health Certificate',
            number: '*',
            issued: '24/05/2021',
            expires: '24/05/2023',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_MI_(HC)_180_(1).jpg',
            },
          ],
        },
        {
          id: '333314',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/333314',
          },
          attributes: {
            type: 'Medical',
            country: 'Ukraine',
            title: 'Drug & Alcohol test',
            number: '202182',
            issued: '24/05/2021',
            expires: '24/05/2022',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(D_A_TEST)_180_(1).jpg',
            },
          ],
        },
        {
          id: '198361',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/198361',
          },
          attributes: {
            type: 'Medical',
            country: 'Ukraine',
            title: 'Vaccination Against: Yellow Fever',
            number: '*',
            issued: '17/08/2007',
            expires: '17/08/2077',
          },
          attachments: [
            {
              filename: '180/Maksymykhin Andriy_Ukraine_(YF)_180_(1).jpg',
            },
          ],
        },
        {
          id: '198361',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/198361',
          },
          attributes: {
            type: 'Other',
            country: 'Ukraine',
            title: 'Civil passport',
            number: 'KA 392445',
            issued: '23/03/1997',
            expires: '',
          },
          attachments: [],
        },
      ],
      meta: { count: 15, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },

    },
  },
  doc1605: {
    data: {
      data: [
        {
          id: '4661',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/4661',
          },
          attributes: {
            type: 'Travel document',
            country: 'Ukraine',
            title: 'International Passport',
            number: 'FE 116573',
            issued: '02/02/2016',
            expires: '02/02/2026',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(PSP)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '4660',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/4660',
          },
          attributes: {
            type: 'Travel document',
            country: 'Ukraine',
            title: "Seaman's book",
            number: 'AB644674',
            issued: '14/08/2018',
            expires: '14/08/2023',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(SBK)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '61297',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/61297',
          },
          attributes: {
            type: 'Travel document',
            country: 'Marshall Islands',
            title: "Seaman's book",
            number: 'MH 1010024',
            issued: '26/06/2008',
            expires: '25/06/2018',
          },
          attachments: [

          ],
        },
        {
          id: '4916',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/4916',
          },
          attributes: {
            type: 'Travel document',
            country: 'United States Of America',
            title: 'US Visa',
            number: 'C1/D',
            issued: '13/12/2017',
            expires: '11/12/2027',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_USA_(US_VISA)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '109482',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/109482',
          },
          attributes: {
            type: 'Travel document',
            country: 'Australia',
            title: 'Maritime Crew Visa',
            number: '2009583947297',
            issued: '29/04/2020',
            expires: '29/04/2023',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Australia_(MCV)_1605_(1).pdf',
            },
          ],
        },
        {
          id: '201454',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/201454',
          },
          attributes: {
            type: 'Certificate of Competency',
            country: 'Ukraine',
            title: 'Deep sea captain',
            number: '00235/2017/03',
            issued: '10/02/2017',
            expires: '',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(DSC)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123550',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123550',
          },
          attributes: {
            type: 'Certificate of Competency',
            country: 'Ukraine',
            title: 'GMDSS (General Operator)',
            number: '00234/2017/03',
            issued: '10/02/2017',
            expires: '',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(GMDSS(O))_1605_(1).jpg',
            },
          ],
        },
        {
          id: '201455',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/201455',
          },
          attributes: {
            type: 'Endorsement',
            country: 'Ukraine',
            title: 'Deep sea captain Endorsement',
            number: '00235/2017/03',
            issued: '10/02/2017',
            expires: '08/02/2022',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(DSC_END)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '201456',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/201456',
          },
          attributes: {
            type: 'Endorsement',
            country: 'Marshall Islands',
            title: 'Deep sea captain Endorsement',
            number: '256916',
            issued: '03/09/2012',
            expires: '31/12/2016',
          },
          attachments: [
          ],
        },
        {
          id: '244368',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/244368',
          },
          attributes: {
            type: 'Endorsement',
            country: 'Ukraine',
            title: 'GMDSS Endorsement',
            number: '00234/2017/03',
            issued: '10/02/2017',
            expires: '09/02/2022',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(GMDSS_END)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123544',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123544',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Safety training and instruction for all seafarers (Basic STCW)',
            number: '34808',
            issued: '06/12/2016',
            expires: '06/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(BS)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123545',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123545',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Proficiency in Survival Craft and Rescue Boats (other then Fast Rescue Boats)',
            number: '28808',
            issued: '08/12/2016',
            expires: '08/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(BS)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123551',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123551',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Advanced Fire Fighting',
            number: '24674',
            issued: '09/12/2016',
            expires: '09/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(AFF)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123547',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123547',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Medical Care on board ship',
            number: '7236',
            issued: '16/12/2016',
            expires: '16/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(MCARE)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123552',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123552',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Ship Security Officer',
            number: '2518',
            issued: '20/07/2018',
            expires: '20/07/2023',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(SSO)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123549',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123549',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Radar Navigation - Management Level (Radar, ARPA, Bridge teamwork, search and rescue)',
            number: '5111',
            issued: '02/12/2016',
            expires: '02/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(Radar_ARPA(M))_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123546',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123546',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Carriage of Dangerous and Hazardous Substances',
            number: '0709/2018',
            issued: '16/07/2018',
            expires: '16/07/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(HAZMAT)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '123553',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/123553',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Ukraine',
            title: 'Operational Use of Electronic Chart Display and Information System (ECDIS)',
            number: '3356',
            issued: '23/12/2016',
            expires: '23/12/2021',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_Ukraine_(ECDIS)_1605_(1).jpg',
            },
          ],
        },
        {
          id: '201458',
          type: 'documents',
          links: {
            self: 'http://backend.timeplan.shipvisor.net/documents/201458',
          },
          attributes: {
            type: 'Training Certificate',
            country: 'Marshall Islands',
            title: 'Health Certificate',
            number: '*',
            issued: '05/03/2020',
            expires: '05/03/2022',
          },
          attachments: [
            {
              filename: '1605/Osipov Kostyantyn_MI_(HC)_1605_(1).jpg',
            },
          ],
        },
      ],
      meta: { count: 19, page_count: 2 },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },

    },
  },
  docEmpty: {
    data: {
      data: [],
      meta: {
        count: 0,
        page_count: 1
      },
      links: {
        first: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=1&page%5Bsize%5D=15',
        next: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
        last: 'http://qa2.planvisor.softo.net/vacancies?page%5Bnumber%5D=2&page%5Bsize%5D=15',
      },
    },
  },

};
